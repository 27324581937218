import { useNavigateToPedido } from 'hooks/useNavigateToPedido';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function usePostClonePedido() {
    const { row, setDisabledActions } = useActionMenu();
    const { idPedido } = row;
    const { navigateToPedido } = useNavigateToPedido();
    const { mutate, isLoading: isLoadingClonar } = useFormMutation();
    const { showError } = useShowError();

    const handleClone = (): void => {
        setDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/clonar?substituirRascunhoAtual=true`, preventSnack: true },
            {
                onSuccess: () => navigateToPedido(row),
                onError: (error) => {
                    setDisabledActions(false);

                    showError(error, 'Não foi possível clonar o pedido. Tente novamente');
                },
            },
        );
    };

    return { handleClone, isLoadingClonar };
}
