import { FormikHelpers } from 'formik';

import { CondicaoPagamento } from '../types';

import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';

export function useMutateCondicaoPagamento() {
    const { setContent } = useFormContext();
    const { mutate } = useFormMutation<{ data: { data: CondicaoPagamento } }>();

    const handleSubmit = (formData: CondicaoPagamento, formik: FormikHelpers<CondicaoPagamento>): void => {
        mutate(
            {
                formik,
                formData,
                url: '/gestao/condicaopagamento',
                idField: 'idCondicaoPagamento',
            },
            {
                onSuccess: (response) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    return { handleSubmit };
}
