import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useGetCobflexConfig } from 'hooks/useGetCobflexConfig';
import { useGetCobflexURL } from 'hooks/useGetCobflexURL';

export type CobflexContextData = {
    useCobflex: boolean;
    openCobflex(): void;
};

type CobflexProvider = {
    children?: ReactNode;
};

const CobflexContext = createContext<CobflexContextData>({} as CobflexContextData);

export function CobflexProvider({ children }: CobflexProvider): JSX.Element {
    const [storeUseCobflex, setStoreUseCobflex] = useState<boolean>(false);
    const [cobflexURL, setCobflexURL] = useState<string | null>('');

    /**
     * Abre o Cobflex em uma nova aba, caso possível.
     */
    const openCobflex = useCallback(() => {
        if (storeUseCobflex && cobflexURL) {
            window.open(cobflexURL, '_blank');
        }
    }, [storeUseCobflex, cobflexURL]);

    const { data: dataCobflexUrl } = useGetCobflexURL();

    const { data: dataCobflexConfig } = useGetCobflexConfig();

    useEffect(() => setCobflexURL(dataCobflexUrl || ''), [dataCobflexUrl]);

    useEffect(() => setStoreUseCobflex(dataCobflexConfig?.dsValor === 'true'), [dataCobflexConfig]);

    const contextValue = useMemo(
        () => ({
            openCobflex,
            useCobflex: Boolean(storeUseCobflex && cobflexURL),
        }),
        [storeUseCobflex, cobflexURL, openCobflex],
    );

    return <CobflexContext.Provider value={contextValue}>{children}</CobflexContext.Provider>;
}

export const useCobflexContext = (): CobflexContextData => useContext(CobflexContext);
