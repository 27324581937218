import { useEffect, useState } from 'react';

import { Box, Checkbox } from '@mui/material';

import { PagamentoPixModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { PagamentoMethodAutoComplete } from 'pages/compra/pagamento/components';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoPix() {
    const {
        pagamento,
        isPending,
        isFinishing,
        condicoesPagamentoPix,
        openPagamentoPixModal,
        setOpenPagamentoPixModal,
        selecaoMeioPagamento,
        setSelecaoMeioPagamento,
    } = usePagamentoContext();
    const [condicaoPagamentoPix, setCondicaoPagamentoPix] = useState<CondicaoPagamento | null>(null);

    /**
     * Ao alterar as condições de pagamento, seta a primeira condição de pagamento como padrão.
     */
    useEffect(() => setCondicaoPagamentoPix(condicoesPagamentoPix[0]), [condicoesPagamentoPix]);

    return (
        <>
            {pagamento?.pedidoPagamento && (
                <PagamentoPixModal pagamento={pagamento.pedidoPagamento} open={openPagamentoPixModal} setOpen={setOpenPagamentoPixModal} />
            )}

            <SubTitle label="Pix" sx={{ mb: 0 }} />

            <Box sx={{ display: 'flex', ml: -1 }}>
                <Checkbox
                    disabled={isFinishing || isPending}
                    checked={selecaoMeioPagamento?.meioPagamentoEnum === 'PIX'}
                    onChange={() =>
                        setSelecaoMeioPagamento({
                            meioPagamentoEnum: 'PIX',
                            condicaoPagamento: condicaoPagamentoPix as CondicaoPagamento,
                        })
                    }
                />

                <PagamentoMethodAutoComplete
                    value={condicaoPagamentoPix}
                    condicoesPagamento={condicoesPagamentoPix}
                    onChange={(value) => setCondicaoPagamentoPix(value as CondicaoPagamento)}
                />
            </Box>
        </>
    );
}
