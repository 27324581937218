import React, { ChangeEvent } from 'react';

import { NumberFormatValues, PatternFormat } from 'react-number-format';

import { CissTextField, CissTextFieldProps } from 'components/fields/CissTextField';

const formats = {
    cep: '#####-###',
    phone: '(##) #####-####',
    cpf: '###.###.###-##',
    cnpj: '##.###.###/####-##',
    card: '#### #### #### ####',
} as const;

type CissMaskField = CissTextFieldProps & {
    format: keyof typeof formats;
};

export function CissMaskField({ format, onChange, value, ...textField }: CissMaskField): JSX.Element {
    return (
        <CissTextField
            {...textField}
            InputProps={{
                inputComponent: PatternFormat as any,
                inputProps: {
                    value,
                    mask: '_',
                    format: formats[format],
                    onValueChange: ({ value }: NumberFormatValues): void => {
                        if (onChange) {
                            onChange({ target: { value } } as ChangeEvent<HTMLInputElement>);
                        }
                    },
                },
            }}
        />
    );
}
