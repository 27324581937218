import { Pix } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export function PagamentoPixVerifying() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                my: 'auto',
                gap: 2,
            }}
        >
            <Box sx={{ position: 'relative' }}>
                <Pix
                    sx={{
                        color: '#3ec1b2',
                        fontSize: 160,
                        position: 'relative',
                        zIndex: 1,
                    }}
                />
                <Box
                    sx={{
                        'position': 'absolute',
                        'top': 0,
                        'left': 0,
                        'right': 0,
                        'bottom': 0,
                        'borderRadius': '50%',
                        'backgroundColor': 'rgb(62, 193, 178, 0.3)',
                        'animation': 'pulse 2s infinite',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(0.8)',
                                opacity: 0.9,
                            },
                            '100%': {
                                transform: 'scale(1.8)',
                                opacity: 0,
                            },
                        },
                    }}
                />
            </Box>

            <Typography sx={{ mt: 2, fontSize: 18 }}>Verificando pagamento...</Typography>
        </Box>
    );
}
