import { Dispatch, SetStateAction } from 'react';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { FormProvider } from 'context/FormContext';
import { PagamentoCartaoCreditoModalForm } from 'pages/compra/pagamento/components';

type PagamentoCartaoCreditoModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function PagamentoCartaoCreditoModal({ open, setOpen }: PagamentoCartaoCreditoModal) {
    return (
        <ContentModal
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            setOpen={setOpen}
            sx={{
                padding: 2,
                borderRadius: 1,
                width: {
                    xs: '90vw',
                    sm: 500,
                },
            }}
        >
            <SubTitle label="Incluir Cartão de Crédito" />

            <FormProvider>
                <PagamentoCartaoCreditoModalForm />
            </FormProvider>
        </ContentModal>
    );
}
