import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn } from 'components/buttons';
import { CissNumberField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfiguracaoPedidoCompraRemoveBtn,
    ConfiguracaoPedidoCompraImportacaoUploadBtn,
    ConfiguracaoPedidoHeaderActions,
    ConfigPedidoProdutosModal,
} from 'pages/compra/configuracao-pedido/components';
import { ProdutoEstoque } from 'pages/compra/configuracao-pedido/types';
interface ProdutoPost {
    idConfigPedido: number;
    qtEstoqueAtual: number;
    fgAtivo: boolean;
    idProduto: number;
}

export function ConfiguracaoPedidoCompraImportarEstoque(): JSX.Element {
    const [selectedProducts, setSelectedProducts] = useState<ProdutoEstoque[]>([]);
    const [openProdutoModal, setOpenProdutoModal] = useState<boolean>(false);
    const [estoque, setEstoque] = useState<ProdutoEstoque[]>([]);
    const [valueFilter, setValueFilter] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isLoading } = useFormMutation();
    const { setActiveStepSaved, setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const RequestListagem = useRequestListagem();
    const { content } = useFormContext();
    const { id } = useParams();
    const { estoqueArquivo } = content;

    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedidoprodutoestoque/configpedido/${id}`,
    };

    const { isFetching } = useQuery([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), {
        onSuccess: (data) => {
            setEstoque(data);
        },
    });

    const handleExcluir = useCallback(
        (idList: number[]): void => {
            const newStock = estoque.map((produtoEstoque: ProdutoEstoque) => {
                if (idList.includes(produtoEstoque.idProduto)) {
                    produtoEstoque.fgAtivo = false;
                }

                return produtoEstoque;
            });

            setEstoque(newStock);

            setActiveStepDirty();
        },
        [estoque, setActiveStepDirty],
    );

    const handleAlterarQuantidade = useCallback(
        (valor: string, idProduto: number): void => {
            const newStock = estoque.map((produtoEstoque: ProdutoEstoque) => {
                if (produtoEstoque.idProduto === idProduto) {
                    produtoEstoque.qtEstoqueTotal = +valor;
                }
                return produtoEstoque;
            });

            setEstoque(newStock);

            setActiveStepDirty();
        },
        [estoque, setActiveStepDirty],
    );

    const handleAdicionarProdutos = useCallback((): void => {
        const existingProducts: string[] = [];
        const removeProducts: number[] = [];

        const products = selectedProducts
            .filter((produto) => {
                let isOk = true;

                if (estoque) {
                    estoque.forEach((produtoEstoque: ProdutoEstoque) => {
                        if (produto.idProduto === produtoEstoque.idProduto) {
                            if (produtoEstoque.fgAtivo) {
                                existingProducts.push(produto.dsProduto);
                                isOk = false;
                            } else {
                                removeProducts.push(produtoEstoque.idProduto);
                            }
                        }
                    });
                }

                return isOk;
            })
            .map((produto) => ({ ...produto, qtEstoqueAtual: 0 }));

        existingProducts.forEach((dsProduto) => {
            enqueueSnackbar(`O produto ${dsProduto} já existe na lista de produtos`, { variant: 'error' });
        });

        if (estoque) {
            setEstoque([
                ...(removeProducts.length
                    ? estoque.filter((produtoEstoque) => {
                          return !removeProducts.find((id) => produtoEstoque.idProduto === id);
                      })
                    : estoque),
                ...products,
            ]);
        } else {
            setEstoque(products);
        }

        setSelectedProducts([]);

        setOpenProdutoModal(false);

        setActiveStepDirty();
    }, [enqueueSnackbar, estoque, selectedProducts, setActiveStepDirty]);

    const getFormData = useMemo(
        (): ProdutoPost[] =>
            estoque.map((produtoEstoque: ProdutoEstoque) => {
                return {
                    idProduto: produtoEstoque.idProduto,
                    idConfigPedido: Number(id),
                    qtEstoqueAtual: produtoEstoque.qtEstoqueAtual || 0,
                    qtEstoqueTotal: produtoEstoque.qtEstoqueTotal || 0,
                    fgAtivo: produtoEstoque.fgAtivo,
                };
            }),
        [estoque, id],
    );

    const handleSalvar = useCallback((): void => {
        mutate(
            {
                url: '/gestao/configpedidoprodutoestoque/insertlist',
                formData: getFormData,
                preventSnack: true,
            },
            {
                onSuccess: (response: any) => {
                    setEstoque(response.data);
                    
                    enqueueSnackbar('Configuração de estoque salva com sucesso', { variant: 'success' });

                    setActiveStepSaved();
                },
                onError: () => {
                    enqueueSnackbar('Falha ao salvar a configuração de estoque', { variant: 'error' });
                },
            },
        );
    }, [enqueueSnackbar, getFormData, mutate, setActiveStepSaved]);

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'cdProduto', headerName: 'Código', minWidth: 130 },
            { field: 'dsProduto', headerName: 'Descrição', flex: 2, minWidth: 120 },
            {
                field: 'qtEstoqueTotal',
                headerName: 'Quantidade Total',
                minWidth: 150,
                flex: 1,
                renderCell: ({ row }) => (
                    <CissNumberField
                        allowNegative={false}
                        inputType={row.fgFracionavel ? 'decimal' : 'number'}
                        value={row.qtEstoqueTotal}
                        onChange={(e) => handleAlterarQuantidade(e.target.value, row.id)}
                        size="small"
                    />
                ),
            },
            { field: 'qtEstoqueAtual', headerName: 'Quantidade Disponível', minWidth: 90 },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => <ConfiguracaoPedidoCompraRemoveBtn onClick={() => handleExcluir([row.id])} />,
            },
        ],
        [handleAlterarQuantidade, handleExcluir],
    );

    const filterEstoque = useCallback(
        () =>
            estoque.filter((item) => {
                const { dsProduto, cdProduto, fgAtivo } = item;

                return fgAtivo && (!valueFilter || dsProduto.toLowerCase().includes(valueFilter.trim().toLowerCase()) || cdProduto.toString().includes(valueFilter.trim().toLowerCase()));
            }),
        [estoque, valueFilter],
    );

    const mapEstoque = useCallback(
        (produtoEstoque: ProdutoEstoque) => ({
            id: produtoEstoque.idProduto,
            cdProduto: produtoEstoque.cdProduto,
            dsProduto: produtoEstoque.dsProduto,
            qtEstoqueAtual: produtoEstoque.qtEstoqueAtual,
            qtEstoqueTotal: produtoEstoque.qtEstoqueTotal,
            fgFracionavel: produtoEstoque.produto?.unidadeMedida?.fgFracionavel ?? produtoEstoque.fgFracionavel ?? false,
        }),
        [],
    );

    const makeRows = useCallback((): GridRowsProp => {
        if (Array.isArray(estoque) && estoque.length) {
            return filterEstoque().map(mapEstoque);
        }

        return [];
    }, [estoque, filterEstoque, mapEstoque]);

    const rows = makeRows();

    useEffect(() => {
        if (estoqueArquivo) {
            const inactiveStock = estoque
                .filter((produtoEstoque) => {
                    return !estoqueArquivo.find((produtoEstoqueArquivo: ProdutoEstoque) => produtoEstoque.idProduto === produtoEstoqueArquivo.idProduto);
                })
                .map((produtoEstoque) => ({ ...produtoEstoque, fgAtivo: false }));

            setEstoque([...inactiveStock, ...estoqueArquivo]);
        }
    }, [estoqueArquivo]);

    return (
        <React.Fragment>
            <FullLoading loading={isLoading} />

            <ConfigPedidoProdutosModal
                open={openProdutoModal}
                setOpen={setOpenProdutoModal}
                handleAdicionarProdutos={handleAdicionarProdutos}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            />

            <Block>
                <SubTitle label="Ações" />

                <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ConfiguracaoPedidoCompraImportacaoUploadBtn contentListName="estoqueArquivo" url={'gestao/configpedidoprodutoestoque/upload/'} onSend={() => setActiveStepDirty()} />
                    </Grid>
                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_ESTOQUE" />
                    </Grid>
                </Grid>
            </Block>

            <Block>
                <SubTitle label={'Produtos'} />

                <ConfiguracaoPedidoHeaderActions
                    setValueFilter={setValueFilter}
                    selectedRows={selectedRows}
                    searchPlaceholder="Código ou nome do produto..."
                    onClickAdd={() => setOpenProdutoModal(true)}
                    onClickRemove={() => handleExcluir(selectedRows)}
                />

                <PanelGrid
                    rows={rows}
                    rowsCount={rows.length}
                    columns={columns}
                    loading={isFetching}
                    reload={false}
                    remote={false}
                    checkboxSelection
                    onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
                />
            </Block>

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <LoadingButton variant="contained" loading={isLoading} disabled={isLoading} size="large" onClick={() => handleSalvar()}>
                    Salvar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}
