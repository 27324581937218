import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Autocomplete, Box, SxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';

import { CissTextField } from 'components/fields/';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export interface CissAutoCompleteProps {
    label?: string;
    value: unknown;
    selectedStringTemplate?: string;
    optionStringTemplate?: string;
    sx?: SxProps;
    error?: string;
    multiple?: boolean;
    size?: 'small' | 'medium' | undefined;
    disabled?: boolean;
    options?: Array<string | number | object | boolean>;
    requestOptions?: RequestOptionsType | null;
    objectId?: string;
    onChange: (e: SyntheticEvent<Element, Event>, value: any) => void;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
    disableCloseOnSelect?: boolean;
    renderTags?: (value: any[], getTagProps: any) => JSX.Element[];
    event?: any;
    name?: string;
    queryKey?: string;
    getOptionString?: (option: any) => string;
    getSelectedString?: (option: any) => string;
    disableClearable?: boolean;
    getOptionDisabled?: (option: any) => boolean;
    readOnly?: boolean;
}

export const CissAutoComplete = ({
    label,
    value,
    error = '',
    multiple = true,
    selectedStringTemplate = '',
    optionStringTemplate = '',
    options = [],
    requestOptions = null,
    sx,
    size = 'medium',
    disabled = false,
    objectId = '',
    onChange = () => {},
    isOptionEqualToValue = () => false,
    disableCloseOnSelect,
    renderTags,
    queryKey,
    name,
    getOptionString,
    getSelectedString,
    getOptionDisabled,
    disableClearable = false,
    readOnly,
}: CissAutoCompleteProps): JSX.Element => {
    const RequestListagem = useRequestListagem();
    const [query, setQuery] = useState<string>('');
    const [queryValue] = useDebounce(query, 500);
    const [internalError, setInternalError] = useState<string>('');
    const regexGetKeyColumn = useRef(/({[\w.]{1,}})/g);
    const regexRemoveBraces = useRef(/[{}]+/g);

    if (requestOptions) {
        requestOptions.limit = requestOptions.limit || 50;
        requestOptions.query = queryValue;
    }

    const { isLoading, fetchStatus, data } = useQuery(
        [queryKey, requestOptions],
        () => {
            if (requestOptions) {
                return RequestListagem(requestOptions).then((res) => res.data);
            }
        },
        {
            enabled: requestOptions !== null,
            onError: (err: any) => {
                setInternalError(err.data?.message || '');
            },
            onSuccess: () => setInternalError(''),
        },
    );

    const getOptionStringByTemplate = (option: any): string => {
        if (!optionStringTemplate) return option;
        return optionStringTemplate.replace(regexGetKeyColumn.current, (match): string => {
            const keyColumn = match.replace(regexRemoveBraces.current, '');
            if (keyColumn.includes('.')) {
                let value = option;
                keyColumn.split('.').forEach((key) => (value = value[key]));
                return value;
            }

            const key = keyColumn as keyof typeof option;
            const value = option[key] ?? match;
            return value;
        });
    };

    const getSelectedStringByTemplate = (option: any): string => {
        if (!selectedStringTemplate) return option;
        return selectedStringTemplate.replace(regexGetKeyColumn.current, (match): string => {
            const keyColumn = match.replace(regexRemoveBraces.current, '');
            if (keyColumn.includes('.')) {
                let value = option;
                keyColumn.split('.').forEach((key) => (value = value[key]));
                return value;
            }

            const key = keyColumn as keyof typeof option;
            const value = option[key] ?? match;
            return value;
        });
    };

    return (
        <Autocomplete
            sx={sx}
            value={!multiple && (!value || !Object.keys(value as object).length) ? null : value}
            multiple={multiple}
            loading={isLoading || fetchStatus !== 'idle'}
            options={requestOptions ? data?.data || [] : options}
            loadingText={requestOptions ? 'Carregando...' : 'Sem opções'}
            getOptionLabel={(option) => (getSelectedString ? getSelectedString(option) : getSelectedStringByTemplate(option))}
            renderOption={(props, option: any) => {
                if (objectId) {
                    const key = option[objectId];
                    return (
                        <Box component="li" {...props} key={key}>
                            <div
                                dangerouslySetInnerHTML={{ __html: getOptionString ? getOptionString(option) : getOptionStringByTemplate(option) }}
                            ></div>
                        </Box>
                    );
                } else {
                    return (
                        <Box component="li" {...props}>
                            <div
                                dangerouslySetInnerHTML={{ __html: getOptionString ? getOptionString(option) : getOptionStringByTemplate(option) }}
                            ></div>
                        </Box>
                    );
                }
            }}
            noOptionsText={'Sem opções'}
            isOptionEqualToValue={isOptionEqualToValue}
            onSelect={() => setQuery('')}
            onChange={onChange}
            disabled={disabled}
            size={size}
            limitTags={1}
            disableCloseOnSelect={disableCloseOnSelect ?? multiple}
            renderInput={(params) => (
                <CissTextField {...params} name={name} label={label} onChange={(e) => setQuery(e.target.value)} error={internalError || error} />
            )}
            renderTags={renderTags}
            disableClearable={disableClearable}
            getOptionDisabled={getOptionDisabled}
            readOnly={readOnly}
        />
    );
};
