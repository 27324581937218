import { FormControlLabel, Switch } from '@mui/material';

import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoPromocaoSwitch() {
    const { filterPromocao, setFilterPromocao } = usePedidoFilterContext();

    return (
        <FormControlLabel
            label="Somente produtos em promoção"
            control={
                <Switch
                    checked={filterPromocao.value}
                    onChange={(e, checked) => setFilterPromocao((oldState: FilterValuesProps) => ({ ...oldState, value: checked || null }))}
                />
            }
        />
    );
}
