import React, { useCallback, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { FormikHelpers, FormikProps } from 'formik';
import { useFormMutation } from 'mutations/useFormMutation';
import { Marcador } from 'pages/calendario/evento/marcadores/types';
import { ChromePicker } from 'react-color';
import * as Yup from 'yup';
import { useFormContext } from 'context/FormContext';

export function EventoMarcadoresFormComp(): JSX.Element {
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const { mutate } = useFormMutation();
    const { setContent } = useFormContext();
    const url = '/gestao/calendarioeventotag';

    const initialValues: Marcador = {
        idCalendarioEventoTag: 0,
        dsCalendarioEventoTag: '',
        dsCorTag: '#000000',
    };

    const validationSchema: any = useMemo(
        () =>
            Yup.object({
                dsCalendarioEventoTag: Yup.string().max(30).required(),
                dsCorTag: Yup.string().required(),
            }),
        [],
    );

    const handleSubmit = useCallback(
        (values: Marcador, formik: FormikHelpers<Marcador>): void => {
            mutate(
                {
                    url,
                    formik,
                    idField: 'idCalendarioEventoTag',
                    formData: values,
                },
                {
                    onSuccess: (response: any) => {
                        const { data } = response.data;

                        if (data) {
                            setContent(data);
                        }
                    },
                },
            );
        },
        [mutate, setContent],
    );

    return (
        <FormPage title="Marcadores" values={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} url={url}>
            {(formik: FormikProps<Marcador>) => (
                <Block>
                    <Row>
                        <Grid item flex={1}>
                            <CissTextField
                                label="Nome do marcador"
                                name="dsCalendarioEventoTag"
                                value={formik.values.dsCalendarioEventoTag}
                                onChange={(e) => formik.setFieldValue('dsCalendarioEventoTag', e.target.value)}
                                error={formik.errors.dsCalendarioEventoTag}
                            />
                        </Grid>

                        <Grid item flex={1}>
                            <Typography>
                                <strong>Clique para selecionar uma cor padrão para o marcador:</strong>
                            </Typography>
                            <div onClick={() => setPickerVisible((oldState) => !oldState)} style={{ backgroundColor: formik.values.dsCorTag, width: 25, height: 25, borderRadius: '100%' }} />
                            {pickerVisible && (
                                <React.Fragment>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px',
                                        }}
                                        onClick={() => setPickerVisible((oldState) => !oldState)}
                                    />
                                    <div style={{ position: 'absolute', zIndex: 2 }}>
                                        <ChromePicker color={formik.values.dsCorTag} onChange={({ hex }: any) => formik.setFieldValue('dsCorTag', hex)} />
                                    </div>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Row>
                </Block>
            )}
        </FormPage>
    );
}
