import { createSearchParams, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

import { ContentModal } from 'components/modal';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useCarrinhoContext } from 'pages/compra/carrinho/contexts';

export function CarrinhoFinishSuccessModal() {
    const { labelFinishedSuccessModal, openFinishedSuccessModal, setOpenFinishedSuccessModal } = useCarrinhoContext();
    const { hasPermission } = usePermissionsContext();
    const canRedirect = hasPermission(['COMPRA_PEDIDO_LISTAGEM_VISUALIZAR']);
    const navigate = useNavigate();

    /**
     * Navega para a tela de pedido realizado.
     */
    const navigateToPedidoRealizado = () =>
        navigate({
            pathname: '/compra/pedido-realizado',
            search: createSearchParams({
                idUsuarioPedido: 'null',
                dhInclusao: JSON.stringify({
                    startDate: new Date(),
                    endDate: new Date(),
                }),
            }).toString(),
        });

    /**
     * Confirma a ação do modal. Ou redireciona para a tela de pedido realizado ou fecha o modal.
     */
    const confirmModal = () => {
        if (canRedirect) {
            navigateToPedidoRealizado();
        } else {
            setOpenFinishedSuccessModal(false);
        }
    };

    return (
        <ContentModal
            open={openFinishedSuccessModal}
            setOpen={setOpenFinishedSuccessModal}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 1,
                minWidth: { xs: '90dvw', sm: 360 },
            }}
        >
            <Typography variant="h6">{labelFinishedSuccessModal.current}</Typography>

            {canRedirect && <Typography sx={{ color: 'grey.600' }}>Deseja visualizar os últimos pedidos finalizados?</Typography>}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                {canRedirect && (
                    <Button variant="text" onClick={() => setOpenFinishedSuccessModal(false)}>
                        Não
                    </Button>
                )}

                <Button variant="text" onClick={confirmModal}>
                    {canRedirect ? 'Sim' : 'Ok'}
                </Button>
            </Box>
        </ContentModal>
    );
}
