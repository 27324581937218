import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Button, Typography } from '@mui/material';

import { Block } from 'components/page';
import { useCobflexContext } from 'context/CobflexContext';
import { useTituloContext } from 'context/TituloContext';
import { TituloExportarRelatorioBtn } from 'pages/financeiro/titulo/components/atoms/TituloExportarRelatorioBtn';

export function TituloRelatorio(): JSX.Element {
    const { hasTitulos } = useTituloContext();
    const { useCobflex, openCobflex } = useCobflexContext();

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                flexDirection: { xs: 'column', md: 'row' },
                marginTop: { xs: 2, lg: 0 },
            }}
        >
            {useCobflex && (
                <Block
                    sx={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'space-between',
                        flex: 1,
                        gap: 2,
                        margin: 0,
                        alignItems: { xs: 'stretch', sm: 'center' },
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    <Typography>Sou optante pelo Cobflex</Typography>

                    <Button variant="contained" onClick={openCobflex}>
                        Acessar o cobflex
                    </Button>
                </Block>
            )}

            <Block
                sx={{
                    display: 'flex',
                    flex: 1,
                    gap: 2,
                    margin: 0,
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TableViewOutlinedIcon color="primary" />

                    <Typography>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                    <ChevronRightOutlinedIcon color="primary" />
                </Box>

                <TituloExportarRelatorioBtn
                    disabled={!hasTitulos}
                    label={'Relatório em .xlsx'}
                    sx={{ color: 'green', borderColor: 'green', width: { xs: '100%', sm: 'auto' } }}
                />
            </Block>
        </Box>
    );
}
