import { useState } from 'react';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HistoryIcon from '@mui/icons-material/History';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { ConfirmModal } from 'components/modal';
import { PedidoLogModal } from 'components/modal/PedidoLogModal';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useNavigateToPedido } from 'hooks/useNavigateToPedido';
import { useCarrinhoContext } from 'pages/compra/carrinho/contexts';
import { useDeletePedido, useFinishPedido } from 'pages/compra/carrinho/hooks';

interface CarrinhoActionColumn {
    row: GridRowModel;
}

export function CarrinhoActionColumn({ row }: CarrinhoActionColumn) {
    const { hasPermission } = usePermissionsContext();
    const { navigateToPedido } = useNavigateToPedido();
    const { disabledActions } = useCarrinhoContext();
    const { handleDelete } = useDeletePedido();
    const { finishPedido } = useFinishPedido({ row });

    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);
    const [openLogModal, setOpenLogModal] = useState<boolean>(false);

    return (
        <>
            <PedidoLogModal open={openLogModal} setOpen={setOpenLogModal} idPedido={row.idPedido} />

            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={() => handleDelete(row.id)} />

            <ConfirmModal
                open={openFinishModal}
                setOpen={setOpenFinishModal}
                confirm={finishPedido}
                title="Finalizar pedido"
                text="Tem certeza que deseja finalizar o pedido selecionado?"
            />

            <Box sx={{ diplay: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Editar">
                    <IconButton disabled={disabledActions} onClick={() => navigateToPedido(row)}>
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Remover">
                    <IconButton disabled={disabledActions} onClick={() => setOpenConfirmModal(true)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>

                {hasPermission(['COMPRA_PEDIDO_FINALIZAR']) && (
                    <Tooltip title="Finalizar">
                        <IconButton disabled={disabledActions} onClick={() => setOpenFinishModal(true)}>
                            <CheckCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Logs">
                    <IconButton disabled={disabledActions} onClick={() => setOpenLogModal(true)}>
                        <HistoryIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    );
}
