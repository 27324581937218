import React, { ComponentProps } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { useListagemContext } from 'context/ListagemContext';
import { useDreListContext } from 'pages/financeiro/dre/contexts/DreListContext';
import { useDreFilterLojas } from 'pages/financeiro/dre/hooks';
import { findValueFilter, getDsLojaReport } from 'pages/financeiro/dre/utils';

type DreReportnBtn = {
    text: string;
    pathname: string;
    color: ComponentProps<typeof Button>['color'];
};

export function DreReportBtn({ text, pathname, color }: DreReportnBtn): JSX.Element {
    const { hasDre } = useDreListContext();
    const { filter } = useListagemContext();
    const filterLojas = useDreFilterLojas();
    const navigate = useNavigate();

    const handleClickDownload = (): void => {
        const idLoja = findValueFilter(filter, 'idLoja');
        const nrAno = findValueFilter(filter, 'nrAno');
        const dsLoja = getDsLojaReport(filterLojas);

        navigate({
            pathname,
            search: `?${createSearchParams({
                idLoja,
                dsLoja,
                nrAno,
            })}`,
        });
    };

    return (
        <Button variant="outlined" color={color} sx={{ width: { xs: '100%', md: 'auto' } }} onClick={handleClickDownload} disabled={!hasDre}>
            {text}
        </Button>
    );
}
