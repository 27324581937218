import React, { useState, useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, CircularProgress, Divider, Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Row } from 'components/form';
import { Centered } from 'components/grid';
import { FullLoading } from 'components/loading';
import { OneColumn } from 'components/page';
import { SubTitle } from 'components/text';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFocusErrorFormik } from 'hooks/useFocusErrorFormik';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { DreFormAsideMenu, DreFormBbarInfo, DreFormField, DreFormGroup, DreLoadF360Btn } from 'pages/financeiro/dre/components';
import {
    DreMercadologicaCustosProps,
    DreMercadologicaGrupoExibicaoProps,
    initialDrePageValues,
    RequestDrePageProps,
} from 'pages/financeiro/dre/types';
import { getErrorMessage } from 'util/error';
import { brlPrice } from 'util/format';

interface CreateCustosGroupProps {
    [key: string]: {
        id: number;
        group: DreMercadologicaGrupoExibicaoProps;
        fields: DreMercadologicaCustosProps[];
    };
}

let tabIndexKey = 0;

export function DreFormComp(): JSX.Element {
    const { mutate } = useFormMutation<RequestDrePageProps, AxiosError>();
    const { mutate: saveDre } = useFormMutation<RequestDrePageProps>();
    const RequestListagem = useRequestListagem();
    const [formData, setFormData] = useState(initialDrePageValues);
    const [formRequestStatus, setFormRequestStatus] = useState<number>();
    const [salvando, setSalvando] = useState(false);
    const { id } = useParams();
    const { showError } = useShowError();
    const navigate = useNavigate();
    const { hasPermission } = usePermissionsContext();
    const { focusError } = useFocusErrorFormik();
    const url = '/dre/dremercadologica';

    const handleSubmit = (finalizar: boolean, formik: FormikHelpers<any>): void => {
        const submitUrl = finalizar ? `${url}/finaliza` : url; // se estiver finalizando o sufixo da url é finaliza
        const method = finalizar ? 'POST' : 'PUT'; // se estiver finalizando o verbo é POST

        setSalvando(true);

        saveDre(
            {
                url: `${submitUrl}/${formData.idDreMercadologica}`,
                method,
                formik,
                formData,
                preventRedirect: true,
            },
            {
                onSuccess: (response) => {
                    const { data } = response.data;

                    if (finalizar) {
                        navigate(-1);
                    } else if (data) {
                        setFormData(data);
                    }
                },
                onSettled: () => {
                    setSalvando(false);
                },
            },
        );
    };

    const { isLoading, fetchStatus, isError, error } = useQuery<RequestDrePageProps>(
        ['getDre'],
        () =>
            RequestListagem({
                url: `${url}/${id}`,
            }),
        {
            onSuccess: (response) => {
                const { data, status } = response;

                setFormData(data?.data);
                setFormRequestStatus(status);
            },
        },
    );

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dreMercadologicaVendas: Yup.array().of(
                    Yup.object().shape({
                        vlTotal: Yup.number().required(),
                    }),
                ),
                dreMercadologicaCustos: Yup.array().of(
                    Yup.object().shape({
                        vlTotal: Yup.number().required(),
                    }),
                ),
                dreMercadologicaDespesas: Yup.array().of(
                    Yup.object().shape({
                        vlTotal: Yup.number().required(),
                    }),
                ),
                dreMercadologicaDespesasVariaveis: Yup.array().of(
                    Yup.object().shape({
                        dsDespesa: Yup.string(),
                        vlTotal: Yup.number().required(),
                    }),
                ),
                dreMercadologicaImpostos: Yup.array().of(
                    Yup.object().shape({
                        vlTotal: Yup.number().required(),
                    }),
                ),
            }),
        [],
    );

    const handleRecalcular = async (): Promise<void> => {
        setSalvando(true);

        return validationSchema
            .validate(formData)
            .then(() => {
                return mutate(
                    {
                        url: `${url}/recalcula`,
                        formData,
                        preventSnack: true,
                    },
                    {
                        onSuccess: (response) => {
                            const { data } = response.data;

                            setFormData(data);
                        },
                        onError: (error) => {
                            showError(error, 'Ocorreu um erro ao fazer o recalculo automático dos valores.');
                        },
                        onSettled: () => {
                            setSalvando(false);
                        },
                    },
                );
            })
            .catch(() => {
                setSalvando(false);
            });
    };

    const [errorRequest] = useMemo(() => getErrorMessage(error), [error]);

    // salvo durante loop dos fields o subgrupo atual, para identificar quando adicionar o subtitulo nos blocos
    let actualSubGroup: string;

    // para custos, agrupamos os itens na linha pela dezena correspondente (10, 11, 12, 13 = tudo mesma linha, 20, 21, 22 nova linha)
    const createCustosGroup = useCallback((custos: DreMercadologicaCustosProps[]): CreateCustosGroupProps => {
        const groups: CreateCustosGroupProps = {};

        let previusRow = 0;

        custos.forEach((field, index) => {
            const row = Math.floor(field.dreMercadologicaConfigCusto.nrSequencia / 10);

            if (previusRow !== row) {
                previusRow = row;

                groups[`row_${previusRow}`] = {
                    id: field.idDreMercadologicaCusto,
                    group: field.dreMercadologicaConfigCusto.dreMercadologicaGrupoExibicao,
                    fields: [{ ...field, index }],
                };
            } else {
                groups[`row_${previusRow}`]?.fields.push({ ...field, index });
            }
        });

        return groups;
    }, []);

    const getTabIndex = (): number => {
        tabIndexKey += 1;
        return tabIndexKey;
    };

    return (
        <OneColumn
            title="Preenchimento de Estimativa de Resultado"
            goBackButton="/financeiro/dre"
            extraContent={
                <DreLoadF360Btn formData={formData} setFormData={setFormData} setFormRequestStatus={setFormRequestStatus} setSalvando={setSalvando} />
            }
        >
            <Formik initialValues={{}} onSubmit={() => {}}>
                {(formik: any) => {
                    focusError(formik.errors);

                    return (
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <DreFormAsideMenu />
                            </Grid>

                            <Grid item md={9} xs={12}>
                                <FullLoading loading={salvando} />

                                {isLoading && fetchStatus === 'fetching' && (
                                    <Centered>
                                        <CircularProgress />
                                    </Centered>
                                )}

                                {isError && errorRequest && <Alert severity="error">{errorRequest}</Alert>}

                                {formRequestStatus === 204 && (
                                    <Alert severity="info">
                                        Estimativa de resultado para o mês e empresa escolhidos ainda não está disponivel para preenchimento
                                    </Alert>
                                )}

                                {formRequestStatus === 200 && (
                                    <React.Fragment>
                                        {Boolean(formData.dreMercadologicaVendas.length) && (
                                            <DreFormGroup label="Vendas" id="1">
                                                {formData.dreMercadologicaVendas.map((field, index) => {
                                                    const previusSubGroup = actualSubGroup;
                                                    const subGroupInfo = field.dreMercadologicaConfigVenda.dreMercadologicaGrupoExibicao;

                                                    actualSubGroup = subGroupInfo.dsDreMercadologicaGrupoExibicao;

                                                    return (
                                                        <React.Fragment key={field.idDreMercadologicaVenda}>
                                                            {previusSubGroup !== actualSubGroup && (
                                                                <SubTitle
                                                                    id={`1_${subGroupInfo.idDreMercadologicaGrupoExibicao}`}
                                                                    label={
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <strong>{actualSubGroup}</strong>
                                                                            <Box sx={{ ml: 2 }}>{brlPrice(subGroupInfo.vlTotal)}</Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            )}

                                                            <Row>
                                                                <DreFormField
                                                                    name={`dreMercadologicaVendas[${index}].vlTotal`}
                                                                    error={formik.errors[`dreMercadologicaVendas[${index}].vlTotal`]}
                                                                    fieldLabel={field.dreMercadologicaConfigVenda.dsNomeExibicao}
                                                                    fieldDisabled
                                                                    fieldValue={field.vlTotal}
                                                                />

                                                                <DreFormField
                                                                    name={`dreMercadologicaVendas[${index}].vlAjuste`}
                                                                    error={formik.errors[`dreMercadologicaVendas[${index}].vlAjuste`]}
                                                                    fieldLabel="Valor ajuste"
                                                                    fieldValue={field.vlAjuste}
                                                                    fieldChange={(newValue) => {
                                                                        setFormData((oldState) => {
                                                                            oldState.dreMercadologicaVendas[index].vlAjuste = Number(newValue);

                                                                            return oldState;
                                                                        });
                                                                    }}
                                                                    onBlur={() => handleRecalcular()}
                                                                    sx={{ display: 'none' }}
                                                                    tabIndex={getTabIndex()}
                                                                />

                                                                <DreFormField
                                                                    name={`dreMercadologicaVendas[${index}].vlTotalAjustado`}
                                                                    error={formik.errors[`dreMercadologicaVendas[${index}].vlTotalAjustado`]}
                                                                    fieldLabel="Valor total"
                                                                    fieldValue={field.vlTotalAjustado}
                                                                    fieldDisabled
                                                                />

                                                                <DreFormField
                                                                    fieldLabel="Justificativa"
                                                                    name={`dreMercadologicaVendas[${index}].dsJustificativaAjuste`}
                                                                    error={formik.errors[`dreMercadologicaVendas[${index}].dsJustificativaAjuste`]}
                                                                    fieldValue={field.dsJustificativaAjuste}
                                                                    fieldType="text"
                                                                    tooltip={field.dreMercadologicaConfigVenda.dsObservacao}
                                                                    fieldChange={(newValue) =>
                                                                        setFormData((oldState) => {
                                                                            oldState.dreMercadologicaVendas[index].dsJustificativaAjuste = newValue;

                                                                            return oldState;
                                                                        })
                                                                    }
                                                                    sx={{ display: 'none' }}
                                                                    tabIndex={getTabIndex()}
                                                                />
                                                            </Row>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </DreFormGroup>
                                        )}

                                        {Boolean(formData.dreMercadologicaCustos.length) && (
                                            <DreFormGroup label="Custos" id="2">
                                                {Object.entries(createCustosGroup(formData.dreMercadologicaCustos)).map(([key, rowCustos]) => {
                                                    const previusSubGroup = actualSubGroup;
                                                    const subGroupInfo = rowCustos.group;

                                                    actualSubGroup = subGroupInfo.dsDreMercadologicaGrupoExibicao;

                                                    return (
                                                        <React.Fragment key={rowCustos.id}>
                                                            {previusSubGroup !== actualSubGroup && (
                                                                <SubTitle
                                                                    id={`2_${subGroupInfo.idDreMercadologicaGrupoExibicao}`}
                                                                    label={<strong>{actualSubGroup}</strong>}
                                                                />
                                                            )}

                                                            <Row>
                                                                {rowCustos.fields.map((field) => {
                                                                    const dreDescricao =
                                                                        field.dreMercadologicaConfigCusto.dsObservacao || 'Não informado.';
                                                                    const dreFormula =
                                                                        field.dreMercadologicaConfigCusto.dsValorConfig || 'Não informado.';

                                                                    return (
                                                                        <DreFormField
                                                                            key={`2_field_${field.idDreMercadologicaCusto}`}
                                                                            name={`dreMercadologicaCustos[${field.index}].vlTotal`}
                                                                            error={formik.errors[`dreMercadologicaCustos[${field.index}].vlTotal`]}
                                                                            fieldLabel={field.dreMercadologicaConfigCusto.dsNomeExibicao}
                                                                            fieldValue={field.vlTotal}
                                                                            tooltip={'Clique para saber mais'}
                                                                            modalTooltip={{
                                                                                titulo: field.dreMercadologicaConfigCusto.dsNomeExibicao,
                                                                                descricao: `Descrição: ${dreDescricao}\nFórmula: ${dreFormula}`,
                                                                            }}
                                                                            fieldDisabled={
                                                                                field.dreMercadologicaConfigCusto.dreTipoPreenchimento
                                                                                    .idDreTipoPreenchimento === 1
                                                                            }
                                                                            fieldChange={(newValue) => {
                                                                                setFormData((oldState) => {
                                                                                    oldState.dreMercadologicaCustos[field.index].vlTotal =
                                                                                        Number(newValue);

                                                                                    return oldState;
                                                                                });
                                                                            }}
                                                                            onBlur={() => handleRecalcular()}
                                                                            tabIndex={getTabIndex()}
                                                                        />
                                                                    );
                                                                })}
                                                            </Row>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </DreFormGroup>
                                        )}

                                        {Boolean(formData.dreMercadologicaDespesas.length) && (
                                            <DreFormGroup label="Despesas" id="3">
                                                {formData.dreMercadologicaDespesas.map((field, index) => {
                                                    const previusSubGroup = actualSubGroup;
                                                    const subGroupInfo = field.dreMercadologicaConfigDespesa.dreMercadologicaGrupoExibicao;
                                                    const isFormula = field.dreMercadologicaConfigDespesa.dreTipoPreenchimento.idDreTipoPreenchimento === 1;

                                                    actualSubGroup = subGroupInfo.dsDreMercadologicaGrupoExibicao;

                                                    return (
                                                        <React.Fragment key={field.idDreMercadologicaDespesa}>
                                                            {previusSubGroup !== actualSubGroup && (
                                                                <SubTitle
                                                                    id={`3_${subGroupInfo.idDreMercadologicaGrupoExibicao}`}
                                                                    label={
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <strong>{actualSubGroup}</strong>
                                                                            <Box sx={{ ml: 2 }}>{brlPrice(subGroupInfo.vlTotal)}</Box>
                                                                        </Box>
                                                                    }
                                                                />
                                                            )}

                                                            <Row>
                                                                <DreFormField
                                                                    allowNegative
                                                                    name={`dreMercadologicaDespesas[${index}].vlTotal`}
                                                                    error={formik.errors[`dreMercadologicaDespesas[${index}].vlTotal`]}
                                                                    fieldLabel={field.dreMercadologicaConfigDespesa?.dsNomeExibicao}
                                                                    fieldValue={field.vlTotal}
                                                                    tooltip={field.dreMercadologicaConfigDespesa.dsObservacao}
                                                                    checkboxValue={field.fgRecorrente}
                                                                    checkboxChange={(checked) => {
                                                                        setFormData((oldState) => {
                                                                            oldState.dreMercadologicaDespesas[index].fgRecorrente = checked;

                                                                            return oldState;
                                                                        });
                                                                    }}
                                                                    fieldChange={(newValue) => {
                                                                        setFormData((oldState) => {
                                                                            oldState.dreMercadologicaDespesas[index].vlTotal = Number(newValue);

                                                                            return oldState;
                                                                        });
                                                                    }}
                                                                    onBlur={() => handleRecalcular()}
                                                                    tabIndex={getTabIndex()}
                                                                    fieldDisabled={isFormula}
                                                                    checkboxDisabled={isFormula}
                                                                />
                                                            </Row>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </DreFormGroup>
                                        )}

                                        {Boolean(formData.dreMercadologicaDespesas.length) && (
                                            <DreFormGroup label="Despesas Extras" id="4">
                                                <Box sx={{ 'mb': 2, '> .MuiDivider-root:last-of-type': { display: 'none' } }}>
                                                    {formData.dreMercadologicaDespesasVariaveis.map((field, index) => {
                                                        return (
                                                            <React.Fragment key={field.idDreMercadologicaDespesaVariavel}>
                                                                <Row
                                                                    item
                                                                    sx={{
                                                                        flexDirection: {
                                                                            xs: 'column',
                                                                            md: 'row',
                                                                        },
                                                                    }}
                                                                >
                                                                    <DreFormField
                                                                        fieldLabel="Nome Despesa"
                                                                        name={`dreMercadologicaDespesasVariaveis[${index}].dsDespesa`}
                                                                        error={formik.errors[`dreMercadologicaDespesasVariaveis[${index}].dsDespesa`]}
                                                                        fieldType="text"
                                                                        fieldValue={field.dsDespesa}
                                                                        fieldChange={(newValue) =>
                                                                            setFormData((oldState) => {
                                                                                oldState.dreMercadologicaDespesasVariaveis[index].dsDespesa =
                                                                                    newValue;

                                                                                return oldState;
                                                                            })
                                                                        }
                                                                        tabIndex={getTabIndex()}
                                                                    />

                                                                    <DreFormField
                                                                        allowNegative
                                                                        fieldLabel="Valor"
                                                                        name={`dreMercadologicaDespesasVariaveis[${index}].vlTotal`}
                                                                        error={formik.errors[`dreMercadologicaDespesasVariaveis[${index}].vlTotal`]}
                                                                        fieldValue={field.vlTotal}
                                                                        fieldChange={(newValue) => {
                                                                            setFormData((oldState) => {
                                                                                oldState.dreMercadologicaDespesasVariaveis[index].vlTotal =
                                                                                    Number(newValue);

                                                                                return oldState;
                                                                            });
                                                                        }}
                                                                        onBlur={() => handleRecalcular()}
                                                                        tabIndex={getTabIndex()}
                                                                    />

                                                                    <Grid
                                                                        item
                                                                        container
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flex: 1,
                                                                            maxWidth: { md: '50%', xs: '100%' },
                                                                        }}
                                                                    >
                                                                        <DreFormField
                                                                            sx={{ maxWidth: '50%' }}
                                                                            name={`dreMercadologicaDespesasVariaveis[${index}].fgRecorrente`}
                                                                            checkboxValue={field.fgRecorrente}
                                                                            checkboxChange={(checked) =>
                                                                                setFormData((oldState) => {
                                                                                    oldState.dreMercadologicaDespesasVariaveis[index].fgRecorrente =
                                                                                        checked;

                                                                                    return oldState;
                                                                                })
                                                                            }
                                                                            tabIndex={getTabIndex()}
                                                                        />

                                                                        <Grid item width={50} display="flex" alignItems="center">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    setFormData((oldState) => {
                                                                                        const newDate = structuredClone(oldState);

                                                                                        newDate.dreMercadologicaDespesasVariaveis.splice(index, 1);

                                                                                        return newDate;
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <DeleteOutlineIcon color="error" />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Row>
                                                                <Divider sx={{ mb: 2 }} />
                                                            </React.Fragment>
                                                        );
                                                    })}

                                                    <Grid item sx={{ width: '100%' }}>
                                                        <Button
                                                            onClick={() => {
                                                                setFormData((oldState) => {
                                                                    const newIndex = (oldState.dreMercadologicaDespesasVariaveis.length + 1) * -1;
                                                                    const newDate = structuredClone(oldState);

                                                                    newDate.dreMercadologicaDespesasVariaveis.push({
                                                                        idDreMercadologicaDespesaVariavel: newIndex,
                                                                        dsDespesa: '',
                                                                        vlTotal: 0,
                                                                        fgRecorrente: false,
                                                                    });

                                                                    return newDate;
                                                                });
                                                            }}
                                                        >
                                                            + adicionar despesa extra
                                                        </Button>
                                                    </Grid>
                                                </Box>
                                            </DreFormGroup>
                                        )}

                                        {Boolean(formData.dreMercadologicaImpostos.length) && (
                                            <DreFormGroup label="Impostos" id="5">
                                                {formData.dreMercadologicaImpostos.map((field, index) => {
                                                    return (
                                                        <Row key={field.idDreMercadologicaImposto}>
                                                            <DreFormField
                                                                name={`dreMercadologicaImpostos[${index}].vlTotal`}
                                                                error={formik.errors[`dreMercadologicaImpostos[${index}].vlTotal`]}
                                                                fieldLabel={field.dreMercadologicaConfigImposto.dsNomeExibicao}
                                                                fieldValue={field.vlTotal}
                                                                tooltip={field.dreMercadologicaConfigImposto.dsObservacao}
                                                                fieldChange={(newValue) => {
                                                                    setFormData((oldState) => {
                                                                        oldState.dreMercadologicaImpostos[index].vlTotal = Number(newValue);

                                                                        return oldState;
                                                                    });
                                                                }}
                                                                onBlur={() => handleRecalcular()}
                                                                tabIndex={getTabIndex()}
                                                            />
                                                        </Row>
                                                    );
                                                })}
                                            </DreFormGroup>
                                        )}

                                        <Box
                                            sx={{
                                                position: 'sticky',
                                                bottom: 0,
                                                zIndex: 3,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                p: 2,
                                                mx: -2,
                                                backgroundColor: (theme) => theme.palette.grey[100],
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    'justifyContent': 'flex-end',
                                                    '& .MuiGrid-item': {
                                                        width: { xs: '100%', sm: 'auto' },
                                                        justifyContent: 'center',
                                                        display: 'flex',
                                                    },
                                                }}
                                            >
                                                <Grid item>
                                                    <DreFormBbarInfo />
                                                </Grid>

                                                <Grid item>
                                                    <LoadingButton
                                                        variant="contained"
                                                        loading={isLoading}
                                                        disabled={isLoading}
                                                        onClick={() => {
                                                            handleRecalcular().then(() => handleSubmit(false, formik));
                                                        }}
                                                        size="large"
                                                        tabIndex={getTabIndex()}
                                                    >
                                                        Salvar
                                                    </LoadingButton>

                                                    {hasPermission(['FINANCEIRO_DRE_ENVIAR']) && (
                                                        <LoadingButton
                                                            variant="contained"
                                                            loading={isLoading}
                                                            disabled={isLoading}
                                                            onClick={() => handleSubmit(true, formik)}
                                                            size="large"
                                                            sx={{ ml: 2 }}
                                                            tabIndex={getTabIndex()}
                                                        >
                                                            Encerrar Estimativa de Resultado
                                                        </LoadingButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </OneColumn>
    );
}
