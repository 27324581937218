import { TableViewOutlined } from '@mui/icons-material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Box, Typography } from '@mui/material';

import { Block } from 'components/page';
import { LojaReportBtn } from 'pages/cadastro/loja/components/atoms/LojaReportBtn';

export function LojaReport() {
    return (
        <Block
            sx={{
                display: 'flex',
                flex: 1,
                gap: 2,
                margin: 0,
                marginTop: { xs: 2, lg: 0 },
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
            }}
        >
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                <TableViewOutlined color="primary" />

                <Typography>EXPORTAR CADASTRO DE LOJAS</Typography>

                <ChevronRightOutlinedIcon color="primary" />
            </Box>

            <LojaReportBtn />
        </Block>
    );
}
