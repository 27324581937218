import React, { ComponentProps } from 'react';

import { Chip } from '@mui/material';

interface DreListLojaColumnProp {
    idStatus: number;
    title: string;
}

const getColor = (idStatus: number): ComponentProps<typeof Chip>['color'] => {
    switch (idStatus) {
        case 2: // 2 = Em preenchimento
            return 'info';
        case 3: // 3 = Finalizado
            return 'success';
        case 4: // 4 = Bloqueado
            return 'error';
        case 1: // 1 = A preencher
        default:
            return 'warning';
    }
};

export function DreStatusChip({ idStatus, title }: DreListLojaColumnProp): JSX.Element {
    return <Chip label={title} variant="outlined" size="small" color={getColor(idStatus)} sx={{ width: '100%' }} />;
}
