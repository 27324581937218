import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { ConfigPedidoProduto } from 'pages/compra/configuracao-pedido/types';

export type ConfigPedidoProdutosContext = {
    configPedidoProdutos: ConfigPedidoProduto[];
    setConfigPedidoProdutos: Dispatch<SetStateAction<ConfigPedidoProduto[]>>;

    loja: StoreOptionsProps | null;
    setLoja: Dispatch<SetStateAction<StoreOptionsProps | null>>;

    openProdutoModal: boolean;
    setOpenProdutoModal: Dispatch<SetStateAction<boolean>>;

    initialConfigPedidoProdutos: string;
    setInitialConfigPedidoProdutos: Dispatch<SetStateAction<string>>;
};

interface ConfigPedidoProdutosProvider {
    children?: ReactNode;
}

const ConfigPedidoProdutosContext = createContext<ConfigPedidoProdutosContext>({} as ConfigPedidoProdutosContext);

export function ConfigPedidoProdutosProvider({ children }: ConfigPedidoProdutosProvider) {
    const [configPedidoProdutos, setConfigPedidoProdutos] = useState<ConfigPedidoProduto[]>([]);
    const [loja, setLoja] = useState<StoreOptionsProps | null>(null);
    const [initialConfigPedidoProdutos, setInitialConfigPedidoProdutos] = useState<string>('');
    const [openProdutoModal, setOpenProdutoModal] = useState<boolean>(false);

    return (
        <ConfigPedidoProdutosContext.Provider
            value={{
                configPedidoProdutos,
                setConfigPedidoProdutos,
                initialConfigPedidoProdutos,
                setInitialConfigPedidoProdutos,
                loja,
                setLoja,
                openProdutoModal,
                setOpenProdutoModal,
            }}
        >
            {children}
        </ConfigPedidoProdutosContext.Provider>
    );
}

export const useConfigPedidoProdutosContext = () => useContext(ConfigPedidoProdutosContext);
