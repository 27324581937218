import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { getDialogGradeError } from 'pages/compra/pedido-realizado/components/atoms/action-menu/PedidoRealizadoActionMenu';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoAproveBtn() {
    const { row, disabledActions, setDisabledActions, setOpenConfirmModal, setDataConfirmModal } = useActionMenu();
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { idPedido } = row;
    const queryClient = useQueryClient();

    const handleAprove = (fgEnviaNovaGrade = false) => {
        setDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/aprovar${fgEnviaNovaGrade ? '?fgEnviaNovaGrade=true' : ''}`, preventSnack: true },
            {
                onSuccess: () => {
                    enqueueSnackbar('Pedido aprovado com sucesso', { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setDisabledActions(false));
                },
                onError: (error) => {
                    const dialogGradeError = getDialogGradeError(error);

                    setDisabledActions(false);

                    if (dialogGradeError) {
                        setOpenConfirmModal(true);

                        setDataConfirmModal({
                            callback: () => handleAprove(true),
                            text: dialogGradeError,
                        });
                    } else {
                        showError(error, 'Não foi possível aprovar o pedido. Tente novamente');
                    }
                },
            },
        );
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<CheckCircleOutlineOutlinedIcon />}
            disabled={disabledActions}
            loading={isLoading}
            onClick={() => {
                setOpenConfirmModal(true);

                setDataConfirmModal({
                    callback: handleAprove,
                    text: 'Tem certeza que deseja aprovar o registro selecionado?',
                });
            }}
        >
            Aprovar
        </LoadingButton>
    );
}
