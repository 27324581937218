import { Box, Typography } from '@mui/material';

export function PagamentoTitle({ title }: { title: string }) {
    return (
        <Box
            sx={{
                display: 'flex',
                py: 1,
                px: 2,
                backgroundColor: 'secondary.main',
                borderRadius: '4px 4px 0 0',
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    lineHeight: 1.25,
                    color: 'common.white',
                }}
            >
                {title}
            </Typography>
        </Box>
    );
}
