import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

import { CissTextField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';
import { UsuariosModalGrid } from 'pages/cadastro/permissoes/components/templates/UsuariosModalGrid';
import { UsuariosProps } from 'pages/cadastro/permissoes/types';

interface UsuariosListModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    usuarios: UsuariosProps[];
    setUsuarios: Dispatch<SetStateAction<UsuariosProps[]>>;
}

export function UsuariosModal({ open, setOpen, usuarios, setUsuarios }: UsuariosListModalProps) {
    const [usuariosModal, setUsuariosModal] = useState<UsuariosProps[]>([]);
    const [query, setQuery] = useState('');

    /**
     * Ao clicar para atualizar, define os usuários atualizados e fecha o modal.
     */
    const handleConfirmModal = () => {
        setUsuarios(usuariosModal);

        setOpen(false);
    };

    /**
     * Define os usuários iniciais do modal.
     */
    const setInitialUsuariosModal = () => {
        if (open) {
            setUsuariosModal(usuarios);

            setQuery('');
        }
    };

    useEffect(setInitialUsuariosModal, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            centered={false}
            sx={{
                maxWidth: 960,
                width: '90vw',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
            }}
        >
            <SubTitle label="Gerenciar usuários" />

            <CissTextField
                size="small"
                label="Pesquisar"
                placeholder="Digite um usuário..."
                deboundTime={500}
                onChange={(e) => setQuery(e.target.value)}
                sx={{
                    mb: 2,
                    width: { xs: '100%', md: '50%' },
                }}
            />

            <ListagemProvider>
                <UsuariosModalGrid query={query} usuariosModal={usuariosModal} setUsuariosModal={setUsuariosModal} />
            </ListagemProvider>

            <Box
                sx={{
                    mt: 2,
                    gap: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button variant="outlined" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>

                <LoadingButton variant="contained" onClick={handleConfirmModal}>
                    Salvar
                </LoadingButton>
            </Box>
        </ContentModal>
    );
}
