import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { FuncionarioAutoComplete, StoreAutoComplete } from 'components/autocompletes';
import { Funcionario } from 'components/autocompletes/FuncionarioAutoComplete';
import { Filter } from 'components/page';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { useValidationUsuario } from 'hooks/useValidationUsuario';

export function CarrinhoGridFilter() {
    const { hasPermission } = usePermissionsContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { formattedLoja, usuario } = useAplicacaoDataContext();
    const { validateUsuario } = useValidationUsuario();
    const [searchParams] = useSearchParams();
    const funcionarioParams = searchParams.get('idUsuarioPedido');
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'in',
        value: [],
        idProperty: 'idLoja',
    });

    const [filterFuncionario, setFilterFuncionario] = useState<FilterValuesProps>({
        property: 'idUsuarioPedido',
        operator: 'eq',
        value: null,
        idProperty: 'idUsuario',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterFuncionario, setter: setFilterFuncionario },
    ];

    /**
     * Define o filtro de loja de acordo com a loja do usuário logado.
     */
    const setDefaultFilterLoja = () => {
        if (formattedLoja) {
            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [formattedLoja] }));
        }
    };

    /**
     * Reseta os filtros e define o filtro de loja de acordo com a loja do usuário logado.
     */
    const resetFilter = (): void => {
        handleFilterReset({ filters });

        setDefaultFilterLoja();
    };

    /**
     * Valida se há um funcionário ou se o usuário logado está válido para executar pedidos.
     */
    const validateFuncionario = (): boolean => {
        return Boolean(funcionarioParams) || validateUsuario();
    };

    /**
     * Inicia o filtro caso caso não seja um pedido de funcionário ou
     * caso seja um pedido de funcionário e o usuário logado esteja válido.
     */
    const startFilter = (): void => {
        if (isTipoPedidoFuncionario && !validateFuncionario()) {
            return;
        }

        handleFilterSubmit({ filters });
    };

    /**
     * Define o filtro de funcionário de acordo com o usuário logado caso:
     * - O tipo de pedido seja de funcionário;
     * - O usuário tenha permissão pra finzalizar pedidos;
     * - Não exista um funcionário nos parâmetros da URL (está sendo filtrado).
     */
    const setDefaultFilterFuncionario = (): void => {
        if (isTipoPedidoFuncionario && hasPermissionFinalizacaoGeral && usuario && !funcionarioParams) {
            setFilterFuncionario((oldState: FilterValuesProps) => ({
                ...oldState,
                value: { idUsuario: usuario.idUsuario, dsUsuario: usuario.dsUsuario },
            }));
        }
    };

    /**
     * Caso o tipo de pedido seja de funcionário e o usuário logado esteja válido,
     * define o filtro de loja, funcionario e os filtros padrões.
     * Caso o tipo de pedido seja padrão, apenas define os filtros padrões.
     */
    useEffect(() => {
        if (isTipoPedidoFuncionario && usuario && formattedLoja) {
            if (!validateFuncionario()) {
                return;
            }

            setDefaultFilterLoja();

            setDefaultFilterFuncionario();

            setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
        } else if (isTipoPedidoPadrao) {
            setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
        }
    }, [usuario, formattedLoja]);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            {isTipoPedidoPadrao && (
                <Grid item flex={1}>
                    <StoreAutoComplete
                        size="small"
                        multiple
                        value={filterLoja.value}
                        onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    />
                </Grid>
            )}

            {isTipoPedidoFuncionario && hasPermissionFinalizacaoGeral && (
                <Grid item flex={1}>
                    <FuncionarioAutoComplete
                        size="small"
                        value={filterFuncionario.value}
                        onChange={(e, value: Funcionario) => {
                            let valueLoja = formattedLoja;

                            if (value) {
                                valueLoja = {
                                    idLoja: value.loja.idLoja,
                                    cdLoja: value.loja.cdLoja,
                                    dsNomeFantasia: value.loja.dsNomeFantasia,
                                };
                            }

                            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [valueLoja] }));

                            setFilterFuncionario((oldState: FilterValuesProps) => ({
                                ...oldState,
                                value: value && { idUsuario: value.idUsuario, dsUsuario: value.dsUsuario },
                            }));
                        }}
                    />
                </Grid>
            )}
        </Filter>
    );
}
