import * as Yup from 'yup';

import { FormPage } from 'components/page';
import { CondicaoPagamentoFormMain } from 'pages/complementar/condicao-pagamento/components';
import { useMutateCondicaoPagamento } from 'pages/complementar/condicao-pagamento/hooks';

export function CondicaoPagamentoFormComp() {
    const { handleSubmit } = useMutateCondicaoPagamento();

    const validationSchema = Yup.object({
        dsCondicaoPagamento: Yup.string().min(2).max(200).required().nullable(),
        cdCondicaoPagamento: Yup.string().required().nullable(),
        nrParcela: Yup.number().required().nullable(),
        meioPagamento: Yup.object().required().nullable(),
        gatewayPagamento: Yup.object().required().nullable(),
    });

    return (
        <FormPage
            fgAtivo
            goBackButton
            title="Condição de Pagamento"
            url="/gestao/condicaopagamento/"
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnSubmit={true}
            validateOnChange={false}
            values={{}}
        >
            <CondicaoPagamentoFormMain />
        </FormPage>
    );
}
