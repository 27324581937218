import { FullLoading } from 'components/loading';
import {
    ConfigPedidoProdutosImport,
    ConfigPedidoProdutosMain,
    ConfigPedidoProdutosSave,
    ConfigPedidoProdutosStore,
} from 'pages/compra/configuracao-pedido/components';
import { useQueryProdutos } from 'pages/compra/configuracao-pedido/hooks';

export function ConfigPedidoProdutos(): JSX.Element {
    const { isFetching } = useQueryProdutos();

    return (
        <>
            <FullLoading loading={isFetching} />

            <ConfigPedidoProdutosImport />

            <ConfigPedidoProdutosStore />

            <ConfigPedidoProdutosMain />

            <ConfigPedidoProdutosSave />
        </>
    );
}
