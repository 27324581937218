import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { ConfigPedidoCondicoesPagamento } from 'pages/compra/configuracao-pedido/types';

export type ConfigPedidoCondicoesPagamentoContext = {
    configPedidoCondicoesPagamento: ConfigPedidoCondicoesPagamento;
    setConfigPedidoCondicoesPagamento: Dispatch<SetStateAction<ConfigPedidoCondicoesPagamento>>;
};

interface ConfigPedidoCondicoesPagamentoProvider {
    children?: ReactNode;
}

const ConfigPedidoCondicoesPagamentoContext = createContext<ConfigPedidoCondicoesPagamentoContext>({} as ConfigPedidoCondicoesPagamentoContext);

export function ConfigPedidoCondicoesPagamentoProvider({ children }: ConfigPedidoCondicoesPagamentoProvider) {
    const [configPedidoCondicoesPagamento, setConfigPedidoCondicoesPagamento] = useState<ConfigPedidoCondicoesPagamento>({
        gatewayPagamento: [],
        condicaoPagamentoGeral: [],
        condicaoPagamentoLoja: [],
    });

    return (
        <ConfigPedidoCondicoesPagamentoContext.Provider value={{ configPedidoCondicoesPagamento, setConfigPedidoCondicoesPagamento }}>
            {children}
        </ConfigPedidoCondicoesPagamentoContext.Provider>
    );
}

export const useConfigPedidoCondicoesPagamentoContext = () => useContext(ConfigPedidoCondicoesPagamentoContext);
