import { GatewayPagamento } from 'components/autocompletes/GatewayPagamentoAutoComplete';
import { TipoControlePedidoMinimo } from 'components/autocompletes/TipoControlePedidoMinimoAutoComplete';
import { TipoQuantidadeMaximaProduto } from 'components/autocompletes/TipoQuantidadeMaximaProdutoAutoComplete';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';

export interface ConfigPedidoProps {
    idConfigPedido: number;
}

export interface ConfigPedidoOperacoes {
    idConfigPedidoOperacao?: number | null;
    tipoLoja: {
        idTipoLoja: number | null;
        dsTipoLoja: string | null;
    };
    operacao?: {
        idOperacao: number | null;
        dsOperacao: string;
    };
    dsOperacao?: string | null;
    idOperacao?: number | null;
    idConfigPedido?: number | null;
    idTipoLoja?: number | null;
}

export interface DadosBasicosProps {
    idConfigPedido: number | null;
    idConfigPedidoOrigem?: number | null;
    dsConfigPedido: string;
    dtInicioVigencia: string;
    dtFimVigencia: string;
    vlMinimo: number | undefined;
    fgEnviaEmailPendenteGrade: boolean;
    fgSincronizaTerceiros: boolean;
    dsObservacao: string;
    peRoyalties: number;
    tipoControleEstoquePedido: null | {
        idTipoControleEstoquePedido: number;
        cdTipoControleEstoquePedido: string;
        dsTipoControleEstoquePedido: string;
    };
    tipoPedido: null | {
        idTipoPedido: number;
        cdTipoPedido: string;
        dsTipoPedido: string;
    };
    tipoGrade: null | {
        idTipoGrade: number;
        cdTipoGrade: string;
        dsTipoGrade: string;
    };
    deposito: null | {
        idDeposito: number;
        cdDepostio: string;
        dsDeposito: string;
    };
    centroDistribuicao: null | {
        idCentroDistribuicao: number;
        cdCentroDistribuicao: string;
        dsCentroDistribuicao: string;
    };
    centroPedido: null | {
        idCentroPedido: number;
        cdCentroPedido: string;
        dsCentroPedido: string;
    };
    centroCusto: null | {
        idCentroCusto: number;
        cdCentroCusto: string;
        dsCentroCusto: string;
    };
    tabelaPreco: null | {
        idTabelaPreco: number;
        cdTabelaPreco: string;
        dsTabelaPreco: string;
    };
    condicaoPagamento: null | {
        idCondicaoPagamento: number;
        cdCondicaoPagamento: string;
        dsCondicaoPagamento: string;
    };
    pedidoTipoNotaDebito: null | {
        idPedidoTipoNotaDebito: number;
        cdPedidoTipoNotaDebito: string;
        dsPedidoTipoNotaDebito: string;
        idTipoPedido: number;
        dsTipoPedido: string;
    };
    fgLojaMetaAtingida: boolean;
    fgLojaFaixaRoyalties: boolean;
    fgLojaPropria: boolean;
    fgAtivo: boolean;
    configPedidoOperacoes: ConfigPedidoOperacoes[];
    coefNotaDebito: number | string | undefined;
    fgGeraNotaDebito: boolean;
    fgVisivel: boolean;
}

export interface TipoLojasProps {
    idTipoLoja: number;
    dsTipoLoja: string;
}
export interface TipoLojasRequestProps {
    data: TipoLojasProps[];
}

// Listagem

export interface TipoGradeProps {
    idTipoGrade: number;
    dsTipoGrade: string;
}

interface TipoPedidoProps {
    idTipoPedido: number;
    dsTipoPedido: string;
}
export interface ConfiguracaoPedidoCompraList {
    idConfigPedido: number;
    dsConfigPedido: string;
    dtInicioVigencia: string;
    dtFimVigencia: string;
    tipoGrade: TipoGradeProps;
    tipoPedido: TipoPedidoProps;
    fgAtivo: boolean;
    fgVisivel: boolean;
    idConfigPedidoStatus: number;
}

export interface RequestConfiguracaoPedidoCompraList {
    data: ConfiguracaoPedidoCompraList[];
    total: number;
    hasNext: boolean;
}

// Menu Aside
export interface RequestDadosBasicosProps {
    data: DadosBasicosProps;
}

export const initialDadosBasicosValues: DadosBasicosProps = {
    condicaoPagamento: null,
    idConfigPedido: null,
    dsConfigPedido: '',
    dtInicioVigencia: '',
    dtFimVigencia: '',
    vlMinimo: undefined,
    fgEnviaEmailPendenteGrade: true,
    fgSincronizaTerceiros: false,
    dsObservacao: '',
    peRoyalties: 0,
    tipoControleEstoquePedido: null,
    tipoPedido: null,
    tipoGrade: null,
    deposito: null,
    centroDistribuicao: null,
    centroPedido: null,
    centroCusto: null,
    tabelaPreco: null,
    pedidoTipoNotaDebito: null,
    fgLojaMetaAtingida: false,
    fgLojaFaixaRoyalties: false,
    fgLojaPropria: false,
    fgAtivo: true,
    configPedidoOperacoes: [],
    coefNotaDebito: 0,
    fgGeraNotaDebito: false,
    fgVisivel: true,
};

// Regras Validação

export interface RegrasValidacaoProps {
    idConfigPedidoRegraValidacao?: number;
    idConfigPedido?: number;
    peOferta: number | null;
    qtPedidosVisualizar: number;
    fgValidaQuantidadeMinimaMultiploProduto: boolean;
    fgExibeSugestaoProduto: boolean;
    fgRequerAprovacaoPedido: boolean;
    fgValidaInadimplencia: boolean;
    fgUtilizaDescontoCadastroLoja: boolean;
    fgAtivo: boolean;
    vlBaseFreteGratis?: number | null;
    fgUtilizaVlBaseFreteGratis?: boolean;
    tipoControleQuantidadeMaximaProduto?: TipoQuantidadeMaximaProduto;
}

export const initialRegrasValidacaoValues: RegrasValidacaoProps = {
    peOferta: 0,
    qtPedidosVisualizar: 0,
    fgValidaQuantidadeMinimaMultiploProduto: false,
    fgExibeSugestaoProduto: false,
    fgRequerAprovacaoPedido: false,
    fgValidaInadimplencia: false,
    fgUtilizaDescontoCadastroLoja: false,
    fgUtilizaVlBaseFreteGratis: false,
    fgAtivo: true,
};

export interface RequestRegrasValidacaoProps {
    status: number;
    data: RegrasValidacaoProps;
}

// Condições de Visualização
export interface LojasProps {
    idLoja: number;
    cdLoja: number;
    nrTelefoneFormatado: number;
    cnpjFormatado: number;
    cepFormatado: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
    fgAtivo?: boolean;
    peRoyalties?: number;
    vlPedidoMinimo?: number;
    tipoControlePedidoMinimo?: TipoControlePedidoMinimo;
}

export interface EstadosProps {
    idEstado: number;
    dsEstado: string;
}
export interface ConfigPedidoFaixaRoyalties {
    idConfigPedidoFaixaRoyalties?: number;
    peConfigPedidoFaixaRoyalties?: number;
}

export interface ConfigPedidoLojasProps {
    configPedido: ConfigPedidoProps;
    loja: LojasProps;
    fgAtivo: boolean;
    peRoyalties?: number;
    vlPedidoMinimo?: number;
    tipoControlePedidoMinimo?: TipoControlePedidoMinimo;
}

export interface ConfigPedidoEstadosProps {
    configPedido: ConfigPedidoProps;
    estado: EstadosProps;
    fgAtivo: boolean;
}

export interface TipoLojaProps {
    idTipoLoja: number;
    dsTipoLoja: string;
}
export interface CondicoesVisualizacaoProps {
    idConfigPedido?: number;
    tpDisponibilizacao: string;
    fgLojaMetaAtingida: boolean;
    fgLojaFaixaRoyalties: boolean;
    tipoLojaVisualizar: TipoLojaProps | null;
    configPedidoLojas?: ConfigPedidoLojasProps[];
    configPedidoEstados?: ConfigPedidoEstadosProps[];
    configPedidoFaixaRoyalties?: ConfigPedidoFaixaRoyalties | null;
}

export const initialCondicoesVisualizacaoValues: CondicoesVisualizacaoProps = {
    tpDisponibilizacao: 'lojas',
    fgLojaMetaAtingida: false,
    fgLojaFaixaRoyalties: false,
    tipoLojaVisualizar: null,
    configPedidoFaixaRoyalties: null,
};

// Produtos
export interface ProdutosProps {
    idProduto: number;
    cdProduto: number;
    dsProduto: string;
    fgAtivo: boolean;
    dsGrupo: string;
    dsMarca: string;
    qtMinimaCompra: number;
    fgFracionavel: boolean;
    unidadeMedida?: {
        fgFracionavel: boolean;
    };
}

export type ConfigPedidoProduto = {
    configPedido?: ConfigPedidoProps;
    idProduto?: number;
    produto: ProdutosProps;
    fgAtivo?: boolean;
    qtMinimaProduto?: number;
    qtMaximaProduto?: number;
    peOferta?: number;
    fgShowPeOferta?: boolean;
};

export interface StepMenuProps {
    idConfigPedidoStep: number;
    idConfigPedido: number;
    dsChave: string;
    dsValor: boolean;
}

export interface RequestStepsMenuProps {
    status: number;
    data: {
        data: StepMenuProps[];
    };
}

export interface ProdutoEstoque {
    cdProduto: string;
    dsProduto: string;
    fgAtivo: boolean;
    idProduto: number;
    qtEstoqueAtual?: number;
    qtEstoqueTotal?: number;
    fgFracionavel?: boolean;
    produto?: {
        unidadeMedida?: {
            fgFracionavel?: boolean;
        };
    };
}

export interface Produto {
    idProduto: number;
    dsProduto: string;
    cdProduto: string;
}

export interface ProdutoQuebra {
    fgAtivo?: boolean;
    qtQuebra?: number;
    nrQuebra?: number;
    peQuebra: number;
    idConfigPedidoQuebra?: number;
    dtFim?: string;
    dtInicio?: string;
    produto: Produto;
    condicaoPagamento?: CondicaoPagamento;
}

export interface MetaProps {
    idMeta: number;
    dsMeta: string;
    vlMeta: boolean;
}

export interface TipoLogisticaLojaProps {
    idTipoLogisticaLoja: number;
    dsTipoLogisticaLoja: string;
}

export interface LojaMetaAtingidaProps {
    fgMetaAtingida: boolean;
    loja: LojasProps;
    tipoLogisticaLoja: TipoLogisticaLojaProps | null;
    configPedido: ConfigPedidoProps;
}

export interface PercentualNotaMetaSazonalProps {
    idPercentualNotaRoyaltiesSazonal: number;
    dsPercentualNotaRoyaltiesSazonal: string;
}

export interface LojasMetasRequestProps {
    lojasMetaAtingida: LojaMetaAtingidaProps;
    percentualNotaRoyaltiesSazonal: PercentualNotaMetaSazonalProps;
}

export interface ConfigPedidoAutomaticaProcessamentoProps {
    configPedidoAutomaticaProcessamentoStatus: {
        idConfigPedidoAutomaticaProcessamentoStatus: number;
        dsConfigPedidoAutomaticaProcessamentoStatus: string;
    };
}

export enum StatusGeracaoConfig {
    AGUARDANDO = 1,
    PROCESSANDO = 2,
    FINALIZADO_COM_SUCESSO = 3,
    FINALIZADO_COM_FALHA = 4,
    REVERTIDO = 5,
}

export type CondicaoPagamentoLoja = {
    loja: LojasProps;
    condicaoPagamentoList: CondicaoPagamento[];
};

export type ConfigPedidoCondicoesPagamento = {
    gatewayPagamento: GatewayPagamento[];
    condicaoPagamentoGeral: CondicaoPagamento[];
    condicaoPagamentoLoja: CondicaoPagamentoLoja[];
};
