import { Dispatch, SetStateAction } from 'react';

import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { CissNumberField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraRemoveBtn';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';
import { useActionsProdutos } from 'pages/compra/configuracao-pedido/hooks';
import { ConfigPedidoProduto } from 'pages/compra/configuracao-pedido/types';

interface ConfigPedidoProdutosGrid {
    valueFilter: string;
    setSelectedRows: Dispatch<SetStateAction<number[]>>;
}

const getFilteredRows = (products: ConfigPedidoProduto[], valueFilter: string) => {
    return products.filter((item: ConfigPedidoProduto) => {
        const { dsProduto, dsGrupo, cdProduto } = item.produto;

        return (
            !valueFilter ||
            dsProduto.toLowerCase().includes(valueFilter.trim().toLowerCase()) ||
            dsGrupo?.toLowerCase().includes(valueFilter.trim().toLowerCase()) ||
            cdProduto.toString().includes(valueFilter.trim().toLowerCase())
        );
    });
};

const getRows = (products: ConfigPedidoProduto[]) => {
    return products.map((item: ConfigPedidoProduto) => ({
        id: item.produto.idProduto,
        cdProduto: item.produto.cdProduto,
        dsProduto: item.produto.dsProduto,
        fgFracionavel: item.produto.fgFracionavel ?? item.produto?.unidadeMedida?.fgFracionavel ?? false,
        dsCategoria: item.produto.dsGrupo,
        dsMarca: item.produto.dsMarca,
        qtMinimaProduto: item.qtMinimaProduto,
        qtMaximaProduto: item.qtMaximaProduto,
        peOferta: item.peOferta,
        fgShowPeOferta: item.fgShowPeOferta,
    }));
};

export function ConfigPedidoProdutosGrid({ valueFilter, setSelectedRows }: ConfigPedidoProdutosGrid): JSX.Element {
    const { configPedidoProdutos, loja } = useConfigPedidoProdutosContext();
    const { removeConfigPedidoProdutos, updateConfigPedidoProduto } = useActionsProdutos();

    const columns: GridColDef[] = [
        { field: 'cdProduto', headerName: 'Código', type: 'number', width: 80 },
        { field: 'dsProduto', headerName: 'Descrição', type: 'string', flex: 1, minWidth: 100 },
        { field: 'dsMarca', headerName: 'Marca', type: 'string', width: 70 },
        { field: 'dsCategoria', headerName: 'Categoria', type: 'string', width: 100 },
        {
            field: 'qtMinimaProduto',
            headerName: 'Quantidade mínima',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType={row.fgFracionavel ? 'decimal' : 'number'}
                    allowNegative={false}
                    value={row.qtMinimaProduto}
                    disabled={Boolean(loja)}
                    onChange={(e) => updateConfigPedidoProduto(row.id, Number(e.target.value), 'qtMinimaProduto')}
                />
            ),
        },
        {
            field: 'qtMaximaProduto',
            headerName: 'Quantidade máxima',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType={row.fgFracionavel ? 'decimal' : 'number'}
                    allowNegative={false}
                    value={row.qtMaximaProduto}
                    disabled={Boolean(loja)}
                    onChange={(e) => updateConfigPedidoProduto(row.id, Number(e.target.value), 'qtMaximaProduto')}
                />
            ),
        },
        {
            field: 'peOferta',
            headerName: 'Desconto (%)',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType="decimal"
                    maxValue={100}
                    forceMaxValue
                    allowNegative={false}
                    value={row.peOferta || 0}
                    onChange={(e) => updateConfigPedidoProduto(row.id, Number(e.target.value), 'peOferta')}
                />
            ),
        },
        {
            field: 'fgShowPeOferta',
            align: 'center',
            headerName: 'Exibir desconto',
            minWidth: 60,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <Checkbox
                    disabled={Boolean(loja)}
                    checked={row.fgShowPeOferta}
                    onChange={(e, checked) => updateConfigPedidoProduto(row.id, checked, 'fgShowPeOferta')}
                />
            ),
        },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 70,
            renderCell: ({ row }) => (
                <ConfiguracaoPedidoCompraRemoveBtn disabled={Boolean(loja)} onClick={() => removeConfigPedidoProdutos([row.id])} />
            ),
        },
    ];

    const filteredRows = getFilteredRows(configPedidoProdutos, valueFilter);
    const rows = getRows(filteredRows);

    return (
        <PanelGrid
            checkboxSelection
            rows={rows}
            rowsCount={rows.length}
            columns={columns}
            reload={false}
            remote={false}
            loading={false}
            onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
        />
    );
}
