import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { usePermissionsContext } from 'context/PermissionsContext';
import { Comunicado, useQueryComunicado } from 'hooks/comunicado-modal/useQueryComunicado';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useQueryDre } from 'hooks/useQueryDre';
import { Grade, useQueryGrade } from 'hooks/useQueryGrade';

export type PopupContext = {
    dataComunicado?: Comunicado[];
    openComunicadoModal: boolean;
    setOpenComunicadoModal: Dispatch<SetStateAction<boolean>>;

    dataGrade: Grade | null;
    openGradeModal: boolean;
    setOpenGradeModal: Dispatch<SetStateAction<boolean>>;

    openDreModal: boolean;
    setOpenDreModal: Dispatch<SetStateAction<boolean>>;
};

type Popup = 'grade' | 'comunicado' | 'dre';

const PopupContext = createContext<PopupContext>({} as PopupContext);

export function PopupProvider({ children }: { children: ReactNode }) {
    const { isAuthenticated } = useGenericAuth();
    const { pathname } = useLocation();
    const { hasPermission, isLoadingPermissions } = usePermissionsContext();

    const [openGradeModal, setOpenGradeModal] = useState<boolean>(false);
    const [openComunicadoModal, setOpenComunicadoModal] = useState<boolean>(false);
    const [openDreModal, setOpenDreModal] = useState<boolean>(false);

    const [modalQueue, setModalQueue] = useState<Popup[]>([]);
    const initialized = useRef<boolean>(false);

    const { refetch: refetchGrade, data: dataGrade, isFetched: isFetchedGrade } = useQueryGrade();
    const { refetch: refetchComunicado, data: dataComunicado, isFetched: isFetchedComunicado } = useQueryComunicado();
    const { refetch: refetchDre, data: dataDre, isFetched: isFetchedDre } = useQueryDre();

    /**
     * Caso o usuário entrou no sistema, carregou as permissões, e está na tela inicial do sistema
     * (tela de BI ou tela de comunicado, de acordo com as permissões do sistema),
     * realiza as requests dos dados das modais.
     */
    const refetchPopups = () => {
        if (isAuthenticated && !isLoadingPermissions && !initialized.current && pathname !== '/login') {
            const isInitialRoute = pathname === '/';
            const isComunicadoRoute =
                pathname === '/comunicado' && hasPermission(['COMUNICADO_VISUALIZAR']) && !hasPermission(['BI_INICIO_VISUALIZAR']);

            initialized.current = true;

            if (isInitialRoute || isComunicadoRoute) {
                refetchComunicado();

                refetchGrade();

                refetchDre();
            }
        }
    };

    /**
     * Após todas as requests dos dados das modais feitas,
     * define a fila de modais que serão exibidos.
     */
    const defineModalQueue = () => {
        if (isFetchedGrade && isFetchedComunicado && isFetchedDre) {
            if (Array.isArray(dataComunicado) && dataComunicado.length) {
                setModalQueue((data) => [...data, 'comunicado']);
            }

            if (dataGrade) {
                setModalQueue((data) => [...data, 'grade']);
            }

            if (dataDre) {
                setModalQueue((data) => [...data, 'dre']);
            }
        }
    };

    /**
     * Abre a modal que está na fila de modais.
     */
    const openModalFromQueue = () => {
        if (modalQueue.length) {
            const [modal] = modalQueue;

            if (modal === 'comunicado') {
                setOpenComunicadoModal(true);
            }

            if (modal === 'grade') {
                setOpenGradeModal(true);
            }

            if (modal === 'dre') {
                setOpenDreModal(true);
            }
        }
    };

    /**
     * Caso o modal atual foi fechado, remove ele da lista de modais,
     * fazendo com que o próximo modal seja aberto através do método `openModalFromQueue`.
     */
    const removeCurrentModalFromQueue = () => {
        if (modalQueue.length && !openGradeModal && !openComunicadoModal) {
            setModalQueue((data) => data.slice(1));
        }
    };

    /**
     * Caso o usuário saiu do sistema ou trocou de empresa, reseta o estado de inicialização dos popups.
     */
    const resetInitializationPopups = () => {
        if (!isAuthenticated && initialized.current) {
            initialized.current = false;
        }
    };

    useEffect(refetchPopups, [pathname, isAuthenticated, isLoadingPermissions]);

    useEffect(defineModalQueue, [isFetchedComunicado, isFetchedGrade, isFetchedDre]);

    useEffect(openModalFromQueue, [modalQueue]);

    useEffect(removeCurrentModalFromQueue, [openComunicadoModal, openGradeModal, openDreModal]);

    useEffect(resetInitializationPopups, [isAuthenticated]);

    return (
        <PopupContext.Provider
            value={{
                dataComunicado,
                openComunicadoModal,
                setOpenComunicadoModal,

                dataGrade,
                openGradeModal,
                setOpenGradeModal,

                openDreModal,
                setOpenDreModal,
            }}
        >
            {children}
        </PopupContext.Provider>
    );
}

export const usePopupContext = () => useContext(PopupContext);
