import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';

type CissNumberFieldV2Props = {
    name?: string;
    error?: string;
    maxValue?: number;
    onChange(value: number | null): void;
    inputType?: 'number' | 'decimal' | 'percentual' | 'currency';
} & Pick<NumericFormatProps, 'value' | 'fixedDecimalScale'> &
    Pick<TextFieldProps, 'label' | 'size' | 'sx' | 'disabled'>;

const inputTypeCustomProps = {
    number: {
        decimalScale: 0,
    },
    decimal: {
        decimalScale: 2,
    },
    percentual: {
        suffix: '%',
        decimalScale: 2,
    },
    currency: {
        prefix: 'R$ ',
        decimalScale: 2,
        fixedDecimalScale: true,
    },
};

const maxLimitPercentual = 100;

export function CissNumberFieldV2({ error, onChange, maxValue, inputType = 'number', ...props }: CissNumberFieldV2Props): JSX.Element {
    const isAllowed = (values: NumberFormatValues): boolean => {
        const { floatValue } = values;

        if (floatValue) {
            if (inputType === 'percentual') {
                return floatValue <= maxLimitPercentual;
            } else if (maxValue) {
                return floatValue <= maxValue;
            }
        }

        return true;
    };

    return (
        <NumericFormat
            {...props}
            {...inputTypeCustomProps[inputType]}
            decimalSeparator=","
            allowedDecimalSeparators={[',', '.']}
            allowNegative={false}
            isAllowed={isAllowed}
            error={Boolean(error)}
            customInput={TextField}
            helperText={typeof error === 'string' && error}
            onValueChange={({ floatValue }) => onChange(floatValue ?? null)}
        />
    );
}
