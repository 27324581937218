import { GridRowModel } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { ProdutoPedidoProps } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useQueryProdutos({ row }: { row: GridRowModel }) {
    const { limit, page, sort, filter } = useListagemContext();
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/compra/pedidocompra/rascunho/itens?idPedido=${row.id}`,
        sort: sort.length ? sort : [[{ property: 'dsSubgrupo', direction: 'ASC' }]],
        page,
        filter,
        limit,
    };

    return useQuery<ProdutoPedidoProps[]>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data));
}
