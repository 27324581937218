import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useCarrinhoContext } from '../contexts';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { SuccessMessageProps, getSuccessMessage } from 'util/success';

export function useDeletePedido() {
    const { showError } = useShowError();
    const { setDisabledActions } = useCarrinhoContext();
    const api = useServiceApiContext();
    const queryClient = useQueryClient();

    /**
     * Exclui o rascunho e atualiza a lista de rascunhos e o totalizador.
     */
    const handleDelete = (idPedido: number) => {
        setDisabledActions(true);

        api.delete(`/compra/pedidocompra/rascunho/${idPedido}`)
            .then((result: SuccessMessageProps) => {
                const message = getSuccessMessage(result);

                enqueueSnackbar(message, { variant: 'success' });

                queryClient.invalidateQueries(['getListRascunhos']);
                queryClient.invalidateQueries(['getTotalizadorRascunhos']);
            })
            .catch((error) => showError(error, 'Não foi possível remover o pedido. Tente novamente'))
            .finally(() => setDisabledActions(false));
    };

    return { handleDelete };
}
