import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { UsuariosProps } from 'pages/cadastro/permissoes/types';

/**
 * Retorna a lista de usuários para ser exibida na grid.
 */
const getRows = (data: UsuariosProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idUsuario: item.idUsuario,
            dsUsuario: item.dsUsuario,
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'idUsuario', headerName: 'Código', width: 120, minWidth: 70 },
    { field: 'dsUsuario', headerName: 'Nome Usuário', flex: 1, minWidth: 100 },
];

export function UsuariosGrid({ usuarios }: { usuarios: UsuariosProps[] }): JSX.Element {
    const rows = getRows(usuarios);

    return (
        <PanelGrid
            rows={rows}
            rowsCount={rows.length}
            reload={false}
            remote={false}
            loading={false}
            columns={columns}
            customMessageNoRows="Sem usuários adicionados. Para adicionar usuários, clique em gerenciar usuários."
        />
    );
}
