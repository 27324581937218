import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

import { GatewayPagamento } from 'components/autocompletes/GatewayPagamentoAutoComplete';

export type MeioPagamento = {
    idMeioPagamento: number;
    dsMeioPagamento: string;
    meioPagamentoEnum: 'CARTAO_CREDITO' | 'PIX' | 'DESCONTO_FOLHA';
    nrMaximoParcelas?: number;
    gatewayPagamento?: GatewayPagamento;
};

type MeioPagamentoAutoComplete = Pick<
    CissAutoCompleteProps,
    'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'size' | 'requestOptions'
>;

export function MeioPagamentoAutoComplete({
    sx,
    name,
    value,
    error,
    size,
    multiple = false,
    disabled = false,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/meiopagamento',
        sort: [{ property: 'idMeioPagamento', direction: 'ASC' }],
        filter: [{ property: 'fgAtivo', operator: 'eq', value: true }],
        limit: 50,
    },
}: MeioPagamentoAutoComplete) {
    return (
        <CissAutoComplete
            label="Meio de pagamento"
            objectId="idMeioPagamento"
            optionStringTemplate="{dsMeioPagamento}"
            selectedStringTemplate="{dsMeioPagamento}"
            sx={sx}
            size={size}
            name={name}
            value={value}
            error={error}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: MeioPagamento, value: MeioPagamento) => option.idMeioPagamento === value.idMeioPagamento}
        />
    );
}
