import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { IconButton, Tooltip } from '@mui/material';

import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';

export function ConfigPedidoCondicoesPagamentoEditBtn({ onClick }: { onClick(): void }) {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();

    return (
        <Tooltip title="Editar">
            <span>
                <IconButton sx={{ p: 0, ml: 0 }} onClick={onClick} disabled={!configPedidoCondicoesPagamento.gatewayPagamento.length}>
                    <ModeEditOutlineOutlinedIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}
