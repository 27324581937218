import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { LoadingButton } from '@mui/lab';

import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';
import { useMutatePayment } from 'pages/compra/pedido-realizado/hooks';

export function PedidoRealizadoPaymentBtn() {
    const { disabledActions, setDataConfirmModal, setOpenConfirmModal } = useActionMenu();
    const { mutatePayment } = useMutatePayment();

    const onClickPayment = () => {
        setOpenConfirmModal(true);

        setDataConfirmModal({
            callback: mutatePayment,
            text: 'Deseja realmente alterar o pagamento do pedido?',
        });
    };

    return (
        <LoadingButton color="inherit" disabled={disabledActions} startIcon={<CurrencyExchangeIcon />} onClick={onClickPayment}>
            Alterar pagamento
        </LoadingButton>
    );
}
