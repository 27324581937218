import React, { useCallback, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Field, FormikHelpers, FormikProps, getIn } from 'formik';
import * as Yup from 'yup';

import { StoreAutoComplete, TipoUsuarioAutoComplete } from 'components/autocompletes';
import { CargoFuncionarioAutoComplete } from 'components/autocompletes/CargoFuncionarioAutoComplete';
import { LocalFuncionarioAutoComplete } from 'components/autocompletes/LocalFuncionarioAutoComplete';
import { TipoEntregaAutoComplete } from 'components/autocompletes/TipoEntregaAutoComplete';
import { CissMaskField, CissNumberField, CissPrimaryKeyField, CissTextField } from 'components/fields/';
import { Row } from 'components/form';
import { FullLoading } from 'components/loading';
import { FormPage, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { CepField, GrupoUsuarioField, GrupoEconomicoListGrid, GrupoEconomicoListGridModal } from 'pages/cadastro/usuario/components';
import { GruposEconomicosProps, initialUsuarioValues, RequestGrupoUsuario, UsersOptionsProps, UsuarioProps } from 'pages/cadastro/usuario/types';

export function UsuarioFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const { mutate, isLoading } = useFormMutation();
    const navigate = useNavigate();
    const RequestListagem = useRequestListagem();
    const url = '/gestao/usuario';
    const { id } = useParams();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    const [openUsuarioModal, setOpenUsuarioModal] = useState<boolean>(false);

    const handleSubmit = useCallback(
        (formData: UsuarioProps, formik: FormikHelpers<UsuarioProps>): void => {
            if (
                formData.tipoUsuario?.tipoUsuarioEnum === 'FUNCIONARIO' &&
                formData.usuarioFuncionario &&
                typeof formData.usuarioFuncionario.fgAtivoCompra !== 'boolean'
            ) {
                formData.usuarioFuncionario.fgAtivoCompra = true;
            }

            mutate(
                {
                    url,
                    formik,
                    formData,
                    idField: 'idUsuario',
                    preventRedirect: !hasPermission(['USUARIO_ALTERAR']),
                },
                {
                    onSuccess: (response: any) => {
                        const { data } = response.data;

                        if (data && hasPermission(['USUARIO_ALTERAR'])) {
                            setContent({ usuarioAcessoGrupoEconomicos: [], ...data });
                        } else {
                            navigate('/cadastro/usuario');
                        }
                    },
                },
            );
        },
        [hasPermission, mutate, navigate, setContent],
    );

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsUsuario: Yup.string().required().nullable(),
                dsLogin: Yup.string().required().nullable(),
                tipoUsuario: Yup.object().required().nullable(),
                dsCelular: Yup.string().min(11).nullable(),
                dsFone: Yup.string().min(10).nullable(),
                usuarioAcessoGrupoEconomicos: Yup.array().when('tipoUsuario', {
                    is: (tipoUsuario: UsersOptionsProps) => tipoUsuario?.tipoUsuarioEnum === 'FRANQUEADO',
                    then: Yup.array().min(1, 'É necessário informar ao mínimo 1 Grupo Econômico'),
                }),
                loja: Yup.object().required().nullable(),
                usuarioFuncionario: Yup.object().when('tipoUsuario', {
                    is: (tipoUsuario: UsersOptionsProps) => tipoUsuario?.tipoUsuarioEnum === 'FUNCIONARIO',
                    then: Yup.object({
                        cargo: Yup.object().required(),
                        tipoEntrega: Yup.object().required().nullable(),
                        localFuncionario: Yup.object().required().nullable(),
                    }),
                    otherwise: Yup.object({
                        cargo: Yup.object().nullable(),
                        tipoEntrega: Yup.object().nullable(),
                        localFuncionario: Yup.object().nullable(),
                    }),
                }),
            }),
        [],
    );

    const getFilterLojaGrupoEconomico = useCallback(
        (gruposEconomicos: GruposEconomicosProps[]): FilterValuesProps[] => [
            {
                property: 'idGrupoEconomico',
                value: gruposEconomicos?.map((grupo) => grupo.idGrupoEconomico),
                operator: 'in',
                logicalOperator: 'AND',
            },
        ],
        [],
    );
    const requestOptionsAcessos: RequestOptionsType = {
        url: '/gestao/grupousuario',
        filter: [{ property: 'fgPadraoFranqueado', operator: 'eq', value: true }],
        sort: [{ property: 'idGrupoUsuario', direction: 'DESC' }],
    };

    const { data: dataPermissoesPadrao } = useQuery(
        [requestOptionsAcessos],
        (): Promise<RequestGrupoUsuario> => RequestListagem(requestOptionsAcessos).then((res) => res.data),
        {
            enabled: !id,
            cacheTime: 0,
        },
    );

    // Formata os campos de telefone e celular, para caso tiverem mais de 11 números, pegar os últimos 11 digitos
    const mapContentToInitialValues = useCallback((content: UsuarioProps) => {
        if (content?.dsCelular && content?.dsCelular?.length > 11) {
            content.dsCelular = content.dsCelular.slice(-11);
        }

        if (content?.dsFone && content?.dsFone?.length > 11) {
            content.dsFone = content.dsFone.slice(-11);
        }

        return content;
    }, []);

    return (
        <FormPage
            title="Usuário"
            values={initialUsuarioValues}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            mapContentToInitialValues={mapContentToInitialValues}
            goBackButton
            fgAtivo
        >
            {(formik: FormikProps<UsuarioProps>) => {
                const usuarioAcessoGrupoEconomicos = formik.values.usuarioAcessoGrupoEconomicos;

                return (
                    <React.Fragment>
                        <FullLoading loading={isLoading} />

                        <Block>
                            <Row>
                                <Grid item>
                                    <CissPrimaryKeyField value={formik.values.idUsuario} name="idUsuario" />
                                </Grid>

                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Nome completo"
                                        name="dsUsuario"
                                        value={formik.values.dsUsuario}
                                        error={formik.errors.dsUsuario}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('dsUsuario', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Login"
                                        value={formik.values.dsLogin}
                                        error={formik.errors.dsLogin}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('dsLogin', e.target.value)}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Código Externo"
                                        name="cdUsuario"
                                        value={formik.values.cdUsuario}
                                        error={formik.errors.cdUsuario}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('cdUsuario', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="E-mail"
                                        name="dsEmail"
                                        value={formik.values.dsEmail}
                                        error={formik.errors.dsEmail}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('dsEmail', e.target.value || null)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissMaskField
                                        label="Telefone"
                                        name="dsFone"
                                        format="phone"
                                        value={formik.values.dsFone}
                                        error={formik.errors.dsFone}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('dsFone', e.target.value || null)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissMaskField
                                        label="Celular/WhatsApp"
                                        name="dsCelular"
                                        format="phone"
                                        value={formik.values.dsCelular}
                                        error={formik.errors.dsCelular}
                                        disabled={isTipoPedidoFuncionario}
                                        onChange={(e) => formik.setFieldValue('dsCelular', e.target.value || null)}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CepField value={formik.values.cep?.nrCep || ''} disabled={isTipoPedidoFuncionario} />
                                </Grid>

                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Endereço"
                                        name="dsCelular"
                                        value={formik.values.dsEndereco}
                                        error={formik.errors.dsEndereco}
                                        disabled={isTipoPedidoFuncionario || !formik.values.cep?.cidade?.dsCidade}
                                        onChange={(e) => formik.setFieldValue('dsEndereco', e.target.value || null)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Número"
                                        name="nrEndereco"
                                        preventEmptyField={false}
                                        value={formik.values.nrEndereco}
                                        error={formik.errors.nrEndereco}
                                        disabled={isTipoPedidoFuncionario || !formik.values.cep?.cidade?.dsCidade}
                                        onChange={(e) => formik.setFieldValue('nrEndereco', e.target.value || null)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Complemento"
                                        name="dsComplemento"
                                        value={formik.values.dsComplemento}
                                        error={formik.errors.dsComplemento}
                                        disabled={isTipoPedidoFuncionario || !formik.values.cep?.cidade?.dsCidade}
                                        onChange={(e) => formik.setFieldValue('dsComplemento', e.target.value || null)}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Bairro"
                                        name="dsBairro"
                                        value={formik.values.dsBairro}
                                        error={formik.errors.dsBairro}
                                        disabled={isTipoPedidoFuncionario || !formik.values.cep?.cidade?.dsCidade}
                                        onChange={(e) => formik.setFieldValue('dsBairro', e.target.value || null)}
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <CissTextField
                                        disabled
                                        label="Estado"
                                        name="dsEstado"
                                        value={formik.values.cep?.estado?.dsEstado || ''}
                                        error={getIn(formik.errors, 'cep.estado')}
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <CissTextField
                                        disabled
                                        label="Cidade"
                                        name="dsCidade"
                                        value={formik.values.cep?.cidade?.dsCidade || ''}
                                        error={getIn(formik.errors, 'cep.cidade')}
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label={'Tipo do usuário'} />

                            <Row>
                                <Grid item flex={1}>
                                    <TipoUsuarioAutoComplete
                                        value={formik.values.tipoUsuario}
                                        name="tipoUsuario"
                                        error={formik.errors.tipoUsuario}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('tipoUsuario', value);

                                            if (value?.idTipoUsuario === 2) {
                                                formik.setFieldValue('usuarioAcessoGrupoEconomicos', []);
                                                formik.setFieldValue('usuarioAcessoLojas', []);
                                                formik.setFieldValue('usuarioGrupoUsuarios', dataPermissoesPadrao?.data || []);
                                                formik.setFieldValue('loja', null);
                                            } else {
                                                formik.setFieldValue('usuarioGrupoUsuarios', []);
                                            }
                                        }}
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        {formik.values.tipoUsuario?.tipoUsuarioEnum === 'FUNCIONARIO' && (
                            <Block>
                                <SubTitle label={'Funcionário'} />

                                <Row>
                                    <Grid item flex={1}>
                                        <LocalFuncionarioAutoComplete
                                            value={formik.values.usuarioFuncionario?.localFuncionario}
                                            disableClearable={true}
                                            name="usuarioFuncionario.localFuncionario"
                                            error={getIn(formik.errors, 'usuarioFuncionario.localFuncionario')}
                                            onChange={(e, value) => formik.setFieldValue('usuarioFuncionario.localFuncionario', value || null)}
                                        />
                                    </Grid>

                                    <Grid item flex={1}>
                                        <TipoEntregaAutoComplete
                                            value={formik.values.usuarioFuncionario?.tipoEntrega || null}
                                            disableClearable={true}
                                            name="usuarioFuncionario.tipoEntrega"
                                            error={getIn(formik.errors, 'usuarioFuncionario.tipoEntrega')}
                                            onChange={(e, value) => formik.setFieldValue('usuarioFuncionario.tipoEntrega', value)}
                                        />
                                    </Grid>

                                    <Grid item flex={1}>
                                        <CargoFuncionarioAutoComplete
                                            value={formik.values.usuarioFuncionario?.cargo || null}
                                            name="usuarioFuncionario.cargo"
                                            error={getIn(formik.errors, 'usuarioFuncionario.cargo')}
                                            onChange={(e, value) => formik.setFieldValue('usuarioFuncionario.cargo', value)}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        flex={0}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Field
                                            as={FormControlLabel}
                                            type="checkbox"
                                            name="usuarioFuncionario.fgAtivoCompra"
                                            control={
                                                <Checkbox
                                                    checked={!formik.values.usuarioFuncionario?.fgAtivoCompra}
                                                    onChange={(e) => formik.setFieldValue('usuarioFuncionario.fgAtivoCompra', !e.target.checked)}
                                                />
                                            }
                                            label="Ausente"
                                        />
                                    </Grid>
                                </Row>
                            </Block>
                        )}

                        {formik.values.tipoUsuario?.idTipoUsuario === 2 && (
                            <Block>
                                <SubTitle label={'Grupos Econômicos'} />

                                <Button id="usuarioAcessoGrupoEconomicos" variant="contained" onClick={() => setOpenUsuarioModal(true)}>
                                    <Typography variant="button">Gerenciar Grupos Econômicos</Typography>
                                </Button>

                                {formik.errors.usuarioAcessoGrupoEconomicos && (
                                    <Typography color="error" fontSize="0.85rem" lineHeight={1.5} mt={1}>
                                        {formik.errors.usuarioAcessoGrupoEconomicos as string}
                                    </Typography>
                                )}

                                {Boolean(usuarioAcessoGrupoEconomicos?.length) && (
                                    <ListagemProvider>
                                        <GrupoEconomicoListGrid gruposEconomicos={usuarioAcessoGrupoEconomicos} />
                                    </ListagemProvider>
                                )}

                                <ListagemProvider>
                                    <GrupoEconomicoListGridModal
                                        open={openUsuarioModal}
                                        setOpen={setOpenUsuarioModal}
                                        gruposEconomicos={usuarioAcessoGrupoEconomicos}
                                    />
                                </ListagemProvider>
                            </Block>
                        )}

                        {(Boolean(usuarioAcessoGrupoEconomicos?.length) || formik.values.tipoUsuario?.idTipoUsuario !== 2) && (
                            <Block>
                                <SubTitle label={'Lojas'} />

                                {formik.values.tipoUsuario?.idTipoUsuario === 2 && (
                                    <Row>
                                        <Grid item flex={1}>
                                            <StoreAutoComplete
                                                label="Lojas"
                                                name="usuarioAcessoLojas"
                                                multiple
                                                defaultStore={false}
                                                importStores={false}
                                                value={formik.values.usuarioAcessoLojas}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('usuarioAcessoLojas', value);

                                                    if (value.length && formik.values.loja && !value.includes(formik.values.loja)) {
                                                        formik.setFieldValue('loja', null);
                                                    }
                                                }}
                                                requestOptions={{
                                                    url: '/gestao/loja',
                                                    sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                                                    columns: 'idLoja,cdLoja,dsNomeFantasia',
                                                    filter: getFilterLojaGrupoEconomico(usuarioAcessoGrupoEconomicos),
                                                }}
                                            />
                                        </Grid>
                                    </Row>
                                )}

                                <Row>
                                    <Grid item flex={1}>
                                        <StoreAutoComplete
                                            label="Loja Padrão"
                                            name="loja"
                                            defaultStore={false}
                                            value={formik.values.loja}
                                            error={formik.errors.loja}
                                            options={formik.values.usuarioAcessoLojas.length ? formik.values.usuarioAcessoLojas : undefined}
                                            onChange={(e, value) => formik.setFieldValue('loja', value)}
                                            requestOptions={
                                                formik.values.usuarioAcessoLojas.length
                                                    ? null
                                                    : {
                                                          url: '/gestao/loja',
                                                          sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                                                          columns: 'idLoja,cdLoja,dsNomeFantasia',
                                                          filter: getFilterLojaGrupoEconomico(usuarioAcessoGrupoEconomicos),
                                                      }
                                            }
                                        />
                                    </Grid>
                                </Row>
                            </Block>
                        )}

                        <Block>
                            <SubTitle label={'Perfis do usuário'} />

                            <Row>
                                <Grid item flex={1}>
                                    <Field component={GrupoUsuarioField} multiple={true} name="usuarioGrupoUsuarios" />
                                </Grid>
                            </Row>
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
