import { useNavigate } from 'react-router-dom';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';
import { PedidoRealizadoModelProps } from 'pages/compra/pedido-realizado/types';

export function useMutatePayment() {
    const { row, setDisabledActions } = useActionMenu();
    const { mutate } = useFormMutation<{ data: { data: PedidoRealizadoModelProps } }>();
    const { showError } = useShowError();
    const navigate = useNavigate();

    const mutatePayment = () => {
        setDisabledActions(true);

        mutate(
            {
                url: `/compra/pedidocompra/${row.id}/clona/cancela?substituirRascunhoAtual=true`,
                preventSnack: true,
                preventRedirect: true,
            },
            {
                onSuccess: ({ data }) => navigate(`/compra/pagamento?idsPedido=${data.data.idPedido}`),
                onError: (error) => showError(error, 'Erro ao realizar o processo de alterar o pagamento do pedido'),
                onSettled: () => setDisabledActions(false),
            },
        );
    };

    return { mutatePayment };
}
