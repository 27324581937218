import { useSearchParams } from 'react-router-dom';

import { Alert } from '@mui/material';

import { usePermissionsContext } from 'context/PermissionsContext';
import { PagamentoFormComp } from 'pages/compra/pagamento/components';
import { PagamentoProvider } from 'pages/compra/pagamento/contexts';

export function PagamentoForm() {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    const [searchParams] = useSearchParams();

    if (!hasPermission(['COMPRA_PEDIDO_FINALIZAR'])) return alertNotPermission();

    if (!searchParams.get('idsPedido')) {
        return (
            <Alert sx={{ m: 2 }} severity="error">
                É necessário informar um id de pedido para efetuar o pagamento.
            </Alert>
        );
    }

    return (
        <PagamentoProvider>
            <PagamentoFormComp />
        </PagamentoProvider>
    );
}
