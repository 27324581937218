import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { usePagamentoContext } from '../contexts/PagamentoContext';
import { CartaoPagamento, RequestCartaoPagamento, RequestPagarme, ResponsePagarme } from '../types';

import { useMutateCartaoPagamento } from './useMutateCartaoPagamento';

export function useMutateGateway() {
    const { gatewayPagamento, pagamento } = usePagamentoContext();
    const { mutateCartaoPagamento, isLoadingCartoesPagamento } = useMutateCartaoPagamento();
    const isPagarme = gatewayPagamento?.gatewayPagamentoEnum === 'PAGARME';

    /**
     * Retorna a url para realizar a requisição de tokenização do cartão.
     */
    const getUrlGateway = () => {
        if (isPagarme) {
            return `https://api.pagar.me/core/v5/tokens?appId=${gatewayPagamento.dsChavePublica}`;
        }

        return '';
    };

    /**
     * Retorna os dados do cartão formatados de acordo com o tipo de gateway utilizado para realizar a request ao gateway.
     */
    const getFormattedRequestData = (data: RequestCartaoPagamento): RequestPagarme | null => {
        if (isPagarme) {
            return {
                card: {
                    cvv: data.cdCvv,
                    number: data.nrCartao,
                    holder_name: data.dsApelido,
                    exp_year: data.dtValidade?.getFullYear(),
                    exp_month: data.dtValidade?.getMonth() + 1,
                },
            };
        }

        return null;
    };

    /**
     * Retorna os dados do retorno do gateway do cartão formatados de acordo com o tipo de gateway utilizado.
     */
    const getFormattedResponseData = (response: ResponsePagarme): CartaoPagamento | null => {
        if (isPagarme) {
            return {
                token: response.id,
                dsApelido: response.card.holder_name,
                cdDigitosFinais: response.card.last_four_digits,
                nrMesValidade: response.card.exp_month,
                nrAnoValidade: response.card.exp_year,
                gatewayPagamento,
                tipoCartaoPagamento: {
                    tipoCartaoPagamentoEnum: 'CREDITO',
                },
                bandeiraCartao: {
                    dsBandeiraCartao: response.card.brand,
                },
                usuario: {
                    idUsuario: pagamento?.idUsuarioPedido as number,
                },
            };
        }

        return null;
    };

    const { mutate: mutateGateway, isLoading: isLoadingGateway } = useMutation<ResponsePagarme, unknown, RequestCartaoPagamento>(
        (data) =>
            axios
                .post(getUrlGateway(), getFormattedRequestData(data), { headers: { 'content-type': 'application/json' } })
                .then((response) => response.data),
        {
            onSuccess: (data) => {
                const formattedData = getFormattedResponseData(data);

                if (formattedData) {
                    mutateCartaoPagamento(formattedData);
                }
            },
            onError: () => enqueueSnackbar('Erro ao salvar cartão. Verifique os dados e/ou tente novamente.', { variant: 'error' }),
        },
    );

    return { mutateGateway, isLoading: isLoadingCartoesPagamento || isLoadingGateway };
}
