import { useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { RequestListagem, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';

export function useQueryCondicoesPagamento() {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const requestListagem = useRequestListagem();
    const { sort, page, limit } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        page,
        limit,
        nested: true,
        url: '/gestao/condicaopagamento',
        sort: sort?.length ? sort : [{ property: 'idCondicaoPagamento', direction: 'ASC' }],
        filter: [
            { property: 'fgAtivo', value: true, operator: 'eq' },
            {
                property: 'idGatewayPagamento',
                operator: 'in',
                value: configPedidoCondicoesPagamento.gatewayPagamento.map((item) => item.idGatewayPagamento),
            },
            {
                property: 'idCondicaoPagamento',
                value: configPedidoCondicoesPagamento.condicaoPagamentoGeral.map((item) => item.idCondicaoPagamento),
                operator: 'neq',
            },
        ],
    };

    return useQuery<RequestListagem<CondicaoPagamento[]>>(['queryCondicoesPagamento', requestOptions], () =>
        requestListagem(requestOptions).then((res) => res.data),
    );
}
