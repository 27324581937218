import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { getDialogGradeError } from 'pages/compra/pedido-realizado/components/atoms/action-menu/PedidoRealizadoActionMenu';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoReprocessBtn() {
    const { row, disabledActions, setDisabledActions, setOpenConfirmModal, setDataConfirmModal } = useActionMenu();
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { idPedido } = row;
    const queryClient = useQueryClient();

    const handleReprocess = (fgEnviaNovaGrade = false) => {
        setDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/reprocessa${fgEnviaNovaGrade ? '?fgEnviaNovaGrade=true' : ''}`, preventSnack: true },
            {
                onSuccess: () => {
                    enqueueSnackbar('Pedido reprocessado com sucesso', { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setDisabledActions(false));
                },
                onError: (error) => {
                    const dialogGradeError = getDialogGradeError(error);

                    setDisabledActions(false);

                    if (dialogGradeError) {
                        setOpenConfirmModal(true);
                        setDataConfirmModal({ callback: () => handleReprocess(true), text: dialogGradeError });

                        return;
                    }

                    showError(error, 'Não foi possível reprocessar o pedido. Tente novamente');
                },
            },
        );
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<CachedOutlinedIcon />}
            disabled={disabledActions}
            loading={isLoading}
            onClick={() => {
                setOpenConfirmModal(true);

                setDataConfirmModal({
                    callback: handleReprocess,
                    text: 'Tem certeza que deseja reprocessar o registro selecionado?',
                });
            }}
        >
            Reprocessar
        </LoadingButton>
    );
}
