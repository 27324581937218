import { Box } from '@mui/material';

import { PagamentoFinishButton, PagamentoNextButton, PagamentoPixButton } from 'pages/compra/pagamento/components';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoFooter() {
    const { pagamento, isPending, selecaoMeioPagamento, pagamentoRequestError } = usePagamentoContext();
    const isPendingPix = isPending && selecaoMeioPagamento?.meioPagamentoEnum === 'PIX';
    const hasErrors = Boolean(pagamento?.erros?.length || pagamentoRequestError);
    const showFinishButton = !isPendingPix && !hasErrors;
    const showNextButton = hasErrors || isPendingPix;

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'sticky',
                justifyContent: 'flex-end',
                backgroundColor: 'grey.100',
                gap: 2,
                bottom: 0,
                padding: 2,
                marginX: -2,
            }}
        >
            {isPendingPix && <PagamentoPixButton />}

            {showNextButton && <PagamentoNextButton />}

            {showFinishButton && <PagamentoFinishButton />}
        </Box>
    );
}
