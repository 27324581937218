import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { ContentModal } from 'components/modal';
import { PagamentoPixFailure } from 'components/modal/pagamento-pix/PagamentoPixFailure';
import { PagamentoPixPending } from 'components/modal/pagamento-pix/PagamentoPixPending';
import { PagamentoPixSuccess } from 'components/modal/pagamento-pix/PagamentoPixSuccess';
import { PagamentoPixVerifying } from 'components/modal/pagamento-pix/PagamentoPixVerifying';
import { SubTitle } from 'components/text';
import { useBeforeUnloadWindow } from 'hooks/useBeforeUnloadWindow';
import { useQueryPayment } from 'hooks/useQueryPayment';
import { useVisibilityChangeWindow } from 'hooks/useVisibilityChangeWindow';
import { PedidoPagamento } from 'pages/compra/pagamento/types';

type PagamentoPixModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    pagamento: PedidoPagamento;
    closeOnPending?: boolean;
};

/**
 * Retorna o tempo restante para o pagamento, de acordo com o horário de expiração.
 */
const getInitialPaymentTimeLeft = (pagamento: PedidoPagamento) => {
    const expirationTime = new Date(pagamento.dhExpiracaoPagamento).getTime();
    const currentTime = new Date().getTime();

    return Math.max(Math.floor((expirationTime - currentTime) / 1000), 0);
};

export function PagamentoPixModal({ open, setOpen, pagamento, closeOnPending = false }: PagamentoPixModal) {
    const [paymentTimeLeft, setPaymentTimeLeft] = useState<number>(() => getInitialPaymentTimeLeft(pagamento));
    const { data } = useQueryPayment({ idPagamento: pagamento.idPedidoPagamento });
    const failurePayment = data && ['FALHA', 'CANCELADO'].includes(data.statusPedidoPagamento.statusPedidoPagamentoEnum);
    const successPayment = data?.statusPedidoPagamento.statusPedidoPagamentoEnum === 'PAGO';
    const pendingPayment = !failurePayment && !successPayment && paymentTimeLeft > 0;
    const veriyingPayment = !failurePayment && !successPayment && paymentTimeLeft === 0;

    useBeforeUnloadWindow({ prevent: !closeOnPending && pendingPayment });

    useVisibilityChangeWindow({
        callback: () => {
            if (!document.hidden) {
                setPaymentTimeLeft(getInitialPaymentTimeLeft(pagamento));
            }
        },
    });

    /**
     * Cria um intervalo para ajustar o tempo restante.
     */
    const adjustPayementTimeLeft = () => {
        const timer = setInterval(() => {
            setPaymentTimeLeft((prev) => {
                if (prev > 0) return prev - 1;

                clearInterval(timer);

                return 0;
            });
        }, 1000);

        if (failurePayment || successPayment) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    };

    useEffect(adjustPayementTimeLeft, [failurePayment, successPayment]);

    return (
        <ContentModal
            closeButton={!pendingPayment || closeOnPending}
            disableBackdropClick={pendingPayment && !closeOnPending}
            disableEscapeKeyDown={pendingPayment && !closeOnPending}
            open={open}
            setOpen={setOpen}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                borderRadius: 1,
                width: { xs: '90vw', sm: 500 },
                maxHeight: '90vh',
                minHeight: 480,
            }}
        >
            <SubTitle label="Pix" sx={{ m: 0 }} />

            {successPayment && <PagamentoPixSuccess />}

            {failurePayment && <PagamentoPixFailure />}

            {veriyingPayment && <PagamentoPixVerifying />}

            {pendingPayment && <PagamentoPixPending pagamento={pagamento} paymentTimeLeft={paymentTimeLeft} />}

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                }}
            >
                {pendingPayment && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigator.clipboard.writeText(pagamento.qrCode);

                            enqueueSnackbar('Código copiado com sucesso', { variant: 'success' });
                        }}
                    >
                        Copiar código
                    </Button>
                )}

                {(!pendingPayment || closeOnPending) && (
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                )}
            </Box>
        </ContentModal>
    );
}
