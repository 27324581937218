import { Button } from '@mui/material';

import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoPixButton() {
    const { setOpenPagamentoPixModal } = usePagamentoContext();

    return (
        <Button variant="outlined" onClick={() => setOpenPagamentoPixModal(true)}>
            Visualizar pagamento pix
        </Button>
    );
}
