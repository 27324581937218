import React from 'react';

import { DreFormComp, DreListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { DreListProvider } from 'pages/financeiro/dre/contexts';

export function DreForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DRE_PREENCHER'])) return alertNotPermission();

    return (
        <FormProvider>
            <DreFormComp />
        </FormProvider>
    );
}

export function DreList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DRE_VISUALIZAR'])) return alertNotPermission();

    return (
        <DreListProvider>
            <ListagemProvider>
                <DreListComp />
            </ListagemProvider>
        </DreListProvider>
    );
}
