import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { Field, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissNumberField, CissTextField } from 'components/fields';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { ConfigEstadoRequestProps, initialValuesFormik } from 'pages/complementar/dados-entrega/types';

export function DadosEntregaFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const url = '/gestao/configestado';
    const { mutate } = useFormMutation();

    const handleSubmit = (values: ConfigEstadoRequestProps, formik: FormikHelpers<ConfigEstadoRequestProps>): void => {
        mutate(
            {
                url,
                idField: 'idConfigEstado',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                vlFrete: Yup.number().required(),
                nrDiasPrevisaoEntrega: Yup.number().required(),
            }),
        [],
    );

    return (
        <FormPage title="Frete e Prazos" values={initialValuesFormik} fgAtivo={false} onSubmit={handleSubmit} url={url} validationSchema={validationSchema} goBackButton>
            {(formik: FormikProps<ConfigEstadoRequestProps>) => {
                return (
                    <Block>
                        <Row>
                            <Grid item flex={1}>
                                <Field component={StateFieldFormik} name="estado" multiple={false} disabled />
                            </Grid>

                            <Grid item flex={1}>
                                <CissNumberField
                                    label="Valor Frete R$"
                                    name="vlFrete"
                                    value={formik.values.vlFrete}
                                    error={formik.errors.vlFrete}
                                    onChange={(e) => formik.setFieldValue('vlFrete', Number(e.target.value))}
                                    inputType="decimal"
                                />
                            </Grid>
                        </Row>

                        <Row>
                            <Grid item flex={1}>
                                <CissNumberField
                                    preventEmptyField={false}
                                    label="Dias de Previsão para entrega"
                                    name="nrDiasPrevisaoEntrega"
                                    value={formik.values.nrDiasPrevisaoEntrega}
                                    error={formik.errors.nrDiasPrevisaoEntrega}
                                    onChange={(e) => formik.setFieldValue('nrDiasPrevisaoEntrega', e.target.value)}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField
                                    label="Transportadora"
                                    name="dsTransportadora"
                                    value={formik.values.dsTransportadora}
                                    error={formik.errors.dsTransportadora}
                                    onChange={(e) => formik.setFieldValue('dsTransportadora', e.target.value || null)}
                                />
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
