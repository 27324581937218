import { GatewayPagamento } from 'components/autocompletes/GatewayPagamentoAutoComplete';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { CondicaoPagamentoLoja, LojasProps } from 'pages/compra/configuracao-pedido/types';

export function useActionsCondicoesPagamento() {
    const { configPedidoCondicoesPagamento, setConfigPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();

    /**
     * Retorna as novas lojas a serem adicionas de acordo com as lojas selecionadas.
     */
    const getNewCondicoesPagamentoLoja = (selectedLojas: LojasProps[]): CondicaoPagamentoLoja[] =>
        selectedLojas
            .filter((selectedLoja) => !configPedidoCondicoesPagamento.condicaoPagamentoLoja.some((loja) => loja.loja.cdLoja === selectedLoja.cdLoja))
            .map(
                (selectedLoja): CondicaoPagamentoLoja => ({
                    loja: selectedLoja,
                    condicaoPagamentoList: [],
                }),
            );

    /**
     * Adiciona novas lojas nas condições de pagamento.
     */
    const addCondicoesPagamentoLoja = (selectedLojas: LojasProps[]) => {
        const condicoesPagamentoLojaToAdd = getNewCondicoesPagamentoLoja(selectedLojas);

        setActiveStepDirty();

        setConfigPedidoCondicoesPagamento((oldValues) => ({
            ...oldValues,
            condicaoPagamentoLoja: [...configPedidoCondicoesPagamento.condicaoPagamentoLoja, ...condicoesPagamentoLojaToAdd],
        }));
    };

    /**
     * Remove as condições de pagamento da loja com base no array informado.
     */
    const removeCondicoesPagamentoLoja = (cdsLoja: number[]) => {
        const newCondicoesPagamentoLoja = configPedidoCondicoesPagamento.condicaoPagamentoLoja.filter((loja) => !cdsLoja.includes(loja.loja.cdLoja));

        setActiveStepDirty();

        setConfigPedidoCondicoesPagamento((oldValues) => ({
            ...oldValues,
            condicaoPagamentoLoja: newCondicoesPagamentoLoja,
        }));
    };

    /**
     * Retorna as condições de pagamento da loja atualizadas com base no array informado.
     */
    const getUpdatedCondicoesPagamentoLoja = (cdLoja: number | null, condicoesPagamento: CondicaoPagamento[]): CondicaoPagamentoLoja[] =>
        configPedidoCondicoesPagamento.condicaoPagamentoLoja.map((loja) => {
            if (loja.loja.cdLoja === cdLoja) {
                return {
                    loja: loja.loja,
                    condicaoPagamentoList: condicoesPagamento,
                };
            }

            return loja;
        });

    /**
     * Atualiza as condições de pagamento da loja com base no array informado.
     */
    const updateCondicoesPagamentoLoja = (cdLoja: number | null, condicoesPagamento: CondicaoPagamento[]) => {
        const newCondicoesPagamentoLoja = getUpdatedCondicoesPagamentoLoja(cdLoja, condicoesPagamento);

        setActiveStepDirty();

        setConfigPedidoCondicoesPagamento((oldValues) => ({
            ...oldValues,
            condicaoPagamentoLoja: newCondicoesPagamentoLoja,
        }));
    };

    /**
     * Retorna as condições de pagamento dentro da loja que não estão na lista de condições de pagamento geral.
     */
    const getFormattedCondicoesPagamentoLojaList = (
        idsCondicoesPagamento: number[],
        condicaoPagamentoList: CondicaoPagamento[],
    ): CondicaoPagamento[] =>
        condicaoPagamentoList.filter((condicaoPagamento) => !idsCondicoesPagamento.includes(condicaoPagamento.idCondicaoPagamento as number));

    /**
     * Retorna as condições de pagamento por loja formatadas de acordo com as condições de pagamento geral.
     */
    const getFormattedCondicoesPagamentoLoja = (condicoesPagamento: CondicaoPagamento[]): CondicaoPagamentoLoja[] => {
        const idsCondicoesPagamento = condicoesPagamento.map((condicaoPagamento) => condicaoPagamento.idCondicaoPagamento as number);

        return configPedidoCondicoesPagamento.condicaoPagamentoLoja.map((loja) => {
            return {
                loja: loja.loja,
                condicaoPagamentoList: getFormattedCondicoesPagamentoLojaList(idsCondicoesPagamento, loja.condicaoPagamentoList),
            };
        });
    };

    /**
     * Atualiza as condições de pagamento das lojas com as condições de pagamento que não estão na lista de condições de pagamento geral.
     */
    const formatCondicoesPagamentoLoja = (condicoesPagamento: CondicaoPagamento[]) => {
        const newCondicoesPagamentoLoja = getFormattedCondicoesPagamentoLoja(condicoesPagamento);

        setActiveStepDirty();

        setConfigPedidoCondicoesPagamento((oldValues) => ({
            ...oldValues,
            condicaoPagamentoGeral: condicoesPagamento,
            condicaoPagamentoLoja: newCondicoesPagamentoLoja,
        }));
    };

    /**
     * Retorna um set com os enuns dos gateways de pagamento informados como parâmetro.
     */
    const getGatewayPagamentoEnum = (gatewaysPagamento: GatewayPagamento[]) =>
        new Set(gatewaysPagamento.map((gateway) => gateway.gatewayPagamentoEnum));

    /**
     * Retorna as condições de pagamento informadas de acordo com os gateways de pagamento informados.
     */
    const filterCondicoesPagamentoFromGateway = (gatewaysPagamento: GatewayPagamento[], condicoesPagamento: CondicaoPagamento[]) => {
        const gatewaysPagamentoEnum = getGatewayPagamentoEnum(gatewaysPagamento);

        return condicoesPagamento.filter((condicaoPagamento) =>
            gatewaysPagamentoEnum.has(condicaoPagamento.gatewayPagamento?.gatewayPagamentoEnum as GatewayPagamento['gatewayPagamentoEnum']),
        );
    };

    /**
     * Formata as condições de pagamento, tanto as gerais quanto as existentes dentro das lojas,
     * de acordo com os gateways de pagamento informados.
     */
    const formatCondicoesPagamentoFromGateway = (gatewaysPagamento: GatewayPagamento[]) => {
        const newCondicoesPagamento = filterCondicoesPagamentoFromGateway(gatewaysPagamento, configPedidoCondicoesPagamento.condicaoPagamentoGeral);

        const newCondicoesPagamentoLoja = configPedidoCondicoesPagamento.condicaoPagamentoLoja.map((loja) => ({
            loja: loja.loja,
            condicaoPagamentoList: filterCondicoesPagamentoFromGateway(gatewaysPagamento, loja.condicaoPagamentoList),
        }));

        setConfigPedidoCondicoesPagamento({
            gatewayPagamento: gatewaysPagamento,
            condicaoPagamentoGeral: newCondicoesPagamento,
            condicaoPagamentoLoja: newCondicoesPagamentoLoja,
        });
    };

    return {
        addCondicoesPagamentoLoja,
        removeCondicoesPagamentoLoja,
        updateCondicoesPagamentoLoja,
        formatCondicoesPagamentoLoja,
        formatCondicoesPagamentoFromGateway,
    };
}
