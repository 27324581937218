import { FullLoading } from 'components/loading';
import { OneColumn } from 'components/page';
import { PagamentoErrors, PagamentoFooter, PagamentoInfo, PagamentoMethod } from 'pages/compra/pagamento/components';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { useQueryPagamento } from 'pages/compra/pagamento/hooks';

export function PagamentoFormComp() {
    const { pagamento, pagamentoRequestError } = usePagamentoContext();
    const { isLoadingPagamento } = useQueryPagamento();
    const hasErrors = Boolean(pagamento?.erros?.length || pagamentoRequestError);
    const hasCondicoesPagamento = pagamento?.condicaoPagamentoList?.length;

    return (
        <>
            <FullLoading loading={isLoadingPagamento} />

            <OneColumn title={pagamento ? `Pagamento - Pedido ${pagamento.nrPedido}` : 'Pagamento'} goBackButton>
                <PagamentoInfo />

                {hasErrors && <PagamentoErrors />}

                {!hasErrors && hasCondicoesPagamento && <PagamentoMethod />}

                {<PagamentoFooter />}
            </OneColumn>
        </>
    );
}
