import { Box, Button } from '@mui/material';

import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';
import { PagamentoCartaoCreditoGrid, PagamentoCartaoCreditoModal } from 'pages/compra/pagamento/components';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoCartaoCredito() {
    const { openCartaoCreditoModal, setOpenCartaoCreditoModal, isPending, isFinishing } = usePagamentoContext();

    return (
        <>
            <PagamentoCartaoCreditoModal open={openCartaoCreditoModal} setOpen={setOpenCartaoCreditoModal} />

            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <SubTitle
                        label="Cartão de Crédito"
                        sx={{
                            flex: { xs: 1, lg: 'none' },
                            marginRight: { xs: 'none', lg: 'auto' },
                        }}
                    />

                    <Button variant="contained" size="small" disabled={isPending || isFinishing} onClick={() => setOpenCartaoCreditoModal(true)}>
                        Incluir
                    </Button>
                </Box>

                <ListagemProvider>
                    <PagamentoCartaoCreditoGrid />
                </ListagemProvider>
            </Box>
        </>
    );
}
