import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';

export function CondicaoPagamentoAutoComplete({
    sx,
    name,
    value,
    error,
    options,
    readOnly,
    disableClearable,
    multiple = false,
    disabled = false,
    onChange = () => {},
    size = 'medium',
    label = 'Condição de Pagamento',
    requestOptions = {
        url: '/gestao/condicaopagamento',
        sort: [{ property: 'idCondicaoPagamento', direction: 'ASC' }],
        filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
        limit: 50,
    },
}: CissAutoCompleteProps) {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            size={size}
            label={label}
            value={value}
            error={error}
            options={options}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            disableClearable={disableClearable}
            requestOptions={requestOptions}
            objectId="idCondicaoPagamento"
            selectedStringTemplate="{dsCondicaoPagamento}"
            optionStringTemplate="{dsCondicaoPagamento}"
            isOptionEqualToValue={(option: CondicaoPagamento, value: CondicaoPagamento) =>
                value && option && option.idCondicaoPagamento === value.idCondicaoPagamento
            }
        />
    );
}
