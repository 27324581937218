import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CondicaoPagamentoAutoComplete } from 'components/autocompletes';
import { ProdutoAutoComplete } from 'components/autocompletes/ProdutoAutoComplete';
import { CissNumberField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { Produto, ProdutoQuebra } from 'pages/compra/configuracao-pedido/types';

interface ConfiguracaoPedidoCompraProdutosQuebraModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const getNewQuebras = (produto: Produto, condicaoPagamento: CondicaoPagamento, qtQuebra: number): ProdutoQuebra[] => {
    const newQuebras: ProdutoQuebra[] = [];
    const now = new Date();
    const date = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000).toISOString();

    for (let i = 1; i <= qtQuebra; i++) {
        const peQuebra = Math.floor(100 / qtQuebra);

        newQuebras.push({
            produto: produto,
            qtQuebra: qtQuebra,
            condicaoPagamento: condicaoPagamento,
            nrQuebra: i,
            peQuebra: i !== qtQuebra ? peQuebra : 100 - peQuebra * qtQuebra + peQuebra,
            dtInicio: date,
            dtFim: date,
            fgAtivo: true,
        });
    }

    return newQuebras;
};

export function ConfiguracaoPedidoCompraProdutosQuebraModal({ open, setOpen }: ConfiguracaoPedidoCompraProdutosQuebraModalProps): JSX.Element {
    const [produto, setProduto] = useState<Produto | null>();
    const [condicaoPagamento, setCondicaoPagamento] = useState<CondicaoPagamento | null>();
    const [qtQuebra, setQtQuebra] = useState<number>(0);
    const { content, setContent } = useFormContext();
    const { enqueueSnackbar } = useSnackbar();
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { produtoQuebraList } = content || { produtoQuebraList: [] };

    const handleAdicionarQuebra = (): void => {
        if (produto && condicaoPagamento) {
            const productsInList = produtoQuebraList.some((produtoQuebra: ProdutoQuebra) => produtoQuebra.produto.idProduto === produto.idProduto);

            if (productsInList) {
                enqueueSnackbar('Já existem quebras relacionadas a esse produto', { variant: 'error' });

                return;
            }

            const newQuebras = getNewQuebras(produto, condicaoPagamento, qtQuebra);

            setContent({ ...content, produtoQuebraList: [...produtoQuebraList, ...newQuebras] });

            setOpen(false);

            setActiveStepDirty();
        }
    };

    useEffect(() => {
        if (!open) {
            setProduto(null);
            setCondicaoPagamento(null);
            setQtQuebra(0);
        }
    }, [open]);

    return (
        <ContentModal closeButton open={open} setOpen={setOpen} sx={{ padding: 2, borderRadius: 1, maxWidth: 500 }}>
            <React.Fragment>
                <SubTitle label="Incluir Quebra" />

                <ProdutoAutoComplete
                    label="Produtos"
                    size="small"
                    multiple={false}
                    value={produto}
                    sx={{ mb: 2 }}
                    onChange={(e, value) => setProduto(value)}
                />

                <CissNumberField
                    label="Quantidade de quebras"
                    size="small"
                    value={qtQuebra}
                    sx={{ mb: 2 }}
                    onChange={(e) => setQtQuebra(Number(e.target.value))}
                />

                <CondicaoPagamentoAutoComplete
                    sx={{ mb: 2 }}
                    size="small"
                    value={condicaoPagamento}
                    onChange={(e, value) => setCondicaoPagamento(value)}
                />

                <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <Button variant="contained" onClick={() => handleAdicionarQuebra()} disabled={!produto || !qtQuebra || !condicaoPagamento}>
                        Incluir
                    </Button>
                </Stack>
            </React.Fragment>
        </ContentModal>
    );
}
