import { ChangeEvent, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { useGenericAuth } from 'hooks/useGenericAuth';

export function RevendedorGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { tokenInfo } = useGenericAuth();
    const requiredStore = tokenInfo?.tpUsuario && !['MASTER', 'SUPORTE_CISS'].includes(tokenInfo?.tpUsuario);

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        idProperty: 'cdLoja',
        property: 'cdSap',
        operator: 'eq',
        value: null,
        required: requiredStore ? 'Loja é obrigatória' : '',
    });

    const [filterDocumento, setFilterDocumento] = useState<FilterValuesProps>({
        property: 'documento',
        operator: 'eq',
        value: null,
    });

    const filters = [
        { value: filterDocumento, setter: setFilterDocumento },
        { value: filterLoja, setter: setFilterLoja },
    ];

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    /**
     * Formata o input para permitir apenas números.
     */
    const onInputChangeDocumento = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/\D/g, '');
    };

    return (
        <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
            <Grid item flex={4}>
                <StoreAutoComplete
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Documento"
                    value={filterDocumento.value}
                    onInput={onInputChangeDocumento}
                    onChange={(e) => setFilterDocumento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
