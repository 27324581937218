import React, { Dispatch, SetStateAction, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, SxProps } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid-pro';

import { ConfirmModal } from 'components/modal';
import { ConfiguracaoPedidoCompraPesquisaField } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraPesquisaField';

type ConfiguracaoPedidoHeaderActions = {
    searchPlaceholder?: string;
    selectedRows: GridSelectionModel;
    setValueFilter?: Dispatch<SetStateAction<string>>;
    onClickRemove: () => void;
    onClickAdd: () => void;
    sx?: SxProps;
    showSearchField?: boolean;
    addDisabled?: boolean;
    removeDisabled?: boolean;
};

export function ConfiguracaoPedidoHeaderActions({
    searchPlaceholder,
    setValueFilter = () => {},
    onClickRemove,
    onClickAdd,
    selectedRows,
    sx,
    showSearchField = true,
    addDisabled,
    removeDisabled,
}: ConfiguracaoPedidoHeaderActions): JSX.Element {
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const selectedRowsLength = selectedRows.length;

    return (
        <React.Fragment>
            <ConfirmModal
                open={openRemoveModal}
                setOpen={setOpenRemoveModal}
                confirm={onClickRemove}
                text={`Tem certeza que deseja remover ${selectedRowsLength} registro(s) selecionado(s)?`}
            />

            <Box
                sx={{
                    gap: 2,
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'center',
                    justifyContent: showSearchField ? 'normal' : 'flex-end',
                    mb: 2,
                    ...sx,
                }}
            >
                {showSearchField && (
                    <ConfiguracaoPedidoCompraPesquisaField
                        placeholder={searchPlaceholder}
                        setValueFilter={setValueFilter}
                        sx={{ mr: { xs: 'none', md: 'auto' } }}
                    />
                )}

                {Boolean(selectedRowsLength) && (
                    <Button
                        variant="outlined"
                        color="error"
                        disabled={removeDisabled}
                        onClick={() => setOpenRemoveModal(true)}
                        startIcon={<DeleteOutlineOutlinedIcon />}
                        sx={{
                            height: 33,
                            width: { xs: '100%', md: 'auto' },
                        }}
                    >
                        Remover
                    </Button>
                )}

                <Button
                    variant="contained"
                    disabled={addDisabled}
                    onClick={onClickAdd}
                    sx={{
                        height: 33,
                        width: { xs: '100%', md: 'auto' },
                    }}
                >
                    Incluir
                </Button>
            </Box>
        </React.Fragment>
    );
}
