import { Dispatch, SetStateAction, useEffect } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { useQueryCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

type ConfigPedidoProdutosGrid = {
    id: number | null;
    open: boolean;
    selectedCondicoesPagamento: CondicaoPagamento[];
    setSelectedCondicoesPagamento: Dispatch<SetStateAction<CondicaoPagamento[]>>;
};

const getRows = (data?: CondicaoPagamento[]) => {
    if (Array.isArray(data)) {
        return data?.map((item) => ({
            id: item.idCondicaoPagamento,
            nrParcela: item.nrParcela,
            dsCondicaoPagamento: item.dsCondicaoPagamento,
            cdCondicaoPagamento: item.cdCondicaoPagamento,
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', width: 80 },
    { field: 'dsCondicaoPagamento', headerName: 'Descrição', flex: 1, minWidth: 100 },
    { field: 'nrParcela', headerName: 'Número de parcelas', width: 80 },
    { field: 'cdCondicaoPagamento', headerName: 'Código de integração', width: 100 },
];

export function ConfigPedidoCondicoesPagamentoModalGrid({
    id,
    open,
    selectedCondicoesPagamento,
    setSelectedCondicoesPagamento,
}: ConfigPedidoProdutosGrid) {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { data, refetch, isFetching,  } = useQueryCondicoesPagamento();
    const rows = getRows(data?.data);

    const doSelectionGrid = () => {
        if (open && !isFetching) {
            const condicaoPagamentoLoja = configPedidoCondicoesPagamento.condicaoPagamentoLoja.find((loja) => loja.loja.cdLoja === id);

            if (condicaoPagamentoLoja) {
                setSelectedCondicoesPagamento(condicaoPagamentoLoja.condicaoPagamentoList);
            }
        }
    };

    useEffect(doSelectionGrid, [open, isFetching]);

    const onSelectRow = (selectionModel: GridSelectionModel) => {
        const condicaoPagamento = data?.data?.find((item) => item.idCondicaoPagamento === selectionModel.at(-1));

        if (condicaoPagamento) {
            setSelectedCondicoesPagamento((oldSelectedCondicoesPagamento) => [...oldSelectedCondicoesPagamento, condicaoPagamento]);
        }
    };

    const onDeselectRow = (selectionModel: GridSelectionModel) => {
        const selectionSet = new Set(selectionModel);

        setSelectedCondicoesPagamento((oldSelectedCondicoesPagamento) =>
            oldSelectedCondicoesPagamento.filter((item) => selectionSet.has(item.idCondicaoPagamento as number)),
        );
    };

    const onClickRow = (selectionModel: GridSelectionModel) => {
        if (selectionModel.length > selectedCondicoesPagamento.length) {
            onSelectRow(selectionModel);
        } else {
            onDeselectRow(selectionModel);
        }
    };

    return (
        // Usamos o stopPropagation para evitar que ocorra erro ao clicar num campo da grid.
        <Box sx={{ height: 400 }} onClick={(event) => event.stopPropagation()}>
            <PanelGrid
                checkboxSelection
                autoHeight={false}
                rows={rows}
                rowsCount={rows.length ?? 0}
                columns={columns}
                reload={refetch}
                loading={isFetching}
                onClickRow={onClickRow}
                selectionModel={selectedCondicoesPagamento.map((item) => item.idCondicaoPagamento) as GridSelectionModel}
            />
        </Box>
    );
}
