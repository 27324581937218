import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoLojaProps {
    idTipoLoja: number;
    dsTipoLoja: string;
}

type TipoLojaAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'size' | 'name'>;

export function TipoLojaAutoComplete({ value, error, multiple = false, sx, onChange, label = 'Tipo de Loja', size, name }: TipoLojaAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTipoLoja}"
            optionStringTemplate="{dsTipoLoja}"
            requestOptions={{
                url: '/gestao/tipoloja',
                columns: 'idTipoLoja,dsTipoLoja',
                sort: [{ property: 'idTipoLoja', direction: 'ASC' }],
            }}
            isOptionEqualToValue={(option: TipoLojaProps, value: TipoLojaProps) => option.idTipoLoja === value.idTipoLoja}
            onChange={onChange}
            sx={sx}
            size={size}
            name={name}
        />
    );
}
