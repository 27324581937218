import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useCarrinhoContext } from '../contexts';
import { RequestPedidoProps } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';
import { ResponsePagamento } from 'pages/compra/pagamento/types';

export function useFinishAllPedidos() {
    const { mutateAsync } = useFormMutation<ResponsePagamento>();
    const { setDisabledActions, setOpenFinishedSuccessModal, labelFinishedSuccessModal, contentFinishedErrorModal, setOpenFinishedErrorModal } =
        useCarrinhoContext();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    /**
     * Caso ocorra sucesso, abre o modal de sucesso e realiza as requests da tela novamente.
     */
    const onSuccess = () => {
        labelFinishedSuccessModal.current = 'Pedidos finalizados com sucesso!';

        setOpenFinishedSuccessModal(true);

        queryClient.invalidateQueries(['getListRascunhos']);
        queryClient.invalidateQueries(['getTotalizadorRascunhos']);
    };

    /**
     * Abre o modal de erro com a mensagem de erro.
     */
    const onError = () => {
        contentFinishedErrorModal.current = (
            <Typography sx={{ color: 'grey.600' }}>
                Alguns pedidos não foram finalizados com sucesso.
                <br />
                Verifique o erro clicando em "Falha ao Finalizar" em cada pedido.
            </Typography>
        );

        setOpenFinishedErrorModal(true);
    };

    /**
     *  Retorna os pedidos listados na grid.
     */
    const getPedidos = () => (queryClient.getQueriesData(['getListRascunhos'])[0][1] as RequestPedidoProps).data;

    /**
     * Realiza a request para finalizar todos os pedidos da grid.
     */
    const mutatePedidos = () => {
        const pedidos = getPedidos();
        const idsPedidos = pedidos.map((pedido) => pedido.idPedido);
        const mutates = idsPedidos.map((idPedido) => mutateAsync({ url: `/compra/pedidocompra/rascunho/${idPedido}/finalizar`, preventSnack: true }));

        setDisabledActions(true);

        Promise.allSettled(mutates).then((results) => {
            const hasError = results.some((result) => result.status === 'rejected');

            if (hasError) {
                onError();
            } else {
                onSuccess();
            }

            setDisabledActions(false);
        });
    };

    /**
     * Navega para a página de pagamento com os ids dos pedidos listados na grid.
     */
    const navigateToPagamento = () => {
        const pedidos = getPedidos();
        const idsPedido = pedidos
            .map((pedido) => pedido.idPedido)
            .reverse()
            .join(',');

        navigate(`/compra/pagamento?idsPedido=${idsPedido}`);
    };

    /**
     * Finaliza os pedidos. Redireciona para a tela de pagamento, se possível, ou realiza as requests para finalizar.
     */
    const finishAllPedidos = () => {
        const pedidos = getPedidos();
        const needToRedirect = pedidos.some((pedido) => pedido.fgTelaPagamento);

        if (needToRedirect) {
            navigateToPagamento();
        } else {
            mutatePedidos();
        }
    };

    return { finishAllPedidos };
}
