import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export type DreListContextData = {
    hasDre: boolean;
    setHasDre: Dispatch<SetStateAction<boolean>>;
};

interface DreListProviderProps {
    children?: ReactNode;
}

const DreListContext = createContext<DreListContextData>({} as DreListContextData);

export function DreListProvider({ children }: DreListProviderProps): JSX.Element {
    const [hasDre, setHasDre] = useState(false);

    return (
        <DreListContext.Provider
            value={{
                hasDre,
                setHasDre,
            }}
        >
            {children}
        </DreListContext.Provider>
    );
}

export const useDreListContext = (): DreListContextData => useContext(DreListContext);
