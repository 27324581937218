import React, { SyntheticEvent } from 'react';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { usePermissionsContext } from 'context/PermissionsContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType } from 'hooks/useRequestListagem';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';
import { ConfigPedidoProps } from 'pages/compra/pedido/types';

export function PedidoConfigPedidoAutoComplete(): JSX.Element {
    const { tokenInfo } = useGenericAuth();
    const { tipoPedido } = useGetDomainConfig();
    const { filterLoja, filterConfigPedido, setFilterConfigPedido, setFilterCategoria, setFilterMarca } = usePedidoFilterContext();
    const { hasPermission } = usePermissionsContext();
    const hasAcessoGeral = hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL']);
    const idLoja = filterLoja.value?.idLoja;
    const filterFgVisivel = tokenInfo?.tpUsuario && ['FRANQUEADO', 'FUNCIONARIO'].includes(tokenInfo?.tpUsuario) && !hasAcessoGeral;

    const requestOptions: RequestOptionsType = {
        url: '/gestao/configpedido/vigente/loja',
        columns:
            'idConfigPedido,dsConfigPedido,idTipoControleEstoquePedido,fgValidaQuantidadeMinimaMultiploProduto,' +
            'vlBaseFreteGratis,peRoyaltiesLoja,peRoyalties,vlPedidoMinimoLoja,idTipoControlePedidoMinimo',
        limit: 9999,
        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
        filter: [
            { property: 'idLoja', operator: 'eq', value: filterLoja.value?.idLoja },
            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
        ],
    };

    if (filterFgVisivel) {
        requestOptions?.filter?.push({ property: 'fgVisivel', operator: 'eq', value: true });
    }

    /**
     * Ao trocar a configuração do pedido, limpa os campos de marca e jornada e atualiza o valor da configuração do pedido.
     */
    const onChangeConfigPedido = (e: SyntheticEvent<Element, Event>, value: ConfigPedidoProps) => {
        setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        setFilterMarca((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        setFilterConfigPedido((oldState: FilterValuesProps) => ({
            ...oldState,
            value: value && {
                idConfigPedido: value.idConfigPedido,
                dsConfigPedido: value.dsConfigPedido,
                vlPedidoMinimoLoja: value.vlPedidoMinimoLoja,
                tpControlePedidoMinimoLoja: value.tpControlePedidoMinimoLoja,
                idTipoControleEstoquePedido: value.idTipoControleEstoquePedido,
                configPedidoRegraValidacao: value.configPedidoRegraValidacao,
            },
        }));
    };

    return (
        <ConfiguracaoPedidoAutoComplete
            size="small"
            label={tipoPedido === 'funcionario' ? 'Campanha de produtos' : undefined}
            disabled={!idLoja}
            value={filterConfigPedido.value}
            requestOptions={idLoja ? requestOptions : null}
            onChange={onChangeConfigPedido}
        />
    );
}
