import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { ConfiguracaoPedidoHeaderActions } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoHeaderActions';
import { ConfigPedidoCondicoesPagamentoGrid } from 'pages/compra/configuracao-pedido/components/templates/condicoes-pagamento/ConfigPedidoCondicoesPagamentoGrid';
import { ConfiguracaoPedidoCompraLojasModal } from 'pages/compra/configuracao-pedido/components/templates/ConfiguracaoPedidoCompraLojasModal';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { useActionsCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';
import { LojasProps } from 'pages/compra/configuracao-pedido/types';

export function ConfigPedidoCondicoesPagamentoStore() {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { addCondicoesPagamentoLoja, removeCondicoesPagamentoLoja } = useActionsCondicoesPagamento();
    const [openLojasModal, setOpenLojasModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [valueFilter, setValueFilter] = useState<string>('');
    const [selectedLojas, setSelectedLojas] = useState<LojasProps[]>([]);
    const { id } = useParams();

    return (
        <>
            <ConfiguracaoPedidoCompraLojasModal
                open={openLojasModal}
                setOpen={setOpenLojasModal}
                selectedLojas={selectedLojas}
                setSelectedLojas={setSelectedLojas}
                handleAdicionarLojas={() => {
                    addCondicoesPagamentoLoja(selectedLojas);

                    setOpenLojasModal(false);
                }}
                requestOptionsLojas={{
                    url: '/gestao/loja/findbyidsconfigpedido',
                    sort: [{ property: 'idLoja', direction: 'ASC' }],
                    filter: [{ property: 'idConfigPedido', operator: 'eq', value: id }],
                }}
            />

            <Block>
                <SubTitle label="Condições de Pagamento (Por Loja)" />

                <ConfiguracaoPedidoHeaderActions
                    selectedRows={selectedRows}
                    setValueFilter={setValueFilter}
                    addDisabled={!configPedidoCondicoesPagamento.gatewayPagamento.length}
                    searchPlaceholder="Código, nome ou razão social..."
                    onClickAdd={() => setOpenLojasModal(true)}
                    onClickRemove={() => removeCondicoesPagamentoLoja(selectedRows)}
                />

                <ConfigPedidoCondicoesPagamentoGrid setSelectedRows={setSelectedRows} valueFilter={valueFilter} />
            </Block>
        </>
    );
}
