import { FullLoading } from 'components/loading';
import {
    ConfigPedidoCondicoesPagamentoGeneral,
    ConfigPedidoCondicoesPagamentoSave,
    ConfigPedidoCondicoesPagamentoStore,
    ConfigPedidoCondicoesPagamentoGateway,
} from 'pages/compra/configuracao-pedido/components';
import { useQueryConfigPedidoCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

export function ConfigPedidoCondicoesPagamento(): JSX.Element {
    const { isFetching } = useQueryConfigPedidoCondicoesPagamento();

    return (
        <>
            <FullLoading loading={isFetching} />

            <ConfigPedidoCondicoesPagamentoGateway />

            <ConfigPedidoCondicoesPagamentoGeneral />

            <ConfigPedidoCondicoesPagamentoStore />

            <ConfigPedidoCondicoesPagamentoSave />
        </>
    );
}
