import { GrupoAutoComplete } from 'components/autocompletes';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoGrupoAutoComplete(): JSX.Element {
    const { filterConfigPedido, filterCategoria, setFilterCategoria } = usePedidoFilterContext();
    const idConfigPedido = filterConfigPedido.value?.idConfigPedido;

    return (
        <GrupoAutoComplete
            label="Categoria"
            size="small"
            optionStringTemplate="<strong>{dsGrupo}</strong> <br/> Segmento {secao.dsSecao}"
            value={filterCategoria.value}
            disabled={!filterConfigPedido.value?.idConfigPedido}
            onChange={(e, value) => setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value }))}
            requestOptions={
                idConfigPedido
                    ? {
                          url: '/gestao/grupo/pedidocompra',
                          filter: [{ property: 'idConfigPedido', operator: 'eq', value: idConfigPedido }],
                          columns: 'idGrupo,dsGrupo,cdGrupo,dsSecao',
                          nested: true,
                      }
                    : null
            }
        />
    );
}
