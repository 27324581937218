import { CondicaoPagamentoGrid, CondicaoPagamentoGridFilter } from '..';

import { OneColumn } from 'components/page';

export function CondicaoPagamentoListComp() {
    return (
        <OneColumn title="Condições de Pagamento" createButton>
            <CondicaoPagamentoGridFilter />

            <CondicaoPagamentoGrid />
        </OneColumn>
    );
}
