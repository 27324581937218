import { useState } from 'react';

import { Chip, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { CarrinhoFalhasModal } from 'pages/compra/carrinho/components/templates/CarrinhoFalhasModal';
import { PedigoLog } from 'pages/compra/carrinho/types';

export function CarrinhoSituacaoColumn({ row }: { row: GridRowModel }) {
    const [openFalhasModal, setOpenFalhasModal] = useState<PedigoLog[] | boolean>(false);
    const { idStatusRascunho, pedidoTotalizadorValidacoes } = row;

    let color: 'error' | 'info' | 'success' | 'warning' = 'warning';
    let msgTooltip = null;
    let title = null;

    switch (idStatusRascunho) {
        case 1:
            color = 'info';
            title = 'Aguardando Finalizar';
            break;
        case 2:
            title = 'Processando';
            break;
        case 3:
            color = 'error';
            title = 'Falha ao Finalizar';
            msgTooltip = 'Visualizar Falhas';
            break;
    }

    return (
        <>
            <Tooltip title={msgTooltip || ''}>
                <Chip
                    label={title}
                    variant="outlined"
                    size="small"
                    color={color}
                    sx={{ width: '100%' }}
                    onClick={
                        idStatusRascunho === 3
                            ? () => {
                                  setOpenFalhasModal(pedidoTotalizadorValidacoes);
                              }
                            : undefined
                    }
                />
            </Tooltip>

            <CarrinhoFalhasModal open={openFalhasModal} setOpen={setOpenFalhasModal} />
        </>
    );
}
