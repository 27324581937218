import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoReactiveBtn() {
    const { row, disabledActions, setDisabledActions, setOpenConfirmModal, setDataConfirmModal } = useActionMenu();
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { idPedido } = row;
    const queryClient = useQueryClient();

    const handleAprove = () => {
        setDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/ativa`, preventSnack: true },
            {
                onSuccess: () => {
                    enqueueSnackbar('Pedido reativado com sucesso', { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setDisabledActions(false));
                },
                onError: (error) => {
                    setDisabledActions(false);

                    showError(error, 'Não foi possível reativar o pedido. Tente novamente');
                },
            },
        );
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<ReplayIcon />}
            disabled={disabledActions}
            loading={isLoading}
            onClick={() => {
                setOpenConfirmModal(true);

                setDataConfirmModal({ callback: handleAprove, text: 'Tem certeza que deseja reativar o registro selecionado?' });
            }}
        >
            Reativar
        </LoadingButton>
    );
}
