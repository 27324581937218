import { useQuery } from '@tanstack/react-query';

import { usePagamentoContext } from '../contexts/PagamentoContext';

import { useListagemContext } from 'context/ListagemContext';
import { RequestListagem, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CartaoPagamento } from 'pages/compra/pagamento/types';

export function useQueryCartoesPagamento() {
    const requestListagem = useRequestListagem();
    const { sort, page, limit } = useListagemContext();
    const { gatewayPagamento, pagamento, resetCartoesCredito } = usePagamentoContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/cartaopagamento/usuariolojalogado',
        sort: sort?.length ? sort : [{ property: 'idCartaoPagamento', direction: 'ASC' }],
        filter: [
            { property: 'idGatewayPagamento', operator: 'eq', value: gatewayPagamento?.idGatewayPagamento },
            { property: 'idUsuario', operator: 'eq', value: pagamento?.idUsuarioPedido },
        ],
        page,
        limit,
    };

    return useQuery<RequestListagem<CartaoPagamento[]>>(
        ['queryCartoesPagamento', requestOptions],
        () => requestListagem(requestOptions).then((res) => res.data),
        {
            onSuccess: (data) => resetCartoesCredito(data.data),
            enabled: true,
        },
    );
}
