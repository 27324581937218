import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { ConfigPedidoCondicoesPagamentoActionColumn, ConfigPedidoCondicoesPagamentoModal } from 'pages/compra/configuracao-pedido/components';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { useActionsCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';
import { CondicaoPagamentoLoja, ConfigPedidoCondicoesPagamento } from 'pages/compra/configuracao-pedido/types';

interface ConfigPedidoProdutosGrid {
    valueFilter: string;
    setSelectedRows: Dispatch<SetStateAction<number[]>>;
}

const getFilteredRows = (condicoes: ConfigPedidoCondicoesPagamento, valueFilter: string) => {
    return condicoes?.condicaoPagamentoLoja.filter((item) => {
        const { cdLoja, dsNomeFantasia, dsRazaoSocial } = item.loja;
        const valueFilterFormatted = valueFilter.trim().toLowerCase();

        return (
            !valueFilter ||
            dsNomeFantasia.toLowerCase().includes(valueFilterFormatted) ||
            dsRazaoSocial?.toLowerCase().includes(valueFilterFormatted) ||
            cdLoja.toString() === valueFilterFormatted
        );
    });
};

const getRows = (condicoesLojas: CondicaoPagamentoLoja[]) => {
    return condicoesLojas?.map((item) => ({
        id: item.loja.cdLoja,
        dsNomeFantasia: item.loja.dsNomeFantasia,
        dsRazaoSocial: item.loja.dsRazaoSocial,
    }));
};

export function ConfigPedidoCondicoesPagamentoGrid({ valueFilter, setSelectedRows }: ConfigPedidoProdutosGrid): JSX.Element {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { removeCondicoesPagamentoLoja } = useActionsCondicoesPagamento();
    const [openCondicoesPagamentoModal, setOpenCondicoesPagamentoModal] = useState<boolean>(false);
    const idRow = useRef<number | null>(null);
    const filteredRows = getFilteredRows(configPedidoCondicoesPagamento, valueFilter);
    const rows = getRows(filteredRows);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Código', width: 80 },
        { field: 'dsNomeFantasia', headerName: 'Nome', flex: 1, minWidth: 100 },
        { field: 'dsRazaoSocial', headerName: 'Razão social', flex: 1, minWidth: 100, sortable: false },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 70,
            renderCell: ({ row }) => (
                <ConfigPedidoCondicoesPagamentoActionColumn
                    onClickRemove={() => removeCondicoesPagamentoLoja([row.id])}
                    onClickEdit={() => {
                        idRow.current = row.id;

                        setOpenCondicoesPagamentoModal(true);
                    }}
                />
            ),
        },
    ];

    return (
        <>
            <ConfigPedidoCondicoesPagamentoModal id={idRow.current} open={openCondicoesPagamentoModal} setOpen={setOpenCondicoesPagamentoModal} />

            <PanelGrid
                checkboxSelection
                rows={rows}
                rowsCount={rows.length}
                columns={columns}
                reload={false}
                remote={false}
                loading={false}
                onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
            />
        </>
    );
}
