import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, SortProps, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestListagemProdutoPedidos } from 'pages/compra/pedido/types';

export function useGetProdutos(): UseQueryResult<RequestListagemProdutoPedidos> {
    const { setProdutosRequest, focusProdutos } = usePedidoCompraContext();
    const requestListagem = useRequestListagem();
    const { filter, autoLoad, page } = useListagemContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const promotionFilter = filter.find((item) => item.property === 'fgShowPeOferta');
    const sort: SortProps[] = [{ property: 'dsProduto', direction: 'ASC' }];

    if (promotionFilter) {
        sort.unshift({ property: 'peOferta', direction: 'DESC' });
    }

    const requestOptionsProdutos: RequestOptionsType = {
        sort,
        page,
        nested: false,
        limit: 200,
        url: '/compra/pedidocompra/catalogoproduto/configpedido',
        filter: filter?.length ? filter.filter((item) => !['idTipoEntrega'].includes(item.property)) : [],
    };

    return useQuery<RequestListagemProdutoPedidos>([requestOptionsProdutos], () => requestListagem(requestOptionsProdutos).then((res) => res.data), {
        enabled: Boolean(autoLoad && filter.length),
        onSuccess: ({ data }) => {
            setProdutosRequest(data);

            if (isTipoPedidoPadrao) {
                setTimeout(focusProdutos, 200);
            }
        },
    });
}
