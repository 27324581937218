import { useState } from 'react';

import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { ConfirmModal } from 'components/modal';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { useCarrinhoContext } from 'pages/compra/carrinho/contexts';
import { useFinishAllPedidos, useFuncionarioFilter, useGetTotalizador } from 'pages/compra/carrinho/hooks';
import { useLojasFilter } from 'pages/compra/carrinho/hooks/useLojasFilter';
import { brlDecimal, brlPrice } from 'util/format';

export function CarrinhoBbar() {
    const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);
    const { finishAllPedidos } = useFinishAllPedidos();
    const { disabledActions } = useCarrinhoContext();
    const { tipoPedido } = useGetDomainConfig();
    const { hasPermission } = usePermissionsContext();
    const { data: totalizador, isFetching } = useGetTotalizador();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const lojas = useLojasFilter();
    const funcionario = useFuncionarioFilter();
    const withoutFuncionario = !funcionario && isTipoPedidoFuncionario && hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const withoutLoja = lojas.length !== 1 && isTipoPedidoPadrao;
    const disableFinalizarAll = Boolean(disabledActions || !totalizador?.vlTotal || withoutFuncionario || withoutLoja);
    const hasVlPesoLiquidoTotal = typeof totalizador?.vlPesoLiquidoTotal === 'number';
    const hasVlTotal = typeof totalizador?.vlTotal === 'number';

    return (
        <>
            <ConfirmModal
                open={openFinishModal}
                setOpen={setOpenFinishModal}
                confirm={finishAllPedidos}
                title="Finalizar todos os pedidos"
                text="Tem certeza que deseja finalizar todos os pedidos?"
            />

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    p: 2,
                    gap: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                {isFetching && <CircularProgress size={20} />}

                {!isFetching && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        {hasVlPesoLiquidoTotal && (
                            <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                Peso total: <strong>{brlDecimal(totalizador.vlPesoLiquidoTotal, 0, 3)} Kg</strong>
                            </Typography>
                        )}

                        {hasVlTotal && (
                            <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                Valor total: <strong>{brlPrice(totalizador?.vlTotal)}</strong>
                            </Typography>
                        )}
                    </Box>
                )}

                {hasPermission(['COMPRA_PEDIDO_FINALIZAR']) && (
                    <Button variant="contained" disabled={disableFinalizarAll} onClick={() => setOpenFinishModal(true)} size="large">
                        Finalizar Todos os Pedidos
                    </Button>
                )}
            </Box>
        </>
    );
}
