import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';

import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { useMutatePagamento } from 'pages/compra/pagamento/hooks';

export function PagamentoFinishButton() {
    const { selecaoMeioPagamento } = usePagamentoContext();
    const { mutatePagamento, isLoadingPagamento } = useMutatePagamento();

    return (
        <Tooltip title={selecaoMeioPagamento ? '' : 'Selecione um meio de pagamento para poder finalizar'}>
            <span>
                <LoadingButton loading={isLoadingPagamento} disabled={!selecaoMeioPagamento} variant="contained" onClick={mutatePagamento}>
                    Finalizar pagamento
                </LoadingButton>
            </span>
        </Tooltip>
    );
}
