import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { StoreAutoComplete, MetaAutoComplete, TipoLogisticaLojaAutoComplete, PercentualNotaMetaSazonalAutoComplete } from 'components/autocompletes';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfiguracaoPedidoCompraLojasMetasGrid,
    ConfiguracaoPedidoCompraImportacaoUploadBtn,
    ConfiguracaoPedidoCompraLojasModal,
    ConfiguracaoPedidoHeaderActions,
} from 'pages/compra/configuracao-pedido/components';
import { ConfiguracaoPedidoCompraGeracaoConfigs } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraGeracaoConfigs';
import {
    LojasProps,
    MetaProps,
    LojaMetaAtingidaProps,
    TipoLogisticaLojaProps,
    PercentualNotaMetaSazonalProps,
} from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraPedidosSazonais(): JSX.Element {
    const { setActiveStepSaved, setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { mutate, isLoading: mutateLoading } = useFormMutation();
    const { content, setContent } = useFormContext();
    const { metasList } = content ?? { metasList: [] };
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { id } = useParams();

    const [valueFilterLojas, setValueFilterLojas] = useState<LojasProps[]>([]);
    const [valueFilterMetas, setValueFilterMetas] = useState<MetaProps | null>(null);
    const [percentualNotaMetaSazonal, setPercentualNotaMetaSazonal] = useState<PercentualNotaMetaSazonalProps | null>(null);
    const [valueFilterLogisticaLoja, setValueFilterLogisticaLoja] = useState<TipoLogisticaLojaProps[]>([]);
    const [openLojasModal, setOpenLojasModal] = useState<boolean>(false);
    const [selectedLojas, setSelectedLojas] = useState<LojasProps[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const validateMetasList = useCallback((): boolean => {
        const productIncorrect = metasList.find((metaLoja: LojaMetaAtingidaProps) => !metaLoja.tipoLogisticaLoja);

        let messageError;

        if (productIncorrect) {
            const { dsNomeFantasia } = productIncorrect.loja;

            messageError = `A loja ${dsNomeFantasia} não contém tipo de loja`;
        } else if (!percentualNotaMetaSazonal) {
            messageError = 'Tipo de percentual de nota fiscal é obrigatório';
        }

        if (messageError) {
            enqueueSnackbar(messageError, { variant: 'error' });
        }

        return !messageError;
    }, [enqueueSnackbar, metasList, percentualNotaMetaSazonal]);

    const onSubmitForm = useCallback((): void => {
        if (validateMetasList()) {
            mutate(
                {
                    url: `/gestao/lojametaatingida/configpedido/${id}/save`,
                    formData: {
                        lojasMetaAtingida: metasList,
                        percentualNotaRoyaltiesSazonal: percentualNotaMetaSazonal,
                    },
                    preventRedirect: true,
                    preventSnack: true,
                },
                {
                    onSuccess: ({ data }: any) => {
                        const { message, percentualNotaRoyaltiesSazonal, lojasMetaAtingida } = data;

                        setContent({ ...content, initialMetasList: lojasMetaAtingida, tipoPercentual: percentualNotaRoyaltiesSazonal });

                        setActiveStepSaved();

                        enqueueSnackbar('Metas salvas com sucesso', { variant: 'success' });

                        if (message) {
                            enqueueSnackbar(message, { variant: 'warning' });
                        }
                    },
                    onError: (error: any) => showError(error, 'Erro ao salvar as metas'),
                },
            );
        }
    }, [validateMetasList, mutate, id, metasList, percentualNotaMetaSazonal, setContent, content, setActiveStepSaved, enqueueSnackbar, showError]);

    const onSuccessUploadFile = useCallback(
        (data: any) => {
            const { message, lojasMetaAtingida } = data.data;

            if (message) {
                enqueueSnackbar(message, { variant: 'warning' });
            }

            setContent({ ...content, metasList: lojasMetaAtingida });
        },
        [content, enqueueSnackbar, setContent],
    );

    const getNewMetasList = useCallback(
        (newLojas: LojasProps[]): LojaMetaAtingidaProps[] => {
            return newLojas.map((loja) => {
                return {
                    loja,
                    fgMetaAtingida: false,
                    tipoLogisticaLoja: null,
                    configPedido: {
                        idConfigPedido: Number(id),
                    },
                };
            });
        },
        [id],
    );

    const handleAdicionarLojas = useCallback(() => {
        let adicionarLojas = true;

        const newLojas = selectedLojas.filter((loja) => {
            const lojaInList = metasList.find((metaLoja: LojaMetaAtingidaProps) => metaLoja.loja.idLoja === loja.idLoja);

            if (lojaInList) {
                enqueueSnackbar(`A loja ${lojaInList.loja.dsNomeFantasia} já existe na lista de lojas`, { variant: 'error' });

                adicionarLojas = false;

                return false;
            }

            return true;
        });

        if (adicionarLojas) {
            const newLojasList = [...metasList, ...getNewMetasList(newLojas)];

            setOpenLojasModal(false);

            setActiveStepDirty();

            setContent({ ...content, metasList: newLojasList });
        }
    }, [content, enqueueSnackbar, getNewMetasList, metasList, selectedLojas, setActiveStepDirty, setContent]);

    const removeLojas = useCallback(
        (idList: number[]) => {
            const newMetasList = metasList.filter((metaLoja: LojaMetaAtingidaProps) => !idList.includes(metaLoja.loja.idLoja));

            setActiveStepDirty();

            setContent({ ...content, metasList: newMetasList });
        },
        [content, metasList, setContent, setActiveStepDirty],
    );

    return (
        <React.Fragment>
            <FullLoading loading={mutateLoading} />

            <ConfiguracaoPedidoCompraLojasModal
                open={openLojasModal}
                setOpen={setOpenLojasModal}
                handleAdicionarLojas={handleAdicionarLojas}
                selectedLojas={selectedLojas}
                setSelectedLojas={setSelectedLojas}
                requestOptionsLojas={{
                    url: '/gestao/loja/findbyidsconfigpedido',
                    sort: [{ property: 'idLoja', direction: 'ASC' }],
                    filter: [{ property: 'idConfigPedido', operator: 'eq', value: id }],
                }}
            />

            <Block>
                <SubTitle label="Importação de Metas" />

                <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ConfiguracaoPedidoCompraImportacaoUploadBtn
                            url={`/gestao/lojametaatingida/configpedido/${id}/upload`}
                            onSend={() => setActiveStepDirty()}
                            onSuccessUploadFile={onSuccessUploadFile}
                        />
                    </Grid>

                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_LOJA_META_ATINGIDA" />
                    </Grid>
                </Grid>
            </Block>

            <Block>
                <SubTitle label="Filtro Lojas" />

                <Stack
                    sx={{
                        mb: 2,
                        flexDirection: {
                            xs: 'column',
                            md: 'row',
                        },
                        gap: 2,
                    }}
                >
                    <StoreAutoComplete
                        sx={{ flex: 1 }}
                        defaultStore={false}
                        multiple={true}
                        value={valueFilterLojas}
                        onChange={(e, newLojas) => setValueFilterLojas(newLojas)}
                    />

                    <MetaAutoComplete sx={{ flex: 1 }} value={valueFilterMetas} onChange={(e, newMeta) => setValueFilterMetas(newMeta)} />

                    <TipoLogisticaLojaAutoComplete
                        sx={{ flex: 1 }}
                        multiple={true}
                        value={valueFilterLogisticaLoja}
                        onChange={(e, newLogisticaLoja) => setValueFilterLogisticaLoja(newLogisticaLoja)}
                    />
                </Stack>
            </Block>

            <Block>
                <SubTitle label="Lojas" />

                <ConfiguracaoPedidoHeaderActions
                    selectedRows={selectedRows}
                    onClickAdd={() => setOpenLojasModal(true)}
                    onClickRemove={() => removeLojas(selectedRows)}
                    showSearchField={false}
                />

                <ListagemProvider>
                    <ConfiguracaoPedidoCompraLojasMetasGrid
                        valueFilterMetas={valueFilterMetas}
                        valueFilterLojas={valueFilterLojas}
                        valueFilterLogisticaLoja={valueFilterLogisticaLoja}
                        removeLojas={removeLojas}
                        setSelectedRows={setSelectedRows}
                    />
                </ListagemProvider>
            </Block>

            <ConfiguracaoPedidoCompraGeracaoConfigs
                percentualNotaMetaSazonal={percentualNotaMetaSazonal}
                setPercentualNotaMetaSazonal={setPercentualNotaMetaSazonal}
            />

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <LoadingButton variant="contained" loading={mutateLoading} disabled={mutateLoading} onClick={onSubmitForm} size="large">
                    Salvar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}
