import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { Filter } from 'components/page';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useFilter } from 'hooks/useFilter';
import {
    PedidoStoreAutoComplete,
    PedidoProdutoTextField,
    PedidoGrupoAutoComplete,
    PedidoConfigPedidoAutoComplete,
    PedidoChangeConfigModal,
} from 'pages/compra/pedido/components';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';
import { useGetAdimplencia, useSearchParamsFilters } from 'pages/compra/pedido/hooks';

export function PedidoCompraListFilter(): JSX.Element {
    const { setConfigPedido, setLoja } = usePedidoCompraContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { isDownLg } = useBreakpoints();
    const { configPedidoParam, lojaParam } = useSearchParamsFilters();
    const {
        filterConfigPedido,
        filterDsProduto,
        filterCategoria,
        filterLoja,
        filterCdProduto,
        setFilterCdProduto,
        setFilterConfigPedido,
        setFilterDsProduto,
        setFilterCategoria,
        setFilterLoja,
        setDefaultFilterLoja,
        validateLoja,
        validateConfigPedido,
        validateChangeCarrinho,
        openChangeConfigModal,
        setOpenChangeConfigModal,
    } = usePedidoFilterContext();
    const { data: isAdimplente, isFetching: isFetchingAdimplencia } = useGetAdimplencia();

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterDsProduto, setter: setFilterDsProduto },
        { value: filterCdProduto, setter: setFilterCdProduto },
        { value: filterCategoria, setter: setFilterCategoria },
    ];

    const validateAdimplencia = (): boolean => {
        if (!isAdimplente) {
            let message = 'Loja selecionada está inadimplente';

            if (isAdimplente === undefined) {
                message = 'Não foi possível validar a adimplência da loja';
            }

            enqueueSnackbar(message, { variant: 'error' });
        }

        return Boolean(isAdimplente);
    };

    const startFilter = (): void => {
        if (!validateLoja() || !validateConfigPedido() || !validateAdimplencia() || !validateChangeCarrinho()) {
            return;
        }

        handleFilterSubmit({ filters });

        setLoja(filterLoja.value);
        setConfigPedido(filterConfigPedido.value);
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });

        setDefaultFilterLoja();
    };

    const setInitialStates = (): void => {
        if (lojaParam && configPedidoParam) {
            // Ao entrar na tela com os filtros da url e tem a configuração de pedido, define ela como último valor selecionado
            // e faz a request para carregar os dados do carrinho.

            setLoja(lojaParam);
            setConfigPedido(configPedidoParam);
        }
    };

    useEffect(() => {
        setInitialStates();

        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <>
            <PedidoChangeConfigModal open={openChangeConfigModal} setOpen={setOpenChangeConfigModal} filters={filters} />

            <Filter reset={resetFilter} submit={startFilter} fixed={isDownLg} preventOnEnter loadingSubmit={isFetchingAdimplencia}>
                <Grid item lg={6}>
                    <PedidoStoreAutoComplete />
                </Grid>

                <Grid item lg={6}>
                    <PedidoConfigPedidoAutoComplete />
                </Grid>

                <Grid item lg={6}>
                    <PedidoGrupoAutoComplete />
                </Grid>

                <Grid item lg={6}>
                    <PedidoProdutoTextField />
                </Grid>
            </Filter>
        </>
    );
}
