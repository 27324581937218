import { useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';
import { ConfigPedidoProduto } from 'pages/compra/configuracao-pedido/types';

/**
 * Retorna os produtos formatados da maneira que o backend espera.
 */
const getFormattedProducts = (configPedidoProdutos: ConfigPedidoProduto[], id?: string) =>
    configPedidoProdutos.map((produto: ConfigPedidoProduto) => {
        return {
            ...produto,

            configPedido: {
                idConfigPedido: Number(id),
            },
        };
    });

export function useMutateProdutos() {
    const { mutate, isLoading } = useFormMutation<AxiosResponse<ConfigPedidoProduto[]>>();
    const { setActiveStepSaved } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { showError } = useShowError();
    const { configPedidoProdutos, setConfigPedidoProdutos, setInitialConfigPedidoProdutos, loja } = useConfigPedidoProdutosContext();
    const { id } = useParams();

    /**
     * Salva os produtos da step.
     */
    const onSubmitForm = () => {
        let url = `/gestao/configpedidoproduto/insertlist?idConfigPedido=${id}`;

        if (loja) {
            url += `&idLoja=${loja.idLoja}`;
        }

        mutate(
            {
                url,
                formData: getFormattedProducts(configPedidoProdutos, id),
                preventSnack: true,
                preventRedirect: true,
            },
            {
                onSuccess: ({ data }) => {
                    setConfigPedidoProdutos(data);

                    setInitialConfigPedidoProdutos(JSON.stringify(data));

                    setActiveStepSaved();

                    enqueueSnackbar('Produtos salvos com sucesso', { variant: 'success' });
                },
                onError: (error: any) => {
                    showError(error, 'Erro ao salvar Produtos');
                },
            },
        );
    };

    return { onSubmitForm, isLoading };
}
