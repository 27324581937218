import { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';

import { StatusPedidoColumn } from 'components/grid';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { PedidoRealizadoActionMenu } from 'pages/compra/pedido-realizado/components';
import { ActionMenuProvider } from 'pages/compra/pedido-realizado/contexts';
import { brlPrice } from 'util/format';

export function useColumnsPedido() {
    const { hasPermission } = usePermissionsContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const columns = useMemo(() => {
        const baseColumns: GridColDef[] = [
            { field: 'dhInclusao', headerName: 'Data', width: 90, minWidth: 90 },
            {
                field: 'dsConfigPedido',
                headerName: 'Configuração de pedido',
                flex: 1,
                minWidth: 120,
                renderCell: ({ row }) => row.configPedido.dsConfigPedido,
            },
            { field: 'nrPedido', headerName: 'Código do pedido', width: 100, minWidth: 90 },
            {
                field: 'cdPedido',
                headerName: 'Código de Integração',
                width: 100,
                minWidth: 90,
                renderCell: ({ row }) => (row.cdPedidoExterno ? `${row.cdPedido}/${row.cdPedidoExterno}` : row.cdPedido),
            },
            { field: 'dhConfirmacaoPedido', headerName: 'Data de pedido confirmado', minWidth: 90, width: 90 },
            {
                field: 'vlTotal',
                headerName: 'Valor do pedido',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => brlPrice(value),
            },
            {
                field: 'dsPedidoStatus',
                headerName: 'Situação do pedido',
                width: 150,
                minWidth: 150,
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: ({ row }) => (
                    <StatusPedidoColumn
                        pedidoStatusEnum={row.pedidoStatus.pedidoStatusEnum}
                        dsStatus={row.pedidoStatus.dsPedidoStatus}
                        dsValidacao={row.dsValidacao}
                    />
                ),
            },
            {
                field: 'Ações',
                align: 'center',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 90,
                minWidth: 90,
                renderCell: ({ row }) => (
                    <ActionMenuProvider row={row}>
                        <PedidoRealizadoActionMenu />
                    </ActionMenuProvider>
                ),
            },
        ];

        if (isTipoPedidoPadrao) {
            const gradeColumns: GridColDef[] = [
                { field: 'dtLimitePedido', headerName: 'Data limite do pedido', minWidth: 90, width: 90 },
                { field: 'dtColeta', headerName: 'Data da coleta', minWidth: 90, width: 90 },
                { field: 'dtEntrega', headerName: 'Data da entrega', minWidth: 90, width: 90 },
            ];

            baseColumns.splice(1, 0, ...gradeColumns);

            baseColumns.splice(3, 0, {
                field: 'dsNomeFantasia',
                headerName: 'Loja',
                flex: 1,
                minWidth: 130,
                renderCell: ({ row }) => row.loja.dsNomeFantasia,
            });
        } else {
            if (hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL'])) {
                baseColumns.splice(2, 0, {
                    field: 'dsUsuarioPedido',
                    headerName: 'Funcionário',
                    minWidth: 120,
                    renderCell: ({ row }) => row.usuarioPedido.dsUsuario,
                });
            }

            baseColumns.splice(
                7,
                0,
                {
                    field: 'vlFrete',
                    headerName: 'Frete',
                    type: 'number',
                    align: 'right',
                    minWidth: 80,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
                {
                    field: 'vlTotalBruto',
                    headerName: 'Total do pedido',
                    type: 'number',
                    align: 'right',
                    minWidth: 110,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
            );
        }

        return baseColumns;
    }, [isTipoPedidoPadrao]);

    return { columns };
}
