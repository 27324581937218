import { useQuery } from '@tanstack/react-query';

import { RequestPedidoProps } from '../types';

import { useLojasFilter } from './useLojasFilter';

import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useGetCarrinho() {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const requestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const lojas = useLojasFilter();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isLojasEqualOne = lojas.length === 1;

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho/loja',
        sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
        filter: [
            { property: 'fgAtivo', operator: 'eq', value: true, logicalOperator: 'AND' },
            { property: 'vlTotal', operator: 'neq', value: 0 },
            ...filter,
        ],
        limit: isLojasEqualOne || isTipoPedidoFuncionario ? 100 : limit,
        page,
    };

    return useQuery<RequestPedidoProps>(['getListRascunhos', requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: (isTipoPedidoFuncionario && !hasPermissionFinalizacaoGeral) || autoLoad,
        cacheTime: 0,
    });
}
