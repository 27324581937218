import { CondicaoPagamentoListComp, CondicaoPagamentoFormComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function CondicaoPagamentoList() {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CONDICAO_PAGAMENTO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <CondicaoPagamentoListComp />
        </ListagemProvider>
    );
}

export function CondicaoPagamentoForm() {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CONDICAO_PAGAMENTO_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <CondicaoPagamentoFormComp />
        </FormProvider>
    );
}
