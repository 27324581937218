import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { GatewayPagamentoAutoComplete, MeioPagamentoAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function CondicaoPagamentoGridFilter() {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterGatewayPagamento, setFilterGatewayPagamento] = useState<FilterValuesProps>({
        property: 'idGatewayPagamento',
        idProperty: 'idGatewayPagamento',
        operator: 'eq',
        value: null,
    });

    const [filterMeioPagamento, setFilterMeioPagamento] = useState<FilterValuesProps>({
        property: 'idMeioPagamento',
        idProperty: 'idMeioPagamento',
        operator: 'eq',
        value: null,
    });

    const [filterCdCondicaoPagmento, setFilterCdCondicaoPagmento] = useState<FilterValuesProps>({
        property: 'cdCondicaoPagamento',
        operator: 'like',
        value: null,
        idProperty: 'cdCondicaoPagamento',
    });

    const filters = [
        { value: filterGatewayPagamento, setter: setFilterGatewayPagamento },
        { value: filterMeioPagamento, setter: setFilterMeioPagamento },
        { value: filterCdCondicaoPagmento, setter: setFilterCdCondicaoPagmento },
    ];

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    const filterRequestMeioPagamento: FilterValuesProps[] = [{ property: 'fgAtivo', operator: 'eq', value: true }];

    let urlRequestMeioPagamento = '/gestao/meiopagamento';

    if (filterGatewayPagamento.value) {
        urlRequestMeioPagamento = '/gestao/meiopagamento/gatewaypagamento';

        filterRequestMeioPagamento.push({
            property: 'idGatewayPagamento',
            operator: 'eq',
            value: filterGatewayPagamento.value.idGatewayPagamento,
        });
    }

    return (
        <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
            <Grid item flex={4}>
                <GatewayPagamentoAutoComplete
                    value={filterGatewayPagamento.value}
                    onChange={(e, value) => {
                        setFilterGatewayPagamento((oldState: FilterValuesProps) => ({ ...oldState, value }));

                        setFilterMeioPagamento((oldState: FilterValuesProps) => ({ ...oldState, value: null }));
                    }}
                />
            </Grid>

            <Grid item flex={4}>
                <MeioPagamentoAutoComplete
                    value={filterMeioPagamento.value}
                    onChange={(e, value) => setFilterMeioPagamento((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: urlRequestMeioPagamento,
                        filter: filterRequestMeioPagamento,
                        sort: [{ property: 'idMeioPagamento', direction: 'ASC' }],
                        limit: 50,
                    }}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Código de integração"
                    value={filterCdCondicaoPagmento.value}
                    onChange={(e) => setFilterCdCondicaoPagmento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
