import { Box, Button, Typography } from '@mui/material';

import { ContentModal } from 'components/modal';
import { useCarrinhoContext } from 'pages/compra/carrinho/contexts';

export function CarrinhoFinishErrorModal() {
    const { contentFinishedErrorModal, openFinishedErrorModal, setOpenFinishedErrorModal } = useCarrinhoContext();

    return (
        <ContentModal
            closeButton
            open={openFinishedErrorModal}
            setOpen={setOpenFinishedErrorModal}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 1,
                minWidth: { xs: '90dvw', sm: 400 },
                maxWidth: 600,
            }}
        >
            <Typography variant="h6">Falha ao finalizar</Typography>

            {contentFinishedErrorModal.current}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button variant="text" onClick={() => setOpenFinishedErrorModal(false)}>
                    Fechar
                </Button>
            </Box>
        </ContentModal>
    );
}
