import { Box } from '@mui/material';

import { ConfigPedidoCondicoesPagamentoEditBtn } from 'pages/compra/configuracao-pedido/components';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraRemoveBtn';

type ConfigPedidoCondicoesPagamentoActionColumn = {
    onClickEdit(): void;
    onClickRemove(): void;
};

export function ConfigPedidoCondicoesPagamentoActionColumn({ onClickEdit, onClickRemove }: ConfigPedidoCondicoesPagamentoActionColumn) {
    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <ConfigPedidoCondicoesPagamentoEditBtn onClick={onClickEdit} />

            <ConfiguracaoPedidoCompraRemoveBtn onClick={onClickRemove} />
        </Box>
    );
}
