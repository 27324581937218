import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { FullLoading } from 'components/loading';
import { useMutateCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

export function ConfigPedidoCondicoesPagamentoSave() {
    const { isLoading, onSubmitForm } = useMutateCondicoesPagamento();

    return (
        <>
            <FullLoading loading={isLoading} />

            <Box
                sx={{
                    display: 'flex',
                    position: 'sticky',
                    justifyContent: 'flex-end',
                    p: 2,
                    mx: -2,
                    bottom: 0,
                    zIndex: 3,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <LoadingButton variant="contained" loading={isLoading} onClick={onSubmitForm} size="large">
                    Salvar
                </LoadingButton>
            </Box>
        </>
    );
}
