import React, { useState, useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, FormControlLabel, Typography, Radio } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { Field, FormikHelpers, FormikProps } from 'formik';
import { ChromePicker } from 'react-color';
import * as Yup from 'yup';

import { initialEventoFormValues, RequestEventoModelProps } from '../../types';

import { StoreImportAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissTextField, Editor } from 'components/fields/';
import { CityFieldFormik } from 'components/fields/CityFieldFormik';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { StoreProfileFieldFormik } from 'components/fields/StoreProfileFieldFormik';
import { StoreTypeFieldFormik } from 'components/fields/StoreTypeFieldFormik';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';

interface MutateEventoFormProps {
    data: {
        data: RequestEventoModelProps;
    };
}

export function EventoFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const { id } = useParams();

    const url = '/gestao/calendarioevento';

    const { mutate } = useFormMutation<MutateEventoFormProps>();
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const handleSubmit = useCallback(
        (values: RequestEventoModelProps, formik: FormikHelpers<RequestEventoModelProps>): void => {
            if (values.tipoEvento === 'lojas') {
                values.calendarioEventoEstados = [];
            } else {
                values.calendarioEventoLojas = [];
            }

            mutate(
                {
                    url,
                    idField: 'idCalendarioEvento',
                    formData: values,
                    formik,
                    method: id ? 'PUT' : 'POST',
                },
                {
                    onSuccess: (response) => {
                        const { data } = response.data;

                        if (data) {
                            setContent(data);
                        }
                    },
                },
            );
        },
        [id, mutate, setContent],
    );

    const validationSchema = useMemo(
        () =>
            Yup.object({
                dsCalendarioEvento: Yup.string().max(30).required(),
                dhInicio: Yup.date().typeError('A data não é válida.').required().nullable(),
                dhFim: Yup.date().typeError('A data não é válida.').required().nullable(),
                calendarioEventoTag: Yup.object().required().nullable(),
                dsConteudo: Yup.string().required().nullable(),
                dsTextoLink: Yup.string().max(24),
                dsUrlLink: Yup.string()
                    .when('dsTextoLink', {
                        is: (value: string) => Boolean(value),
                        then: (schema) => schema.required(),
                    })
                    .nullable(),
                dsCor: Yup.string().max(7).typeError('O limite de caracteres da cor é 7'),
            }),
        [],
    );

    const mapContentToInitialValues = (content: RequestEventoModelProps): RequestEventoModelProps => {
        if (content.calendarioEventoEstados?.length) {
            content.tipoEvento = 'estados';
        } else if (content.calendarioEventoCidades?.length) {
            content.tipoEvento = 'cidades';
        } else {
            content.tipoEvento = 'lojas';
        }
        return content;
    };

    const optionStringTemplateMarcador =
        '<div style="display: flex; flex-direction: row; align-items: center"><strong>{idCalendarioEventoTag}</strong> - {dsCalendarioEventoTag} - <div style="background-color: {dsCorTag}; width: 15px; height: 15px; border-radius: 100%; margin-left: 5px" /></div>';

    return (
        <FormPage
            title="Evento Personalizado"
            mapContentToInitialValues={mapContentToInitialValues}
            values={initialEventoFormValues}
            onSubmit={handleSubmit}
            fgAtivo={false}
            url={url}
            validationSchema={validationSchema}
            extraTopButtons={[
                {
                    title: 'Gerenciar Marcadores',
                    onClick: () => navigate('/calendario/evento/form/marcadores'),
                    visible: hasPermission(['CALENDARIO_EVENTO_MARCADOR_GERENCIAR']),
                },
            ]}
        >
            {(formik: FormikProps<RequestEventoModelProps>) => (
                <React.Fragment>
                    <Block>
                        <Row>
                            <Grid item flex={1}>
                                <CissTextField
                                    label="Titulo do evento"
                                    name="dsCalendarioEvento"
                                    value={formik.values.dsCalendarioEvento}
                                    onChange={(e) => formik.setFieldValue('dsCalendarioEvento', e.target.value)}
                                    error={formik.errors.dsCalendarioEvento}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissAutoComplete
                                    label="Marcador"
                                    name="calendarioEventoTag"
                                    onChange={(e, value) => {
                                        formik.setFieldValue('calendarioEventoTag', value);
                                        formik.setFieldValue('dsCor', value.dsCorTag);
                                    }}
                                    value={formik.values.calendarioEventoTag}
                                    multiple={false}
                                    requestOptions={{
                                        url: '/gestao/calendarioeventotag',
                                        sort: [{ property: 'idCalendarioEventoTag', direction: 'ASC' }],
                                        columns: 'idCalendarioEventoTag,dsCalendarioEventoTag,dsCorTag',
                                        limit: 50,
                                    }}
                                    error={formik.errors.calendarioEventoTag}
                                    optionStringTemplate={optionStringTemplateMarcador}
                                    selectedStringTemplate={'{dsCalendarioEventoTag}'}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.idCalendarioEventoTag === value.idCalendarioEventoTag;
                                    }}
                                />
                            </Grid>
                        </Row>

                        <Row>
                            <Grid item flex={1}>
                                <LocalizationProvider
                                    adapterLocale={ptBR}
                                    dateAdapter={AdapterDateFns}
                                    localeText={{ start: 'Início Exibição', end: 'Final Exibição' }}
                                >
                                    <DateRangePicker
                                        value={[formik.values.dhInicio, formik.values.dhFim]}
                                        onChange={(newValue) => {
                                            const [start, end] = newValue;

                                            formik.setFieldValue('dhInicio', start);
                                            formik.setFieldValue('dhFim', end);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <CissTextField name="dhInicio" {...startProps} error={formik.errors.dhInicio} autoComplete="off" />
                                                <Box sx={{ mx: 2 }}> até </Box>
                                                <CissTextField name="dhFim" {...endProps} error={formik.errors.dhFim} autoComplete="off" />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item flex={1}>
                                <Typography>
                                    <strong>Clique para selecionar uma cor para o evento:</strong>
                                </Typography>
                                <div
                                    onClick={() => setPickerVisible((oldState) => !oldState)}
                                    style={{ backgroundColor: formik.values.dsCor, width: 25, height: 25, borderRadius: '100%' }}
                                />
                                {pickerVisible && (
                                    <React.Fragment>
                                        <div
                                            style={{
                                                position: 'fixed',
                                                top: '0px',
                                                right: '0px',
                                                bottom: '0px',
                                                left: '0px',
                                            }}
                                            onClick={() => setPickerVisible((oldState) => !oldState)}
                                        />
                                        <div style={{ position: 'absolute', zIndex: 2 }}>
                                            <ChromePicker
                                                color={formik.values.dsCor}
                                                onChange={({ hex }: any) => formik.setFieldValue('dsCor', hex)}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </Grid>

                            {/*
                            Baseado no levantamento junto ao cliente foi vista a necessidade de repensar o checkbox de promoção. Até que se tenha uma  posição de como ficará essa situação o componente ficará  comentado.
                            <Grid item flex={1}>
                                <Field as={FormControlLabel} type="checkbox" name="promocao" control={<Checkbox />} label="Promoção" />
                            </Grid> */}
                        </Row>

                        <Row>
                            <Grid item flex={1}>
                                <Field
                                    component={StoreImportAutoComplete}
                                    sx={{ display: formik.values.tipoEvento === 'lojas' ? 'flex' : 'none' }}
                                    multiple={true}
                                    name="calendarioEventoLojas"
                                />

                                <Field
                                    component={StateFieldFormik}
                                    name="calendarioEventoEstados"
                                    sx={{ display: formik.values.tipoEvento === 'estados' ? 'block' : 'none' }}
                                />

                                <Field
                                    component={CityFieldFormik}
                                    value={formik.values.calendarioEventoCidades}
                                    name="calendarioEventoCidades"
                                    sx={{ display: formik.values.tipoEvento === 'cidades' ? 'block' : 'none' }}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <Typography>
                                    <strong>Evento será disponibilizado para:</strong>
                                </Typography>

                                <Grid item>
                                    <FormControlLabel
                                        label="Lojas"
                                        control={
                                            <Radio
                                                checked={formik.values.tipoEvento === 'lojas'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('tipoEvento', e.target.value);
                                                }}
                                            />
                                        }
                                        value="lojas"
                                    />

                                    <FormControlLabel
                                        label="Estados"
                                        control={
                                            <Radio
                                                checked={formik.values.tipoEvento === 'estados'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('tipoEvento', e.target.value);
                                                }}
                                            />
                                        }
                                        value="estados"
                                    />
                                    <FormControlLabel
                                        label="Cidades"
                                        control={
                                            <Radio
                                                checked={formik.values.tipoEvento === 'cidades'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('tipoEvento', e.target.value);
                                                }}
                                            />
                                        }
                                        value="cidades"
                                    />
                                </Grid>
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item flex={1}>
                                <Field component={StoreTypeFieldFormik} name="calendarioEventoTipoLojas" />
                            </Grid>
                            <Grid item flex={1}>
                                <Field component={StoreProfileFieldFormik} name="calendarioEventoLojaPerfis" />
                            </Grid>
                        </Row>
                    </Block>

                    <Block>
                        <SubTitle label="Descrição" />

                        <Grid item xs={12}>
                            <Editor
                                value={formik.values.dsConteudo}
                                onChange={(value: string) => {
                                    formik.setFieldValue('dsConteudo', value);
                                }}
                            />

                            {formik.errors.dsConteudo && (
                                <Typography id="dsConteudo" color="error" fontSize="0.85rem" lineHeight={1.5} mt={1}>
                                    {formik.errors.dsConteudo as string}
                                </Typography>
                            )}
                        </Grid>
                    </Block>

                    <Block>
                        <SubTitle label="Links" />

                        <Row>
                            <Grid item flex={1}>
                                <CissTextField
                                    label="Texto do Link"
                                    name="dsTextoLink"
                                    error={formik.errors.dsTextoLink}
                                    value={formik.values.dsTextoLink}
                                    onChange={(e) => {
                                        formik.setFieldValue('dsTextoLink', e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField
                                    label="Link"
                                    name="dsUrlLink"
                                    disabled={!formik.values.dsTextoLink}
                                    error={formik.errors.dsUrlLink}
                                    value={formik.values.dsUrlLink}
                                    onChange={(e) => {
                                        formik.setFieldValue('dsUrlLink', e.target.value);
                                    }}
                                />
                            </Grid>
                        </Row>
                    </Block>
                </React.Fragment>
            )}
        </FormPage>
    );
}
