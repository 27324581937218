import React from 'react';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';

type DreListLojaColumnProp = {
    id: number;
    idStatus: number;
    vlReceita: string;
    isLoading: boolean;
};


const getDisabled = (idStatus: number, vlReceita: string): boolean => {
    // 1 = A preencher
    // 2 = Em preenchimento
    let enabled = [1, 2].includes(idStatus);

    // se a preencher, somente disponivel quando existir receita bruta
    if (idStatus === 1) {
        enabled = Boolean(vlReceita);
    }

    return !enabled;
};

export function DrePreencherBtn({ id, idStatus, vlReceita, isLoading }: DreListLojaColumnProp): JSX.Element {
    const navigate = useNavigate();

    return (
        <LoadingButton
            size="small"
            variant="contained"
            fullWidth={false}
            loading={isLoading}
            sx={{ px: 1, py: 0, minWidth: 22 }}
            disabled={getDisabled(idStatus, vlReceita)}
            onClick={() =>
                navigate({
                    pathname: `form/${id}`,
                })
            }
        >
            Preencher
        </LoadingButton>
    );
}
