import { useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestUsuariosListProps } from 'pages/cadastro/permissoes/types';

export function useQueryUsuarios({ query }: { query: string }) {
    const { limit, page, sort } = useListagemContext();
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/usuario',
        columns: 'idUsuario,dsUsuario',
        sort: sort.length ? sort : [{ property: 'dsUsuario', direction: 'ASC' }],
        limit,
        page,
        query,
    };

    return useQuery<RequestUsuariosListProps>([requestOptions], () => requestListagem(requestOptions).then((result) => result.data));
}
