import React from 'react';

import { EspelhoRoyaltyListComp } from './components';

import { CobflexProvider } from 'context/CobflexContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function EspelhoRoyaltyList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_ESPELHO_ROYALTIES_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <CobflexProvider>
                <EspelhoRoyaltyListComp />
            </CobflexProvider>
        </ListagemProvider>
    );
}
