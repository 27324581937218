import React from 'react';

import { Grid, Typography } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField, Editor } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialValues } from 'pages/marketing-trade/trade/solicitacoes-pecas/mensagem-padrao/types';
import { MensagemPadrao } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';

export function MensagemPadraoFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const url = '/gestao/mensagempadrao';
    const { mutate } = useFormMutation();

    const handleSubmit = (values: MensagemPadrao, formik: FormikHelpers<MensagemPadrao>): void => {
        mutate(
            {
                url,
                idField: 'idMensagemPadrao',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = Yup.object({
        dsTitulo: Yup.string().required(),
        dsMensagemPadrao: Yup.string().required(),
    });

    return (
        <FormPage title="Mensagem Padrão" values={initialValues} fgAtivo={false} onSubmit={handleSubmit} url={url} validationSchema={validationSchema} goBackButton>
            {({ values, errors, setFieldValue }: FormikProps<MensagemPadrao>) => {
                return (
                    <Block>
                        <Row>
                            <Grid item flex={1}>
                                <CissTextField label="Título" value={values.dsTitulo} onChange={(e) => setFieldValue('dsTitulo', e.target.value)} />
                            </Grid>
                        </Row>

                        <Row>
                            <Grid item flex={1}>
                                <SubTitle label="Mensagem" />

                                <Editor value={values.dsMensagemPadrao} onChange={(value) => setFieldValue('dsMensagemPadrao', value)} />

                                {errors.dsMensagemPadrao && (
                                    <Typography color="error" fontSize="0.85rem" lineHeight={1.5} mt={1}>
                                        {errors.dsMensagemPadrao}
                                    </Typography>
                                )}
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
