import { useState } from 'react';

import PixIcon from '@mui/icons-material/Pix';
import { Button } from '@mui/material';

import { PagamentoPixModal } from 'components/modal';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoPixBtn() {
    const { row, disabledActions } = useActionMenu();
    const [openPagamentoPixModal, setOpenPagamentoPixModal] = useState<boolean>(false);
    const pagamento = row?.pedidoPagamentos?.at(0);

    return (
        <>
            {pagamento && <PagamentoPixModal closeOnPending pagamento={pagamento} open={openPagamentoPixModal} setOpen={setOpenPagamentoPixModal} />}

            <Button color="inherit" startIcon={<PixIcon />} disabled={disabledActions} onClick={() => setOpenPagamentoPixModal(true)}>
                Código Pix
            </Button>
        </>
    );
}
