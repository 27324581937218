import { Stack, Typography } from '@mui/material';

import { ProductStock } from 'pages/compra/carrinho/hooks/useFinishPedido';

export function CarrinhoStockErrorText({ products }: { products: ProductStock[] }) {
    return (
        <>
            <Typography>
                Devido a quantidade de estoque indisponível, os produtos abaixo precisam ser atualizados para essas novas quantidades para serem
                finalizados:
            </Typography>

            <Stack gap={0.5}>
                {products.map((product) => {
                    const { dsProduto, qtEstoqueDisponivel, sgUnidadeMedida, cdProduto } = product;

                    return (
                        <li key={cdProduto}>
                            {dsProduto} -{' '}
                            <strong>
                                {qtEstoqueDisponivel} {sgUnidadeMedida}
                            </strong>
                        </li>
                    );
                })}
            </Stack>
        </>
    );
}
