import { useNavigate } from 'react-router-dom';

import { OneColumn } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { CarrinhoGrid, CarrinhoGridFilter, CarrinhoBbar, CarrinhoFinishSuccessModal } from 'pages/compra/carrinho/components';
import { CarrinhoFinishErrorModal } from 'pages/compra/carrinho/components/templates/CarrinhoFinishErrorModal';

export function CarrinhoListComp() {
    const { hasPermission } = usePermissionsContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const navigate = useNavigate();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    return (
        <>
            <CarrinhoFinishSuccessModal />

            <CarrinhoFinishErrorModal />

            <OneColumn
                title="Meu Carrinho"
                goBackButton
                extraButtons={[
                    {
                        title: 'Continuar Comprando',
                        onClick: () => navigate('/compra/pedido'),
                    },
                ]}
            >
                {(isTipoPedidoPadrao || (isTipoPedidoFuncionario && hasPermissionFinalizacaoGeral)) && <CarrinhoGridFilter />}

                <CarrinhoGrid />

                <CarrinhoBbar />
            </OneColumn>
        </>
    );
}
