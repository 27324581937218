import React, { useCallback, useMemo, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, FormControlLabel, Checkbox, Radio, Tooltip, Typography } from '@mui/material';
import { Field, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { StoreImportAutoComplete } from 'components/autocompletes';
import { AzureUpload } from 'components/azureUpload';
import { Bbar } from 'components/docked';
import { Editor, CissTextField, MarketingCampanhaFieldFormik, MarketingFormatoPecaFieldFormik, CissNumberField, ToggleFgAtivo } from 'components/fields';
import { CityFieldFormik } from 'components/fields/CityFieldFormik';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { StoreProfileFieldFormik } from 'components/fields/StoreProfileFieldFormik';
import { StoreTypeFieldFormik } from 'components/fields/StoreTypeFieldFormik';
import { TagPecaMarketingFieldFormik } from 'components/fields/TagPecaMarketingFieldFormik';
import { TagPecaTradeFieldFormik } from 'components/fields/TagPecaTradeFieldFormik';
import { Row } from 'components/form';
import { ConfirmModal } from 'components/modal';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { getTypeMarketing, initialMarketingValues, MarketingTradeEditableFilesProps, MarketingTradePage, MarketingTradeProps } from 'pages/marketing-trade/types';
import { editableFormats, filterEditableFiles } from 'pages/marketing-trade/util/file';

const getActiveFiles = (lista: MarketingTradeEditableFilesProps[]): MarketingTradeEditableFilesProps[] => lista.filter((file) => !file.fgExcluido);

const hasEditableFile = (lista: MarketingTradeEditableFilesProps[]): boolean => lista.some(filterEditableFiles);

const mapContentToInitialValues = (contentData: MarketingTradeProps): MarketingTradeProps => {
    if (contentData.pecaMarketingEstados?.length) {
        contentData.tipoDisponibilizacao = 'estados';
    } else if (contentData.pecaMarketingCidades?.length) {
        contentData.tipoDisponibilizacao = 'cidades';
    } else {
        contentData.tipoDisponibilizacao = 'lojas';
    }

    return contentData;
};

export function MarketingTradeFormComp({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const { setContent } = useFormContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { hasPermission } = usePermissionsContext();
    const url = '/gestao/pecamarketing';
    const { mutate: mudateMarketing } = useFormMutation();

    const validationSchema = useMemo(
        () =>
            Yup.object({
                dsPecaMarketing: Yup.string().required().nullable(),
                campanhaMarketing: Yup.object().required().nullable(),
                pecaMarketingFormato: Yup.object().required().nullable(),
                arquivosDownload: Yup.array().when('fgEditavel', {
                    is: true,
                    then: (schema) =>
                        schema
                            .test({
                                name: 'Arquivos não excluídos',
                                test: (value) => (!value ? false : value.some((item) => !item.fgExcluido)),
                            })
                            .min(1)
                            .nullable(),
                }),
                imagensThumb: Yup.array()
                    .when('fgAtivo', {
                        is: true,
                        then: (schema) => schema.required().min(1),
                    })
                    .nullable(),
            }),
        [],
    );

    const saveMarketing = (values: MarketingTradeProps, formik: FormikHelpers<MarketingTradeProps>): void => {
        if (values.tipoMarketing.idTipoMarketing !== getTypeMarketing(isMarketing)) {
            enqueueSnackbar('Não é possivel editar/visualizar este item no módulo TRADE.', { variant: 'error' });
        } else {
            mudateMarketing(
                {
                    url,
                    formik,
                    idField: 'idPecaMarketing',
                    formData: values,
                    preventRedirect: values.fgEditavel || !hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR']),
                },
                {
                    onSuccess: (response: any) => {
                        const { data } = response.data;

                        if (data?.fgEditavel && data?.fgAtivo) {
                            if (!id) {
                                navigate(`${location.pathname}/${data.idPecaMarketing}`, { replace: true });
                            }

                            navigate(`/${isMarketing ? 'marketing' : 'trade'}/form/${data.idPecaMarketing}/edicao`, {});
                        } else if (data && hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR'])) {
                            setContent(data);
                        } else {
                            navigate(`/${isMarketing ? 'marketing' : 'trade'}`);
                        }
                    },
                },
            );
        }
    };

    const handleSubmit = useCallback((values: MarketingTradeProps, formik: FormikHelpers<MarketingTradeProps>): void => {
        if (values.fgEditavel && values.arquivosDownload && !hasEditableFile(getActiveFiles(values.arquivosDownload))) {
            enqueueSnackbar(
                <Typography>
                    Para prosseguir, informe arquivo(s) com os formatos <strong>{editableFormats.join(', ')}</strong>
                    <br />
                    ou desative a opção <strong>Peça Editável</strong>.
                </Typography>,
                { variant: 'error' },
            );

            return;
        }

        if (values.fgFrenteVerso && values.arquivosDownload && getActiveFiles(values.arquivosDownload).length < 2) {
            setOpenConfirmModal(true);

            return;
        }

        if (values.pecaMarketingFormato?.idPecaMarketingFormato === 2) {
            values.fgFrenteVerso = false;
            values.fgFaca = false;
            values.dsTipoLamina = '';
            values.dsPapel = '';
            values.dsGramatura = '';
            values.dsFormatoAberto = '';
            values.dsFormatoFechado = '';
            values.dsCores = '';
            values.nrPagina = '';
            values.dsAcabamento = '';
        }

        saveMarketing(values, formik);
    }, []);

    return (
        <FormPage
            title={`Peça de ${isMarketing ? 'Marketing' : 'Trade'}`}
            mapContentToInitialValues={mapContentToInitialValues}
            values={initialMarketingValues(isMarketing)}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            hideBbar
            extraTopButtons={[
                {
                    title: 'Gerenciar Tags',
                    onClick: () => navigate(`/${isMarketing ? 'marketing' : 'trade'}/tag`),
                    visible: hasPermission([isMarketing ? 'MARKETING_PECA_MODERAR' : 'TRADE_PECA_MODERAR']),
                },
            ]}
        >
            {(formik: FormikProps<MarketingTradeProps>) => {
                return (
                    <React.Fragment>
                        <Block>
                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Titulo"
                                        name="dsPecaMarketing"
                                        value={formik.values.dsPecaMarketing}
                                        onChange={(e) => formik.setFieldValue('dsPecaMarketing', e.target.value)}
                                        error={formik.errors.dsPecaMarketing}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <Field
                                        component={MarketingCampanhaFieldFormik}
                                        name="campanhaMarketing"
                                        filter={[
                                            {
                                                property: 'idTipoMarketing',
                                                operator: 'eq',
                                                value: getTypeMarketing(isMarketing),
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    {isMarketing ? (
                                        <Field component={TagPecaMarketingFieldFormik} name="pecaMarketingTagsPecaMarketing" />
                                    ) : (
                                        <Field component={TagPecaTradeFieldFormik} name="pecaTradeTagsPecaTrade" />
                                    )}
                                </Grid>

                                <Grid item flex={1}>
                                    <Field component={MarketingFormatoPecaFieldFormik} name="pecaMarketingFormato" />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <Field
                                        component={StoreImportAutoComplete}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'lojas' ? 'flex' : 'none' }}
                                        multiple={true}
                                        name="pecaMarketingLojas"
                                    />
                                    <Field
                                        component={StateFieldFormik}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'estados' ? 'block' : 'none' }}
                                        multiple={true}
                                        name="pecaMarketingEstados"
                                    />

                                    <Field
                                        component={CityFieldFormik}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'cidades' ? 'block' : 'none' }}
                                        multiple={true}
                                        name="pecaMarketingCidades"
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <Typography>
                                        <strong>Será disponibilizado para:</strong>
                                    </Typography>

                                    <Grid item>
                                        <FormControlLabel
                                            label="Lojas"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'lojas'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="lojas"
                                        />
                                        <FormControlLabel
                                            label="Estados"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'estados'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="estados"
                                        />
                                        <FormControlLabel
                                            label="Cidades"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'cidades'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="cidades"
                                        />
                                    </Grid>
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <Field component={StoreTypeFieldFormik} name="pecaMarketingTiposLojas" />
                                </Grid>
                                <Grid item flex={1}>
                                    <Field component={StoreProfileFieldFormik} name="pecaMarketingLojaPerfis" />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Público Alvo e breve descrição de utilização" />

                            <Grid item xs={12}>
                                <Editor value={formik.values.dsConteudo} onChange={(value: string) => formik.setFieldValue('dsConteudo', value)} />
                            </Grid>
                        </Block>

                        {isMarketing ? (
                            <Block>
                                <Field as={FormControlLabel} type="checkbox" name="fgEditavel" control={<Checkbox />} label="Peça Editável" />
                            </Block>
                        ) : (
                            <React.Fragment>
                                <Block>
                                    <Row>
                                        <Grid item sx={{ width: { xs: '100%', lg: '25%' } }}>
                                            <Field
                                                as={FormControlLabel}
                                                type="checkbox"
                                                name="fgFrenteVerso"
                                                control={<Checkbox />}
                                                label="Peça frente e verso"
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item sx={{ width: { xs: '100%', lg: '25%' } }}>
                                            <Field
                                                as={FormControlLabel}
                                                type="checkbox"
                                                name="fgFaca"
                                                control={<Checkbox />}
                                                label="Peça faca"
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item sx={{ width: { xs: '100%', lg: '25%' } }}>
                                            <Field as={FormControlLabel} type="checkbox" name="fgEditavel" control={<Checkbox />} label="Peça editável" />
                                        </Grid>
                                    </Row>

                                    <Row>
                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Tipo lâmina"
                                                placeholder="Capa, Miolo, Única, 1ª Via, 2ª Via..."
                                                name="dsTipoLamina"
                                                value={formik.values.dsTipoLamina}
                                                onChange={(e) => formik.setFieldValue('dsTipoLamina', e.target.value)}
                                                error={formik.errors.dsTipoLamina}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Papel"
                                                placeholder="Couche, Alvura..."
                                                name="dsPapel"
                                                value={formik.values.dsPapel}
                                                onChange={(e) => formik.setFieldValue('dsPapel', e.target.value)}
                                                error={formik.errors.dsPapel}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Gramatura"
                                                placeholder="120, 180..."
                                                name="dsGramatura"
                                                value={formik.values.dsGramatura}
                                                onChange={(e) => formik.setFieldValue('dsGramatura', e.target.value)}
                                                error={formik.errors.dsGramatura}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Formato de trabalho aberto"
                                                placeholder="21x29.7, 42x30..."
                                                name="dsFormatoAberto"
                                                value={formik.values.dsFormatoAberto}
                                                onChange={(e) => formik.setFieldValue('dsFormatoAberto', e.target.value)}
                                                error={formik.errors.dsFormatoAberto}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>
                                    </Row>

                                    <Row>
                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Formato de trabalho fechado"
                                                placeholder="21x29.7, 21x30..."
                                                name="dsFormatoFechado"
                                                value={formik.values.dsFormatoFechado}
                                                onChange={(e) => formik.setFieldValue('dsFormatoFechado', e.target.value)}
                                                error={formik.errors.dsFormatoFechado}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Cores"
                                                placeholder="4x4, 4x0, 1x0, 1x1..."
                                                name="dsCores"
                                                value={formik.values.dsCores}
                                                onChange={(e) => formik.setFieldValue('dsCores', e.target.value)}
                                                error={formik.errors.dsCores}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissNumberField
                                                label="Páginas"
                                                placeholder="2, 4, 6, 8, 16..."
                                                name="nrPagina"
                                                preventEmptyField={false}
                                                value={formik.values.nrPagina}
                                                error={formik.errors.nrPagina}
                                                onChange={(e) => {
                                                    formik.setFieldValue('nrPagina', e.target.value);
                                                }}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>

                                        <Grid item flex={1}>
                                            <CissTextField
                                                label="Acabamento"
                                                placeholder="Laminação Fosca, Verniz Localizado..."
                                                name="dsAcabamento"
                                                value={formik.values.dsAcabamento}
                                                onChange={(e) => formik.setFieldValue('dsAcabamento', e.target.value)}
                                                error={formik.errors.dsAcabamento}
                                                disabled={Boolean(formik.values.pecaMarketingFormato?.idPecaMarketingFormato === 2)}
                                            />
                                        </Grid>
                                    </Row>
                                </Block>

                                {formik.values.fgFaca && (
                                    <Block>
                                        <SubTitle
                                            label={
                                                <React.Fragment>
                                                    Imagem Faca
                                                    <Tooltip title="Imagem para auxiliar o gráfico no corte da peça.">
                                                        <InfoOutlinedIcon
                                                            color="success"
                                                            sx={{
                                                                ml: 2,
                                                                transform: 'translateY(3px)',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        />
                                        <Upload
                                            id="imagensFaca"
                                            listFiles={formik.values.imagensFaca || []}
                                            setListFiles={(list) => formik.setFieldValue('imagensFaca', list)}
                                            fileLimit={10}
                                            accept={['.pdf', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.xls', '.xlsx', '.tiff']}
                                        />
                                    </Block>
                                )}

                                <Block>
                                    <SubTitle
                                        label={
                                            <React.Fragment>
                                                Imagem Mockup (modelo)
                                                <Tooltip title="Deve ser enviada uma imagem menor, com tamanho máximo de 2MB. Imagem para auxiliar o gráfico como será o resultado final da peça.">
                                                    <InfoOutlinedIcon
                                                        color="success"
                                                        sx={{
                                                            ml: 2,
                                                            transform: 'translateY(3px)',
                                                        }}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        }
                                    />
                                    <Upload
                                        id="imagensMockup"
                                        listFiles={formik.values.imagensMockup || []}
                                        setListFiles={(list) => formik.setFieldValue('imagensMockup', list)}
                                        fileLimit={1}
                                        accept={['.png', '.jpg', '.jpeg', '.gif']}
                                    />
                                </Block>
                            </React.Fragment>
                        )}

                        <Block>
                            <SubTitle
                                label={
                                    <React.Fragment>
                                        Imagem Thumb
                                        <Tooltip title="Imagem de capa da peça. Formatos compatíveis: PNG e JPG.">
                                            <InfoOutlinedIcon
                                                color="success"
                                                sx={{
                                                    ml: 2,
                                                    transform: 'translateY(3px)',
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            />
                            <Upload
                                id="imagensThumb"
                                listFiles={formik.values.imagensThumb || []}
                                setListFiles={(list) => formik.setFieldValue('imagensThumb', list)}
                                fileLimit={1}
                                accept={['.jpg', '.png']}
                            />
                            {formik.errors.imagensThumb && (
                                <Typography
                                    sx={{
                                        color: (theme) => theme.palette.error.light,
                                    }}
                                >
                                    Imagem Thumb é obrigatória quando a peça marketing está ativa.
                                </Typography>
                            )}
                        </Block>

                        <Block>
                            <SubTitle
                                label={
                                    <React.Fragment>
                                        Arquivo para Download
                                        <Tooltip title="Sua peça está marcada como frente e verso. Deseja enviar somente um arquivo para o download?">
                                            <InfoOutlinedIcon
                                                color="success"
                                                sx={{
                                                    ml: 2,
                                                    transform: 'translateY(3px)',
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            />

                            <AzureUpload id="arquivosDownload" multipleUploads files={formik.values.arquivosDownload || []} onChange={(files) => formik.setFieldValue('arquivosDownload', files)} />
                            {formik.errors.arquivosDownload && (
                                <Typography
                                    sx={{
                                        color: (theme) => theme.palette.error.light,
                                    }}
                                >
                                    Arquivo para download é obrigatório quando a peça for editável.
                                </Typography>
                            )}
                        </Block>

                        <ConfirmModal
                            open={openConfirmModal}
                            setOpen={setOpenConfirmModal}
                            text="Sua peça está marcada como frente e verso. Deseja enviar somente um arquivo para download ?"
                            confirm={() => saveMarketing(formik.values, formik)}
                        />

                        <Bbar primaryBtnText={formik.values.fgEditavel ? 'Salvar e continuar' : 'Salvar'} primaryBtnHandler={formik.submitForm}>
                            {hasPermission([isMarketing ? 'MARKETING_PECA_MODERAR' : 'TRADE_PECA_MODERAR']) && <ToggleFgAtivo />}
                        </Bbar>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
