import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FilterEstatisticas(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterDocumento, setFilterDocumento] = useState<FilterValuesProps>({
        property: 'dsTipoDocumento',
        operator: 'like',
        value: null,
        idProperty: 'dsTipoDocumento',
    });

    const [filterDtInicial, setFilterDtInicial] = useState<FilterValuesProps>({
        property: 'dtInicial',
        operator: 'eq',
        value: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        required: 'É necessário selecionar um intervalo de tempo',
    });

    const [filterDtFinal, setFilterDtFinal] = useState<FilterValuesProps>({
        property: 'dtFinal',
        operator: 'eq',
        value: new Date(),
        required: 'É necessário selecionar um intervalo de tempo',
    });

    const filters = [
        { value: filterDocumento, setter: setFilterDocumento },
        { value: filterDtInicial, setter: setFilterDtInicial },
        { value: filterDtFinal, setter: setFilterDtFinal },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissAutoComplete
                    value={filterDocumento.value}
                    onChange={(e, value) => {
                        setFilterDocumento({ ...filterDocumento, value });
                    }}
                    requestOptions={{
                        url: '/gestao/tipodocumento',
                        columns: 'idTipoDocumento,dsTipoDocumento',
                    }}
                    isOptionEqualToValue={(option, value) => option && value && option.idTipoDocumento === value.idTipoDocumento}
                    multiple={false}
                    optionStringTemplate={'<strong>{idTipoDocumento}</strong> - {dsTipoDocumento}'}
                    selectedStringTemplate={'{idTipoDocumento} - {dsTipoDocumento}'}
                    label={'Tipo de documento'}
                />
            </Grid>

            <Grid item flex={1}>
                <DateRangePicker
                    value={[filterDtInicial.value ?? null, filterDtFinal.value ?? null]}
                    onChange={([startDate, endDate]) => {
                        setFilterDtInicial({ ...filterDtInicial, value: startDate });
                        setFilterDtFinal({ ...filterDtFinal, value: endDate });
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <CissTextField {...startProps} autoComplete="off" label={'Data início'} />
                            <Box sx={{ mx: 2 }}> até </Box>
                            <CissTextField {...endProps} autoComplete="off" label={'Data fim'} />
                        </React.Fragment>
                    )}
                />
            </Grid>
        </Filter>
    );
}
