import { useEffect } from 'react';

import { enqueueSnackbar } from 'notistack';

import { usePagamentoContext } from '../contexts/PagamentoContext';
import { ResponsePagamento } from '../types';

import { useActionsPagamento } from './useActionsPagamento';
import { useNavigateToPedidoRealizado } from './useNavigateToPedidoRealizado';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';

const errorMessage =
    'Falha ao realizar o pagamento do pedido. Para finalizar novamente,' +
    ' acesse a opção "Alterar pagamento" no pedido através da tela de "Consulta de Pedidos".';

export function useMutatePagamento() {
    const {
        idPedido,
        idsPedido,
        setIsPending,
        setPagamento,
        setIsFinishing,
        selecaoMeioPagamento,
        setSelecaoMeioPagamento,
        setOpenPagamentoPixModal,
    } = usePagamentoContext();
    const { mutate, isLoading: isLoadingPagamento } = useFormMutation<ResponsePagamento>();
    const { setNextIdPedido } = useActionsPagamento();
    const { showError } = useShowError();
    const { navigateToPedidoRealizado } = useNavigateToPedidoRealizado();
    const isLastPedido = idsPedido.length === 1;

    useEffect(() => setIsFinishing(isLoadingPagamento), [isLoadingPagamento]);

    /**
     * No sucesso da finalização do pedido caso:
     * - Haja falha no pagamento, exibe uma mensagem de erro no bloco de falhas, ocultando as formas de pagamento e exibindo apenas o botão de avançar.
     * - Caso o pagamento foi por pix, define que há um pagamento pendente e abre o modal do pix.
     * - Por último, exibe um toast, e caso foi o último pedido, redireciona para a tela de pedido realizado, senão, avança para o próximo pedido.
     */
    const onSuccess = (data: ResponsePagamento) => {
        const pedidoPagamento = data.data.data.pedidoPagamentos[0];
        const pedidoHasErrors = pedidoPagamento?.statusPedidoPagamento.statusPedidoPagamentoEnum === 'FALHA';
        const hasPagamentoPix = selecaoMeioPagamento?.meioPagamentoEnum === 'PIX';

        if (pedidoHasErrors) {
            setPagamento((pagamento) => pagamento && { ...pagamento, erros: [errorMessage] });
        } else if (hasPagamentoPix) {
            setIsPending(true);

            setOpenPagamentoPixModal(true);

            setPagamento((pagamento) => pagamento && { ...pagamento, pedidoPagamento });
        } else {
            enqueueSnackbar('Pedido finalizado com sucesso!', { variant: 'success' });

            if (isLastPedido) {
                navigateToPedidoRealizado();
            } else {
                setNextIdPedido();

                setSelecaoMeioPagamento(null);
            }
        }
    };

    /**
     * Retorna a url para realizar o pagamento do pedido.
     */
    const getMutateUrl = () => {
        let url = `/compra/pedidocompra/rascunho/${idPedido}/finalizar?idCondicaoPagamento=${selecaoMeioPagamento?.condicaoPagamento.idCondicaoPagamento}`;

        if (selecaoMeioPagamento?.meioPagamentoEnum === 'CARTAO_CREDITO') {
            url += `&idCartaoPagamento=${selecaoMeioPagamento?.cartaoPagamento?.idCartaoPagamento}`;
        }

        return url;
    };

    /**
     * Envia os dados para realizar o pagamento do pedido.
     */
    const mutatePagamento = () => {
        mutate(
            {
                url: getMutateUrl(),
                preventSnack: true,
                formData: selecaoMeioPagamento,
            },
            {
                onSuccess,
                onError: (error) => showError(error, 'Falha ao finalizar o pedido.'),
            },
        );
    };

    return { mutatePagamento, isLoadingPagamento };
}
