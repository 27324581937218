import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { Block, FormPage } from 'components/page';
import { Upload } from 'components/upload';
import { useFormMutation } from 'mutations/useFormMutation';
import { Typography } from '@mui/material';

interface FontePeso {
    dsPeso: string;
    vlFontePeso: number;
}

interface FormValues {
    fonte: any[];
    fontePeso: FontePeso | null;
}

interface RequestFonteObj {
    dsFonteTextoEstilo: string;
    dsFonteTextoFamilia: string;
    dsFonteTextoFormato: string;
    lkFonteTexto: string;
}

export function FontesFormComp(): JSX.Element {
    const { mutate } = useFormMutation();
    const navigate = useNavigate();

    const fontWeigthOptions = [
        {
            dsPeso: 'Normal',
            vlFontePeso: 400,
        },
        {
            dsPeso: 'Negrito',
            vlFontePeso: 700,
        },
    ];

    const initialValues: FormValues = {
        fonte: [],
        fontePeso: fontWeigthOptions[0],
    };

    const onSubmit = useCallback(
        (values: FormValues, formik: FormikHelpers<FormValues>) => {
            const fonte = values.fonte[0];
            const extension = fonte.arquivo.dsExtensao;
            const fontFamily = fonte.arquivo.dsArquivo.split('.').shift();
            const link = fonte.arquivo.dsLink;
            const style = `font-weight: ${values.fontePeso?.vlFontePeso};font-style: normal;font-display: swap;`;

            const formats = {
                otf: 'opentype',
                ttf: 'truetype',
                eot: 'embedded-opentype',
                ttc: 'collection',
            };

            const formData: RequestFonteObj = {
                dsFonteTextoEstilo: style,
                dsFonteTextoFamilia: fontFamily,
                dsFonteTextoFormato: formats[extension as keyof typeof formats],
                lkFonteTexto: link,
            };

            mutate(
                {
                    url: '/gestao/fontetexto',
                    idField: 'idPecaMarketing',
                    formData,
                    formik,
                },
                {
                    onSuccess: () => {
                        navigate(-1);

                        const fontesCss = document.getElementById('fontes_edicao_pecas') as HTMLLinkElement;
                        if (fontesCss) {
                            fontesCss.href = `/proxy/fonts.css?hash=${Math.ceil(Math.random() * 100000)}`;
                        }
                    },
                },
            );
        },
        [mutate, navigate],
    );

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                fontePeso: Yup.object().required().nullable(),
                fonte: Yup.array().required().nullable(),
            }),
        [],
    );

    return (
        <FormPage title={'Fontes'} values={initialValues} onSubmit={onSubmit} url="/gestao/fontetexto/" validationSchema={validationSchema}>
            {(formik: FormikProps<FormValues>) => (
                <React.Fragment>
                    <Block>
                        <CissAutoComplete
                            label="Estilo de fonte"
                            name="fontePeso"
                            value={formik.values.fontePeso}
                            error={formik.errors.fontePeso}
                            onChange={(e, value) => formik.setFieldValue('fontePeso', value)}
                            options={fontWeigthOptions}
                            multiple={false}
                            optionStringTemplate={'{dsPeso}'}
                            selectedStringTemplate={'{dsPeso}'}
                            isOptionEqualToValue={(option, value) => option.vlFontePeso === value.vlFontePeso}
                        />
                    </Block>

                    <Block>
                        <Upload
                            id="fonte"
                            listFiles={formik.values.fonte}
                            setListFiles={(list: any) => {
                                formik.setFieldValue('fonte', list);
                            }}
                            fileLimit={10}
                            accept={['.ttc', '.odt', '.eot', '.ttf', '.otf']}
                        />

                        {formik.errors.fonte && (
                            <Typography color="error" fontSize="0.85rem" lineHeight={1.5} mt={1}>
                                {formik.errors.fonte as string}
                            </Typography>
                        )}
                    </Block>
                </React.Fragment>
            )}
        </FormPage>
    );
}
