import React from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';
import { QueryObserverBaseResult } from '@tanstack/react-query';

import { usePermissionsContext } from 'context/PermissionsContext';
import { DreConsultaGridTooltip, DreLiberarCheckbox, DrePreencherBtn, DreStatusChip } from 'pages/financeiro/dre/components';
import { useDreFilterLojas } from 'pages/financeiro/dre/hooks';
import { Apuracao, RequestDreListProps } from 'pages/financeiro/dre/types';
import { getColumnTooltip } from 'pages/financeiro/dre/utils';

export function useDreGridColumns({ data, refetch }: { data?: RequestDreListProps; refetch: QueryObserverBaseResult['refetch'] }): GridColDef[] {
    const { hasPermission } = usePermissionsContext();
    const filterLojas = useDreFilterLojas();
    const isMultipleLojas = filterLojas?.value.length > 1;

    const columns: GridColDef[] = [
        { field: 'dsMes', headerName: 'Mês', minWidth: 74, sortable: false },
        {
            field: 'vlReceitaBruta',
            headerName: 'Receita Bruta',
            type: 'number',
            width: 125,
            minWidth: 110,
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.RECEITA_BRUTA, data)}>Receita Bruta</DreConsultaGridTooltip>
            ),
        },
        {
            field: 'vlReceitaLiquida',
            headerName: 'Receita Líquida',
            type: 'number',
            width: 125,
            minWidth: 110,
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.RECEITA_LIQUIDA, data)}>Receita Líquida</DreConsultaGridTooltip>
            ),
        },
        {
            field: 'vlLucroLiquido',
            headerName: 'Lucro',
            type: 'number',
            width: 125,
            minWidth: 80,
            flex: 1,
            sortable: false,
            renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.LUCRO_LIQUIDO, data)}>Lucro</DreConsultaGridTooltip>,
        },
        {
            field: 'vlCmv',
            headerName: 'CMV',
            type: 'number',
            width: 125,
            minWidth: 80,
            flex: 1,
            sortable: false,
            renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.CMV, data)}>CMV</DreConsultaGridTooltip>,
        },
        {
            field: 'vlGastos',
            headerName: 'Despesas',
            type: 'number',
            width: 125,
            minWidth: 90,
            flex: 1,
            sortable: false,
            renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.GASTOS, data)}>Despesas</DreConsultaGridTooltip>,
        },
    ];

    if (!isMultipleLojas) {
        columns.unshift({
            field: 'dsLoja',
            headerName: 'Loja',
            flex: 1,
            minWidth: 190,
            sortable: false,
            filterable: false,
        });

        columns.push({
            field: 'dsStatus',
            headerName: 'Status',
            headerAlign: 'center',
            minWidth: 140,
            sortable: false,
            renderCell: ({ row }) => <DreStatusChip idStatus={row.idStatus} title={row.dsStatus} />,
        });

        if (hasPermission(['FINANCEIRO_DRE_PREENCHER'])) {
            columns.push({
                field: 'preencher',
                align: 'center',
                headerName: 'Preencher Estimativa de Resultado',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 110,
                renderCell: ({ row }) => {
                    return (
                        <DrePreencherBtn
                            idStatus={row.idStatus}
                            isLoading={row.isLoading}
                            id={row.idDreMercadologica}
                            vlReceita={row.vlReceitaBruta}
                        />
                    );
                },
            });
        }

        if (hasPermission(['FINANCEIRO_DRE_MODERAR'])) {
            columns.push({
                field: 'liberar',
                align: 'center',
                headerName: 'Liberar',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 75,
                minWidth: 60,
                renderCell: ({ row }) => <DreLiberarCheckbox idStatus={row.idStatus} id={row.idDreMercadologica} refresh={refetch} />,
            });
        }
    }

    return columns;
}
