import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useRef, useState } from 'react';

import { PedidoRealizadoRow } from 'pages/compra/pedido-realizado/types';

type ConfirmModal = {
    callback(): void;
    text?: string;
};

type ActionMenuData = {
    row: PedidoRealizadoRow;

    disabledActions: boolean;
    setDisabledActions: Dispatch<SetStateAction<boolean>>;

    openConfirmModal: boolean;
    setOpenConfirmModal: Dispatch<SetStateAction<boolean>>;

    dataConfirmModal: ConfirmModal | null;
    setDataConfirmModal: Dispatch<SetStateAction<ConfirmModal | null>>;
};

type ActionMenuProvider = {
    children: ReactNode;
    row: PedidoRealizadoRow;
};

const ActionMenuContext = createContext<ActionMenuData>({} as ActionMenuData);

export function ActionMenuProvider({ children, row }: ActionMenuProvider) {
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [disabledActions, setDisabledActions] = useState<boolean>(false);
    const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModal | null>(null);

    return (
        <ActionMenuContext.Provider
            value={{
                row,
                openConfirmModal,
                setOpenConfirmModal,
                dataConfirmModal,
                setDataConfirmModal,
                disabledActions,
                setDisabledActions,
            }}
        >
            {children}
        </ActionMenuContext.Provider>
    );
}

export const useActionMenu = (): ActionMenuData => useContext(ActionMenuContext);
