import { useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { ConfirmModal } from 'components/modal';
import { useDeleteCartaoPagamento } from 'pages/compra/pagamento/hooks';

export function PagamentoCartaoCreditoDeleteColumn({ row }: { row: GridRowModel }) {
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const { handleDelete, isLoading } = useDeleteCartaoPagamento();

    return (
        <>
            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={() => handleDelete(row.id)} />

            <Tooltip title="Excluir">
                <span>
                    <IconButton disabled={isLoading} onClick={() => setOpenConfirmModal(true)}>
                        <DeleteOutlinedIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
}
