import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { Field, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField, MarketingCampanhaFieldFormik, ToggleFgSomenteLojasRevendedor } from 'components/fields/';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { getTypeMarketing, initialCampanhaFormValues, MarketingTradePage, RequestCampanhaModelProps } from 'pages/marketing-trade/types';

const mapContentToInitialValues = (contentData: RequestCampanhaModelProps): RequestCampanhaModelProps => {
    if (!contentData.fgPermanente) {
        if (!contentData.dtInicioExibicao?.endsWith('T00:00:00')) {
            contentData.dtInicioExibicao = `${contentData.dtInicioExibicao}T00:00:00`;
        }

        if (!contentData.dtFimExibicao?.endsWith('T00:00:00')) {
            contentData.dtFimExibicao = `${contentData.dtFimExibicao}T00:00:00`;
        }
    }

    return contentData;
};

export function MarketingTradeCampanhaForm({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const { setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const { mutate } = useFormMutation();
    const { id } = useParams();
    const hasModeratePermission = hasPermission([isMarketing ? 'MARKETING_CAMPANHA_MODERAR' : 'TRADE_CAMPANHA_MODERAR']);

    const url = '/gestao/campanhamarketing';

    const handleSubmit = (values: RequestCampanhaModelProps, formik: FormikHelpers<RequestCampanhaModelProps>): void => {
        if (values.fgPermanente) {
            values.dtInicioExibicao = null;
            values.dtFimExibicao = null;
        }

        mutate(
            {
                url,
                formik,
                idField: 'idCampanhaMarketing',
                formData: values,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsCampanhaMarketing: Yup.string().required().min(3),
                dtInicioExibicao: Yup.date()
                    .when('fgPermanente', {
                        is: false,
                        then: (schema) => schema.required(),
                    })
                    .typeError('A data não é válida.')
                    .nullable(),
                dtFimExibicao: Yup.date()
                    .when('fgPermanente', {
                        is: false,
                        then: (schema) => schema.required(),
                    })
                    .typeError('A data não é válida.')
                    .nullable(),
            }),
        [],
    );

    return (
        <FormPage
            title={`Campanha de ${isMarketing ? 'Marketing' : 'Trade'}`}
            validateOnSubmit={true}
            validateOnChange={false}
            url={url}
            onSubmit={handleSubmit}
            fgAtivo={hasModeratePermission}
            validationSchema={validationSchema}
            values={initialCampanhaFormValues(isMarketing)}
            mapContentToInitialValues={mapContentToInitialValues}
            extraContent={hasModeratePermission ? <ToggleFgSomenteLojasRevendedor /> : null}
        >
            {(formik: FormikProps<RequestCampanhaModelProps>) => (
                <Block>
                    <Row>
                        <Grid item flex={1}>
                            <CissTextField
                                label="Nome da Campanha"
                                name="dsCampanhaMarketing"
                                value={formik.values.dsCampanhaMarketing}
                                onChange={(e) => formik.setFieldValue('dsCampanhaMarketing', e.target.value)}
                                error={formik.errors.dsCampanhaMarketing}
                            />
                        </Grid>

                        <Grid item flex={1}>
                            <Field
                                component={MarketingCampanhaFieldFormik}
                                name="campanhaMarketingPai"
                                limitViewLevelsByCampanha={id || true}
                                filter={[
                                    {
                                        property: 'idTipoMarketing',
                                        operator: 'eq',
                                        value: getTypeMarketing(isMarketing),
                                    },
                                ]}
                            />
                        </Grid>
                    </Row>

                    <Row sx={{ alignItems: 'center' }}>
                        <Grid item>
                            <Field as={FormControlLabel} type="checkbox" name="fgPermanente" control={<Checkbox />} label="Campanha Permanente" />
                        </Grid>

                        {!formik.values.fgPermanente && (
                            <Grid item flex={1}>
                                <LocalizationProvider
                                    adapterLocale={ptBR}
                                    dateAdapter={AdapterDateFns}
                                    localeText={{ start: 'Início Exibição', end: 'Final Exibição' }}
                                >
                                    <DateRangePicker
                                        value={[formik.values.dtInicioExibicao || null, formik.values.dtFimExibicao || null]}
                                        onChange={(newValue) => {
                                            const [start, end] = newValue;

                                            formik.setFieldValue('dtInicioExibicao', start);
                                            formik.setFieldValue('dtFimExibicao', end);
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <CissTextField
                                                    {...startProps}
                                                    name="dtInicioExibicao"
                                                    error={formik.errors.dtInicioExibicao}
                                                    autoComplete="off"
                                                />
                                                <Box sx={{ mx: 2 }}> até </Box>
                                                <CissTextField
                                                    {...endProps}
                                                    name="dtFimExibicao"
                                                    error={formik.errors.dtFimExibicao}
                                                    autoComplete="off"
                                                />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        )}
                    </Row>
                </Block>
            )}
        </FormPage>
    );
}
