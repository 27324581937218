import { Dispatch, SetStateAction, useState } from 'react';

import { Box, Button } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { ConfigPedidoCondicoesPagamentoModalGrid } from 'pages/compra/configuracao-pedido/components';
import { useActionsCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

type ConfigPedidoCondicoesPagamentoModal = {
    id: number | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function ConfigPedidoCondicoesPagamentoModal({ id, open, setOpen }: ConfigPedidoCondicoesPagamentoModal) {
    const { updateCondicoesPagamentoLoja } = useActionsCondicoesPagamento();
    const [selectedCondicoesPagamento, setSelectedCondicoesPagamento] = useState<CondicaoPagamento[]>([]);

    return (
        <ContentModal
            closeButton
            open={open}
            setOpen={setOpen}
            sx={{
                padding: 2,
                borderRadius: 1,
                width: {
                    xs: '90vw',
                    sm: 700,
                },
            }}
        >
            <SubTitle label="Condições de Pagamento" />

            <ListagemProvider>
                <ConfigPedidoCondicoesPagamentoModalGrid
                    id={id}
                    open={open}
                    selectedCondicoesPagamento={selectedCondicoesPagamento}
                    setSelectedCondicoesPagamento={setSelectedCondicoesPagamento}
                />
            </ListagemProvider>

            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2, mt: 2 }}>
                <Button variant="outlined" onClick={() => setOpen(false)}>
                    Cancelar
                </Button>

                <Button
                    variant="contained"
                    onClick={() => {
                        updateCondicoesPagamentoLoja(id, selectedCondicoesPagamento);

                        setOpen(false);
                    }}
                >
                    Incluir
                </Button>
            </Box>
        </ContentModal>
    );
}
