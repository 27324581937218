import React, { useState } from 'react';

import { Checkbox } from '@mui/material';

import { ConfirmModal } from 'components/modal';
import { useFormMutation } from 'mutations/useFormMutation';

type DreLiberarCheckbox = {
    idStatus: number;
    id: number;
    refresh: () => void;
};

export function DreLiberarCheckbox({ idStatus, id, refresh }: DreLiberarCheckbox): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const { mutate } = useFormMutation();
    const isFinalizado = idStatus === 3; // 3 = Finalizado
    const isBloqueado = idStatus === 4; // 4 = Bloqueado

    const handleChangeLiberarDre = (): void => {
        setChecked(true);

        setOpenConfirmModal(true);
    };

    const confirmChangeLiberarDre = (): void => {
        mutate(
            {
                url: `/dre/dremercadologica/libera?idDreMercadologica=${id}`,
                method: 'PUT',
            },
            {
                onSuccess: () => {
                    refresh();

                    setChecked(false);
                },
                onError: () => setChecked(false),
            },
        );
    };

    return (
        <>
            <Checkbox checked={checked} onChange={handleChangeLiberarDre} disabled={!(isFinalizado || isBloqueado)} />

            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                confirm={confirmChangeLiberarDre}
                cancel={() => setChecked(false)}
                text="Tem certeza que deseja liberar para preenchimento a estimativa de resultado selecionada?"
            />
        </>
    );
}
