import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

export function LojaReportBtn() {
    const requestOptions: RequestOptionsType = {
        url: '/gestao/lojaconfiguracao/exportar',
    };

    const { isLoadingFile, downloadFile } = useDownloadFile({ requestOptions });

    /**
     * Realiza o download do arquivo.
     */
    const handleClickDownload = () => downloadFile({ directDownload: true });

    return (
        <LoadingButton
            variant="outlined"
            onClick={handleClickDownload}
            loading={isLoadingFile}
            startIcon={<RiFileExcel2Fill />}
            sx={{ color: 'green', borderColor: 'green', width: { xs: '100%', sm: 'auto' } }}
        >
            Exportar em .xlsx
        </LoadingButton>
    );
}
