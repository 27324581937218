import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';
import { useGetCarrinho, usePostClonePedido } from 'pages/compra/pedido-realizado/hooks';

export function PedidoRealizadoCloneBtn() {
    const { row, disabledActions, setDisabledActions, setOpenConfirmModal, setDataConfirmModal } = useActionMenu();
    const { enqueueSnackbar } = useSnackbar();
    const { loja, configPedido } = row;
    const { idLoja } = loja;
    const { idConfigPedido } = configPedido;
    const { refetch: refetchCart, isFetching: isFetchingCart } = useGetCarrinho({ idConfigPedido, idLoja });
    const { handleClone, isLoadingClonar } = usePostClonePedido();

    const openCloneConfirmation = () => {
        setDisabledActions(true);

        refetchCart({ throwOnError: true })
            .then((res) => {
                setOpenConfirmModal(true);

                let text;

                if (res?.data?.produtos && res.data.produtos.length > 0) {
                    text = 'Já existe um rascunho para o pedido selecionado. Deseja clonar o pedido e substituir o rascunho atual?';
                } else {
                    text = 'Não existe um rascunho para o pedido selecionado. Deseja clonar o pedido?';
                }

                setDataConfirmModal({ callback: handleClone, text });
            })
            .catch(() => enqueueSnackbar('Não foi possível verificar o rascunho do pedido. Tente novamente', { variant: 'error' }))
            .finally(() => setDisabledActions(false));
    };

    return (
        <LoadingButton
            color="inherit"
            onClick={openCloneConfirmation}
            disabled={disabledActions}
            loading={isFetchingCart || isLoadingClonar}
            startIcon={<ContentCopyIcon />}
        >
            Clonar
        </LoadingButton>
    );
}
