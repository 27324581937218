import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RevendedorRequest } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';

export function useGetRevendedor(): UseQueryResult<RevendedorRequest> {
    const requestListagem = useRequestListagem();
    const { filter, autoLoad } = useListagemContext();
    const { showError } = useShowError();

    const requestOptions: RequestOptionsType = {
        url: '/integracao-revendedor/cadastrorevendedor',
        sort: [{ property: 'nome', direction: 'DESC' }],
        nested: true,
        filter,
    };

    return useQuery<RevendedorRequest>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        onError: (res) => showError(res),
    });
}
