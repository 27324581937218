import { SxProps } from '@mui/system';

import { CondicaoPagamentoAutoComplete } from 'components/autocompletes';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

type PagamentoMethodAutoComplete = {
    condicoesPagamento: CondicaoPagamento[];
    value: CondicaoPagamento | null;
    onChange: (newValue: CondicaoPagamento | null) => void;
    sx?: SxProps;
};

export function PagamentoMethodAutoComplete({ sx, condicoesPagamento, value, onChange }: PagamentoMethodAutoComplete) {
    const { isFinishing, isPending } = usePagamentoContext();

    return (
        <CondicaoPagamentoAutoComplete
            label=""
            size="small"
            requestOptions={null}
            disableClearable={true}
            disabled={isFinishing || isPending}
            value={value}
            options={condicoesPagamento}
            readOnly={condicoesPagamento.length === 1}
            onChange={(e, value) => onChange(value)}
            sx={{ minWidth: 260, flex: { xs: '1', md: 0 }, ...sx }}
        />
    );
}
