import { useState } from 'react';

import HistoryIcon from '@mui/icons-material/History';
import { Button } from '@mui/material';

import { PedidoLogModal } from 'components/modal/PedidoLogModal';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoLogsBtn() {
    const { row, disabledActions } = useActionMenu();
    const [isOpenLogModal, setIsOpenLogModal] = useState<boolean>(false);

    return (
        <>
            <PedidoLogModal open={isOpenLogModal} setOpen={setIsOpenLogModal} idPedido={row.idPedido} />

            <Button color="inherit" startIcon={<HistoryIcon />} disabled={disabledActions} onClick={() => setIsOpenLogModal(true)}>
                Logs
            </Button>
        </>
    );
}
