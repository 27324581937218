import { createContext, Dispatch, MutableRefObject, ReactNode, SetStateAction, useContext, useRef, useState } from 'react';

export type CarrinhoContext = {
    disabledActions: boolean;
    setDisabledActions: Dispatch<SetStateAction<boolean>>;

    openFinishedSuccessModal: boolean;
    setOpenFinishedSuccessModal: Dispatch<SetStateAction<boolean>>;

    openFinishedErrorModal: boolean;
    setOpenFinishedErrorModal: Dispatch<SetStateAction<boolean>>;

    contentFinishedErrorModal: MutableRefObject<ReactNode>;
    labelFinishedSuccessModal: MutableRefObject<string>;
};

type CarrinhoProvider = {
    children?: ReactNode;
};

const CarrinhoContext = createContext<CarrinhoContext>({} as CarrinhoContext);

export function CarrinhoProvider({ children }: CarrinhoProvider) {
    const [disabledActions, setDisabledActions] = useState<boolean>(false);
    const [openFinishedSuccessModal, setOpenFinishedSuccessModal] = useState<boolean>(false);
    const [openFinishedErrorModal, setOpenFinishedErrorModal] = useState<boolean>(false);
    const contentFinishedErrorModal = useRef<ReactNode>(null);
    const labelFinishedSuccessModal = useRef<string>('');

    return (
        <CarrinhoContext.Provider
            value={{
                disabledActions,
                setDisabledActions,
                openFinishedSuccessModal,
                setOpenFinishedSuccessModal,
                openFinishedErrorModal,
                setOpenFinishedErrorModal,
                contentFinishedErrorModal,
                labelFinishedSuccessModal,
            }}
        >
            {children}
        </CarrinhoContext.Provider>
    );
}

export const useCarrinhoContext = () => useContext(CarrinhoContext);
