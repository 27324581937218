import React, { useCallback, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ptBR } from 'date-fns/locale';
import { Form, Formik, FieldArray, FormikProps, FormikHelpers, getIn } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { CentroCustoAutoComplete } from 'components/autocompletes/CentroCustoAutoComplete';
import { CentroDistribuicaoAutoComplete } from 'components/autocompletes/CentroDistribuicaoAutoComplete';
import { CentroPedidoAutoComplete } from 'components/autocompletes/CentroPedidoAutoComplete';
import { CondicaoPagamentoAutoComplete } from 'components/autocompletes/CondicaoPagamentoAutoComplete';
import { ControleEstoqueAutoComplete } from 'components/autocompletes/ControleEstoqueAutoComplete';
import { DepositoAutoComplete } from 'components/autocompletes/DepositoAutoComplete';
import { GeraNotaDebitoAutoComplete } from 'components/autocompletes/GeraNotaDebitoAutoComplete';
import { TabelaPrecoAutoComplete } from 'components/autocompletes/TabelaPrecoAutoComplete';
import { TipoGradeAutoComplete } from 'components/autocompletes/TipoGradeAutoComplete';
import { TipoOperacaoAutoComplete } from 'components/autocompletes/TipoOperacaoAutoComplete';
import { TipoPedidoAutoComplete } from 'components/autocompletes/TipoPedidoAutoComplete';
import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Centered } from 'components/grid';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFocusErrorFormik } from 'hooks/useFocusErrorFormik';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { ValueChangeListener } from 'pages/compra/configuracao-pedido/components/atoms/ValueChangeListener';
import {
    RequestDadosBasicosProps,
    initialDadosBasicosValues,
    DadosBasicosProps,
    ConfigPedidoOperacoes,
    TipoLojasProps,
    TipoLojasRequestProps,
} from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraDadosBasicos(): JSX.Element {
    const { isActiveStepSaved, setActiveStepSaved, setStepDisabled } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { mutate, isLoading: mutateLoading } = useFormMutation();
    const RequestListagem = useRequestListagem();
    const { focusError } = useFocusErrorFormik();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [formData, setFormData] = useState<DadosBasicosProps>(initialDadosBasicosValues);

    const setTipoLojasConfigPedidoOperacoes = useCallback((tipoLojas: TipoLojasProps[], dadosBasicos: DadosBasicosProps) => {
        if (tipoLojas && dadosBasicos) {
            const newConfigPedidoOperacoes: ConfigPedidoOperacoes[] = [];

            tipoLojas.forEach((item, index) => {
                newConfigPedidoOperacoes.push({
                    ...(dadosBasicos?.configPedidoOperacoes[index] ? dadosBasicos?.configPedidoOperacoes[index] : []),
                    tipoLoja: item,
                });
            });

            setFormData((oldFormData) => ({ ...oldFormData, configPedidoOperacoes: newConfigPedidoOperacoes }));
        }
    }, []);

    const tipoLojaRequestOptions: RequestOptionsType = {
        url: '/gestao/tipoloja',
        sort: [{ property: 'dsTipoLoja', direction: 'ASC' }],
        columns: 'idTipoLoja,dsTipoLoja',
    };

    useQuery([tipoLojaRequestOptions], (): Promise<TipoLojasRequestProps> => RequestListagem(tipoLojaRequestOptions).then((res) => res.data), {
        enabled: !id,
        onSuccess: (response) => setTipoLojasConfigPedidoOperacoes(response.data, formData),
        onError: () => enqueueSnackbar('Falha ao processar solicitação de tipo de lojas', { variant: 'error' }),
    });

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedido/${id}`,
    };

    const { fetchStatus } = useQuery(
        [requestOptions],
        (): Promise<RequestDadosBasicosProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: Boolean(id),
            onSuccess: ({ data }) => {
                const { dtInicioVigencia, dtFimVigencia } = data || {};
                const { idTipoControleEstoquePedido } = data.tipoControleEstoquePedido || {};
                const { idTipoPedido } = data.tipoPedido || {};

                data.dtInicioVigencia = `${dtInicioVigencia}T00:00:00`;
                data.dtFimVigencia = `${dtFimVigencia}T00:00:00`;

                setStepDisabled(5, idTipoControleEstoquePedido !== 2);
                setStepDisabled(7, idTipoPedido !== 4);

                setFormData(() => data);
            },
        },
    );

    const onSubmitForm = (values: DadosBasicosProps, formik: FormikHelpers<DadosBasicosProps>): void => {
        values.idConfigPedido = Number(id);

        mutate(
            {
                formik,
                url: '/gestao/configpedido',
                idField: id ? 'idConfigPedido' : '',
                formData: values,
                preventRedirect: true,
                preventSnack: true,
            },
            {
                onSuccess: ({ data }: any) => {
                    const { dtInicioVigencia, dtFimVigencia } = data.data || {};
                    const { idTipoControleEstoquePedido } = data.data.tipoControleEstoquePedido;
                    const { idTipoPedido } = data.data.tipoPedido;

                    data.data.dtInicioVigencia = `${dtInicioVigencia}T00:00:00`;
                    data.data.dtFimVigencia = `${dtFimVigencia}T00:00:00`;

                    enqueueSnackbar('Dados básicos salvos com sucesso', { variant: 'success' });

                    if (!isActiveStepSaved()) {
                        navigate(`/compra/configuracao-pedido/form/${data.data.idConfigPedido}`);
                    }

                    setFormData(() => data.data);

                    setActiveStepSaved();

                    setStepDisabled(5, idTipoControleEstoquePedido !== 2);
                    setStepDisabled(7, idTipoPedido !== 4);

                    queryClient.invalidateQueries(['getConfigPedidoSteps']);
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsConfigPedido: Yup.string().required(),
                dtFimVigencia: Yup.string().required().nullable(),
                dtInicioVigencia: Yup.string().required().nullable(),
                vlMinimo: Yup.number().required(),
                fgEnviaEmailPendenteGrade: Yup.boolean().required(),
                fgSincronizaTerceiros: Yup.boolean().required(),
                tipoControleEstoquePedido: Yup.object().required().nullable(),
                tipoPedido: Yup.object().required().nullable(),
                tipoGrade: Yup.object().required().nullable(),
                deposito: Yup.object().required().nullable(),
                centroDistribuicao: Yup.object().required().nullable(),
                centroPedido: Yup.object().required().nullable(),
                centroCusto: Yup.object().required().nullable(),
                tabelaPreco: Yup.object().required().nullable(),
                pedidoTipoNotaDebito: Yup.object().required().nullable(),
                fgLojaMetaAtingida: Yup.boolean().required().nullable(),
                fgLojaFaixaRoyalties: Yup.boolean().required().nullable(),
                fgAtivo: Yup.boolean().required().nullable(),
                coefNotaDebito: Yup.string().nullable(),
                peRoyalties: Yup.string().nullable(),
                fgGeraNotaDebito: Yup.boolean().nullable(),
                fgVisivel: Yup.boolean().required().nullable(),
                configPedidoOperacoes: Yup.array().of(
                    Yup.object().shape({
                        operacao: Yup.object().required().nullable(),
                    }),
                ),
            }),
        [],
    );

    return (
        <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnSubmit={true}
            onSubmit={onSubmitForm}
            enableReinitialize
        >
            {(formik: FormikProps<DadosBasicosProps>) => {
                focusError(formik.errors);

                return (
                    <Form>
                        {fetchStatus === 'fetching' ? (
                            <Centered>
                                <CircularProgress sx={{ my: 2 }} />
                            </Centered>
                        ) : (
                            <React.Fragment>
                                <FullLoading loading={mutateLoading} />

                                <Box>
                                    <Block>
                                        <SubTitle label="Dados Básicos" />

                                        <Row alignItems="center" sx={{ flexDirection: 'row' }}>
                                            <Grid item flex={1}>
                                                <CissTextField
                                                    label="Nome da configuração"
                                                    name="dsConfigPedido"
                                                    error={formik.errors.dsConfigPedido}
                                                    value={formik.values.dsConfigPedido}
                                                    onChange={(e) => formik.setFieldValue('dsConfigPedido', e.target.value)}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <FormControlLabel
                                                    label="Ativo"
                                                    name="fgAtivo"
                                                    control={<Checkbox />}
                                                    checked={formik.values.fgAtivo}
                                                    onChange={(e, checked) => formik.setFieldValue('fgAtivo', checked)}
                                                />
                                            </Grid>
                                        </Row>
                                        <Row>
                                            <Grid item flex={1}>
                                                <ControleEstoqueAutoComplete
                                                    name="tipoControleEstoquePedido"
                                                    value={formik.values.tipoControleEstoquePedido}
                                                    error={formik.errors.tipoControleEstoquePedido}
                                                    onChange={(e, value) => formik.setFieldValue('tipoControleEstoquePedido', value)}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <LocalizationProvider
                                                    autoComplete="no"
                                                    adapterLocale={ptBR}
                                                    dateAdapter={AdapterDateFns}
                                                    localeText={{ start: 'Vigência início', end: 'Vigência fim' }}
                                                >
                                                    <DateRangePicker
                                                        value={[formik.values.dtInicioVigencia ?? '', formik.values.dtFimVigencia ?? '']}
                                                        onChange={([startDate, endDate]) => {
                                                            formik.setFieldValue('dtInicioVigencia', startDate);
                                                            formik.setFieldValue('dtFimVigencia', endDate);
                                                        }}
                                                        renderInput={(startProps, endProps) => (
                                                            <React.Fragment>
                                                                <CissTextField
                                                                    {...startProps}
                                                                    autoComplete="off"
                                                                    error={formik.errors.dtInicioVigencia}
                                                                    name="dtInicioVigencia"
                                                                />
                                                                <Box sx={{ mx: 2 }}> até </Box>
                                                                <CissTextField
                                                                    {...endProps}
                                                                    autoComplete="off"
                                                                    error={formik.errors.dtFimVigencia}
                                                                    name="dtFimVigencia"
                                                                />
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Row>
                                        <Row>
                                            <Grid item flex={1}>
                                                <TipoPedidoAutoComplete
                                                    name="tipoPedido"
                                                    error={formik.errors.tipoPedido}
                                                    value={formik.values.tipoPedido}
                                                    onChange={(e, value) => formik.setFieldValue('tipoPedido', value)}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <TipoGradeAutoComplete
                                                    name="tipoGrade"
                                                    error={formik.errors.tipoGrade}
                                                    value={formik.values.tipoGrade}
                                                    onChange={(e, value) => formik.setFieldValue('tipoGrade', value)}
                                                />
                                            </Grid>
                                        </Row>
                                        <Row alignItems="center" sx={{ flexDirection: 'row' }}>
                                            <Grid item flex={1}>
                                                <CissNumberField
                                                    label="Valor Mínimo"
                                                    name="vlMinimo"
                                                    value={formik.values.vlMinimo}
                                                    error={formik.errors.vlMinimo}
                                                    onChange={(e) => formik.setFieldValue('vlMinimo', Number(e.target.value))}
                                                    inputType="decimal"
                                                    preventEmptyField={false}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <FormControlLabel
                                                    label="Envia e-mail pendente de grade"
                                                    name="fgEnviaEmailPendenteGrade"
                                                    control={<Checkbox />}
                                                    checked={formik.values.fgEnviaEmailPendenteGrade}
                                                    onChange={(e, checked) => formik.setFieldValue('fgEnviaEmailPendenteGrade', checked)}
                                                />
                                            </Grid>
                                        </Row>
                                        <Row>
                                            <Grid item flex={1}>
                                                <FormControlLabel
                                                    label={'Integrar pedidos automaticamente'}
                                                    control={<Checkbox />}
                                                    checked={formik.values.fgSincronizaTerceiros}
                                                    onChange={(e, checked) => formik.setFieldValue('fgSincronizaTerceiros', checked)}
                                                />
                                            </Grid>
                                        </Row>
                                        <Row>
                                            <Grid item flex={1}>
                                                <CissTextField
                                                    label="Observação"
                                                    name="dsObservacao"
                                                    value={formik.values.dsObservacao}
                                                    onChange={(e) => formik.setFieldValue('dsObservacao', e.target.value)}
                                                    multiline
                                                    error={formik.errors.dsObservacao}
                                                    minRows={5}
                                                />
                                            </Grid>
                                        </Row>
                                    </Block>
                                    <Block>
                                        <SubTitle label="Dados do Estoque" />
                                        <Row>
                                            <Grid item flex={1}>
                                                <DepositoAutoComplete
                                                    name="deposito"
                                                    error={formik.errors.deposito}
                                                    value={formik.values.deposito}
                                                    onChange={(e, value) => formik.setFieldValue('deposito', value)}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <CentroPedidoAutoComplete
                                                    name="centroPedido"
                                                    error={formik.errors.centroPedido}
                                                    value={formik.values.centroPedido}
                                                    onChange={(e, value) => formik.setFieldValue('centroPedido', value)}
                                                />
                                            </Grid>
                                        </Row>

                                        <Row>
                                            <Grid item flex={1}>
                                                <CentroDistribuicaoAutoComplete
                                                    name="centroDistribuicao"
                                                    error={formik.errors.centroDistribuicao}
                                                    value={formik.values.centroDistribuicao}
                                                    onChange={(e, value) => formik.setFieldValue('centroDistribuicao', value)}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <CentroCustoAutoComplete
                                                    name="centroCusto"
                                                    error={formik.errors.centroCusto}
                                                    value={formik.values.centroCusto}
                                                    onChange={(e, value) => formik.setFieldValue('centroCusto', value)}
                                                />
                                            </Grid>
                                        </Row>
                                    </Block>
                                    <Block>
                                        <SubTitle label="Tabela de Preço" />
                                        <Row>
                                            <Grid item flex={1}>
                                                <TabelaPrecoAutoComplete
                                                    label=""
                                                    name="tabelaPreco"
                                                    error={formik.errors.tabelaPreco}
                                                    value={formik.values.tabelaPreco}
                                                    onChange={(e, value) => formik.setFieldValue('tabelaPreco', value)}
                                                />
                                            </Grid>
                                        </Row>
                                    </Block>
                                    <Block>
                                        <SubTitle label="Dados de Transação" />
                                        <Row>
                                            <Grid item flex={1}>
                                                <GeraNotaDebitoAutoComplete
                                                    name="pedidoTipoNotaDebito"
                                                    error={formik.errors.pedidoTipoNotaDebito}
                                                    value={formik.values.pedidoTipoNotaDebito}
                                                    onChange={(e, value) => formik.setFieldValue('pedidoTipoNotaDebito', value)}
                                                />
                                            </Grid>
                                            <Grid item flex={1}>
                                                <CissNumberField
                                                    label="Coeficiente ND"
                                                    name="peRoyalties"
                                                    maxValue={100}
                                                    inputType="decimal"
                                                    value={formik.values.peRoyalties}
                                                    error={formik.errors.peRoyalties}
                                                    onChange={(e) => formik.setFieldValue('peRoyalties', Number(e.target.value))}
                                                />
                                            </Grid>
                                        </Row>
                                        <FieldArray
                                            name="configPedidoOperacoes"
                                            render={() => {
                                                const configPedidoOperacoes = formik.values.configPedidoOperacoes;

                                                return (
                                                    <React.Fragment>
                                                        <SubTitle label="Tipo de Lojas" />

                                                        {configPedidoOperacoes && configPedidoOperacoes.length > 0
                                                            ? configPedidoOperacoes.map((configPedidoOperacao, index) => (
                                                                  <Row key={index}>
                                                                      <Grid item flex={1}>
                                                                          <TipoOperacaoAutoComplete
                                                                              label={`Operação de Loja ${configPedidoOperacao.tipoLoja.dsTipoLoja}`}
                                                                              name={`configPedidoOperacoes[${index}].operacao`}
                                                                              value={formik.values.configPedidoOperacoes[index].operacao}
                                                                              error={getIn(
                                                                                  formik.errors,
                                                                                  `configPedidoOperacoes.[${index}].operacao`,
                                                                              )}
                                                                              onChange={(e, value) => {
                                                                                  const data = {
                                                                                      tipoLoja: configPedidoOperacao.tipoLoja,
                                                                                      operacao: value,
                                                                                      idConfigPedidoOperacao: id
                                                                                          ? configPedidoOperacao.idConfigPedidoOperacao
                                                                                          : null,
                                                                                      idConfigPedido: Number(id),
                                                                                  };

                                                                                  formik.setFieldValue(`configPedidoOperacoes[${index}]`, data);
                                                                              }}
                                                                          />
                                                                      </Grid>
                                                                  </Row>
                                                              ))
                                                            : null}
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    </Block>
                                    <Box
                                        sx={{
                                            position: 'sticky',
                                            bottom: 0,
                                            zIndex: 3,
                                            display: 'flex',
                                            p: 2,
                                            mx: -2,
                                            backgroundColor: (theme) => theme.palette.grey[100],
                                        }}
                                    >
                                        <Box flex={1} />
                                        <LoadingButton
                                            variant="contained"
                                            loading={mutateLoading}
                                            disabled={mutateLoading}
                                            type="submit"
                                            size="large"
                                        >
                                            Salvar
                                        </LoadingButton>
                                    </Box>
                                </Box>

                                <ValueChangeListener />
                            </React.Fragment>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
}
