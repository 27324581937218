import { useQueryClient } from '@tanstack/react-query';

import { usePagamentoContext } from '../contexts/PagamentoContext';
import { CartaoPagamento } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';

export function useMutateCartaoPagamento() {
    const { setOpenCartaoCreditoModal } = usePagamentoContext();
    const { mutate, isLoading: isLoadingCartoesPagamento } = useFormMutation();
    const queryClient = useQueryClient();

    /**
     * Realiza o save do cartão de crédito.
     * Caso de sucesso, recarrega os dados dos cartões de crédito e fecha o modal.
     */
    const mutateCartaoPagamento = (response: CartaoPagamento): void => {
        mutate(
            {
                formData: response,
                url: '/gestao/cartaopagamento',
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['queryCartoesPagamento']);

                    setOpenCartaoCreditoModal(false);
                },
            },
        );
    };

    return { mutateCartaoPagamento, isLoadingCartoesPagamento };
}
