import React, { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissPrimaryKeyField, CissTextField } from 'components/fields/';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { GrupoEconomicoModelProps, initialGrupoEconomicoFormValues } from 'pages/grupo-economico/types';

export function GrupoEconomicoFormComp(): JSX.Element {
    const { content, setContent, setLoading } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const url = '/gestao/grupoeconomico';

    const { mutate, isLoading } = useFormMutation();

    const handleSubmit = (formData: GrupoEconomicoModelProps, formik: FormikHelpers<GrupoEconomicoModelProps>): void => {
        mutate(
            {
                url,
                formik,
                formData,
                idField: 'idGrupoEconomico',
                preventRedirect: !hasPermission(['GRUPO_ECONOMICO_ALTERAR']),
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data && hasPermission(['GRUPO_ECONOMICO_ALTERAR'])) {
                        setContent(data);
                    } else {
                        navigate('/grupo-economico');
                    }
                },
            },
        );
    };

    useEffect(() => {
        setLoading(isLoading);
    }, [content, isLoading, setLoading]);

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsGrupoEconomico: Yup.string().required().min(3),
            }),
        [],
    );

    return (
        <FormPage
            title="Grupo Econômico"
            values={initialGrupoEconomicoFormValues}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            fgAtivo={hasPermission(['GRUPO_ECONOMICO_MODERAR'])}
        >
            {(formik: FormikProps<GrupoEconomicoModelProps>) => (
                <Block>
                    <Row>
                        <Grid item>
                            <CissPrimaryKeyField value={formik.values.idGrupoEconomico} name="idGrupoEconomico" />
                        </Grid>

                        <Grid item flex={1}>
                            <CissTextField
                                label="Nome do Grupo Econômico"
                                name="dsGrupoEconomico"
                                value={formik.values.dsGrupoEconomico}
                                onChange={(e) => formik.setFieldValue('dsGrupoEconomico', e.target.value)}
                                error={formik.errors.dsGrupoEconomico}
                            />
                        </Grid>

                        <Grid item flex={1}>
                            <CissTextField
                                label="Código Grupo Econômico"
                                name="cdGrupoEconomico"
                                value={formik.values.cdGrupoEconomico}
                                onChange={(e) => formik.setFieldValue('cdGrupoEconomico', e.target.value)}
                                error={formik.errors.cdGrupoEconomico}
                            />
                        </Grid>
                    </Row>
                </Block>
            )}
        </FormPage>
    );
}
