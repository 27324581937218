import { PedidoRealizadoModelProps } from '../../types';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { ListagemProvider } from 'context/ListagemContext';
import { PedidoRealizadoProdutosGrid } from 'pages/compra/pedido-realizado/components';
import { useColumnsPedido, useGetPedidos } from 'pages/compra/pedido-realizado/hooks';
import { brlDate } from 'util/format';

/**
 * Retorna os dados formatados para a grid.
 */
export const getRows = (data: PedidoRealizadoModelProps[] | undefined) => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedido,
            idPedido: item.idPedido,
            dhInclusao: new Date(item.dhInclusao).toLocaleDateString('pt-BR'),
            cdPedido: item.cdPedido ?? null,
            cdPedidoExterno: item.cdPedidoExterno,
            nrPedido: item.nrPedido ?? null,
            tipoPedido: item.tipoPedido,
            tipoGrade: item.configPedido.tipoGrade,
            configPedidoStatus: item.configPedido.configPedidoStatus,
            dhConfirmacaoPedido: item.dhConfirmacaoPedido ? new Date(item.dhConfirmacaoPedido).toLocaleDateString('pt-BR') : null,
            pedidoStatus: item.pedidoStatus,
            vlTotal: item.vlTotal || 0,
            vlFrete: item.vlFrete || 0,
            vlTotalBruto: item.vlTotalBruto || 0,
            fgAtivo: item.fgAtivo,
            dsValidacao: item.dsValidacao,
            dtEntrega: item.grade.dtEntrega ? brlDate(`${item.grade.dtEntrega}T00:00:00`) : null,
            dtColeta: item.grade.dtColeta ? brlDate(`${item.grade.dtColeta}T00:00:00`) : null,
            dtLimitePedido: item.grade.dtLimitePedido ? brlDate(`${item.grade.dtLimitePedido}T00:00:00`) : null,
            tipoEntrega: item.tipoEntrega,
            usuarioPedido: item.usuarioPedido,
            dsUrlTrakingPedido: item.dsUrlTrakingPedido,
            pedidoPagamentos: item.pedidoPagamentos,
            configPedido: item.configPedido,
            loja: item.loja,
        }));
    }

    return [];
};

export function PedidoRealizadoGrid() {
    const { fetchStatus, data, refetch } = useGetPedidos();
    const { columns } = useColumnsPedido();

    return (
        <Block>
            <PanelGrid
                isTreeData
                reload={refetch}
                columns={columns}
                rowThreshold={0}
                summaryPrefix={false}
                summary={['vlTotal', 'vlTotalBruto']}
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                loading={fetchStatus === 'fetching'}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={({ row }) => (
                    <ListagemProvider>
                        <PedidoRealizadoProdutosGrid row={row} />
                    </ListagemProvider>
                )}
            />
        </Block>
    );
}
