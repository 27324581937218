import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { SuccessMessageProps, getSuccessMessage } from 'util/success';

export function useDeleteCartaoPagamento() {
    const { showError } = useShowError();
    const api = useServiceApiContext();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /**
     * Exclui cartão de crédito e atualiza a lista de cartões.
     */
    const handleDelete = (idCartaoCredito: number) => {
        setIsLoading(true);

        api.delete(`/gestao/cartaopagamento/${idCartaoCredito}`)
            .then((result: SuccessMessageProps) => {
                const message = getSuccessMessage(result);

                enqueueSnackbar(message, { variant: 'success' });

                queryClient.invalidateQueries(['queryCartoesPagamento']);
            })
            .catch((error) => showError(error, 'Não foi possível remover o cartão. Tente novamente'))
            .finally(() => setIsLoading(false));
    };

    return { handleDelete, isLoading };
}
