import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';
import { ConfigPedidoProduto } from 'pages/compra/configuracao-pedido/types';

export function useQueryProdutos() {
    const { setConfigPedidoProdutos, loja, setInitialConfigPedidoProdutos } = useConfigPedidoProdutosContext();
    const requestListagem = useRequestListagem();
    const { id } = useParams();

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedidoproduto/configpedido/${id}`,
        extraParams: [{ idLoja: loja?.idLoja }],
    };

    return useQuery<ConfigPedidoProduto[]>(['queryProdutos', requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        onSuccess: (data) => {
            setConfigPedidoProdutos(data);

            setInitialConfigPedidoProdutos(JSON.stringify(data));
        },
        enabled: Boolean(id),
    });
}
