import { useState } from 'react';

import { Chip, ChipProps, Tooltip } from '@mui/material';

import { ErroPedidoModal } from 'components/grid';

const statusPedido = {
    PENDENTE_GRADE: 1,
    PENDENTE_SINCRONIZACAO: 2,
    PENDENTE_APROVACAO: 3,
    RASCUNHO: 4,
    TOTALIZADOR: 5,
    AGUARDANDO_FATURAMENTO: 6,
    CANCELADO_SAP: 7,
    CANCELADO_EXTERNO: 8,
    FATURADO_PARCIALMENTE: 9,
    FATURADO_TOTAL: 10,
    PEDIDO_MANUAL: 11,
    EM_SINCRONIZACAO: 12,
    ERRO_SINCRONIZACAO: 13,
    AGUARDANDO_CONFIRMACAO_PAGAMENTO: 14,
    FALHA_PAGAMENTO: 15,
    CANCELADO: 16,
} as const;

export type StatusPedido = keyof typeof statusPedido;

const statusPedidoColor: Record<StatusPedido, ChipProps['color']> = {
    PENDENTE_GRADE: 'warning',
    PENDENTE_SINCRONIZACAO: 'info',
    PENDENTE_APROVACAO: 'info',
    RASCUNHO: 'info',
    TOTALIZADOR: 'info',
    AGUARDANDO_FATURAMENTO: 'success',
    CANCELADO_SAP: 'error',
    CANCELADO_EXTERNO: 'error',
    FATURADO_PARCIALMENTE: 'warning',
    FATURADO_TOTAL: 'success',
    PEDIDO_MANUAL: 'info',
    EM_SINCRONIZACAO: 'info',
    ERRO_SINCRONIZACAO: 'error',
    AGUARDANDO_CONFIRMACAO_PAGAMENTO: 'warning',
    FALHA_PAGAMENTO: 'error',
    CANCELADO: 'error',
} as const;

type StatusPedidoColumn = {
    dsStatus: string;
    dsValidacao: string;
    pedidoStatusEnum: StatusPedido;
};

/**
 * Retorna a variante do chip de acordo com o status do pedido.
 */
const getVariantChip = (pedidoStatusEnum: StatusPedido, hasError: boolean): ChipProps['variant'] => {
    const statusPedidoFilled: StatusPedido[] = ['FATURADO_PARCIALMENTE', 'FATURADO_TOTAL', 'FALHA_PAGAMENTO'];

    if (hasError && statusPedidoFilled.includes(pedidoStatusEnum)) {
        return 'filled';
    }

    return 'outlined';
};

export function StatusPedidoColumn({ pedidoStatusEnum, dsStatus, dsValidacao }: StatusPedidoColumn) {
    const [openErroModal, setOpenErroModal] = useState<boolean>(false);
    const hasError = Boolean(dsValidacao);
    const tooltipChip: string = hasError ? 'Visualizar erro' : '';
    const colorChip: ChipProps['color'] = hasError ? 'error' : statusPedidoColor[pedidoStatusEnum];

    return (
        <>
            <ErroPedidoModal setOpen={setOpenErroModal} open={openErroModal} erro={dsValidacao} />

            <Tooltip title={tooltipChip}>
                <Chip
                    size="small"
                    label={dsStatus}
                    color={colorChip}
                    variant={getVariantChip(pedidoStatusEnum, hasError)}
                    onClick={() => setOpenErroModal(hasError)}
                    sx={{
                        width: '100%',
                        fontSize: '12px',
                        lineHeight: '1rem',
                        fontWeight: 500,
                        pointerEvents: hasError ? 'auto' : 'none',
                    }}
                />
            </Tooltip>
        </>
    );
}
