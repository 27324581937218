import { Box } from '@mui/material';

import { Block } from 'components/page';
import { PagamentoCartaoCredito, PagamentoFolhaPagamento, PagamentoTitle, PagamentoPix } from 'pages/compra/pagamento/components';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoMethod() {
    const { condicoesPagamentoPix, condicoesPagamentoCartaoCredito, condicoesPagamentoFolhaPagamento } = usePagamentoContext();
    const hasCartaoCredito = Boolean(condicoesPagamentoCartaoCredito.length);
    const hasPix = Boolean(condicoesPagamentoPix.length);
    const hasFolhaPagamento = Boolean(condicoesPagamentoFolhaPagamento.length);

    return (
        <Block sx={{ p: 0, mb: 0.5 }}>
            <PagamentoTitle title="Meios de Pagamento" />

            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {hasCartaoCredito && <PagamentoCartaoCredito />}

                {hasPix && <PagamentoPix />}

                {hasFolhaPagamento && <PagamentoFolhaPagamento />}
            </Box>
        </Block>
    );
}
