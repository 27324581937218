import { Dispatch, SetStateAction } from 'react';

import { Alert } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { PedigoLog } from 'pages/compra/carrinho/types';

interface CarrinhoFalhasModal {
    open: PedigoLog[] | boolean;
    setOpen: Dispatch<SetStateAction<PedigoLog[] | boolean>>;
}

/**
 * Recebe um array de PedigoLog e retorna um array de strings
 */
const formatMessages = (messages: PedigoLog[]): string[] => messages.flatMap((item) => item.dsMensagem.split('\n'));

export function CarrinhoFalhasModal({ open, setOpen }: CarrinhoFalhasModal) {
    const messages = Array.isArray(open) ? formatMessages(open) : [];

    return (
        <ContentModal
            closeButton
            open={Boolean(open)}
            setOpen={setOpen}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 1,
                width: { xs: '90dvw', sm: 'auto' },
            }}
        >
            <SubTitle label="Falhas" sx={{ mb: 0 }} />

            {messages.map((item) => (
                <Alert key={Math.random()} variant="outlined" severity="error">
                    {item}
                </Alert>
            ))}
        </ContentModal>
    );
}
