import { useEffect } from 'react';

export function useBeforeUnloadWindow({ prevent }: { prevent: boolean }) {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (prevent) {
                event.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [prevent]);
}
