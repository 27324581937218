import { GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { ListagemProvider } from 'context/ListagemContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { CarrinhoProdutoGrid } from 'pages/compra/carrinho/components';
import { useCarrinhoContext } from 'pages/compra/carrinho/contexts';
import { useColumnsGrid, useGetCarrinho, useLojasFilter } from 'pages/compra/carrinho/hooks';
import { PedidoProps } from 'pages/compra/carrinho/types';
import { brlDate } from 'util/format';

/**
 * Retorna as informações para as linhas da grid formatadas de acordo com a request do carrinho.
 */
const getRows = (data: PedidoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedido,
            nrPedido: item.nrPedido,
            dhInclusao: item.dhInclusao ? brlDate(item.dhInclusao) : null,
            dtLimitePedido: item.grade.dtLimitePedido ? brlDate(`${item.grade.dtLimitePedido}T00:00:00`) : null,
            dtEntrega: item.grade.dtEntrega ? brlDate(`${item.grade.dtEntrega}T00:00:00`) : null,
            vlTotal: item.vlTotal,
            vlFrete: item.vlFrete ? item.vlFrete : 0,
            vlTotalFrete: item.vlFrete ? item.vlTotal + item.vlFrete : item.vlTotal,
            pedidoTotalizadorValidacoes: item.pedidoLog,
            idStatusRascunho: Array.isArray(item.pedidoLog) && item.pedidoLog.length ? 3 : 1,
            tipoEntrega: item.tipoEntrega,
            usuarioPedido: item.usuarioPedido,
            loja: item.loja,
            configPedido: item.configPedido,
            fgTelaPagamento: item.fgTelaPagamento,
        }));
    }

    return [];
};

export function CarrinhoGrid() {
    const { disabledActions } = useCarrinhoContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const { isFetching, data, refetch } = useGetCarrinho();
    const lojas = useLojasFilter();
    const isLojasEqualOne = lojas.length === 1;
    const { columns } = useColumnsGrid();

    return (
        <Block>
            <PanelGrid
                isTreeData
                reload={refetch}
                columns={columns}
                rows={getRows(data?.data)}
                rowsCount={data?.total || 0}
                loading={isFetching || disabledActions}
                getDetailPanelHeight={() => 'auto'}
                summary={['vlTotal', 'vlTotalFrete']}
                visibleRows={isLojasEqualOne || isTipoPedidoFuncionario ? 'all' : undefined}
                getDetailPanelContent={({ row }) => (
                    <ListagemProvider>
                        <CarrinhoProdutoGrid row={row} />
                    </ListagemProvider>
                )}
            />
        </Block>
    );
}
