import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';


import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { MotivoDevolucaoListFilter } from 'pages/financeiro/nota-devolucao/motivo/components';
import { MotivoDevolucaoProps, RequestMotivoDevolucaoListProps } from 'pages/financeiro/nota-devolucao/motivo/types';

const generateMovimentacaoFiscalList = (data: MotivoDevolucaoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idMotivoDevolucao,
            nrCodigo: item.cdMotivo,
            dsTitulo: item.dsMotivoDevolucao,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function MotivoDevolucaoListComp(): JSX.Element {
    const { filter, autoLoad, page, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const url = '/gestao/motivodevolucao';

    const requestOptions: RequestOptionsType = {
        url,
        columns: 'idMotivoDevolucao,dsMotivoDevolucao,fgAtivo,cdMotivo',
        sort: [{ property: 'cdMotivo', direction: 'DESC' }],
        filter,
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestMotivoDevolucaoListProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = [
        { field: 'nrCodigo', headerName: 'Código', type: 'number', width: 110, minWidth: 100 },
        { field: 'dsTitulo', headerName: 'Título', flex: 1, minWidth: 70 },
        { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 60 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 80,
            renderCell: ({ row }) => <EditRemoveBtn id={row.id} refresh={refetch} removeUrl={`${url}${row.id}`} sx={{ ml: 'auto', p: 1.5 }} />,
        },
    ];

    const rows = generateMovimentacaoFiscalList(data?.data);

    return (
        <OneColumn title="Motivo de Devolução" createButton goBackButton>
            <MotivoDevolucaoListFilter />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
