import { useMemo } from 'react';

import { Alert } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { GatewayPagamentoAutoComplete } from 'components/autocompletes';
import { GatewayPagamento } from 'components/autocompletes/GatewayPagamentoAutoComplete';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestListagem } from 'hooks/useRequestListagem';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { useActionsCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

export function ConfigPedidoCondicoesPagamentoGateway() {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { formatCondicoesPagamentoFromGateway } = useActionsCondicoesPagamento();
    const queryClient = useQueryClient();
    const queryData = queryClient.getQueriesData(['queryGatewaysPagamento']);

    /**
     * Retorna o gateway de pagamento "Portal Lojinha" do autocomplete de gateways de pagamento.
     */
    const gatewayPortalLojinha = useMemo(() => {
        if (queryData.length) {
            const data = queryData[0][1] as RequestListagem<GatewayPagamento[]>;

            return data.data.find((gateway) => gateway.gatewayPagamentoEnum === 'PORTAL_LOJINHA');
        }

        return null;
    }, [queryData]);

    return (
        <Block>
            <SubTitle label="Gateways de Pagamento" />

            <GatewayPagamentoAutoComplete
                multiple
                label=""
                size="small"
                queryKey="queryGatewaysPagamento"
                value={configPedidoCondicoesPagamento.gatewayPagamento}
                onChange={(e, value: GatewayPagamento[]) => {
                    const valueHasPortalLojinha = value.some((item) => item.gatewayPagamentoEnum === 'PORTAL_LOJINHA');

                    if (value.length < 2 || (value.length === 2 && valueHasPortalLojinha)) {
                        formatCondicoesPagamentoFromGateway(value);
                    }
                }}
            />

            {gatewayPortalLojinha && (
                <Alert sx={{ py: 0, px: 1, mt: 2 }} severity="info">
                    Somente o gateway {gatewayPortalLojinha.dsGatewayPagamento} pode ser selecionado junto de outro gateway.
                </Alert>
            )}
        </Block>
    );
}
