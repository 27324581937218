import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { GatewayPagamento } from 'components/autocompletes/GatewayPagamentoAutoComplete';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { CartaoPagamento, Pagamento, SelecaoMeioPagamento } from 'pages/compra/pagamento/types';
import { brlPrice } from 'util/format';

export type PagamentoContext = {
    idPedido: number;
    setIdPedido: Dispatch<SetStateAction<number>>;

    idsPedido: number[];
    setIdsPedido: Dispatch<SetStateAction<number[]>>;

    pagamento: Pagamento | null;
    setPagamento: Dispatch<SetStateAction<Pagamento | null>>;

    pagamentoRequestError: string;
    setPagamentoRequestError: Dispatch<SetStateAction<string>>;

    selecaoMeioPagamento: SelecaoMeioPagamento | null;
    setSelecaoMeioPagamento: Dispatch<SetStateAction<SelecaoMeioPagamento | null>>;

    cartoesCredito: CartaoPagamento[];
    setCartoesCredito: Dispatch<SetStateAction<CartaoPagamento[]>>;

    openCartaoCreditoModal: boolean;
    setOpenCartaoCreditoModal: Dispatch<SetStateAction<boolean>>;

    openPagamentoPixModal: boolean;
    setOpenPagamentoPixModal: Dispatch<SetStateAction<boolean>>;

    isPending: boolean;
    setIsPending: Dispatch<SetStateAction<boolean>>;

    isFinishing: boolean;
    setIsFinishing: Dispatch<SetStateAction<boolean>>;

    gatewayPagamento: GatewayPagamento | null;
    condicoesPagamentoPix: CondicaoPagamento[];
    condicoesPagamentoCartaoCredito: CondicaoPagamento[];
    condicoesPagamentoFolhaPagamento: CondicaoPagamento[];

    resetCartoesCredito(cartoesCredito: CartaoPagamento[]): void;
};

interface PagamentoProvider {
    children?: ReactNode;
}

const PagamentoContext = createContext<PagamentoContext>({} as PagamentoContext);

export function PagamentoProvider({ children }: PagamentoProvider) {
    const [pagamento, setPagamento] = useState<Pagamento | null>(null);
    const [pagamentoRequestError, setPagamentoRequestError] = useState<string>('');
    const [cartoesCredito, setCartoesCredito] = useState<CartaoPagamento[]>([]);
    const [selecaoMeioPagamento, setSelecaoMeioPagamento] = useState<SelecaoMeioPagamento | null>(null);
    const [openCartaoCreditoModal, setOpenCartaoCreditoModal] = useState<boolean>(false);
    const [openPagamentoPixModal, setOpenPagamentoPixModal] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isFinishing, setIsFinishing] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    /**
     * Retorna os ids de pedidos da URL.
     */
    const idsPedidoParams = useMemo(() => {
        const idsPedido = searchParams.get('idsPedido');

        if (idsPedido) {
            return idsPedido.split(',').map((id) => Number(id));
        }

        return [];
    }, []);

    /**
     * Retorna o valor inicial do estado de idPedido.
     */
    const initialIdPedido = useMemo(() => idsPedidoParams[0] || 0, []);

    const [idPedido, setIdPedido] = useState<number>(initialIdPedido);
    const [idsPedido, setIdsPedido] = useState<number[]>(idsPedidoParams);

    /**
     * Retorna as condições de cartão de crédito com o valor de acordo com a parcela.
     */
    const getCondicoesCartaoCreditoFormatted = useCallback(
        (condicoes: CondicaoPagamento[]) =>
            condicoes.map((item) => {
                const { nrParcela, dsCondicaoPagamento } = item;
                const value = (pagamento?.vlTotalBruto as number) / (nrParcela as number);

                return {
                    ...item,
                    dsCondicaoPagamento: `${dsCondicaoPagamento} - ${brlPrice(value)}`,
                };
            }),
        [pagamento],
    );

    /**
     * Retorna as condições de pagamento que permitem pagamento via cartão de crédito.
     */
    const condicoesPagamentoCartaoCredito = useMemo(() => {
        const condicoes = pagamento?.condicaoPagamentoList.filter((condicao) => condicao.meioPagamento?.meioPagamentoEnum === 'CARTAO_CREDITO') || [];
        const condicoesFormatted = getCondicoesCartaoCreditoFormatted(condicoes);

        return condicoesFormatted.sort((a, b) => (a.nrParcela as number) - (b.nrParcela as number));
    }, [pagamento]);

    /**
     * Retorna as condições de pagamento que permitem pagamento via PIX.
     */
    const condicoesPagamentoPix = useMemo(
        () => pagamento?.condicaoPagamentoList.filter((condicao) => condicao.meioPagamento?.meioPagamentoEnum === 'PIX') || [],
        [pagamento],
    );

    /**
     * Retorna as condições de pagamento que permitem desconto em folha.
     */
    const condicoesPagamentoFolhaPagamento = useMemo(
        () => pagamento?.condicaoPagamentoList.filter((condicao) => condicao.meioPagamento?.meioPagamentoEnum === 'DESCONTO_FOLHA') || [],
        [pagamento],
    );

    /**
     * Retorna o gateway de pagamento do pedido utilizado pelos cartões de crédito.
     */
    const gatewayPagamento = useMemo(() => condicoesPagamentoCartaoCredito[0]?.gatewayPagamento || null, [condicoesPagamentoCartaoCredito]);

    /**
     * Atualiza a condição de pagamento dos cartões de crédito quando troca de pedido,
     * fazendo com que condição de pagamento selecionada seja de acordo com o pedido atual.
     */
    const resetCartoesCredito = useCallback(
        (cartoesCredito: CartaoPagamento[]) => {
            if (condicoesPagamentoCartaoCredito.length && cartoesCredito.length) {
                const condicaoPagamento = condicoesPagamentoCartaoCredito[0];
                const newCartoesCredito = cartoesCredito.map((cartao) => ({ ...cartao, condicaoPagamento }));

                setCartoesCredito(newCartoesCredito);
            }
        },
        [pagamento],
    );

    useEffect(() => resetCartoesCredito(cartoesCredito), [pagamento]);

    return (
        <PagamentoContext.Provider
            value={{
                idPedido,
                setIdPedido,
                idsPedido,
                setIdsPedido,
                pagamento,
                setPagamento,
                pagamentoRequestError,
                setPagamentoRequestError,
                gatewayPagamento,
                cartoesCredito,
                setCartoesCredito,
                isPending,
                setIsPending,
                isFinishing,
                setIsFinishing,
                selecaoMeioPagamento,
                setSelecaoMeioPagamento,
                openCartaoCreditoModal,
                setOpenCartaoCreditoModal,
                openPagamentoPixModal,
                setOpenPagamentoPixModal,
                condicoesPagamentoPix,
                condicoesPagamentoCartaoCredito,
                condicoesPagamentoFolhaPagamento,
                resetCartoesCredito,
            }}
        >
            {children}
        </PagamentoContext.Provider>
    );
}

export const usePagamentoContext = () => useContext(PagamentoContext);
