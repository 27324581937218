import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useDreListContext } from '../contexts/DreListContext';
import { RequestDreListProps } from '../types';

import { useDreFilterLojas } from './useDreFilterLojas';

import { useListagemContext } from 'context/ListagemContext';
import { useRequestListagem } from 'hooks/useRequestListagem';

export function useDreGetMercadoria(): UseQueryResult<RequestDreListProps> {
    const { setHasDre } = useDreListContext();
    const requestListagem = useRequestListagem();
    const { filter, autoLoad } = useListagemContext();
    const filterLojas = useDreFilterLojas();
    const isMultipleLojas = filterLojas?.value.length > 1;

    const requestOptions = {
        url: isMultipleLojas ? '/dre/dremercadologica/consulta' : '/dre/dremercadologica',
        columns: 'idDreMercadologica',
        nested: true,
        filter,
    };

    return useQuery<RequestDreListProps>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        cacheTime: 0,
        onSuccess: (data) => {
            setHasDre(Boolean(data.data.length));
        },
    });
}
