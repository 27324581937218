import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export type GatewayPagamento = {
    idGatewayPagamento: number;
    dsGatewayPagamento: string;
    gatewayPagamentoEnum: 'PAGARME' | 'PORTAL_LOJINHA';
    dsChavePublica?: string;
};

export function GatewayPagamentoAutoComplete({
    sx,
    name,
    value,
    error,
    size,
    queryKey,
    multiple = false,
    disabled = false,
    onChange = () => {},
    label = 'Gateway de pagamento',
}: CissAutoCompleteProps) {
    return (
        <CissAutoComplete
            objectId="idGatewayPagamento"
            optionStringTemplate="{dsGatewayPagamento}"
            selectedStringTemplate="{dsGatewayPagamento}"
            sx={sx}
            size={size}
            name={name}
            label={label}
            value={value}
            error={error}
            queryKey={queryKey}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            isOptionEqualToValue={(option: GatewayPagamento, value: GatewayPagamento) => option.idGatewayPagamento === value.idGatewayPagamento}
            requestOptions={{
                url: '/gestao/gatewaypagamento',
                sort: [{ property: 'idGatewayPagamento', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', operator: 'eq', value: true }],
                limit: 50,
                nested: true,
            }}
        />
    );
}
