import { useEffect, useState } from 'react';

import { Box, Checkbox } from '@mui/material';

import { SubTitle } from 'components/text';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { PagamentoMethodAutoComplete } from 'pages/compra/pagamento/components/templates/method/PagamentoMethodAutoComplete';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoFolhaPagamento() {
    const { selecaoMeioPagamento, setSelecaoMeioPagamento, condicoesPagamentoFolhaPagamento, isFinishing, isPending } = usePagamentoContext();
    const [condicaoPagamentoFolhaPagamento, setCondicaoPagamentoFolhaPagamento] = useState<CondicaoPagamento | null>(null);

    /**
     * Ao alterar as condições de pagamento, seta a primeira condição de pagamento como padrão.
     */
    useEffect(() => setCondicaoPagamentoFolhaPagamento(condicoesPagamentoFolhaPagamento[0]), [condicoesPagamentoFolhaPagamento]);

    return (
        <>
            <SubTitle label="Folha de Pagamento" sx={{ mb: 0 }} />

            <Box sx={{ display: 'flex', ml: -1 }}>
                <Checkbox
                    disabled={isFinishing || isPending}
                    checked={selecaoMeioPagamento?.meioPagamentoEnum === 'DESCONTO_FOLHA'}
                    onChange={() =>
                        setSelecaoMeioPagamento({
                            meioPagamentoEnum: 'DESCONTO_FOLHA',
                            condicaoPagamento: condicaoPagamentoFolhaPagamento as CondicaoPagamento,
                        })
                    }
                />

                <PagamentoMethodAutoComplete
                    value={condicaoPagamentoFolhaPagamento}
                    condicoesPagamento={condicoesPagamentoFolhaPagamento}
                    onChange={(value) => setCondicaoPagamentoFolhaPagamento(value as CondicaoPagamento)}
                />
            </Box>
        </>
    );
}
