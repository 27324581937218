import { SyntheticEvent } from 'react';

import { FuncionarioAutoComplete } from 'components/autocompletes';
import { Funcionario } from 'components/autocompletes/FuncionarioAutoComplete';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoFuncionarioAutoComplete() {
    const { formattedLoja } = useAplicacaoDataContext();
    const { setFilterLoja, filterFuncionario, setFilterCategoria, setFilterTipoEntrega, setFilterFuncionario, setFilterConfigPedido, setFilterMarca } =
        usePedidoFilterContext();

    /**
     * Ao trocar o funcionário, limpa os campos de entrega, configPedido, jornada, marca e atualiza o valor do funcionário.
     * Muda o valor do filtro de loja de acordo com a existência do funcionário.
     */
    const onChangeFuncionario = (e: SyntheticEvent<Element, Event>, value: Funcionario) => {
        setFilterTipoEntrega((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        setFilterMarca((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

        // Caso selecione um usuário, define o filtro de loja de acordo com a loja do usuário.
        // Caso remova o usuário, define o filtro de loja padrão.

        let valueLoja = formattedLoja;

        if (value) {
            valueLoja = {
                idLoja: value.loja.idLoja,
                cdLoja: value.loja.cdLoja,
                dsNomeFantasia: value.loja.dsNomeFantasia,
            };
        }

        setFilterLoja((oldState: FilterValuesProps) => ({
            ...oldState,
            value: valueLoja,
        }));

        setFilterFuncionario((oldState: FilterValuesProps) => ({
            ...oldState,
            value: value && {
                idUsuario: value.idUsuario,
                dsUsuario: value.dsUsuario,
                idTipoEntrega: value.idTipoEntrega,
            },
        }));
    };

    return <FuncionarioAutoComplete size="small" value={filterFuncionario.value} onChange={onChangeFuncionario} />;
}
