import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { DatePickerYear } from 'components/fields';
import { Filter } from 'components/page';
import { FilterValuesProps, useFilter } from 'hooks/useFilter';

export function DreGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterNrAno, setFilterNrAno] = useState<FilterValuesProps>({
        property: 'nrAno',
        operator: 'eq',
        value: new Date(),
        idProperty: 'year',
        required: 'Selecione o mês/ano para prosseguir.',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'in',
        value: [],
        idProperty: 'idLoja',
        required: 'Loja é obrigatória',
    });

    const filters = [
        { value: filterNrAno, setter: setFilterNrAno },
        { value: filterLoja, setter: setFilterLoja },
    ];

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
            <Grid item flex={1}>
                <DatePickerYear
                    label="Ano"
                    minDate={new Date('2010-01-01')}
                    maxDate={new Date()}
                    value={filterNrAno.value}
                    onChange={(value) => {
                        setFilterNrAno((oldState: FilterValuesProps) => ({ ...oldState, value }));
                    }}
                />
            </Grid>

            <Grid item flex={3}>
                <StoreAutoComplete
                    multiple={true}
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
