import { Box, Typography } from '@mui/material';

import { PedidoPagamento } from 'pages/compra/pagamento/types';

/**
 * Formata o tempo restante para finalizar o pagamento.
 */
const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;

    const partes: string[] = [];

    if (mins > 0) {
        partes.push(`${mins} ${mins === 1 ? 'minuto' : 'minutos'}`);
    }

    if (secs > 0 || mins === 0) {
        partes.push(`${secs} ${secs === 1 ? 'segundo' : 'segundos'}`);
    }

    return partes.join(' e ');
};

export function PagamentoPixPending({ pagamento, paymentTimeLeft }: { pagamento: PedidoPagamento; paymentTimeLeft: number }) {
    return (
        <Box
            sx={{
                'display': 'flex',
                'mb': 'auto',
                'flexDirection': 'column',
                'gap': 1,
                '.MuiTypography-root': {
                    textAlign: 'justify',
                },
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    minWidth: { xs: 200, md: 250 },
                    minHeight: { xs: 200, md: 250 },
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    border: '1px solid common.black',
                    backgroundImage: `url("${pagamento.qrCodeUrl}")`,
                }}
            />

            <Typography>Escaneie o código QR com a câmera do seu celular ou copie e cole o código no aplicativo do seu banco.</Typography>

            <Typography>
                Caso o pagamento não seja realizado em{' '}
                <Typography sx={{ display: 'inline', color: 'success.main', fontWeight: 'bold' }}>{formatTime(paymentTimeLeft)}</Typography>, será
                necessário acessar a tela de <b>Consulta de Pedidos</b> e acessar a opção <b>Alterar pagamento</b> dentro do menu no pedido para poder finalizar
                novamente.
            </Typography>
        </Box>
    );
}
