import React, { useRef, useState } from 'react';

import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { LoadingButton } from '@mui/lab';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import useBlobFile, { RequestToFileResponseProps } from 'hooks/useBlobFile';
import { useEventStream } from 'hooks/useEventStream';
import { useRequestListagem } from 'hooks/useRequestListagem';

type ExportReportBtn = {
    dsChave: string;
};

type ReportMessage = {
    chave: string;
    nomeArquivo: string;
    status: 'CRIADO' | 'EXECUTANDO' | 'SUCESSO' | 'ERRO';
    numero: number;
};

type ReportFilter = {
    nomeColuna: string;
    valores?: number[] | string[];
};

type ReportFile = {
    filtros: ReportFilter[];
};

// Formata o nome da coluna para ficar no padrao pascal case
const formatColumnName = (column: string): string => {
    let newColumn = column;

    for (let i = 1; i < newColumn.length; i++) {
        if (newColumn[i - 1] === '_') {
            newColumn = newColumn.substring(0, i) + newColumn.charAt(i).toUpperCase() + newColumn.slice(i + 1);
        }
    }

    return newColumn.replaceAll('_', '');
};

export function ExportReportBtn({ dsChave }: ExportReportBtn): JSX.Element {
    const { content } = useFormContext();
    const { filter } = useListagemContext();
    const requestListagem = useRequestListagem();
    const { createEventStream } = useEventStream();
    const idRelatorioRef = useRef<string>('');
    const idRelatorioDownloadRef = useRef<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState<boolean>(false);
    const { requestToFile } = useBlobFile();

    // Formata o body deixando apenas os filtros que estão sendo usados e inserindo seus valores.
    const formatBody = (body: ReportFile): ReportFile => {
        if (body.filtros?.length) {
            body.filtros = body.filtros
                .map((item) => {
                    const { nomeColuna } = item;
                    const nomeColunaFormatted = formatColumnName(nomeColuna);
                    const findFilter = filter.find((item) => item.property === nomeColunaFormatted);

                    if (findFilter) {
                        item.valores = findFilter.value;

                        return item;
                    }
                })
                .filter((item) => item) as ReportFilter[];
        }

        return body;
    };

    // Faz a request para baixar o relatório
    const { isFetching: isFetchingDownloadingReport, refetch: refetchDownalodReport } = useQuery<RequestToFileResponseProps>(
        ['getDownloadReport'],
        () =>
            requestListagem({ url: `/report/relatorio/download/${idRelatorioDownloadRef.current}`, requestConfig: { responseType: 'arraybuffer' } }),
        {
            enabled: false,
            onSuccess: (data) => {
                requestToFile(data, true);

                enqueueSnackbar('Download do relatório realizado com sucesso', { variant: 'success' });
            },
            onError: () => enqueueSnackbar('Falha ao realizar download do relatório', { variant: 'error' }),
        },
    );

    // Função chamada ao receber mensagem do createEventStream.
    // Decodifica a mensagem e chama a request para fazer o download do relatório.
    const onMessage = (message: EventSourceMessage): void => {
        try {
            const parsedMessage: ReportMessage = JSON.parse(message.data);

            if (parsedMessage.status === 'SUCESSO') {
                idRelatorioDownloadRef.current = parsedMessage.chave;

                refetchDownalodReport();
            }

            if (parsedMessage.status === 'ERRO') {
                enqueueSnackbar('Falha ao gerar relatório', { variant: 'error' });
            }
        } catch (e) {
            enqueueSnackbar('Falha ao decodificar a mensagem de retorno do relatório', { variant: 'warning' });
        }
    };

    // Com o id do relatorio, recebe as informações a serem manipuladas e enviadas para fazer o download do relatório
    const { isFetching: isFetchingGetReport, refetch: refetchGetRelatorio } = useQuery<ReportFile>(
        ['getReport'],
        () => requestListagem({ url: `report/relatorio/${idRelatorioRef.current}` }).then((res) => res.data),
        {
            enabled: false,
            onSuccess: (data) => {
                setIsLoadingRelatorio(true);

                createEventStream({
                    url: '/report/relatorio/gerar/excel/assinc',
                    body: formatBody(data),
                    events: {
                        onmessage: onMessage,
                        onclose: () => setIsLoadingRelatorio(false),
                        onerror: () => {
                            enqueueSnackbar('Falha ao gerar relatório', { variant: 'error' });
                        },
                    },
                });
            },
        },
    );

    // Realiza a request para buscar o id do relatorio a ser gerado no report
    const { isFetching: isFetchingGetIdRelatorio, refetch: refetchGetIdRelatorio } = useQuery<string>(
        ['getIdRelatorio'],
        () => requestListagem({ url: '/config/configgeral/chave', extraParams: [{ dsChave }] }).then((res) => res.data),
        {
            enabled: false,
            onSuccess: (idRelatorio) => {
                idRelatorioRef.current = idRelatorio;

                refetchGetRelatorio();
            },
        },
    );

    return (
        <LoadingButton
            loading={isFetchingGetIdRelatorio || isFetchingGetReport || isFetchingDownloadingReport || isLoadingRelatorio}
            variant="outlined"
            onClick={() => refetchGetIdRelatorio()}
            disabled={!content}
            sx={{ color: 'green', borderColor: 'green', flex: { xs: 'auto', md: 'none' } }}
            startIcon={<RiFileExcel2Fill />}
        >
            Relatório em .xlsx
        </LoadingButton>
    );
}
