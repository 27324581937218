import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfirmModal } from 'components/modal';
import { OneColumn } from 'components/page';
import { initialStep, useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import {
    ConfiguracaoPedidoCompraMenuAside,
    ConfiguracaoPedidoCompraRegrasValidacao,
    ConfiguracaoPedidoCompraDadosBasicos,
    ConfigPedidoProdutos,
    ConfiguracaoPedidoCompraQuebrasDinamicas,
    ConfiguracaoPedidoCompraCondicoesVisualizacao,
    ConfiguracaoPedidoCompraImportarEstoque,
    ConfiguracaoPedidoCompraPedidosSazonais,
    ConfigPedidoCondicoesPagamento,
} from 'pages/compra/configuracao-pedido/components/index';
import { ConfigPedidoCondicoesPagamentoProvider, ConfigPedidoProdutosProvider } from 'pages/compra/configuracao-pedido/contexts';
import { RequestStepsMenuProps } from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraFormComp(): JSX.Element {
    const { activeStep, setActiveStep, setListStep, isActiveStepDirty, isStepDirty, setStepSaved, isStepSaved, setStepDirty } =
        useConfiguracaoPedidoCompraMenuAsideContext();
    const { setContent } = useFormContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();

    const [activeComponent, setActiveComponent] = useState<number>(initialStep);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [isLeaving, setIsLeaving] = useState<boolean>(false);

    // Ao trocar de step, valida se ela é diferente do componente exibido atualmente, abrindo o modal de confirmação de troca de step.
    useEffect(() => {
        if (activeStep && activeStep !== activeComponent) {
            if (!isStepDirty(activeComponent)) {
                setContent({});

                setActiveComponent(activeStep);
            } else {
                setOpenConfirmModal(true);
            }
        }
    }, [activeComponent, activeStep]);

    // Ao clivcar no botão de voltar, valida se é necessário exibir o modal de confirmação de troca de step.
    useEffect(() => {
        if (isLeaving) {
            if (isActiveStepDirty()) {
                setOpenConfirmModal(true);
            } else {
                navigate(-1);
            }
        }
    }, [isLeaving]);

    const getActiveAsideMenu = useCallback((): JSX.Element => {
        switch (activeComponent) {
            case 1:
                return <ConfiguracaoPedidoCompraDadosBasicos />;
            case 2:
                return <ConfiguracaoPedidoCompraCondicoesVisualizacao />;
            case 3:
                return <ConfiguracaoPedidoCompraRegrasValidacao />;
            case 4:
                return (
                    <ConfigPedidoProdutosProvider>
                        <ConfigPedidoProdutos />
                    </ConfigPedidoProdutosProvider>
                );
            case 5:
                return <ConfiguracaoPedidoCompraImportarEstoque />;
            case 6:
                return <ConfiguracaoPedidoCompraQuebrasDinamicas />;
            case 7:
                return <ConfiguracaoPedidoCompraPedidosSazonais />;
            case 8:
                return (
                    <ConfigPedidoCondicoesPagamentoProvider>
                        <ConfigPedidoCondicoesPagamento />
                    </ConfigPedidoCondicoesPagamentoProvider>
                );
            default:
                return <Box />;
        }
    }, [activeComponent]);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/configpedidostep',
        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
        filter: [{ property: 'idConfigPedido', operator: 'eq', value: id }],
    };

    useQuery(
        ['getConfigPedidoSteps', requestOptions],
        () => {
            const request: Promise<RequestStepsMenuProps> = RequestListagem(requestOptions);

            return request;
        },
        {
            enabled: Boolean(id),
            onSuccess: (response) => {
                const { data } = response;

                setListStep((list) =>
                    list.map((step) => {
                        const { dsValor } = data?.data.find((item) => item.dsChave === step.dsChaveConfigPedidoStep) || { dsValor: false };

                        return { ...step, fgSaved: dsValor };
                    }),
                );
            },
            onError: () =>
                enqueueSnackbar('Ocorreu um erro ao carregar as informações de salvamento do menu', {
                    variant: 'error',
                }),
        },
    );

    return (
        <OneColumn
            title="Configuração de Pedido"
            extraButtons={[
                {
                    title: 'Voltar',
                    onClick: () => setIsLeaving(true),
                },
            ]}
        >
            <Grid container spacing={2} direction="row">
                <ConfirmModal
                    open={openConfirmModal}
                    setOpen={setOpenConfirmModal}
                    text="Ao sair ou trocar de configuração,  alterações não salvas serão perdidas. Gostaria de continuar?"
                    confirm={() => {
                        setContent({});

                        if (isLeaving) {
                            navigate(-1);
                        } else {
                            if (isStepSaved(activeComponent)) {
                                setStepSaved(activeComponent);
                            } else {
                                setStepDirty(activeComponent, false);
                            }

                            setActiveComponent(activeStep);
                        }
                    }}
                    cancel={() => {
                        if (isLeaving) {
                            setIsLeaving(false);
                        } else {
                            setActiveStep(activeComponent);
                        }
                    }}
                />

                <Grid item md={3} xs={12}>
                    <ConfiguracaoPedidoCompraMenuAside />
                </Grid>

                <Grid item md={9} xs={12}>
                    {getActiveAsideMenu()}
                </Grid>
            </Grid>
        </OneColumn>
    );
}
