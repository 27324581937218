import React, { useEffect } from 'react';

import { Button, Stack } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType } from 'hooks/useRequestListagem';
import { LojasProps } from 'pages/compra/configuracao-pedido/types';

interface ConfiguracaoPedidoCompraLojasModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    selectedLojas: LojasProps[];
    setSelectedLojas: (action: LojasProps[]) => void;
    handleAdicionarLojas: () => void;
    requestOptionsLojas?: RequestOptionsType;
}

export function ConfiguracaoPedidoCompraLojasModal({
    open,
    setOpen,
    selectedLojas,
    setSelectedLojas,
    handleAdicionarLojas,
    requestOptionsLojas,
}: ConfiguracaoPedidoCompraLojasModalProps): JSX.Element {
    useEffect(() => {
        if (!open) {
            setSelectedLojas([]);
        }
    }, [open, setSelectedLojas]);

    return (
        <ContentModal
            closeButton
            open={open}
            setOpen={setOpen}
            sx={{
                padding: 2,
                borderRadius: 1,
                minWidth: {
                    xs: '80vw',
                    sm: 500,
                },
            }}
        >
            <React.Fragment>
                <SubTitle label="Incluir Lojas" />

                <StoreAutoComplete
                    flagInstitucional
                    defaultStore={false}
                    size="small"
                    label="Lojas"
                    multiple={true}
                    value={selectedLojas}
                    requestOptions={requestOptionsLojas}
                    onChange={(e, value) => setSelectedLojas(value)}
                />

                <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2} mt={2}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <Button variant="contained" onClick={() => handleAdicionarLojas()} disabled={!selectedLojas.length}>
                        Incluir
                    </Button>
                </Stack>
            </React.Fragment>
        </ContentModal>
    );
}
