import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TabelaPrecoOptionsProps {
    idTabelaPreco: number;
    dsTabelaPreco: string;
    cdTabelaPreco: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'label'>;

export function TabelaPrecoAutoComplete({
    sx,
    name,
    value,
    error,
    label = 'Tabela de preço',
    multiple = false,
    disabled = false,
    onChange = () => {},
}: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            label={label}
            value={value}
            error={error}
            multiple={multiple}
            disabled={disabled}
            onChange={onChange}
            selectedStringTemplate="{cdTabelaPreco} - {dsTabelaPreco}"
            optionStringTemplate="<strong>{cdTabelaPreco}</strong> - {dsTabelaPreco}"
            isOptionEqualToValue={(option: TabelaPrecoOptionsProps, value: TabelaPrecoOptionsProps) =>
                value && option && option.idTabelaPreco === value.idTabelaPreco
            }
            requestOptions={{
                url: '/gestao/tabelapreco',
                sort: [{ property: 'dsTabelaPreco', direction: 'ASC' }],
                limit: 50,
            }}
        />
    );
}
