import { Alert, Box } from '@mui/material';

import { Block } from 'components/page';
import { PagamentoTitle } from 'pages/compra/pagamento/components/templates/PagamentoTitle';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function PagamentoErrors() {
    const { pagamento, pagamentoRequestError } = usePagamentoContext();
    const errors = pagamento?.erros || [pagamentoRequestError];

    return (
        <Block
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                marginBottom: 0.5,
            }}
        >
            <PagamentoTitle title="Falhas" />

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
                {errors.map((error) => (
                    <Alert sx={{ py: 0, px: 1 }} severity="error" key={Math.random()}>
                        {error}
                    </Alert>
                ))}
            </Box>
        </Block>
    );
}
