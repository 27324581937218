import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialCentroPedidosValues, RequestCentroPedidosProps } from 'pages/complementar/centro-pedido/types';

export function CentroPedidosFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const url = '/gestao/centropedido';
    const { id } = useParams();
    const { mutate } = useFormMutation();

    const handleSubmit = (values: RequestCentroPedidosProps, formik: FormikHelpers<RequestCentroPedidosProps>): void => {
        mutate(
            {
                url,
                idField: 'idCentroPedido',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsCentroPedido: Yup.string().required(),
                cdCentroPedido: Yup.string().required(),
                fgAtivo: Yup.boolean().required(),
            }),
        [],
    );

    return (
        <FormPage
            title="Centro de Pedidos"
            values={initialCentroPedidosValues}
            fgAtivo={false}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            validateOnSubmit={true}
            goBackButton
        >
            {(formik: FormikProps<RequestCentroPedidosProps>) => {
                return (
                    <Block>
                        <Row>
                            {id && (
                                <Grid item flex={1}>
                                    <CissTextField label="Código" value={id} disabled={true} />
                                </Grid>
                            )}

                            <Grid item flex={1}>
                                <CissTextField
                                    label="Nome do centro"
                                    name="dsCentroPedido"
                                    error={Boolean(formik.errors.dsCentroPedido)}
                                    helperText={formik.errors.dsCentroPedido && String(formik.errors.dsCentroPedido)}
                                    value={formik.values.dsCentroPedido}
                                    onChange={(e) => formik.setFieldValue('dsCentroPedido', e.target.value)}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField
                                    disabled={Boolean(id)}
                                    label="Código de Integração"
                                    name="cdCentroPedido"
                                    value={formik.values.cdCentroPedido}
                                    error={Boolean(formik.errors.cdCentroPedido)}
                                    helperText={formik.errors.cdCentroPedido && String(formik.errors.cdCentroPedido)}
                                    onChange={(e) => formik.setFieldValue('cdCentroPedido', e.target.value)}
                                />
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
