import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';
import { ConfigPedidoProduto } from 'pages/compra/configuracao-pedido/types';

export function useActionsProdutos() {
    const { configPedidoProdutos, setConfigPedidoProdutos, setOpenProdutoModal } = useConfigPedidoProdutosContext();
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();

    const addConfigPedidoProdutos = (selectedProducts: any) => {
        const productsToAdd = selectedProducts
            .filter(
                (selectedProduct: any) =>
                    !configPedidoProdutos.some((product: ConfigPedidoProduto) => product.produto.idProduto === selectedProduct.idProduto),
            )
            .map(
                (selectedProduct: any): ConfigPedidoProduto => ({
                    produto: selectedProduct,
                    idProduto: selectedProduct.idProduto,
                    qtMinimaProduto: selectedProduct.qtMinimaCompra || 0,
                    qtMaximaProduto: 0,
                }),
            );

        const newProducts = [...configPedidoProdutos, ...productsToAdd];

        setActiveStepDirty();

        setOpenProdutoModal(false);

        setConfigPedidoProdutos(newProducts);
    };

    const removeConfigPedidoProdutos = (idsProduct: number[]) => {
        const newProducts = configPedidoProdutos.filter((product: ConfigPedidoProduto) => !idsProduct.includes(product.produto.idProduto));

        setActiveStepDirty();

        setConfigPedidoProdutos(newProducts);
    };

    const updateConfigPedidoProduto = (idProduto: number, value: number | boolean | null, nameField: string) => {
        const updatedProducts = configPedidoProdutos.map((produto: ConfigPedidoProduto) => {
            if (produto.idProduto === idProduto) {
                return { ...produto, [nameField]: value };
            }

            return produto;
        });

        setConfigPedidoProdutos(updatedProducts);

        setActiveStepDirty();
    };

    return { addConfigPedidoProdutos, removeConfigPedidoProdutos, updateConfigPedidoProduto };
}
