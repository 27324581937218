import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { LoadingButton } from '@mui/lab';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';
import { CopyDocument } from 'pages/compra/pedido-realizado/types';

export function PedidoRealizadoCopyDocumentBtn() {
    const { row, disabledActions, setDisabledActions } = useActionMenu();
    const requestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();

    const requestOptions: RequestOptionsType = { url: '/compra/pedidocompra/v2/payloadPedido', extraParams: [{ nrPedido: row.nrPedido }] };

    const { refetch: refetch, isFetching: isFetching } = useQuery<CopyDocument>(
        [requestOptions],
        () => requestListagem(requestOptions).then((res) => res.data),
        {
            enabled: false,
            onSuccess: ({ data }) => {
                navigator.clipboard.writeText(data);

                enqueueSnackbar('Dados do pedido copiados com sucesso', { variant: 'success' });
            },
            onError: (error: any) => showError(error, 'Não foi possível copiar os dados do pedido. Tente novamente'),
            onSettled: () => setDisabledActions(false),
        },
    );

    return (
        <LoadingButton
            color="inherit"
            startIcon={<DescriptionOutlinedIcon />}
            loading={isFetching}
            disabled={disabledActions}
            onClick={() => {
                setDisabledActions(true);

                refetch();
            }}
        >
            Copiar dados
        </LoadingButton>
    );
}
