import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoPagamento } from 'pages/compra/pagamento/types';

export function useQueryPayment({ idPagamento }: { idPagamento: number }) {
    const requestListagem = useRequestListagem();

    const configRequestOptions: RequestOptionsType = {
        url: `/compra/pedidopagamento/${idPagamento}`,
    };

    return useQuery<PedidoPagamento>(
        ['queryPayment', configRequestOptions],
        () => requestListagem(configRequestOptions).then((res) => res.data.data),
        {
            refetchInterval: (data) => {
                if (data && data.statusPedidoPagamento.statusPedidoPagamentoEnum === 'PENDENTE') {
                    return 1000 * 5; // 5 segundos
                }

                return false;
            },
        },
    );
}
