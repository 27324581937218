import { GridRowsProp } from '@mui/x-data-grid-pro';

import { Apuracao, DreListProps, RequestDreListProps } from '../types';

import { FilterValuesProps } from 'hooks/useFilter';
import { brlPrice, camelCase } from 'util/format';

export const findVlItem = (data: DreListProps, variavel: string): string => {
    const item = data?.dreMercadologicaApuracoes.find((apuracao) => apuracao.dreMercadologicaConfigApuracao.dsNomeVariavel === variavel);

    return item ? brlPrice(item.vlTotal) : '--';
};

const getStrStatus = (item: DreListProps): string => {
    const statusInfo = item.dreStatus.dreStatusEnum.split('_');
    const status = camelCase(statusInfo.length > 1 ? statusInfo[1] : statusInfo[0]);

    if (statusInfo.length > 1) {
        return `${camelCase(statusInfo[0], 0)} ${status}`;
    }

    return status;
};

const getDsLoja = (filterLojas: FilterValuesProps | undefined): string => {
    let dsLoja = '';

    if (filterLojas) {
        const [loja] = filterLojas.value;

        dsLoja = `${loja.cdLoja} - ${loja.dsNomeFantasia}`;
    }

    return dsLoja;
};

export const getRows = ({ data, filterLojas }: { data: DreListProps[] | undefined; filterLojas: FilterValuesProps | undefined }): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: Math.random().toString(36).slice(2),
            idDreMercadologica: item.idDreMercadologica,
            idStatus: item.dreStatus.idDreStatus,
            dsStatus: getStrStatus(item),
            dsLoja: getDsLoja(filterLojas),
            dsMes: camelCase(item.dsMesExtenso),
            vlCmv: '—',
            vlGastos: '—',
            vlLucroLiquido: '—',
            vlReceitaBruta: '—',
            vlReceitaLiquida: '—',
            dtInicio: item.dtInicio,
            isLoading: true,
            loja: item.loja,
        }));
    }

    return [];
};

export const getRowsWithMultipleLojas = (data: DreListProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: Math.random().toString(36).slice(2),
            dsMes: camelCase(item.dsMesExtenso),
            vlReceitaBruta: findVlItem(item, Apuracao.RECEITA_BRUTA),
            vlReceitaLiquida: findVlItem(item, Apuracao.RECEITA_LIQUIDA),
            vlLucroLiquido: findVlItem(item, Apuracao.LUCRO_LIQUIDO),
            vlCmv: findVlItem(item, Apuracao.CMV),
            vlGastos: findVlItem(item, Apuracao.GASTOS),
        }));
    }

    return [];
};

export const getColumnTooltip = (variavel: string, data?: RequestDreListProps): string => {
    let tooltip = '';

    data?.data.forEach((dreProp) => {
        const apuracao = dreProp.dreMercadologicaApuracoes?.find((apuracao) => apuracao.dreMercadologicaConfigApuracao.dsNomeVariavel === variavel);

        tooltip = apuracao?.dreMercadologicaConfigApuracao?.dsFormula || '';
    });

    return tooltip;
};

export const findValueFilter = (filtros: FilterValuesProps[], column: string): string => filtros.find((filtro) => filtro.property === column)?.value;

export const getDsLojaReport = (filterLoja?: FilterValuesProps): string =>
    filterLoja?.value?.map((item: any) => `${item.cdLoja} - ${item.dsNomeFantasia}`).join(', ');
