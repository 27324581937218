import { ChangeEvent } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { CissMaskField, CissTextField, DatePickerMonthYear } from 'components/fields';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { useMutateGateway } from 'pages/compra/pagamento/hooks';
import { CadastroCartaoPagamento, RequestCartaoPagamento } from 'pages/compra/pagamento/types';

const initialValues: CadastroCartaoPagamento = {
    cdCvv: null,
    dsApelido: '',
    nrCartao: null,
    dtValidade: null,
};

/**
 * Retorna o primeiro dia do mês atual.
 */
const getLastDayOfMonth = () => {
    const today = new Date();

    return new Date(today.getFullYear(), today.getMonth(), 1);
};

export function PagamentoCartaoCreditoModalForm() {
    const { setOpenCartaoCreditoModal } = usePagamentoContext();
    const { mutateGateway, isLoading } = useMutateGateway();

    const validationSchema = Yup.object({
        dtValidade: Yup.date().required().nullable().min(getLastDayOfMonth(), 'A data de validade deve ser maior ou igual o mês atual.'),
        nrCartao: Yup.number().required().nullable(),
        dsApelido: Yup.string().required().nullable(),
        cdCvv: Yup.string().required().nullable().min(3, 'O campo deve ter no mínimo 3 dígitos.'),
    });

    /**
     * Formata o input para permitir apenas 4 caracteres e números no campo de CVV.
     */
    const onInputChangeCVV = (e: ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const formattedInput = input.replace(/\D/g, '').slice(0, 4);

        e.target.value = formattedInput;
    };

    return (
        <Formik<CadastroCartaoPagamento>
            onSubmit={(formData) => mutateGateway(formData as RequestCartaoPagamento)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
        >
            {({ values, errors, setFieldValue, submitForm }) => (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <CissTextField
                            label="Nome no cartão"
                            disabled={isLoading}
                            value={values.dsApelido}
                            error={errors.dsApelido}
                            onChange={(e) => setFieldValue('dsApelido', e.target.value)}
                        />

                        <CissMaskField
                            format="card"
                            label="Número do cartão"
                            disabled={isLoading}
                            value={values.nrCartao}
                            error={errors.nrCartao}
                            onChange={(e) => setFieldValue('nrCartao', e.target.value || null)}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                gap: 2,
                            }}
                        >
                            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                                <DatePickerMonthYear
                                    label="Data de validade"
                                    minDate={new Date()}
                                    disabled={isLoading}
                                    value={values.dtValidade}
                                    error={errors.dtValidade}
                                    onChange={(value) => setFieldValue('dtValidade', value)}
                                />
                            </LocalizationProvider>

                            <CissTextField
                                label="Código de segurança (CVV)"
                                disabled={isLoading}
                                value={values.cdCvv}
                                error={errors.cdCvv}
                                onInput={onInputChangeCVV}
                                onChange={(e) => setFieldValue('cdCvv', e.target.value || null)}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                        <Button variant="outlined" disabled={isLoading} onClick={() => setOpenCartaoCreditoModal(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={submitForm}>
                            Salvar
                        </LoadingButton>
                    </Box>
                </>
            )}
        </Formik>
    );
}
