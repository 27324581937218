import { Box, Divider, Typography } from '@mui/material';

import { Block } from 'components/page';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { brlPrice } from 'util/format';

export function PagamentoInfo() {
    const { pagamento } = usePagamentoContext();
    const { isUpMd } = useBreakpoints();

    /**
     * Retorna o texto a ser exibido no campo de previsão de entrega.
     */
    const getDiasPrevisaoEntregaText = () => {
        const dias = pagamento?.nrDiasPrevisaoEntrega;

        if (dias) {
            const diasUteis = dias > 1 ? 'dias úteis' : 'dia útil';

            return `${dias} ${diasUteis} (após o faturamento)`;
        }

        return '–';
    };

    return (
        <Block
            sx={{
                display: 'flex',
                gap: { xs: 1, md: 2 },
                alignItems: { xs: 'flex-start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 1, md: 0 },
                    flexDirection: { xs: 'row', md: 'column' },
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: { xs: 20, md: 14 },
                    }}
                >
                    Valor a pagar:
                </Typography>

                <Typography
                    sx={{
                        color: 'secondary.main',
                        fontWeight: 500,
                        lineHeight: { xs: 1.5, md: 1 },
                        fontSize: { xs: 20, md: 28 },
                    }}
                >
                    {brlPrice(pagamento?.vlTotalBruto || 0)}
                </Typography>
            </Box>

            <Divider orientation={isUpMd ? 'vertical' : 'horizontal'} flexItem />

            <Box
                sx={{
                    'width': '100%',
                    'display': 'flex',
                    'alignItems': 'flex-start',
                    'flexDirection': { xs: 'column', md: 'row' },
                    'gap': { xs: 0.5, md: 1 },
                    '.MuiBox-root': {
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', md: '50%' },
                        gap: 0.5,
                    },
                }}
            >
                <Box>
                    <Typography>
                        <b>Loja:</b> {pagamento?.dsLoja || '–'}
                    </Typography>

                    <Typography>
                        <b>Configuração de pedido:</b> {pagamento?.dsConfigPedido || '–'}
                    </Typography>

                    <Typography>
                        <b>Usuário:</b> {pagamento?.dsUsuarioPedido || '–'}
                    </Typography>
                </Box>

                <Box>
                    <Typography>
                        <b>Endereço de entrega:</b> {pagamento?.dsEnderecoEntrega || '–'}
                    </Typography>

                    <Typography>
                        <b>Previsão de entrega:</b> {getDiasPrevisaoEntregaText()}
                    </Typography>
                </Box>
            </Box>
        </Block>
    );
}
