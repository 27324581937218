import { GridRowId } from '@mui/x-data-grid-pro';

import { usePagamentoContext } from '../contexts/PagamentoContext';

import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';

export function useActionsPagamento() {
    const {
        isPending,
        idsPedido,
        setIdPedido,
        isFinishing,
        setIdsPedido,
        cartoesCredito,
        setCartoesCredito,
        selecaoMeioPagamento,
        setSelecaoMeioPagamento,
    } = usePagamentoContext();

    /**
     * Atualiza o cartao de crédito selecionado com a condição de pagamento informada.
     */
    const updateSelectedCondicaoPagamentoInCartaoCredito = (condicaoPagamento: CondicaoPagamento) => {
        if (!selecaoMeioPagamento) {
            return;
        }

        const newCartoesCredito = cartoesCredito.map((cartao) => {
            if (cartao.idCartaoPagamento === selecaoMeioPagamento?.cartaoPagamento?.idCartaoPagamento) {
                return { ...cartao, condicaoPagamento };
            }

            return cartao;
        });

        setSelecaoMeioPagamento({ ...selecaoMeioPagamento, condicaoPagamento });

        setCartoesCredito(newCartoesCredito);
    };

    /**
     * Retorna a seleção para a grid, caso ela for do tipo cartão de crédito.
     */
    const getSelectionGrid = () => {
        const isSelecaoCartaoCredito = selecaoMeioPagamento?.meioPagamentoEnum === 'CARTAO_CREDITO';

        if (isSelecaoCartaoCredito) {
            return [selecaoMeioPagamento.cartaoPagamento?.idCartaoPagamento] as GridRowId[];
        }

        return [];
    };

    /**
     * Ao clicar na linha, define a seleção como o cartão de crédito clicado.
     */
    const onClickRowGrid = (selectionModel: GridRowId[]) => {
        if (selectionModel.length && !isPending && !isFinishing) {
            const cartao = cartoesCredito.find((cartao) => cartao.idCartaoPagamento === selectionModel.at(-1));

            if (cartao && cartao?.condicaoPagamento) {
                setSelecaoMeioPagamento({
                    meioPagamentoEnum: 'CARTAO_CREDITO',
                    cartaoPagamento: cartao,
                    condicaoPagamento: cartao.condicaoPagamento,
                });
            }
        }
    };

    /**
     * Atualiza o idPedido para o próximo da lista de ids.
     */
    const setNextIdPedido = () => {
        const newIdsPedido = idsPedido.slice(1);
        const newIdPedido = newIdsPedido[0];

        setIdsPedido(newIdsPedido);

        if (newIdPedido) {
            setIdPedido(newIdPedido);
        }
    };

    return {
        onClickRowGrid,
        setNextIdPedido,
        getSelectionGrid,
        updateSelectedCondicaoPagamentoInCartaoCredito,
    };
}
