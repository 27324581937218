import { SyntheticEvent, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { StoreAutoComplete } from 'components/autocompletes';
import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { ConfirmModal } from 'components/modal';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';

export function ConfigPedidoProdutosStore() {
    const { loja, setLoja, configPedidoProdutos, initialConfigPedidoProdutos } = useConfigPedidoProdutosContext();
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const temporaryLoja = useRef<StoreOptionsProps | null>(null);
    const { id } = useParams();

    /**
     * Retorna o texto da opção de confirmação do modal de confirmação.
     */
    const getTextConfirmOption = () => {
        let textConfirmOption = 'trocar de loja selecionada';

        if (!temporaryLoja.current) {
            textConfirmOption = 'limpar a loja selecionada';
        } else if (!loja && temporaryLoja.current) {
            textConfirmOption = 'selecionar uma loja';
        }

        return textConfirmOption;
    };

    /**
     * Ao alterar a loja, verifica se existem alterações não salvas e exibe um modal de confirmação.
     */
    const onChangeStoreAutoComplete = (e: SyntheticEvent<Element, Event>, value: StoreOptionsProps | null) => {
        const isDataDirty = JSON.stringify(configPedidoProdutos) !== initialConfigPedidoProdutos;

        if (isDataDirty) {
            temporaryLoja.current = value;

            setOpenConfirmModal(true);
        } else {
            setLoja(value);
        }
    };

    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                text={`Existem alterações não salvas, gostaria de ${getTextConfirmOption()} assim mesmo?`}
                confirm={() => {
                    setLoja(temporaryLoja.current);

                    setOpenConfirmModal(false);
                }}
            />
            <Block>
                <SubTitle label="Aplicar descontos por loja" />

                <StoreAutoComplete
                    size="small"
                    defaultStore={false}
                    value={loja}
                    onChange={onChangeStoreAutoComplete}
                    requestOptions={{
                        url: '/gestao/loja/findbyidsconfigpedido',
                        sort: [{ property: 'idLoja', direction: 'ASC' }],
                        filter: [{ property: 'idConfigPedido', operator: 'eq', value: id }],
                    }}
                />
            </Block>
        </>
    );
}
