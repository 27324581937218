import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';

export function useGetCobflexURL(): UseQueryResult<string> {
    const requestListagem = useRequestListagem();

    return useQuery(['queryCobflexURL'], () =>
        requestListagem({
            url: '/config/configgeral/chave',
            extraParams: [
                {
                    dsChave: 'URL_COBFLEX',
                },
            ],
        }).then((res) => res.data),
    );
}
