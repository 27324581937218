import { createSearchParams, useNavigate } from 'react-router-dom';

export function useNavigateToPedidoRealizado() {
    const navigate = useNavigate();

    /**
     * Navega para a tela de pedido realizado.
     */
    const navigateToPedidoRealizado = () =>
        navigate(
            {
                pathname: '/compra/pedido-realizado',
                search: createSearchParams({
                    idUsuarioPedido: 'null',
                    dhInclusao: JSON.stringify({
                        startDate: new Date(),
                        endDate: new Date(),
                    }),
                }).toString(),
            },
            { replace: true },
        );

    return { navigateToPedidoRealizado };
}
