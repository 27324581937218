import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';

import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { useActionsPagamento, useNavigateToPedidoRealizado } from 'pages/compra/pagamento/hooks';

export function PagamentoNextButton() {
    const { idsPedido, isPending, selecaoMeioPagamento, setIsPending, setSelecaoMeioPagamento } = usePagamentoContext();
    const { setNextIdPedido } = useActionsPagamento();
    const { navigateToPedidoRealizado } = useNavigateToPedidoRealizado();
    const isLastPedido = idsPedido.length === 1;
    const icon = isLastPedido ? null : <ChevronRightIcon />;
    const isPendingPix = isPending && selecaoMeioPagamento?.meioPagamentoEnum === 'PIX';
    const text = isLastPedido ? 'Consultar pedidos realizados' : 'Próximo pagamento';

    /**
     * Caso for o último pedido, redireciona a tela de pedido realizado.
     * Caso não for, avança para o próximo pedido, mas se o pagamento estiver pendente de pix, limpa o meio de pagamento e não deixa mais pendente.
     */
    const handlerNextButton = () => {
        if (isLastPedido) {
            navigateToPedidoRealizado();
        } else {
            if (isPendingPix) {
                setIsPending(false);
            }

            setSelecaoMeioPagamento(null);

            setNextIdPedido();
        }
    };

    return (
        <Button variant="contained" endIcon={icon} onClick={handlerNextButton}>
            {text}
        </Button>
    );
}
