import { useState } from 'react';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Button } from '@mui/material';

import { PedidoRealizadoTrackModal } from 'pages/compra/pedido-realizado/components/templates/PedidoRealizadoTrackModal';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export function PedidoRealizadoTrackBtn() {
    const { row, disabledActions } = useActionMenu();
    const [isOpenTrackModal, setIsOpenTrackModal] = useState<boolean>(false);

    return (
        <>
            <PedidoRealizadoTrackModal url={row.dsUrlTrakingPedido} open={isOpenTrackModal} setOpen={setIsOpenTrackModal} />

            <Button color="inherit" startIcon={<LocalShippingOutlinedIcon />} disabled={disabledActions} onClick={() => setIsOpenTrackModal(true)}>
                Rastreio
            </Button>
        </>
    );
}
