import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface DreMercadologicaGrupoExibicaoProps {
    fgAtivo: boolean;
    idDreMercadologicaGrupoExibicao: number;
    dsDreMercadologicaGrupoExibicao: string;
    nrSequencia: number;
    dsNomeVariavel: string;

    tipoDreMercadologicaGrupoExibicao: {
        idDreMercadologicaTipoGrupoExibicao: number;
        dsDreMercadologicaTipoGrupoExibicao: string;
    };
}

export interface DreMercadologicaCustosProps {
    idDreMercadologicaCusto: number;
    vlTotal: number;
    index: number; // propriedade criada e usada somente no front

    dreMercadologicaConfigCusto: {
        idDreMercadologicaConfigCusto: number;
        fgAtivo: boolean;
        dsNomeExibicao: string;
        dsNomeVariavel: string;
        dsValorConfig: string;
        nrSequencia: number;
        variaveis: string[];
        dsObservacao: string;

        dreMercadologicaGrupoExibicao: DreMercadologicaGrupoExibicaoProps;

        dreTipoPreenchimento: {
            idDreTipoPreenchimento: number;
        };
    };
}

export interface DreMercadologicaVendasProps {
    idDreMercadologicaVenda: number;
    vlTotal: number;
    vlAjuste: number;
    vlTotalAjustado: number;
    dsJustificativaAjuste: string;

    dreMercadologicaConfigVenda: {
        idDreMercadologicaConfigVenda: number;
        fgAtivo: boolean;
        dsNomeExibicao: string;
        dsNomeVariavel: string;
        nrSequencia: number;
        dsObservacao: string;

        dreMercadologicaGrupoExibicao: {
            fgAtivo: boolean;
            idDreMercadologicaGrupoExibicao: number;
            dsDreMercadologicaGrupoExibicao: string;
            nrSequencia: number;
            dsNomeVariavel: string;
            vlTotal: number;

            tipoDreMercadologicaGrupoExibicao: {
                idDreMercadologicaTipoGrupoExibicao: number;
                dsDreMercadologicaTipoGrupoExibicao: string;
            };
        };
    };
}

export interface DreMercadologicaDespesasProps {
    idDreMercadologicaDespesa: number;
    vlTotal: number;
    fgRecorrente: boolean;

    dreMercadologicaConfigDespesa: {
        idDreMercadologicaConfigDespesa: number;
        fgAtivo: boolean;
        dsNomeExibicao: string;
        dsNomeVariavel: string;
        fgObrigatorio: boolean;
        fgRecorrente: boolean;
        dsObservacao: string;

        dreMercadologicaGrupoExibicao: {
            fgAtivo: boolean;
            idDreMercadologicaGrupoExibicao: number;
            dsDreMercadologicaGrupoExibicao: string;
            nrSequencia: number;
            vlTotal: number;
        };

        dreTipoPreenchimento: {
            idDreTipoPreenchimento: number;
        };
    };
}

export interface DreMercadologicaDespesasVariaveisProps {
    idDreMercadologicaDespesaVariavel: number;
    dsDespesa: string;
    fgRecorrente: boolean;
    vlTotal: number;
}

export interface DreMercadologicaImpostosProps {
    idDreMercadologicaImposto: number;
    vlTotal: number;

    dreMercadologicaConfigImposto: {
        idDreMercadologicaConfigImposto: number;
        idTipoRegimeTributario: number;
        nrSequencia: number;
        dsNomeExibicao: string;
        dsNomeVariavel: string;
        fgAtivo: boolean;
        dsObservacao: string;
    };
}

interface DreStatusProps {
    idDreStatus: number;
    dreStatusEnum: string;
}

type LojaConfiguracao = {
    idLojaConfiguracao: number;
    idConfiguracaoPropriedade: number;
    dsValor: string;
    configuracaoPropriedade: {
        dsChave: string;
        dsConfiguracao: string;
        idConfiguracaoPropriedade: number;
    };
};

interface LojaProps {
    idLoja: number;
    lojaConfiguracoes: LojaConfiguracao[];
}

export interface DrePageProps {
    idDreMercadologica: number;
    dreStatus: DreStatusProps | null;
    loja: LojaProps | null;
    dreMercadologicaCustos: DreMercadologicaCustosProps[];
    dreMercadologicaVendas: DreMercadologicaVendasProps[];
    dreMercadologicaDespesas: DreMercadologicaDespesasProps[];
    dreMercadologicaDespesasVariaveis: DreMercadologicaDespesasVariaveisProps[];
    dreMercadologicaImpostos: DreMercadologicaImpostosProps[];
}

export interface RequestDrePageProps {
    status: number;
    data: {
        data: DrePageProps;
    };
}

export const initialDrePageValues: DrePageProps = {
    idDreMercadologica: -1,
    dreStatus: null,
    loja: null,
    dreMercadologicaCustos: [],
    dreMercadologicaVendas: [],
    dreMercadologicaDespesas: [],
    dreMercadologicaDespesasVariaveis: [],
    dreMercadologicaImpostos: [],
};

interface DreMenuGrupoTipoProps {
    idDreMercadologicaTipoGrupoExibicao: number;
    dsDreMercadologicaTipoGrupoExibicao: string;
}

interface DreMenuGrupoProps {
    idDreMercadologicaGrupoExibicao: number;
    fgAtivo: boolean;
    dsDreMercadologicaGrupoExibicao: string;
    nrSequencia: number;
    tipoDreMercadologicaGrupoExibicao: DreMenuGrupoTipoProps;
}

export interface DreMenuProps {
    idDreMercadologicaTipoGrupoExibicao: number;
    dsDreMercadologicaTipoGrupoExibicao: string;
    nrSequencia: number;
    gruposExibicao: DreMenuGrupoProps[];
}

interface DreMercadologicaConfigApuracaoProps {
    idDreMercadologicaConfigApuracao: number;
    dsNomeExibicao: string;
    dsNomeVariavel: string;
    dsObservacao: string;
    dsFormula: string;
    nrSequencia: number;
    fgTotalizar: boolean;
    fgAtivo: boolean;
}

interface DreMercadologicaApuracoesProps {
    vlTotal: number;
    dreMercadologicaConfigApuracao: DreMercadologicaConfigApuracaoProps;
}

export interface DreListProps {
    idDreMercadologica: number;
    dreStatus: DreStatusProps;
    loja: LojaProps;
    dreMercadologicaApuracoes: DreMercadologicaApuracoesProps[];
    dsMesExtenso: string;
    dtInicio: string;
    isLoading?: boolean;
}

export interface RequestDreListProps extends RequestListagemProps {
    data: DreListProps[];
}

export const Apuracao = {
    RECEITA_BRUTA: 'apuracao_receita_bruta',
    RECEITA_LIQUIDA: 'apuracao_receita_liquida',
    LUCRO_LIQUIDO: 'apuracao_lucro_liquido',
    CMV: 'apuracao_cmv',
    GASTOS: 'apuracao_gastos',
} as const;
