import React, { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { ptBR } from 'date-fns/locale';
import { useSnackbar } from 'notistack';

import { ConfiguracaoPedidoAutoComplete, FuncionarioAutoComplete, StoreAutoComplete, TipoPedidoAutoComplete } from 'components/autocompletes';
import { Funcionario } from 'components/autocompletes/FuncionarioAutoComplete';
import { SituacaoPedidoRealizadoAutoComplete } from 'components/autocompletes/SituacaoPedidoRealizadoAutoComplete';
import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';

export function PedidoRealizadoListFilter(): JSX.Element {
    const { hasPermission } = usePermissionsContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { tokenInfo } = useGenericAuth();
    const { formattedLoja, loja, usuario } = useAplicacaoDataContext();
    const { enqueueSnackbar } = useSnackbar();
    const hasAcessoGeral = hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL']);
    const [searchParams] = useSearchParams();
    const lojaParams = searchParams.get('idLoja');
    const funcionarioParams = searchParams.get('idUsuarioPedido');
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const getDate31DaysAgo = (): Date => {
        const date = new Date();
        date.setDate(date.getDate() - 31);
        return date;
    };

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'eq',
        value: null,
        idProperty: 'idLoja',
    });

    const [filterOrderType, setFilterOrderType] = useState<FilterValuesProps>({
        property: 'idPedidoStatus',
        operator: 'in',
        value: [],
        idProperty: 'idPedidoStatus',
    });

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'eq',
        value: null,
        idProperty: 'idConfigPedido',
    });

    const [filterTipoPedido, setFilterTipoPedido] = useState<FilterValuesProps>({
        property: 'idTipoPedido',
        operator: 'eq',
        value: null,
        idProperty: 'idTipoPedido',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: true,
        idProperty: 'vlStatus',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: getDate31DaysAgo(),
        endDate: new Date(),
    });

    const [filterPedido, setFilterPedido] = useState<FilterValuesProps>({
        property: 'idPedido',
        operator: 'eq',
        value: '',
    });

    const [filterNrPedido, setFilterNrPedido] = useState<FilterValuesProps>({
        property: 'cdPedidoInterno',
        operator: 'eq',
        value: '',
    });

    const [filterCdPedido, setFilterCdPedido] = useState<FilterValuesProps>({
        property: 'cdPedido',
        operator: 'eq',
        value: '',
    });

    const [filterFuncionario, setFilterFuncionario] = useState<FilterValuesProps>({
        property: 'idUsuarioPedido',
        operator: 'eq',
        value: null,
        idProperty: 'idUsuario',
    });

    const filters = [
        { value: filterOrderType, setter: setFilterOrderType },
        { value: filterTipoPedido, setter: setFilterPedido },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterCdPedido, setter: setFilterCdPedido },
        { value: filterNrPedido, setter: setFilterNrPedido },
        { value: filterInterval, setter: setFilterInterval },
        { value: filterPedido, setter: setFilterPedido },
        { value: filterFuncionario, setter: setFilterFuncionario },
    ];

    if (isTipoPedidoPadrao) {
        filters.push({ value: filterLoja, setter: setFilterLoja });
    }

    useEffect(() => {
        if (hasAcessoGeral && usuario && !funcionarioParams) {
            setFilterFuncionario((oldState: FilterValuesProps) => ({
                ...oldState,
                value: { idUsuario: usuario.idUsuario, dsUsuario: usuario.dsUsuario },
            }));
        }
    }, [usuario]);

    const resetFilter = (): void => {
        handleFilterReset({ filters });

        if (formattedLoja && isTipoPedidoPadrao) {
            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: formattedLoja }));
        }
    };

    const startFilter = (): void => {
        if (!filterLoja.value && isTipoPedidoPadrao) {
            enqueueSnackbar(
                <Typography>
                    A consulta pode levar muito tempo ou até mesmo não retornar
                    <br />
                    dados caso não for informado pelo menos uma loja no filtro
                </Typography>,
                { variant: 'warning', autoHideDuration: 8000 },
            );
        }

        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    const colSpanLojinha = isTipoPedidoFuncionario ? 4 : 3;
    const colSpanFuncionario = isTipoPedidoFuncionario ? (hasAcessoGeral ? 4 : 6) : 3;

    // Retorna o filtro usado no autocomplete de COnfiguração de Pedido.
    const filterConfiguracaoPedido = useMemo((): FilterValuesProps[] => {
        const filterConfiguracaoPedido: FilterValuesProps[] = [{ property: 'fgAtivo', operator: 'eq', value: true }];

        if (filterLoja.value) {
            filterConfiguracaoPedido.push({ property: 'idLoja', operator: 'eq', value: filterLoja.value?.idLoja });
        }

        if (tokenInfo?.tpUsuario === 'FRANQUEADO') {
            filterConfiguracaoPedido.push(
                { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                { property: 'fgVisivel', operator: 'eq', value: true },
            );
        }

        return filterConfiguracaoPedido;
    }, [filterLoja.value, tokenInfo?.tpUsuario]);

    const filterSituacao = useMemo((): FilterValuesProps[] => {
        const filter: FilterValuesProps[] = [{ property: 'fgVisivel', operator: 'eq', value: true, logicalOperator: 'AND' }];

        if (filterOrderType.value.length) {
            const [{ cdStatusExterno }] = filterOrderType.value;

            filter.push({ property: 'cdStatusExterno', operator: cdStatusExterno ? 'neq' : 'is null', value: 'null' });
        }

        return filter;
    }, [filterOrderType.value]);

    return (
        <Filter reset={resetFilter} submit={startFilter} fixed={false}>
            {isTipoPedidoPadrao && (
                <Grid item lg={6}>
                    <StoreAutoComplete
                        flagInstitucional
                        size="small"
                        defaultStore={!lojaParams && loja?.fgAtivo}
                        value={filterLoja.value}
                        onChange={(e, value) => {
                            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }));

                            // Limpa a seleção de configuração caso a loja seja alterada.
                            if (value) {
                                setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: null }));
                            }
                        }}
                        requestOptions={{
                            url: '/gestao/loja',
                            sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                            filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                            columns: 'idLoja,cdLoja,dsNomeFantasia,dsRazaoSocial',
                            limit: 50,
                        }}
                    />
                </Grid>
            )}

            <Grid item lg={colSpanFuncionario}>
                <SituacaoPedidoRealizadoAutoComplete
                    multiple
                    size="small"
                    value={filterOrderType.value}
                    onChange={(e, value) => setFilterOrderType((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: '/compra/pedidostatus',
                        sort: [{ property: 'dsPedidoStatus', direction: 'ASC' }],
                        filter: filterSituacao,
                    }}
                />
            </Grid>

            {isTipoPedidoFuncionario && hasAcessoGeral && (
                <Grid item lg={4}>
                    <FuncionarioAutoComplete
                        size="small"
                        value={filterFuncionario.value}
                        onChange={(e, value: Funcionario) => {
                            let valueLoja = formattedLoja;

                            if (value) {
                                valueLoja = {
                                    idLoja: value.loja.idLoja,
                                    cdLoja: value.loja.cdLoja,
                                    dsNomeFantasia: value.loja.dsNomeFantasia,
                                };
                            }

                            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: valueLoja }));

                            setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

                            setFilterFuncionario((oldState: FilterValuesProps) => ({
                                ...oldState,
                                value: value && { idUsuario: value.idUsuario, dsUsuario: value.dsUsuario },
                            }));
                        }}
                    />
                </Grid>
            )}

            {isTipoPedidoPadrao && (
                <Grid item lg={3}>
                    <TipoPedidoAutoComplete
                        size="small"
                        value={filterTipoPedido.value}
                        onChange={(e, value) => setFilterTipoPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    />
                </Grid>
            )}

            <Grid item lg={colSpanFuncionario}>
                <ConfiguracaoPedidoAutoComplete
                    label={isTipoPedidoFuncionario ? 'Campanha de Produtos' : undefined}
                    value={filterConfigPedido.value}
                    size="small"
                    onChange={(e, value) => setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: '/gestao/configpedido/loja',
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        columns: 'idConfigPedido,dsConfigPedido',
                        filter: filterConfiguracaoPedido,
                        limit: 9999,
                    }}
                />
            </Grid>

            <Grid item lg={colSpanLojinha}>
                <CissTextField
                    size="small"
                    label="Código do Pedido"
                    value={filterNrPedido.value}
                    onChange={(e) => setFilterNrPedido((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item lg={colSpanLojinha}>
                <CissTextField
                    size="small"
                    label="Código de Integração"
                    value={filterCdPedido.value}
                    onChange={(e) => setFilterCdPedido((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item lg={colSpanLojinha}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" size="small" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" size="small" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            {hasPermission(['COMPRA_PEDIDO_REATIVAR_PEDIDO_EXCLUIDO']) && (
                <Grid item lg={3}>
                    <StatusField
                        value={filterStatus.value}
                        onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                        both={false}
                        size="small"
                    />
                </Grid>
            )}
        </Filter>
    );
}
