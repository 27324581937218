import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Grid, FormControlLabel, Typography, Radio } from '@mui/material';
import { Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { ArquivoFormInfo } from '..';

import { StoreImportAutoComplete } from 'components/autocompletes';
import { AzureUpload } from 'components/azureUpload';
import { CissTextField, Editor, CategoriaArquivoFieldFormik } from 'components/fields/';
import { CityFieldFormik } from 'components/fields/CityFieldFormik';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { StoreProfileFieldFormik } from 'components/fields/StoreProfileFieldFormik';
import { StoreTypeFieldFormik } from 'components/fields/StoreTypeFieldFormik';
import { Row } from 'components/form';
import { FormPage, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useValidation } from 'hooks/useValidation';
import { useFormMutation } from 'mutations/useFormMutation';
import { CatalogoArquivoTagFieldFormik } from 'pages/arquivo/components/atoms/CatalogoArquivoTagFieldFormik';
import { ArquivoProps, initialArquivoValues } from 'pages/arquivo/types';

export function ArquivoFormComp(): JSX.Element {
    const fileRef = useRef(null);

    const { id } = useParams();
    const { validRouterParams } = useValidation();
    const { setContent, content } = useFormContext();

    const validId = validRouterParams({ id });
    const url = '/gestao/catalogoarquivo';

    const { mutate: mutateArquivo } = useFormMutation();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const handleSubmit = (values: ArquivoProps, formik: FormikHelpers<ArquivoProps>): void => {
        if (values.arquivo) {
            values.fgArquivoAtualizado = values.arquivo && values.arquivo.idArquivo !== fileRef.current;

            values.arquivo.dsTitulo = values.dsTitulo;
        }

        mutateArquivo(
            {
                url,
                idField: 'idCatalogoArquivo',
                formik,
                formData: values,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsTitulo: Yup.string().required(),
                categoriaArquivo: Yup.object({
                    idCategoriaArquivo: Yup.number(),
                    dsCategoriaArquivo: Yup.string(),
                })
                    .required()
                    .nullable(),
                arquivo: Yup.object({
                    idArquivo: Yup.number(),
                    dsLink: Yup.string(),
                })
                    .required()
                    .nullable(),
            }),
        [],
    );

    useEffect(() => {
        if (content && content.arquivo && fileRef.current !== content.arquivo.idArquivo) {
            fileRef.current = content.arquivo.idArquivo;
        }
    }, [content]);

    const mapContentToInitialValues = useCallback((contentData: ArquivoProps) => {
        if (contentData && contentData.arquivo && !contentData.dsTitulo) {
            contentData.dsTitulo = contentData.arquivo.dsTitulo;
        }
        if (contentData.catalogoArquivoEstados?.length) {
            contentData.tipoDisponibilizacao = 'estados';
        } else if (contentData.catalogoArquivoCidades?.length) {
            contentData.tipoDisponibilizacao = 'cidades';
        } else {
            contentData.tipoDisponibilizacao = 'lojas';
        }

        return contentData;
    }, []);

    return (
        <FormPage
            title="Arquivo"
            values={initialArquivoValues}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            mapContentToInitialValues={mapContentToInitialValues}
            fgAtivo={hasPermission(['ARQUIVO_MODERAR'])}
            extraTopButtons={[
                {
                    title: 'Gerenciar Tags',
                    onClick: () => navigate('/arquivo/tag'),
                    visible: hasPermission(['ARQUIVO_MODERAR']),
                },
            ]}
        >
            {(formik: any) => {
                return (
                    <React.Fragment>
                        <Block>
                            <Row>
                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Nome do arquivo"
                                        name="dsTitulo"
                                        value={formik.values.dsTitulo}
                                        onChange={(e) => formik.setFieldValue('dsTitulo', e.target.value)}
                                        error={formik.errors.dsTitulo}
                                    />
                                </Grid>

                                <Grid item flex={0.95}>
                                    <Field component={CategoriaArquivoFieldFormik} multiple={false} name="categoriaArquivo" label="Categoria" />
                                </Grid>

                                <Grid item flex={1}>
                                    <Field component={CatalogoArquivoTagFieldFormik} name="catalogoArquivoTagsCatalogoArquivo" />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <Field
                                        component={StoreImportAutoComplete}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'lojas' ? 'flex' : 'none' }}
                                        multiple={true}
                                        name="catalogoArquivoLojas"
                                    />

                                    <Field
                                        component={StateFieldFormik}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'estados' ? 'block' : 'none' }}
                                        multiple={true}
                                        name="catalogoArquivoEstados"
                                    />

                                    <Field
                                        component={CityFieldFormik}
                                        sx={{ display: formik.values.tipoDisponibilizacao === 'cidades' ? 'block' : 'none' }}
                                        multiple={true}
                                        name="catalogoArquivoCidades"
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <Typography>
                                        <strong>Será disponibilizado para:</strong>
                                    </Typography>

                                    <Grid item>
                                        <FormControlLabel
                                            label="Lojas"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'lojas'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="lojas"
                                        />
                                        <FormControlLabel
                                            label="Estados"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'estados'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="estados"
                                        />
                                        <FormControlLabel
                                            label="Cidades"
                                            control={
                                                <Radio
                                                    checked={formik.values.tipoDisponibilizacao === 'cidades'}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('tipoDisponibilizacao', e.target.value);
                                                    }}
                                                />
                                            }
                                            value="cidades"
                                        />
                                    </Grid>
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <Field component={StoreTypeFieldFormik} name="catalogoArquivoTipoLojas" />
                                </Grid>
                                <Grid item flex={1}>
                                    <Field component={StoreProfileFieldFormik} name="catalogoArquivoLojaPerfis" />
                                </Grid>
                            </Row>
                        </Block>
                        <Block>
                            <SubTitle label="Breve Descrição" />

                            <Grid item xs={12}>
                                <Editor value={formik.values.dsResumo} onChangeText={(text: string) => formik.setFieldValue('dsResumo', text)} limit={80} />
                            </Grid>
                        </Block>

                        <Block>
                            <SubTitle label="Arquivo para Download" />

                            <AzureUpload
                                id="arquivo"
                                files={
                                    formik.values.arquivo
                                        ? [
                                              {
                                                  fgAtivo: true,
                                                  fgCapa: true,
                                                  fgExcluido: false,
                                                  fgNovo: false,
                                                  arquivo: formik.values.arquivo,
                                              },
                                          ]
                                        : []
                                }
                                onChange={(list) => {
                                    if (list && list.length) {
                                        formik.setFieldValue('arquivo', list.at(0)?.fgExcluido ? null : list.at(0)?.arquivo);
                                    }
                                }}
                                removeAll={false}
                                replaceFile={true}
                                error={formik.errors.arquivo}
                            />
                        </Block>

                        {validId && content && (
                            <ArquivoFormInfo
                                content={{
                                    dsAuthor: content.usuarioAlteracao.dsUsuario,
                                    dtCadastro: content.dhAlteracao,
                                    lkArquivo: content.arquivo.dsLink,
                                    qtDownload: content.qtDownload,
                                }}
                            />
                        )}
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
