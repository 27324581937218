import React, { useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissPrimaryKeyField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialMotivoDevolucaoFormValues, MotivoDevolucaoProps, RequestMotivoDevolucaoFormProps } from 'pages/financeiro/nota-devolucao/motivo/types';

export function MotivoDevolucaoFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const { mutate } = useFormMutation<RequestMotivoDevolucaoFormProps>();

    const url = '/gestao/motivodevolucao';

    const handleSubmit = useCallback(
        (values: MotivoDevolucaoProps, formik: FormikHelpers<MotivoDevolucaoProps>): void => {
            mutate(
                {
                    url,
                    formik,
                    idField: 'idMotivoDevolucao',
                    formData: values,
                },
                {
                    onSuccess: (response) => {
                        const { data } = response.data;

                        if (data) {
                            setContent(data);
                        }
                    },
                },
            );
        },
        [mutate, setContent],
    );

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsMotivoDevolucao: Yup.string().required().min(2),
            }),
        [],
    );

    return (
        <FormPage title="Motivo Devolução" url={url} values={initialMotivoDevolucaoFormValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {(formik: FormikProps<MotivoDevolucaoProps>) => {
                return (
                    <Block>
                        <SubTitle label="Dados Gerais" />

                        <Row>
                            <Grid item flex={1}>
                                <CissPrimaryKeyField
                                    name="idMotivoDevolucao"
                                    value={formik.values.idMotivoDevolucao}
                                    onChange={(e) => formik.setFieldValue('idMotivoDevolucao', e.target.value)}
                                    error={formik.errors.idMotivoDevolucao}
                                />
                            </Grid>

                            <Grid item flex={4}>
                                <CissTextField
                                    label="Título"
                                    name="dsMotivoDevolucao"
                                    value={formik.values.dsMotivoDevolucao}
                                    onChange={(e) => formik.setFieldValue('dsMotivoDevolucao', e.target.value)}
                                    error={formik.errors.dsMotivoDevolucao}
                                />
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
