import React from 'react';

import { useNavigate } from 'react-router-dom';

import { OneColumn } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { DreGridFilter, DreReport } from 'pages/financeiro/dre/components';
import { DreGrid } from 'pages/financeiro/dre/components/templates/DreGrid';

export function DreListComp(): JSX.Element {
    const navigate = useNavigate();
    const { hasPermission } = usePermissionsContext();

    return (
        <OneColumn
            title="Estimativa de Resultado"
            extraButtons={[
                {
                    title: 'Gerenciar preenchimentos de estimativa de resultado',
                    visible: hasPermission(['FINANCEIRO_DRE_PREENCHIMENTO_GERENCIAR']),
                    onClick: () => navigate('preenchimento'),
                },
            ]}
        >
            <DreGridFilter />

            <DreReport />

            <DreGrid />
        </OneColumn>
    );
}
