import { useEffect, useState } from 'react';

import { GridValidRowModel } from '@mui/x-data-grid-pro';

import { useDreFilterLojas } from 'pages/financeiro/dre/hooks';
import { RequestDreListProps } from 'pages/financeiro/dre/types';
import { getRows, getRowsWithMultipleLojas } from 'pages/financeiro/dre/utils';

export function useDreGridRows({ data }: { data?: RequestDreListProps }): readonly GridValidRowModel[] {
    const [rows, setRows] = useState<readonly GridValidRowModel[]>([]);
    const filterLojas = useDreFilterLojas();
    const isMultipleLojas = filterLojas?.value.length > 1;

    useEffect(() => {
        setRows(isMultipleLojas ? getRowsWithMultipleLojas(data?.data) : getRows({ data: data?.data, filterLojas }));
    }, [data]);

    return rows;
}
