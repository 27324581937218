import { useState } from 'react';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';
import { ConfigPedidoProdutosGrid, ConfigPedidoProdutosModal } from 'pages/compra/configuracao-pedido/components';
import { ConfiguracaoPedidoHeaderActions } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoHeaderActions';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';
import { useActionsProdutos } from 'pages/compra/configuracao-pedido/hooks';
import { ProdutosProps } from 'pages/compra/configuracao-pedido/types';

export function ConfigPedidoProdutosMain() {
    const { addConfigPedidoProdutos, removeConfigPedidoProdutos } = useActionsProdutos();
    const { openProdutoModal, setOpenProdutoModal, loja } = useConfigPedidoProdutosContext();
    const [valueFilter, setValueFilter] = useState<string>('');
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<ProdutosProps[]>([]);

    return (
        <>
            <ConfigPedidoProdutosModal
                open={openProdutoModal}
                setOpen={setOpenProdutoModal}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleAdicionarProdutos={() => addConfigPedidoProdutos(selectedProducts)}
            />

            <Block>
                <SubTitle label="Produtos" />

                <ConfiguracaoPedidoHeaderActions
                    addDisabled={Boolean(loja)}
                    removeDisabled={Boolean(loja)}
                    selectedRows={selectedRows}
                    setValueFilter={setValueFilter}
                    searchPlaceholder="Código, nome ou categoria do produto..."
                    onClickAdd={() => setOpenProdutoModal(true)}
                    onClickRemove={() => removeConfigPedidoProdutos(selectedRows)}
                />

                <ListagemProvider>
                    <ConfigPedidoProdutosGrid valueFilter={valueFilter} setSelectedRows={setSelectedRows} />
                </ListagemProvider>
            </Block>
        </>
    );
}
