import { useSearchParams } from 'react-router-dom';

import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import * as dateFns from 'date-fns';

import { TinyButton } from 'components/buttons';
import { useCobflexContext } from 'context/CobflexContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface EspelhoRoyaltyBoletoBtnProps {
    params: GridRenderCellParams<any, any, any>;
}

export function EspelhoRoyaltyBoletoBtn({ params }: EspelhoRoyaltyBoletoBtnProps): JSX.Element {
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const { useCobflex, openCobflex } = useCobflexContext();
    const { tpStatus, nrTitulo, nrParcela, boleto, dtVencimento: vencimento } = params.row;
    const dtVencimento = dateFns.format(new Date(vencimento), 'MM/dd/yyyy');
    const fgImpresso = params.row.fgImpresso;
    const usingCobflex = useCobflex && boleto?.fgCobflex;
    const color = usingCobflex || fgImpresso ? 'error' : 'success';
    const text = usingCobflex ? 'Cobflex' : fgImpresso ? 'Impresso' : 'Imprimir';
    const tooltipText = usingCobflex ? 'Sou optante pelo Cobflex' : '';

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/titulo/boleto',
        columns: 'idBoleto',
        nested: true,
        filter: [
            { property: 'cdLoja', operator: 'eq', value: cdLoja, logicalOperator: 'AND' },
            { property: 'dsStatus', operator: 'eq', value: 'VC', logicalOperator: 'AND' },
            { property: 'nrTitulo', operator: 'eq', value: nrTitulo, logicalOperator: 'AND' },
            { property: 'nrParcela', operator: 'eq', value: nrParcela, logicalOperator: 'AND' },
            { property: 'dtVencimento', operator: 'bt', value: [dtVencimento, dtVencimento], logicalOperator: 'AND' },
        ],
    };

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    /**
     * Abre o cobflex ou faz o download do arquivo.
     */
    const handleClick = () => {
        if (useCobflex) {
            openCobflex();
        } else {
            downloadFile();
        }
    };

    const disabled = !usingCobflex && (!boleto || tpStatus === 'PAGO' || isLoadingFile);

    return (
        <Tooltip title={tooltipText}>
            <span>
                <TinyButton variant="outlined" color={color} onClick={handleClick} disabled={disabled}>
                    {text}
                </TinyButton>
            </span>
        </Tooltip>
    );
}
