import { useParams } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { ConfigPedidoCondicoesPagamento } from 'pages/compra/configuracao-pedido/types';

export function useMutateCondicoesPagamento() {
    const { mutate, isLoading } = useFormMutation<AxiosResponse<ConfigPedidoCondicoesPagamento>>();
    const { setActiveStepSaved } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { showError } = useShowError();
    const { configPedidoCondicoesPagamento, setConfigPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { id } = useParams();

    /**
     * Valida se todas as lojas possuem ao menos uma condição de pagamento.
     */
    const validateLojas = () => {
        const { condicaoPagamentoLoja } = configPedidoCondicoesPagamento;
        const invalidLoja = condicaoPagamentoLoja.find((loja) => !loja.condicaoPagamentoList.length);

        if (invalidLoja) {
            enqueueSnackbar(
                <div>
                    A loja <b>{invalidLoja.loja.dsNomeFantasia}</b> deve possuir ao menos uma condição de pagamento
                </div>,
                {
                    variant: 'error',
                },
            );
        }

        return !invalidLoja;
    };

    /**
     * Salva as condições de pagamento.
     */
    const onSubmitForm = () => {
        if (!validateLojas()) {
            return;
        }

        mutate(
            {
                url: `/gestao/configpedidocondicaopagamentowrapper?idConfigPedido=${id}`,
                formData: configPedidoCondicoesPagamento,
                preventSnack: true,
                preventRedirect: true,
            },
            {
                onSuccess: ({ data }) => {
                    setConfigPedidoCondicoesPagamento(data);

                    setActiveStepSaved();

                    enqueueSnackbar('Condições de Pagamento salvas com sucesso', { variant: 'success' });
                },
                onError: (error: any) => {
                    showError(error, 'Erro ao salvar Condições de Pagamento');
                },
            },
        );
    };

    return { onSubmitForm, isLoading };
}
