import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { DownloadModeloArquivoBtn } from 'components/buttons';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { ConfiguracaoPedidoCompraImportacaoUploadBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraImportacaoUploadBtn';
import { useConfigPedidoProdutosContext } from 'pages/compra/configuracao-pedido/contexts/produtos/ConfigPedidoProdutosContext';

export function ConfigPedidoProdutosImport() {
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { setConfigPedidoProdutos, setLoja } = useConfigPedidoProdutosContext();
    const queryClient = useQueryClient();

    return (
        <Block>
            <SubTitle label="Importar produtos" />

            <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                <Grid item>
                    <ConfiguracaoPedidoCompraImportacaoUploadBtn
                        url="/gestao/configpedidoproduto/upload"
                        onSuccessUploadFile={(data) => {
                            setActiveStepDirty();

                            setConfigPedidoProdutos(data);
                        }}
                    />
                </Grid>

                <Grid item>
                    <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_PRODUTOS_CONFIG_PEDIDO" />
                </Grid>
            </Grid>

            <SubTitle sx={{ mt: 2 }} label="Importar descontos" />

            <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                <Grid item>
                    <ConfiguracaoPedidoCompraImportacaoUploadBtn
                        url="/gestao/configpedidoprodutoofertaloja/upload"
                        onSuccessUploadFile={() => {
                            setLoja(null);

                            queryClient.invalidateQueries(['queryProdutos']);
                        }}
                    />
                </Grid>

                <Grid item>
                    <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_CONFIG_PEDIDO_PRODUTO_OFERTA_LOJA" />
                </Grid>
            </Grid>
        </Block>
    );
}
