import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { ChromePicker } from 'react-color';
import * as Yup from 'yup';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormMutation } from 'mutations/useFormMutation';
import { EstiloFonte } from 'pages/predefinicoes-estilo/types';

export function PredefinicaoEstiloFormComp(): JSX.Element {
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const { id } = useParams();
    const { mutate } = useFormMutation();

    const handleSubmit = (values: EstiloFonte): void => {
        if (values.fonteTexto) {
            values.dsFonte = values.fonteTexto.dsFonteTextoFamilia;
        }

        mutate({ url: `/gestao/estilofonte/${id}`, method: 'PUT', formData: values });
    };

    const validationSchema = useMemo(
        () =>
            Yup.object({
                dsCor: Yup.string().required().nullable(),
                dsEstiloFonte: Yup.string().required(),
                dsFonte: Yup.string().required().nullable(),
                nrTamanho: Yup.number().required().nullable(),
                fonteTexto: Yup.object({
                    idFonteTexto: Yup.number().required(),
                    dsFonteTextoFamilia: Yup.string().required().nullable(),
                    dsFonteTextoFormato: Yup.string().required().nullable(),
                }).nullable(),
            }),
        [],
    );

    return (
        <FormPage
            mapContentToInitialValues={(content) => ({ ...content, fonteTexto: null })}
            title={'Predefinições de estilo'}
            values={undefined}
            onSubmit={(values) => handleSubmit(values)}
            url={'/gestao/estilofonte'}
            validationSchema={validationSchema}
        >
            {(formik: FormikProps<EstiloFonte>) => (
                <Block>
                    <Row>
                        <Grid item xs={12}>
                            <CissTextField
                                label="Nome da predefinição de estilo"
                                value={formik.values.dsEstiloFonte}
                                onChange={(e) => formik.setFieldValue('dsEstiloFonte', e.target.value)}
                                error={formik.errors.dsEstiloFonte}
                            />
                        </Grid>
                    </Row>
                    <Row>
                        <Grid item xs={12} lg={4}>
                            <CissAutoComplete
                                label="Fonte"
                                value={formik.values.fonteTexto}
                                requestOptions={{
                                    url: '/gestao/fontetexto',
                                    columns: 'idFonteTexto,dsFonteTextoFamilia,dsFonteTextoEstilo,dsFonteTextoFormato,lkFonteTexto',
                                }}
                                onChange={(e, value) => formik.setFieldValue('fonteTexto', value)}
                                isOptionEqualToValue={(option, value) => option.idFonteTexto === value.idFonteTexto}
                                optionStringTemplate="<strong>{idFonteTexto}</strong> - {dsFonteTextoFamilia}"
                                selectedStringTemplate="{idFonteTexto} - {dsFonteTextoFamilia}"
                                multiple={false}
                                error={formik.errors.fonteTexto}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <CissNumberField label="Tamanho" value={formik.values.nrTamanho} onChange={(e) => formik.setFieldValue('nrTamanho', e.target.value)} error={formik.errors.nrTamanho} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography>
                                <strong>Clique para selecionar uma cor padrão para a fonte:</strong>
                            </Typography>
                            <div onClick={() => setPickerVisible((oldState) => !oldState)} style={{ backgroundColor: formik.values.dsCor, width: 25, height: 25, borderRadius: '100%' }} />
                            {pickerVisible && (
                                <React.Fragment>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            top: '0px',
                                            right: '0px',
                                            bottom: '0px',
                                            left: '0px',
                                        }}
                                        onClick={() => setPickerVisible((oldState) => !oldState)}
                                    />
                                    <div style={{ position: 'absolute', zIndex: 2 }}>
                                        <ChromePicker color={formik.values.dsCor} onChange={({ hex }: any) => formik.setFieldValue('dsCor', hex)} />
                                    </div>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Row>
                </Block>
            )}
        </FormPage>
    );
}
