import React, { ComponentProps } from 'react';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

import { CissTextField } from 'components/fields/';

interface DatePickerMonthYearProps extends Omit<DatePickerProps<Date, Date>, 'views' | 'label' | 'renderInput'> {
    label?: string;
    error?: ComponentProps<typeof CissTextField>['error'];
}

/**
 * Formata o input do componente DatePicker para aceitar apenas valores válidos de mês e ano.
 * Por algum motivo, quando temos views={['month', 'year']}, pode digitar QUALQUER valor no input e não formata corretamente...
 * Esse método resolve isso.
 */
const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const regex = /^0?$|^1?$|^0[1-9]$|^1[0-2]$|^(0[0-9]|1[0-2])\/$|^(0[0-9]|1[0-2])\/\d{1,4}$/;

    let isValid = true;
    let newValue = '';

    for (let i = 0; i < inputValue.length; i++) {
        newValue += inputValue[i];

        if (!regex.test(newValue)) {
            isValid = false;
            newValue = newValue.slice(0, -1);
        }
    }

    event.target.value = isValid ? inputValue : newValue;
};

/**
 * Componente data Mes/Ano com props pré-definidas
 */
export function DatePickerMonthYear({ label = 'Mês/Ano', error, ...props }: DatePickerMonthYearProps): JSX.Element {
    return (
        <DatePicker
            views={['year', 'month']}
            label={label}
            inputFormat="MM/yyyy"
            renderInput={(params) => <CissTextField {...params} autoComplete="off" error={error} onInput={handleInputChange} />}
            {...props}
        />
    );
}
