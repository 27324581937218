import { Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { useQueryUsuarios } from 'pages/cadastro/permissoes/hooks';
import { UsuariosProps } from 'pages/cadastro/permissoes/types';

type UsuariosModalGrid = {
    query: string;
    usuariosModal: UsuariosProps[];
    setUsuariosModal: Dispatch<SetStateAction<UsuariosProps[]>>;
};

/**
 * Retorna um array de objetos com os dados dos usuários.
 */
const getRows = (data: UsuariosProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idUsuario,
            idUsuario: item.idUsuario,
            dsUsuario: item.dsUsuario,
        }));
    }

    return [];
};

/**
 * Colunas da tabela de usuários.
 */
const columns: GridColDef[] = [
    { field: 'idUsuario', headerName: 'Código', width: 80 },
    { field: 'dsUsuario', headerName: 'Usuário', flex: 1, minWidth: 100 },
];

export function UsuariosModalGrid({ query, usuariosModal, setUsuariosModal }: UsuariosModalGrid) {
    const { isFetching, data, refetch } = useQueryUsuarios({ query });

    /**
     * Ao clicar numa linha, adiciona ou remove o usuário da lista de usuários selecionados.
     */
    const onClickRow = (selection: GridSelectionModel) => {
        const added = selection.length > usuariosModal.length;

        if (added) {
            const newUsuario = data?.data.find((item) => item.idUsuario === selection.at(-1));

            if (newUsuario) {
                setUsuariosModal((oldUsuariosModal) => [...oldUsuariosModal, newUsuario]);
            }
        } else {
            setUsuariosModal((oldUsuariosModal) => oldUsuariosModal.filter((item) => selection.includes(item.idUsuario)));
        }
    };

    return (
        <Box sx={{ height: 500 }}>
            <PanelGrid
                checkboxSelection
                keepNonExistentRowsSelected
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                reload={refetch}
                columns={columns}
                autoHeight={false}
                loading={isFetching}
                selectionModel={usuariosModal.map(({ idUsuario }) => idUsuario)}
                onClickRow={onClickRow}
            />
        </Box>
    );
}
