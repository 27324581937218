import { useEffect } from 'react';

import { AxiosResponse } from 'axios';

import { Pagamento } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';

export function useQueryPagamento() {
    const { idPedido, setPagamento, setPagamentoRequestError } = usePagamentoContext();
    const { mutate, isLoading: isLoadingPagamento } = useFormMutation<{ data: Pagamento }, AxiosResponse<{ message: string }>>();

    /**
     * Realiza um post para retornar os dados necessários do pagamento. <br>
     * É um post por questões de backend, portanto foi utilizado um mutate.
     */
    const mutatePagamento = () => {
        if (!idPedido) {
            return;
        }

        mutate(
            {
                url: `/compra/pedidocompra/dados/telapagamento?idPedido=${idPedido}`,
                preventSnack: true,
            },
            {
                onSuccess: (data) => setPagamento(data.data),
                onError: (data) => setPagamentoRequestError(data.data.message),
            },
        );
    };

    useEffect(mutatePagamento, [idPedido]);

    return { isLoadingPagamento };
}
