import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { GatewayPagamentoAutoComplete, MeioPagamentoAutoComplete } from 'components/autocompletes';
import { CissNumberFieldV2, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';

export function CondicaoPagamentoFormMain() {
    const { errors, setFieldValue, values } = useFormikContext<CondicaoPagamento>();

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <CissTextField
                        label="Descrição"
                        value={values.dsCondicaoPagamento}
                        error={errors.dsCondicaoPagamento}
                        onChange={(e) => setFieldValue('dsCondicaoPagamento', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Código de integração"
                        value={values.cdCondicaoPagamento}
                        error={errors.cdCondicaoPagamento}
                        onChange={(e) => setFieldValue('cdCondicaoPagamento', e.target.value)}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item flex={1}>
                    <GatewayPagamentoAutoComplete
                        value={values.gatewayPagamento}
                        error={errors.gatewayPagamento}
                        onChange={(e, value) => {
                            setFieldValue('gatewayPagamento', value);

                            setFieldValue('meioPagamento', null);
                        }}
                    />
                </Grid>

                <Grid item flex={1}>
                    <MeioPagamentoAutoComplete
                        value={values.meioPagamento}
                        error={errors.meioPagamento}
                        disabled={!values.gatewayPagamento}
                        onChange={(e, value) => {
                            setFieldValue('meioPagamento', value);

                            setFieldValue('nrParcela', 1);
                        }}
                        requestOptions={{
                            url: '/gestao/meiopagamento/gatewaypagamento',
                            limit: 50,
                            sort: [{ property: 'idMeioPagamento', direction: 'ASC' }],
                            filter: [
                                { property: 'fgAtivo', operator: 'eq', value: true },
                                { property: 'idGatewayPagamento', operator: 'eq', value: values.gatewayPagamento?.idGatewayPagamento },
                            ],
                        }}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberFieldV2
                        label="Número de parcelas"
                        value={values.nrParcela}
                        error={errors.nrParcela}
                        maxValue={values.meioPagamento?.nrMaximoParcelas}
                        disabled={!values.meioPagamento || values.meioPagamento?.nrMaximoParcelas === 1}
                        onChange={(value) => setFieldValue('nrParcela', value)}
                    />
                </Grid>
            </Row>
        </Block>
    );
}
