import React from 'react';

import { Alert, Grid } from '@mui/material';

import { ConfirmModal } from 'components/modal';
import { Block, OneColumn } from 'components/page';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import {
    PedidoCompraCartAside,
    PedidoCompraListBbar,
    PedidoCarrinhoStack,
    PedidoGradeText,
    PedidoCompraListContainer,
    PedidoCompraUsuarioInfo,
    PedidoCompraListFilter,
    PedidoCompraListFuncionarioFilter,
} from 'pages/compra/pedido/components';
import { useGetProdutos, useGetTotalizador } from 'pages/compra/pedido/hooks';
import { useGetCarrinho } from 'pages/compra/pedido/hooks/useGetCarrinho';
import { getErrorMessage } from 'util/error';

export function PedidoCompraListComp(): JSX.Element {
    const { isDownMd, isDownLg } = useBreakpoints();
    const { loja, configPedido, isOpenInfoModal, setIsOpenInfoModal, dataInfoModal, isOpenMultiploModal, setIsOpenMultiploModal } =
        usePedidoCompraContext();
    const { tipoPedido } = useGetDomainConfig();
    const { isFetching: isFetchingTotalizador } = useGetTotalizador();
    const { isFetching: isFetchingCart } = useGetCarrinho();
    const { fetchStatus, data, error } = useGetProdutos();
    const [errorContent] = getErrorMessage(error);
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    return (
        <>
            <ConfirmModal
                title="Aviso"
                confirmText="Ok"
                showCancel={false}
                open={isOpenInfoModal}
                setOpen={setIsOpenInfoModal}
                text={dataInfoModal}
                confirm={() => setIsOpenInfoModal(false)}
            />

            <ConfirmModal
                title="Aviso"
                text={'A quantidade informada é igual ou superior a 10 múltiplos do produto'}
                confirmText="Ok"
                showCancel={false}
                open={isOpenMultiploModal}
                setOpen={setIsOpenMultiploModal}
                confirm={() => setIsOpenMultiploModal(false)}
            />

            <OneColumn title="Novo Pedido" listType={!isDownMd} extraContent={isTipoPedidoPadrao && <PedidoGradeText />}>
                {isTipoPedidoFuncionario && (
                    <>
                        <PedidoCompraUsuarioInfo />

                        <PedidoCompraListFuncionarioFilter />
                    </>
                )}

                {isTipoPedidoPadrao && <PedidoCompraListFilter />}

                <Grid container columnSpacing={2} mt={2} id="listContainerProdutos">
                    <Grid item xs={12} lg={9}>
                        {configPedido && (
                            <>
                                {errorContent && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {errorContent}
                                    </Alert>
                                )}

                                <PedidoCompraListContainer data={data} fetchStatus={fetchStatus} />
                            </>
                        )}

                        {!configPedido && (
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Selecione um filtro para iniciar.
                            </Alert>
                        )}
                    </Grid>

                    {!isDownLg && (
                        <Grid item lg={3}>
                            <Block sx={{ mt: 0 }}>
                                <PedidoCarrinhoStack isFetching={isFetchingTotalizador} />
                            </Block>

                            {configPedido && loja && <PedidoCompraCartAside isFetching={isFetchingCart} />}
                        </Grid>
                    )}
                </Grid>

                {configPedido && loja && <PedidoCompraListBbar data={data} isFetching={isFetchingTotalizador} />}
            </OneColumn>
        </>
    );
}
