import { useState, MouseEvent } from 'react';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconButton, Menu, Stack } from '@mui/material';

import { StatusPedido } from 'components/grid/columns/StatusPedidoColumn';
import { ConfirmModal } from 'components/modal';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import {
    PedidoRealizadoLogsBtn,
    PedidoRealizadoDeleteBtn,
    PedidoRealizadoCopyDocumentBtn,
    PedidoRealizadoAproveBtn,
    PedidoRealizadoReprocessBtn,
    PedidoRealizadoCloneBtn,
    PedidoRealizadoGradeBtn,
    PedidoRealizadoTrackBtn,
    PedidoRealizadoReactiveBtn,
    PedidoRealizadoPixBtn,
    PedidoRealizadoPaymentBtn,
} from 'pages/compra/pedido-realizado/components';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';

export const getDialogGradeError = (error: any): string | false => {
    const { data } = error;
    const { errors } = data;
    const firstError = errors.at(0);

    return firstError?.codes && firstError.codes.pop(-1) === 'Dialog' ? firstError.message : false;
};

const pedidoStatusEnumExcluir: StatusPedido[] = [
    'PENDENTE_APROVACAO',
    'PENDENTE_GRADE',
    'PENDENTE_SINCRONIZACAO',
    'CANCELADO_SAP',
    'CANCELADO_EXTERNO',
    'ERRO_SINCRONIZACAO',
];

const pedidoStatusEnumReprocessar = ['ERRO_SINCRONIZACAO', 'PENDENTE_SINCRONIZACAO', 'PENDENTE_GRADE'];

export function PedidoRealizadoActionMenu() {
    const { row, setOpenConfirmModal, openConfirmModal, dataConfirmModal } = useActionMenu();
    const { usuario } = useAplicacaoDataContext();
    const { hasPermission } = usePermissionsContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const { pedidoStatus, fgAtivo, dsUrlTrakingPedido, pedidoPagamentos, usuarioPedido } = row;
    const { pedidoStatusEnum } = pedidoStatus;
    const { idUsuario } = usuarioPedido || {};
    const validateExclusaoTotalizador = hasPermission(['COMPRA_PEDIDO_LISTAGEM_EXCLUIR_TOTALIZADOR']) && pedidoStatusEnum === 'TOTALIZADOR';
    const validateExclusaoStatusPedido = hasPermission(['COMPRA_PEDIDO_LISTAGEM_EXCLUIR']) && pedidoStatusEnumExcluir.includes(pedidoStatusEnum);
    const isUsuarioLogged = idUsuario === usuario?.idUsuario;
    const isPagamentoPix = pedidoPagamentos?.at(-1)?.condicaoPagamento?.meioPagamento?.meioPagamentoEnum === 'PIX';

    // Validações para exibir os botões
    const showCloneBtn =
        fgAtivo &&
        hasPermission(['COMPRA_PEDIDO_LISTAGEM_CLONAR']) &&
        (isTipoPedidoPadrao || isUsuarioLogged || (isTipoPedidoFuncionario && hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL'])));
    const showAproveBtn = fgAtivo && pedidoStatusEnum === 'PENDENTE_APROVACAO' && hasPermission(['COMPRA_PEDIDO_LISTAGEM_APROVAR']);
    const showGradeBtn = fgAtivo && pedidoStatusEnum === 'PENDENTE_GRADE' && hasPermission(['COMPRA_PEDIDO_LISTAGEM_GRADE']);
    const showDeleteBtn = fgAtivo && (validateExclusaoStatusPedido || validateExclusaoTotalizador);
    const showReprocessBtn =
        fgAtivo && pedidoStatusEnumReprocessar.includes(pedidoStatusEnum) && hasPermission(['COMPRA_PEDIDO_LISTAGEM_SINCRONIZAR']);
    const showReactiveBtn = !fgAtivo && hasPermission(['COMPRA_PEDIDO_REATIVAR_PEDIDO_EXCLUIDO']);
    const showPixBtn = isPagamentoPix && pedidoStatusEnum === 'AGUARDANDO_CONFIRMACAO_PAGAMENTO';
    const showPaymentBtn = pedidoStatusEnum === 'FALHA_PAGAMENTO' && hasPermission(['COMPRA_PEDIDO_FINALIZAR']);

    // Constantes relacionadas ao menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => setAnchorEl(event.currentTarget);
    const handleClose = (): void => setAnchorEl(null);

    return (
        <>
            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={dataConfirmModal?.callback} text={dataConfirmModal?.text} />

            <IconButton onClick={handleClick}>
                <MenuRoundedIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <Stack
                    sx={{
                        '& .MuiButtonBase-root': {
                            textTransform: 'none',
                            justifyContent: 'flex-start',
                            borderRadius: 0,
                            fontWeight: 400,
                            py: 1,
                            px: 2,
                            minWidth: 100,
                        },
                    }}
                >
                    {showAproveBtn && <PedidoRealizadoAproveBtn />}

                    {showGradeBtn && <PedidoRealizadoGradeBtn />}

                    {showCloneBtn && <PedidoRealizadoCloneBtn />}

                    {hasPermission(['COMPRA_PEDIDO_COPIAR_DADOS_PEDIDOS']) && <PedidoRealizadoCopyDocumentBtn />}

                    {showDeleteBtn && <PedidoRealizadoDeleteBtn />}

                    {hasPermission(['COMPRA_PEDIDO_ACESSAR_LOGS']) && <PedidoRealizadoLogsBtn />}

                    {showReprocessBtn && <PedidoRealizadoReprocessBtn />}

                    {dsUrlTrakingPedido && <PedidoRealizadoTrackBtn />}

                    {showReactiveBtn && <PedidoRealizadoReactiveBtn />}

                    {showPixBtn && <PedidoRealizadoPixBtn />}

                    {showPaymentBtn && <PedidoRealizadoPaymentBtn />}
                </Stack>
            </Menu>
        </>
    );
}
