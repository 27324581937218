import { useQuery } from '@tanstack/react-query';

import { CondicaoPagamento } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestListagem, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useQueryCondicaoPagamento() {
    const requestListagem = useRequestListagem();
    const { page, limit, sort, filter } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/condicaopagamento',
        sort: sort.length ? sort : [{ property: 'idCondicaoPagamento', direction: 'DESC' }],
        nested: true,
        filter: [...filter, { property: 'fgAtivo', value: true, operator: 'eq' }],
        page,
        limit,
    };

    return useQuery<RequestListagem<CondicaoPagamento[]>>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data));
}
