import React, { ReactNode } from 'react';

import { Container, ContainerProps } from '@mui/material';

import { Tbar } from 'components/docked';
import { TbarProps } from 'components/docked/Tbar';
import { useDomainConfigContext } from 'context/DomainConfig';
import { useTitle } from 'hooks/useTitle';

interface OneColumnProps extends TbarProps {
    children: ReactNode;
    maxWidth?: ContainerProps['maxWidth'];
}

/**
 * Estrutura padrão do sistema para página do tipo 1 coluna (oneColumn) (sem aside)
 * Contém header + titulo + conteudo + footer
 */
export function OneColumn({ children, title, maxWidth = 'xl', ...tbarProps }: OneColumnProps): JSX.Element {
    const { data } = useDomainConfigContext();
    const { documentTitle } = data;

    useTitle({ documentTitle, pageTitle: title });

    return (
        <Container
            component="main"
            maxWidth={maxWidth}
            disableGutters
            sx={{
                my: 2,
                px: 2,
            }}
        >
            <Tbar title={title} {...tbarProps} />

            {children}
        </Container>
    );
}
