import React, { useState, useMemo, useCallback, useEffect } from 'react';

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { LoadingButton } from '@mui/lab';
import { IconButton, Chip, Button, Typography, Stack, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

import { DownloadModeloArquivoBtn } from 'components/buttons';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

export interface StoreOptionsProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

export type StoreAutocompleteProps = Pick<
    CissAutoCompleteProps,
    | 'requestOptions'
    | 'value'
    | 'error'
    | 'event'
    | 'multiple'
    | 'disabled'
    | 'sx'
    | 'onChange'
    | 'label'
    | 'options'
    | 'name'
    | 'size'
    | 'selectedStringTemplate'
    | 'optionStringTemplate'
    | 'isOptionEqualToValue'
> & {
    defaultStore?: boolean;
    importStores?: boolean;
    flagInstitucional?: boolean;
};

const tipoLoja = {
    PROPRIA: 1,
    FRANQUIA: 2,
    LOJINHA: 3,
    INSTITUCIONAL: 4,
    INSTITUCIONAL_CLIENTE_FINAL: 5,
    PERMUTA: 6,
    INSTITUCIONAL_S_MULTI: 7,
    GRUPO_FIT: 8,

} as const;

export function StoreAutoComplete({
    sx,
    name,
    size,
    value,
    error,
    event,
    options,
    selectedStringTemplate = '{cdLoja} - {dsNomeFantasia}',
    optionStringTemplate = '<strong>{cdLoja}</strong> - {dsNomeFantasia}',
    label = 'Loja',
    multiple = false,
    disabled = false,
    defaultStore = true,
    importStores = true,
    flagInstitucional = false,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/loja',
        sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
        columns: 'idLoja,cdLoja,dsNomeFantasia,dsRazaoSocial',
        limit: 50,
    },
    isOptionEqualToValue = (option: StoreOptionsProps, value: StoreOptionsProps) => value && option && option.idLoja === value.idLoja,
}: StoreAutocompleteProps): JSX.Element {
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [fileLojas, setFileLojas] = useState<File | null>(null);
    const [showHiddenTags, setShowHiddenTags] = useState(false);
    const [stores, setStores] = useState<StoreOptionsProps[]>([]);
    const { formattedLoja } = useAplicacaoDataContext();
    const { hasPermission } = usePermissionsContext();
    const { enqueueSnackbar } = useSnackbar();
    const [lojaInstitucional, setLojaInstitucional] = useState<boolean>(false);

    useEffect(() => {
        if (defaultStore && formattedLoja) {
            multiple ? onChange(event, [formattedLoja]) : onChange(event, formattedLoja);
        }
    }, [formattedLoja, defaultStore]);

    const setImportedStores = useCallback(
        (lojas: StoreOptionsProps[]) => {
            const newLojas = lojas.filter((loja) => !stores.some((currentStore: StoreOptionsProps) => currentStore.cdLoja === loja.cdLoja));
            const newStores = [...stores, ...newLojas];
            setStores(newStores);
            onChange(event, newStores);
        },
        [stores],
    );

    const { mutate, isLoading: isLoadingLoja } = useUploadFileMutation({
        onSuccess: (response: any) => {
            const { lojas, lojasNaoEncontradas } = response.data;

            const filteredLojas = lojas.map((loja: any) => ({
                idLoja: loja.idLoja,
                dsRazaoSocial: loja.dsRazaoSocial,
                dsNomeFantasia: loja.dsNomeFantasia,
                cdLoja: loja.cdLoja,
            }));

            enqueueSnackbar('Importação de lojas realizada com sucesso', { variant: 'success' });

            if (filteredLojas.length > 0) {
                setImportedStores(filteredLojas);
            }

            if (lojasNaoEncontradas.length > 0) {
                enqueueSnackbar(`Lojas não encontradas: ${lojasNaoEncontradas.join(', ')}`, { variant: 'warning' });
            }

            setImportModalOpen(false);
            setFileLojas(null);
        },
        onError: () => enqueueSnackbar('Falha ao importar as lojas', { variant: 'error' }),
    });

    const onClickImportarLojas = useCallback(() => {
        const formData = new FormData();

        if (fileLojas) {
            formData.append('file', fileLojas);
        }

        mutate({
            url: '/gestao/loja/arquivo',
            formData,
        });
    }, [fileLojas, mutate]);

    const renderCsvImportModal = useMemo((): JSX.Element => {
        return (
            <ContentModal
                open={importModalOpen}
                setOpen={setImportModalOpen}
                closeButton
                sx={{
                    width: 400,
                    borderRadius: 1,
                }}
            >
                <Stack>
                    <SubTitle label="Importar Lojas" />

                    <UploadButton label="Selecionar Arquivo" accept={['.csv']} onChange={(file) => setFileLojas(file)} />

                    {fileLojas && (
                        <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                            {fileLojas.name}
                        </Typography>
                    )}

                    <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                        *São aceitos somente arquivos em formato .csv
                    </Typography>

                    <Stack direction="row">
                        <DownloadModeloArquivoBtn sx={{ flex: 1 }} dsChave="URL_ARQUIVO_MODELO_LOJA" />
                    </Stack>

                    <Stack
                        spacing={2}
                        justifyContent="flex-end"
                        direction="row"
                        sx={{
                            mt: 3,
                        }}
                    >
                        <Button variant="outlined" onClick={() => setImportModalOpen(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" onClick={onClickImportarLojas} disabled={!fileLojas} loading={isLoadingLoja}>
                            Importar Lojas
                        </LoadingButton>
                    </Stack>
                </Stack>
            </ContentModal>
        );
    }, [fileLojas, importModalOpen, isLoadingLoja, onClickImportarLojas]);

    const renderLimitedTags = (tags: StoreOptionsProps[], getTagProps: any): any => {
        if (showHiddenTags) {
            return (
                <React.Fragment>
                    {tags.map((tag, index) => (
                        <Chip key={`${tag.cdLoja}-${tag.idLoja}-${index}`} label={tag.dsNomeFantasia} {...getTagProps({ index })} />
                    ))}
                    {tags.length > 1 && <Chip label={'▲ Ocultar Lojas'} onClick={() => setShowHiddenTags(!showHiddenTags)} color="primary" />}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {tags.slice(0, 1).map((tag, index) => (
                    <Chip key={`${tag.cdLoja}-${tag.idLoja}-1-${index}`} label={tag.dsNomeFantasia} {...getTagProps({ index })} />
                ))}
                {tags.length > 1 && <Chip label={`+${tags.length - 1}`} onClick={() => setShowHiddenTags(true)} />}
            </React.Fragment>
        );
    };

    if (requestOptions && flagInstitucional) {
        const newFilter: FilterValuesProps[] = requestOptions.filter ? requestOptions.filter.filter((item) => item.property !== 'idTipoLoja') : [];

        newFilter.push({
            property: 'idTipoLoja',
            operator: 'in',
            value: lojaInstitucional
                ? [tipoLoja.INSTITUCIONAL, tipoLoja.INSTITUCIONAL_CLIENTE_FINAL, tipoLoja.PERMUTA, tipoLoja.INSTITUCIONAL_S_MULTI]
                : [tipoLoja.FRANQUIA, tipoLoja.PROPRIA, tipoLoja.LOJINHA, tipoLoja.GRUPO_FIT],
        });

        requestOptions.filter = newFilter;
    }

    return (
        <Stack direction={{ xs: 'column', lg: 'row' }} sx={{ rowGap: 1, ...sx }}>
            {renderCsvImportModal}

            {hasPermission(['COMPRA_PEDIDO_ACESSA_LOJA_INSTITUCIONAL']) && flagInstitucional && (
                <FormControlLabel
                    sx={{ flex: 0 }}
                    label="Lojas Institucionais"
                    control={<Checkbox />}
                    value={lojaInstitucional}
                    onChange={(e, value) => {
                        setLojaInstitucional(value);

                        multiple ? onChange(event, []) : onChange(event, null);
                    }}
                />
            )}

            <Box sx={{ display: 'flex', flex: 1 }}>
                <CissAutoComplete
                    size={size}
                    name={name}
                    label={label}
                    value={value}
                    error={error}
                    objectId="idLoja"
                    sx={{ flex: 1 }}
                    options={options}
                    onChange={onChange}
                    multiple={multiple}
                    disabled={disabled}
                    renderTags={renderLimitedTags}
                    requestOptions={requestOptions}
                    optionStringTemplate={optionStringTemplate}
                    selectedStringTemplate={selectedStringTemplate}
                    isOptionEqualToValue={isOptionEqualToValue}
                />

                {multiple && importStores && (
                    <IconButton onClick={() => setImportModalOpen(true)} sx={{ width: 50 }} color="primary">
                        <UploadFileOutlinedIcon />
                    </IconButton>
                )}
            </Box>
        </Stack>
    );
}
