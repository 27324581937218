import React, { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, Filter, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CissTextField } from 'components/fields';
import { FilterValuesProps, useFilter } from 'hooks/useFilter';

interface FonteProps {
    idFonteTexto: number;
    dsFonteTextoFamilia: string;
    dsFonteTextoFormato: string;
}

export function FontesListComp(): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { limit, page, filter } = useListagemContext();
    const { handleFilterReset, handleFilterSubmit } = useFilter();

    const [filterFonte, setFilterFonte] = useState<FilterValuesProps>({
        property: 'dsFonteTextoFamilia',
        operator: 'like',
        value: '',
    });
    const filters = [{ value: filterFonte, setter: setFilterFonte }];

    const url = '/gestao/fontetexto';
    const requestOptions: RequestOptionsType = {
        columns: 'idFonteTexto,dsFonteTextoFamilia,dsFonteTextoEstilo,dsFonteTextoFormato,lkFonteTexto',
        url,
        limit,
        page,
        filter,
    };

    const makeRows = (arr: FonteProps[] = []): any => {
        return arr.map((fonte) => {
            return {
                id: fonte.idFonteTexto,
                ...fonte,
            };
        });
    };

    const { data, fetchStatus, refetch } = useQuery([requestOptions], () => RequestListagem(requestOptions).then((data) => data.data));
    const rows = makeRows(data?.data || []);
    const rowsCount = data?.total || 0;

    const columns: GridColDef[] = [
        { field: 'idFonteTexto', headerName: 'Id', type: 'number', width: 110 },
        { field: 'dsFonteTextoFamilia', headerName: 'Família de fonte', type: 'string', flex: 1 },
        { field: 'dsFonteTextoFormato', headerName: 'Formato', type: 'string', width: 100 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: ({ row }) => <EditRemoveBtn id={row.idFonteTexto} refresh={refetch} showRemoveBtn={true} showEditBtn={false} removeUrl={`${url}${row.id}`} sx={{ ml: 'auto', p: 1.5 }} />,
        },
    ];

    return (
        <OneColumn title="Fontes" goBackButton createButton>
            <Block>
                <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
                    <CissTextField sx={{ ml: 2, mt: 2 }} label={'Nome da fonte'} value={filterFonte.value} onChange={(e) => setFilterFonte((oldValue) => ({ ...oldValue, value: e.target.value }))} />
                </Filter>
            </Block>
            <Block>
                <PanelGrid rows={rows} rowsCount={rowsCount} columns={columns} loading={fetchStatus === 'fetching'} reload={refetch} />
            </Block>
        </OneColumn>
    );
}
