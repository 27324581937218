import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Box, Typography } from '@mui/material';

import { Block } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { DreReportBtn } from 'pages/financeiro/dre/components';

export function DreReport(): JSX.Element {
    const { hasPermission } = usePermissionsContext();

    return (
        <Block>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 1, md: 2 },
                }}
            >
                <Box sx={{ display: 'flex', mr: 'auto' }}>
                    <Typography sx={{ mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                    <ChevronRightOutlinedIcon color="primary" />
                </Box>

                {hasPermission(['FINANCEIRO_DRE_SINTETICA_VISUALIZAR']) && (
                    <DreReportBtn text="Estimativa de Resultado Sintético" pathname="relatorio/sintetico" color="info" />
                )}

                {hasPermission(['FINANCEIRO_DRE_ANALITICA_VISUALIZAR']) && (
                    <DreReportBtn text="Estimativa de Resultado Analítico" pathname="relatorio/analitico" color="success" />
                )}
            </Box>
        </Block>
    );
}
