import React, { useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { CentroDistribuicaoAutoComplete, DepositoAutoComplete, GrupoAutoComplete, SecaoAutoComplete, StatusImagemAutoComplete, SubgrupoAutoComplete } from 'components/autocompletes';
import { CentroDistribuicaoProps } from 'components/autocompletes/CentroDistribuicaoAutoComplete';
import { DepositoProps } from 'components/autocompletes/DepositoAutoComplete';
import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export interface SecaoProps {
    idSecao: number;
    cdSecao: number;
    dsSecao: string;
}

export interface GrupoProps {
    idGrupo: number;
    dsGrupo: string;
    dsSecao: string;
}

export function ProdutoGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();
    const hasPermissionProdutoAlterar = hasPermission(['COMPRA_PRODUTO_ALTERAR']);
    const colSpanFirstRow = hasPermissionProdutoAlterar ? 3 : 6;
    const colSpanSecondRow = hasPermissionProdutoAlterar ? 3 : 4;

    const [filterCdProduto, setFilterCdProduto] = useState<FilterValuesProps>({
        property: 'cdProduto',
        operator: 'eq',
        value: '',
        logicalOperator: 'OR',
    });

    const [filterDsProduto, setFilterDsProduto] = useState<FilterValuesProps>({
        property: 'dsProduto',
        operator: 'like',
        value: '',
        logicalOperator: 'OR',
    });

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'idGrupo',
        operator: 'in',
        value: [],
        idProperty: 'idGrupo',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermissionProdutoAlterar ? null : true,
        idProperty: 'vlStatus',
    });

    const [filterSegmento, setFilterSegmento] = useState<FilterValuesProps>({
        property: 'idSecao',
        operator: 'in',
        value: [],
        idProperty: 'idSecao',
    });

    const [filterJornada, setFilterJornada] = useState<FilterValuesProps>({
        property: 'idSubgrupo',
        operator: 'in',
        value: [],
        idProperty: 'idSubgrupo',
    });

    const [filterCentroDistribuicao, setFilterCentroDistribuicao] = useState<FilterValuesProps>({
        property: 'idCentroDistribuicao',
        operator: 'eq',
        value: null,
        idProperty: 'idCentroDistribuicao',
        required: 'Centro de Distribuição é obrigatório',
    });

    const [filterDeposito, setFilterDeposito] = useState<FilterValuesProps>({
        property: 'idDeposito',
        operator: 'eq',
        value: null,
        idProperty: 'idDeposito',
        required: 'Depósito é obrigatório',
    });

    const [filterImagens, setFilterImagens] = useState<FilterValuesProps>({
        property: 'fgImagem',
        operator: 'eq',
        value: null,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterCdProduto, setter: setFilterCdProduto },
        { value: filterDsProduto, setter: setFilterDsProduto },
        { value: filterSegmento, setter: setFilterSegmento },
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterJornada, setter: setFilterJornada },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterImagens, setter: setFilterImagens },
    ];

    if (hasPermissionProdutoAlterar) {
        filters.push({ value: filterCentroDistribuicao, setter: setFilterCentroDistribuicao }, { value: filterDeposito, setter: setFilterDeposito });
    }

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    const getFiltersJornada = useMemo((): FilterValuesProps[] | undefined => {
        if (filterCategoria.value.length > 0) {
            return [{ property: 'idGrupo', value: filterCategoria.value.map((grupo: GrupoProps) => grupo.idGrupo), operator: 'in' }];
        } else if (filterSegmento.value.length > 0) {
            return [{ property: 'idSecao', value: filterSegmento.value.map((segmento: SecaoProps) => segmento.idSecao), operator: 'in' }];
        } else {
            return undefined;
        }
    }, [filterCategoria.value, filterSegmento.value]);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={colSpanFirstRow}>
                <CissTextField
                    label="Produto"
                    placeholder="Nome ou código do produto..."
                    value={filterDsProduto.value}
                    onChange={(e) => {
                        setFilterCdProduto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
                        setFilterDsProduto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
                    }}
                />
            </Grid>

            <Grid item lg={colSpanFirstRow}>
                <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            {hasPermissionProdutoAlterar && (
                <React.Fragment>
                    <Grid item lg={colSpanFirstRow}>
                        <StatusImagemAutoComplete value={filterImagens.value} onChange={(e, value) => setFilterImagens((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                    </Grid>

                    <Grid item lg={colSpanFirstRow}>
                        <CentroDistribuicaoAutoComplete
                            value={filterCentroDistribuicao.value}
                            onChange={(e, value: CentroDistribuicaoProps) =>
                                setFilterCentroDistribuicao((oldState: FilterValuesProps) => ({
                                    ...oldState,
                                    value: value && { idCentroDistribuicao: value.idCentroDistribuicao, dsCentroDistribuicao: value.dsCentroDistribuicao },
                                }))
                            }
                        />
                    </Grid>

                    <Grid item lg={colSpanSecondRow}>
                        <DepositoAutoComplete
                            value={filterDeposito.value}
                            onChange={(e, value: DepositoProps) =>
                                setFilterDeposito((oldState: FilterValuesProps) => ({
                                    ...oldState,
                                    value: value && { idDeposito: value.idDeposito, dsDeposito: value.dsDeposito },
                                }))
                            }
                        />
                    </Grid>
                </React.Fragment>
            )}

            <Grid item lg={colSpanSecondRow}>
                <SecaoAutoComplete
                    label="Segmento"
                    multiple={true}
                    value={filterSegmento.value}
                    onChange={(e, value: SecaoProps[]) => {
                        if ((value.length < filterSegmento.value.length && value.length !== 0) || filterSegmento.value.length === 0) {
                            setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                            setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }

                        setFilterSegmento((oldState: FilterValuesProps) => ({ ...oldState, value: value.map((item) => ({ idSecao: item.idSecao, dsSecao: item.dsSecao })) }));
                    }}
                />
            </Grid>

            <Grid item lg={colSpanSecondRow}>
                <GrupoAutoComplete
                    label="Categoria"
                    multiple={true}
                    value={filterCategoria.value}
                    onChange={(e, value) => {
                        if ((value.length < filterCategoria.value.length && value.length !== 0) || filterCategoria.value.length === 0) {
                            setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }

                        setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value }));
                    }}
                    requestOptions={{
                        url: '/gestao/grupo',
                        sort: [{ property: 'dsGrupo', direction: 'ASC' }],
                        filter: filterSegmento.value.length > 0 ? [{ property: 'idSecao', value: filterSegmento.value.map((segmento: SecaoProps) => segmento.idSecao), operator: 'in' }] : undefined,
                        columns: 'idGrupo,dsGrupo,dsSecao',
                        limit: 100,
                    }}
                />
            </Grid>

            <Grid item lg={colSpanSecondRow}>
                <SubgrupoAutoComplete
                    multiple={true}
                    label="Jornada"
                    value={filterJornada.value}
                    onChange={(e, value) => setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: '/gestao/subgrupo',
                        sort: [{ property: 'dsSubgrupo', direction: 'ASC' }],
                        filter: getFiltersJornada,
                        columns: 'idSubgrupo,dsSubgrupo,dsGrupo,dsSecao',
                        limit: 100,
                    }}
                />
            </Grid>
        </Filter>
    );
}
