import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { CarrinhoListComp } from 'pages/compra/carrinho/components';
import { CarrinhoProvider } from 'pages/compra/carrinho/contexts';

export function CarrinhoList() {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_VISUALIZAR'])) return alertNotPermission();

    return (
        <CarrinhoProvider>
            <ListagemProvider>
                <CarrinhoListComp />
            </ListagemProvider>
        </CarrinhoProvider>
    );
}
