import React, { useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';

import { ConfirmModal } from 'components/modal';

interface RemoveBtnProps {
    onClick: () => void;
    disabled?: boolean;
}

export function ConfiguracaoPedidoCompraRemoveBtn({ onClick = () => {}, disabled }: RemoveBtnProps): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    return (
        <>
            <Box>
                <Tooltip title="Remover">
                    <span>
                        <IconButton sx={{ p: 0, ml: 0 }} disabled={disabled} onClick={() => setOpenConfirmModal(true)}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>

            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={() => onClick()} />
        </>
    );
}
