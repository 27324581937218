import { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';

import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { CarrinhoActionColumn, CarrinhoSituacaoColumn } from 'pages/compra/carrinho/components';
import { brlPrice } from 'util/format';

export function useColumnsGrid() {
    const { hasPermission } = usePermissionsContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    const columns = useMemo((): GridColDef[] => {
        const baseColumns: GridColDef[] = [
            { field: 'nrPedido', headerName: 'Código do pedido', width: 120, minWidth: 90 },
            { field: 'dhInclusao', headerName: 'Data', width: 120, minWidth: 90 },
            {
                field: 'dsConfigPedido',
                headerName: 'Configuração do pedido',
                flex: 1,
                minWidth: 220,
                renderCell: ({ row }) => row.configPedido.dsConfigPedido,
            },
            {
                field: 'vlTotal',
                headerName: 'Valor do pedido',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => (value ? brlPrice(value) : null),
            },
            {
                field: 'idStatusRascunho',
                headerName: 'Status',
                align: 'center',
                headerAlign: 'center',
                minWidth: 170,
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: ({ row }) => <CarrinhoSituacaoColumn row={row} />,
            },
            {
                field: 'Ações',
                align: 'center',
                headerAlign: 'center',
                minWidth: 160,
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: ({ row }) => <CarrinhoActionColumn row={row} />,
            },
        ];

        if (isTipoPedidoFuncionario) {
            baseColumns.splice(
                4,
                0,
                {
                    field: 'vlFrete',
                    headerName: 'Frete',
                    type: 'number',
                    align: 'right',
                    width: 100,
                    minWidth: 90,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
                {
                    field: 'vlTotalFrete',
                    headerName: 'Total do pedido',
                    type: 'number',
                    align: 'right',
                    width: 120,
                    minWidth: 110,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
            );

            if (hasPermissionFinalizacaoGeral) {
                baseColumns.splice(2, 0, {
                    field: 'dsUsuario',
                    headerName: 'Funcionário',
                    minWidth: 120,
                    renderCell: ({ row }) => row.usuarioPedido?.dsUsuario,
                });
            }
        } else {
            const gradeColumns: GridColDef[] = [
                { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130, renderCell: ({ row }) => row.loja.dsNomeFantasia },
                { field: 'dtLimitePedido', headerName: 'Data limite do pedido', flex: 1, minWidth: 130 },
                { field: 'dtEntrega', headerName: 'Data de entrega', flex: 1, minWidth: 130 },
            ];

            baseColumns.splice(1, 0, ...gradeColumns);
        }

        return baseColumns;
    }, [hasPermissionFinalizacaoGeral, isTipoPedidoFuncionario]);

    return { columns };
}
