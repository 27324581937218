import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EspelhoRoyaltyBoletoBtn } from 'pages/financeiro/extrato/royalty-espelho/components';
import { brlPrice } from 'util/format';

const generateCobrancasList = (data: any[] | undefined): any => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: `_titulo${index}`,
            nrTitulo: item.nrTitulo,
            dsSituacao: item.dsSituacao,
            dtVencimento: `${item.dtVencimento}T00:00:00`,
            vlTitulo: item.vlTitulo,
            nrParcela: item.nrParcela,
            tpStatus: item.tpStatus,
            boleto: item.boleto,
            fgImpresso: item.fgImpresso,
        }));
    }

    return [];
};

export function EspelhoRoyaltyTitulosList(): JSX.Element {
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const nrTitulo = searchParams.get('nrDocumento');
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/royaltyextrato/titulo',
        columns: 'idTitulo,fgImpresso',
        nested: true,
        filter: [
            { property: 'cdLoja', value: cdLoja, operator: 'eq', logicalOperator: 'AND' },
            { property: 'nrTitulo', value: nrTitulo, operator: 'eq', logicalOperator: 'AND' },
        ],
    };

    const { data, refetch, fetchStatus } = useQuery([requestOptions], () => {
        const request: Promise<any> = RequestListagem(requestOptions).then((res: any) => res.data.data);
        return request;
    });

    const columns: GridColDef[] = [
        { field: 'nrTitulo', headerName: 'Documento', minWidth: 130 },
        { field: 'dtVencimento', headerName: 'Vencimento', minWidth: 140, valueFormatter: ({ value }) => dateFns.format(new Date(value), 'dd/MM/yyyy') },
        { field: 'dsSituacao', headerName: 'Situação', minWidth: 140, flex: 1 },
        { field: 'vlTitulo', headerName: 'Total', type: 'number', align: 'right', minWidth: 120, valueFormatter: ({ value }) => brlPrice(value) },
    ];

    if (hasPermission(['FINANCEIRO_TITULO_BOLETO_GERAR'])) {
        columns.push({
            field: 'dsBoleto',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Boleto',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: (params) => <EspelhoRoyaltyBoletoBtn params={params} />,
        });
    }

    const rows = generateCobrancasList(data);

    return (
        <Block>
            <SubTitle label="Cobranças de Royalties Gerados" />

            <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
        </Block>
    );
}
