import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';

import { useCarrinhoContext } from '../contexts';

import { useFormMutation } from 'mutations/useFormMutation';
import { CarrinhoStockErrorText } from 'pages/compra/carrinho/components';
import { ResponsePagamento } from 'pages/compra/pagamento/types';

export type ProductStock = {
    cdProduto: string;
    dsProduto: string;
    sgUnidadeMedida: string;
    qtEstoqueDisponivel: number;
};

type FinishError = {
    data: {
        message: string;
        errors: ProductStock[];
    };
};

export function useFinishPedido({ row }: { row: GridRowModel }) {
    const { mutate } = useFormMutation<ResponsePagamento, FinishError>();
    const { setDisabledActions, setOpenFinishedSuccessModal, labelFinishedSuccessModal, contentFinishedErrorModal, setOpenFinishedErrorModal } =
        useCarrinhoContext();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { fgTelaPagamento, id } = row;

    /**
     * Caso ocorra sucesso, abre o modal de sucesso e realiza as requests da tela novamente.
     */
    const onSuccess = () => {
        setOpenFinishedSuccessModal(true);

        labelFinishedSuccessModal.current = 'Pedido finalizado com sucesso!';

        queryClient.invalidateQueries(['getListRascunhos']);
        queryClient.invalidateQueries(['getTotalizadorRascunhos']);
    };

    /**
     * Retorna se o erro é de estoque.
     */
    const applyStockErrorText = (errors: ProductStock[]) => Array.isArray(errors) && errors.at(0)?.qtEstoqueDisponivel !== undefined;

    /**
     * Abre o modal de erro com a mensagem de erro.
     */
    const onError = (error: FinishError) => {
        const { data } = error;

        let message = <Typography sx={{ color: 'grey.600' }}>{data.message || 'Ocorreu um erro ao finalizar o pedido. Tente novamente.'}</Typography>;

        if (applyStockErrorText(data.errors)) {
            message = <CarrinhoStockErrorText products={data.errors} />;
        }

        contentFinishedErrorModal.current = message;

        setOpenFinishedErrorModal(true);
    };

    /**
     * Realiza a request para finalizar o pedido.
     */
    const mutatePedido = () => {
        setDisabledActions(true);

        mutate(
            {
                url: `/compra/pedidocompra/rascunho/${id}/finalizar`,
                preventSnack: true,
            },
            {
                onSuccess,
                onError,
                onSettled: () => setDisabledActions(false),
            },
        );
    };

    /**
     * Finaliza o pedido. Redireciona para a tela de pagamento, se possível, ou realiza uma request.
     */
    const finishPedido = () => {
        if (fgTelaPagamento) {
            navigate(`/compra/pagamento?idsPedido=${id}`);
        } else {
            mutatePedido();
        }
    };

    return { finishPedido };
}
