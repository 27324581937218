import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface SubgrupoProps {
    idSubgrupo: number;
    dsSubgrupo: string;
    dsGrupo: string;
    dsSecao: string;
}

type SubgrupoAutoComplete = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'requestOptions'>;

export function SubgrupoAutoComplete({
    value,
    error,
    label = 'Subgrupo',
    multiple = false,
    sx,
    onChange,
    requestOptions = {
        url: '/gestao/subgrupo',
        sort: [{ property: 'dsSubgrupo', direction: 'ASC' }],
        columns: 'idSubgrupo,dsSubgrupo,dsGrupo,dsSecao',
        limit: 100,
    },
}: SubgrupoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            value={value}
            onChange={onChange}
            sx={sx}
            label={label}
            error={error}
            multiple={multiple}
            selectedStringTemplate="{dsSubgrupo}"
            optionStringTemplate="<strong>{dsSubgrupo}</strong> <br/> Categoria {dsGrupo} <br/> Segmento {dsSecao}"
            isOptionEqualToValue={(option: SubgrupoProps, value: SubgrupoProps) => option.idSubgrupo === value.idSubgrupo}
            requestOptions={requestOptions}
        />
    );
}
