import React, { ChangeEvent, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import { Alert, LoadingButton } from '@mui/lab';
import { AxiosResponse } from 'axios';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { Password } from 'components/fields/';
import { PasswordValidationGroup, SubTitle } from 'components/text';
import { useFormMutation } from 'mutations/useFormMutation';
import { LoginRecuperacaoSenhaRequestProps, LoginRecuperacaoSenhaEnviaEmailRequestProps } from 'pages/login-recuperacao-senha/types';
import { ValidationsPassword, validatePassword, initialValidationsPassword } from 'util/validatePassword';

export function LoginRecuperacaoSenhaForm(): JSX.Element {
    const [searchParams] = useSearchParams();
    const [formPage, setFormPage] = useState('enviaemail');
    const { mutate, isLoading, error } = useFormMutation<unknown, AxiosResponse>();
    const navigate = useNavigate();
    const [validationsPassword, setValidationsPassword] = useState<ValidationsPassword>(initialValidationsPassword);

    const onSubmitRecuperacaoEnviaEmail = (
        values: LoginRecuperacaoSenhaEnviaEmailRequestProps,
        formik: FormikHelpers<LoginRecuperacaoSenhaEnviaEmailRequestProps>,
    ): void => {
        mutate(
            {
                url: '/token-recuperacao-senha/enviaemail/bydslogin',
                formData: values,
                preventSnack: true,
            },
            {
                onSuccess: () => setFormPage('alterasenha'),
                onError: () => formik.resetForm({ values }),
            },
        );
    };

    const onSubmitRecuperacaoAlteraSenha = (
        values: LoginRecuperacaoSenhaRequestProps,
        formik: FormikHelpers<LoginRecuperacaoSenhaRequestProps>,
    ): void => {
        if (!Object.values(validationsPassword).every((value) => value)) {
            return;
        }

        mutate(
            {
                url: '/token-recuperacao-senha/alterasenha',
                formData: values,
                preventSnack: true,
            },
            {
                onSuccess: () => navigate('/login'),
                onError: () => formik.resetForm({ values }),
            },
        );
    };

    const validationSchemaEnviaEmail = useMemo(
        (): any =>
            Yup.object({
                dsLogin: Yup.string().required(),
            }),
        [],
    );

    const validationSchemaAlteraSenha = useMemo(
        (): any =>
            Yup.object({
                dsLogin: Yup.string().required(),
                dsToken: Yup.string().required(),
                dsNovaSenha: Yup.string(),
                dsConfirmacaoSenha: Yup.string().test('passwords-match', 'As senhas precisam ser iguais', function (value) {
                    return this.parent.dsNovaSenha === value;
                }),
            }),
        [],
    );

    return formPage === 'enviaemail' ? (
        <Formik
            initialValues={{
                dsLogin: searchParams.get('email') || '',
            }}
            onSubmit={onSubmitRecuperacaoEnviaEmail}
            validationSchema={validationSchemaEnviaEmail}
        >
            <Form>
                <SubTitle label={'Recuperação de senha'} fullWidth sx={{ mb: 1 }} />

                <Field
                    name="dsLogin"
                    component={TextField}
                    required
                    fullWidth
                    id="dsLogin"
                    disabled={Boolean(searchParams.get('email'))}
                    label="Usuário"
                    autoComplete="new-login"
                    margin="dense"
                    InputProps={{
                        endAdornment: <Person />,
                    }}
                />

                <LoadingButton loading={isLoading} type="submit" fullWidth variant="contained" size="large" sx={{ mt: 2 }}>
                    Salvar
                </LoadingButton>

                {error && (
                    <Alert sx={{ mt: 2, px: 1 }} severity="error">
                        {error?.data?.message || 'Não foi possível enviar o e-mail de recuperação'}
                    </Alert>
                )}
            </Form>
        </Formik>
    ) : (
        <Formik
            initialValues={{
                dsLogin: searchParams.get('email') || '',
                dsNovaSenha: '',
                dsToken: '',
                dsConfirmacaoSenha: '',
            }}
            onSubmit={onSubmitRecuperacaoAlteraSenha}
            validationSchema={validationSchemaAlteraSenha}
        >
            {({ setFieldValue }: FormikProps<LoginRecuperacaoSenhaRequestProps>) => (
                <Form>
                    <SubTitle label={'Ativação de cadastro'} fullWidth sx={{ mb: 1 }} />

                    <Field
                        name="dsLogin"
                        component={TextField}
                        required
                        fullWidth
                        id="dsLogin"
                        disabled={false}
                        label="Usuário"
                        autoComplete="new-login"
                        margin="dense"
                        InputProps={{
                            endAdornment: <Person />,
                        }}
                    />

                    <Field
                        name="dsToken"
                        margin="dense"
                        component={TextField}
                        disabled={false}
                        required
                        id="dsToken"
                        label="Código de ativação"
                        autoComplete="new-token"
                    />

                    <Field
                        name="dsNovaSenha"
                        margin="dense"
                        component={Password}
                        id="password"
                        disabled={false}
                        required
                        label="Senha"
                        autoComplete="new-password"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const inputValue = e.target.value;

                            setValidationsPassword(validatePassword(inputValue));

                            setFieldValue('dsNovaSenha', inputValue);

                            return inputValue;
                        }}
                    />

                    <PasswordValidationGroup validationsPassword={validationsPassword} />

                    <Field
                        name="dsConfirmacaoSenha"
                        margin="dense"
                        component={Password}
                        id="new-password"
                        disabled={false}
                        required
                        label="Confirmação de senha"
                        autoComplete="new-password-repeat"
                    />

                    <LoadingButton loading={isLoading} type="submit" fullWidth variant="contained" size="large" sx={{ mt: 2 }}>
                        Salvar
                    </LoadingButton>

                    {error && (
                        <Alert sx={{ mt: 2, px: 1 }} severity="error">
                            {error?.data?.message || 'Não foi possível enviar o e-mail de recuperação'}
                        </Alert>
                    )}
                </Form>
            )}
        </Formik>
    );
}
