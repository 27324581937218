import { CondicaoPagamentoAutoComplete } from 'components/autocompletes';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { useActionsCondicoesPagamento } from 'pages/compra/configuracao-pedido/hooks';

export function ConfigPedidoCondicoesPagamentoGeneral() {
    const { configPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const { formatCondicoesPagamentoLoja } = useActionsCondicoesPagamento();

    return (
        <Block>
            <SubTitle label="Condições de Pagamento (Geral)" />

            <CondicaoPagamentoAutoComplete
                multiple
                label=""
                size="small"
                value={configPedidoCondicoesPagamento?.condicaoPagamentoGeral}
                disabled={!configPedidoCondicoesPagamento.gatewayPagamento.length}
                onChange={(e, value) => formatCondicoesPagamentoLoja(value)}
                requestOptions={{
                    nested: true,
                    url: '/gestao/condicaopagamento',
                    sort: [{ property: 'idCondicaoPagamento', direction: 'ASC' }],
                    filter: [
                        { property: 'fgAtivo', value: true, operator: 'eq' },
                        {
                            property: 'idGatewayPagamento',
                            operator: 'in',
                            value: configPedidoCondicoesPagamento.gatewayPagamento.map((item) => item.idGatewayPagamento),
                        },
                    ],
                }}
            />
        </Block>
    );
}
