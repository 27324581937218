import { RefObject, useEffect } from 'react';

import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { Apuracao } from '../types';
import { findVlItem } from '../utils';

import { useDreFilterLojas } from './useDreFilterLojas';
import { useDreGetCalculo } from './useDreGetCalculo';

type DreListUpdate = {
    rows: readonly GridValidRowModel[];
    grid: null | RefObject<GridApiPro>;
};

export function useDreGridUpdate({ rows, grid }: DreListUpdate): void {
    const filterLojas = useDreFilterLojas();
    const isMultipleLojas = filterLojas?.value.length > 1;
    const { getDreCalculo } = useDreGetCalculo();

    useEffect(() => {
        if (!isMultipleLojas) {
            rows.forEach((rowProps) => {
                getDreCalculo(rowProps).then((newDreProps) => {
                    grid?.current?.updateRows([
                        {
                            id: rowProps.id,
                            idStatus: newDreProps.dreStatus.idDreStatus,
                            vlCmv: findVlItem(newDreProps, Apuracao.CMV),
                            vlGastos: findVlItem(newDreProps, Apuracao.GASTOS),
                            vlReceitaBruta: findVlItem(newDreProps, Apuracao.RECEITA_BRUTA),
                            vlLucroLiquido: findVlItem(newDreProps, Apuracao.LUCRO_LIQUIDO),
                            vlReceitaLiquida: findVlItem(newDreProps, Apuracao.RECEITA_LIQUIDA),
                            isLoading: false,
                        },
                    ]);
                });
            });
        }
    }, [rows]);
}
