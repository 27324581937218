import React, { useRef } from 'react';

import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useDreGridUpdate, useDreGetMercadoria, useDreGridColumns, useDreGridRows } from 'pages/financeiro/dre/hooks';

export function DreGrid(): JSX.Element {
    const grid = useRef<GridApiPro>(null);
    const { data, refetch, isFetching } = useDreGetMercadoria();
    const columns = useDreGridColumns({ data, refetch });
    const rows = useDreGridRows({ data });

    useDreGridUpdate({ rows, grid });

    return (
        <Block>
            <PanelGrid
                ref={grid}
                rows={rows}
                reload={refetch}
                columns={columns}
                loading={isFetching}
                rowsCount={rows.length}
                remote={false}
                visibleRows="all"
            />
        </Block>
    );
}
