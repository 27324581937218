import { useQuery } from '@tanstack/react-query';

import { RequestPedidoRealizadoListModelProps } from '../types';

import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useGetPedidos() {
    const requestListagem = useRequestListagem();
    const { setContent } = useFormContext();
    const { limit, page, sort, filter, autoLoad } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/historico',
        sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
        page,
        limit,
        filter,
    };

    return useQuery<RequestPedidoRealizadoListModelProps>(
        ['getListPedidosRealizados', requestOptions],
        () => requestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
            onSuccess: (data) => setContent(data.total),
        },
    );
}
