import { useSnackbar } from 'notistack';
import { useState } from 'react';

export interface RequestToFileResponseProps {
    data: ArrayBuffer;
    request: XMLHttpRequest;
    status: number;
}

interface NewBlobProps {
    type?: string | null;
    data: ArrayBuffer;
    filename: string;
}

interface FailBytesToUrlProps {
    status: number;
}

interface SuccessBytesToUrlProps {
    directDownload?: boolean;
    downloadUrl: string;
    filename: string;
}

interface BytesToFileProps {
    data: ArrayBuffer;
    filename: string;
    type?: string | null;
}

interface UrlToFileProps {
    url: string;
    filename: string;
    directDownload?: boolean;
}

interface useBytesToFileProps {
    requestToFile: (response: RequestToFileResponseProps, directDownload?: boolean) => void;
    bytesToFile: (props: BytesToFileProps) => string;
    urlToFile: (props: UrlToFileProps) => void;
    isFetching: boolean;
}

export default (): useBytesToFileProps => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    // captura nome do arquivo recebido no header ou gera um novo com timestamp atual
    const getFileName = (request: XMLHttpRequest): string => {
        const disposition = request.getResponseHeader('Content-Disposition');

        let filename = new Date().getTime().toString();

        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);

            if (matches && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    };

    // gera um blob ou file de acordo com o suporte do navegador
    const newBlob = ({ data, filename, type }: NewBlobProps): File | Blob => {
        const supportFile = typeof File === 'function';
        const opts: FilePropertyBag = {};

        if (type) {
            opts.type = type;
        }

        return supportFile ? new File([data], filename, opts) : new Blob([data], opts);
    };

    const successBytesToUrl = ({ directDownload, downloadUrl, filename }: SuccessBytesToUrlProps): void => {
        if (directDownload === true) {
            const a = document.createElement('a');

            a.href = downloadUrl;
            a.download = filename;

            document.body.appendChild(a);

            a.click();

            URL.revokeObjectURL(downloadUrl);
        } else {
            window.open(downloadUrl, '_blank');
        }
    };

    const failBytesToUrl = ({ status }: FailBytesToUrlProps): void => {
        let message = 'Ocorreu um erro durante a geração de seu arquivo.';

        if (status === 204) {
            message = 'Não existem dados suficientes para gerar seu arquivo.';
        }

        enqueueSnackbar(message, { variant: 'warning' });
    };

    const bytesToFile = ({ data, filename, type }: BytesToFileProps): string => {
        const blob = newBlob({ data, filename, type });

        return window.URL.createObjectURL(blob);
    };

    const requestToFile = (response: RequestToFileResponseProps, directDownload?: boolean): void => {
        const { data, request, status } = response;
        const filename = getFileName(request);
        const type = request.getResponseHeader('Content-Type');
        const downloadUrl = bytesToFile({ data, filename, type });

        if (status === 200) {
            successBytesToUrl({ directDownload, downloadUrl, filename });
        } else {
            failBytesToUrl({ status });
        }
    };

    const urlToFile = ({ url, filename, directDownload = true }: UrlToFileProps): void => {
        setIsFetching(true);

        fetch(url)
            .then((response) => response.arrayBuffer())
            .then((data) => {
                setIsFetching(false);

                const downloadUrl = bytesToFile({
                    filename,
                    data,
                });

                if (downloadUrl) {
                    successBytesToUrl({
                        directDownload,
                        downloadUrl,
                        filename,
                    });
                }
            })
            .catch(() => setIsFetching(false));
    };

    return { requestToFile, bytesToFile, urlToFile, isFetching };
};
