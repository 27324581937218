import React, { useEffect, useMemo } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4';

import { PrivateRoute } from 'components/router';
import { useDomainConfigContext } from 'context/DomainConfig';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useTitle } from 'hooks/useTitle';
import { Page404Detail } from 'pages/404';
import { ArquivoForm, ArquivoList } from 'pages/arquivo';
import { CategoriaForm, CategoriaList } from 'pages/arquivo/categoria';
import { TagListArquivo, TagFormArquivo } from 'pages/arquivo/tag';
import { BannerForm, BannerList } from 'pages/banner';
import { BiPage } from 'pages/bi';
import { LojaForm, LojaList } from 'pages/cadastro/loja';
import { PermissoesForm, PermissoesList } from 'pages/cadastro/permissoes';
import { UsuarioForm, UsuarioList } from 'pages/cadastro/usuario';
import { CalendarioList } from 'pages/calendario';
import { EventoForm, EventoList } from 'pages/calendario/evento';
import { EventoMarcadoresList, EventoMarcadoresForm } from 'pages/calendario/evento/marcadores';
import { BandaPrecoList, BandaPrecoForm } from 'pages/complementar/banda-preco';
import { CargoForm, CargoList } from 'pages/complementar/cargo';
import { CentroCustoForm, CentroCustoList } from 'pages/complementar/centro-custo';
import { CentroDistribuicaoForm, CentroDistribuicaoList } from 'pages/complementar/centro-distribuicao';
import { CentroPedidoForm, CentroPedidoList } from 'pages/complementar/centro-pedido';
import { CondicaoPagamentoList, CondicaoPagamentoForm } from 'pages/complementar/condicao-pagamento';
import { DadosEntregaForm, DadosEntregaList } from 'pages/complementar/dados-entrega';
import { DepositoList, DepositosForm } from 'pages/complementar/depositos';
import { GrupoEmailsForm, GrupoEmailsList } from 'pages/complementar/grupo-emails';
import { LocalFuncionarioList, LocalFuncionarioForm } from 'pages/complementar/local-funcionario';
import { OperacoesList, OperacoesForm } from 'pages/complementar/operacoes';
import { CarrinhoList } from 'pages/compra/carrinho';
import { ConfiguracaoPedidoCompraForm, ConfiguracaoPedidoCompraList } from 'pages/compra/configuracao-pedido';
import { PagamentoForm } from 'pages/compra/pagamento';
import { PedidoCompraList } from 'pages/compra/pedido';
import { PedidoLoteCompraList } from 'pages/compra/pedido-lote';
import { PedidoRealizadoList } from 'pages/compra/pedido-realizado';
import { PedidoTotalizadorList } from 'pages/compra/pedido-totalizador';
import { StatusIntegracaoList } from 'pages/compra/pedido-totalizador/status-integracao';
import { PercentualNotaFiscalList } from 'pages/compra/percentual-nota-fiscal';
import { ProdutoList } from 'pages/compra/produto';
import { SugestaoCompraList } from 'pages/compra/sugestao';
import { TabelaPrecoList } from 'pages/compra/tabela-preco';
import { ComunicadoDetail, ComunicadoForm, ComunicadoList } from 'pages/comunicado';
import { CategoriaComunicadoForm, CategoriaComunicadoList } from 'pages/comunicado/categoria';
import { TagFormComunicado, TagListComunicado } from 'pages/comunicado/tag';
import { EstatisticasList } from 'pages/estatisticas';
import { DetalhamentoCreditoDetail, DetalhamentoCreditoList } from 'pages/financeiro/detalhamento-creditos';
import { DetalhamentoCreditosImportacao } from 'pages/financeiro/detalhamento-creditos/importar';
import { DreForm, DreList } from 'pages/financeiro/dre';
import { PreenchimentoList } from 'pages/financeiro/dre/preenchimento';
import { DreRelatorio } from 'pages/financeiro/dre/relatorio';
import { ExtratoFppList } from 'pages/financeiro/extrato/fpp';
import { EspelhoFppList } from 'pages/financeiro/extrato/fpp-espelho';
import { FppEspelhoNotaProdutoDetail } from 'pages/financeiro/extrato/fpp-espelho-nota-produto';
import { ExtratoRoyaltyList } from 'pages/financeiro/extrato/royalty';
import { EspelhoRoyaltyList } from 'pages/financeiro/extrato/royalty-espelho';
import { NotaProdutoDetail } from 'pages/financeiro/extrato/royalty-espelho-nota-produto';
import { MovimentacaoList } from 'pages/financeiro/nota';
import { NotaDevolucaoForm, NotaDevolucaoList } from 'pages/financeiro/nota-devolucao';
import { MotivoDevolucaoForm, MotivoDevolucaoList } from 'pages/financeiro/nota-devolucao/motivo';
import { RecebiveisPage } from 'pages/financeiro/recebiveis';
import { TituloList } from 'pages/financeiro/titulo';
import { FontesForm, FontesList } from 'pages/fontes';
import { GrupoEconomicoList, GrupoEconomicoForm } from 'pages/grupo-economico';
import { InicioDetail } from 'pages/inicio';
import { LoginPage } from 'pages/login';
import { LoginAtivacaoPage } from 'pages/login-ativacao';
import { LoginRecuperacaoSenhaPage } from 'pages/login-recuperacao-senha';
import { GradeExclusao, GradeForm, GradeImportacao, GradeList } from 'pages/logistica/grade';
import { QuebrasList } from 'pages/logistica/quebras';
import {
    MarketingCampanhaForm,
    MarketingCampanhaList,
    MarketingDetail,
    MarketingEdicaoPecas,
    MarketingForm,
    MarketingList,
} from 'pages/marketing-trade/marketing';
import { TagFormMarketing, TagListMarketing } from 'pages/marketing-trade/marketing/tag';
import { TradeCampanhaForm, TradeCampanhaList, TradeDetail, TradeEdicaoPecas, TradeForm, TradeList } from 'pages/marketing-trade/trade';
import {
    TradeSolicitacaoPecaMensagemForm,
    TradeSolicitacoesPecasForm,
    TradeSolicitacoesPecasList,
} from 'pages/marketing-trade/trade/solicitacoes-pecas';
import { MensagemPadraoForm, MensagemPadraoList } from 'pages/marketing-trade/trade/solicitacoes-pecas/mensagem-padrao';
import { TagFormTrade, TagListTrade } from 'pages/marketing-trade/trade/tag';
import { PedidoForm, PedidoGrade, PedidoList } from 'pages/pedido';
import { PortalDetail } from 'pages/portal';
import { PredefinicaoEstiloForm, PredefinicoesEstiloList } from 'pages/predefinicoes-estilo';
import { ConfiguracaoGeralForm } from 'pages/revendedor/configuracao-geral';
import { PromocaoForm, PromocaoList } from 'pages/revendedor/promocao';
import { RevendedorForm, RevendedorList } from 'pages/revendedor/revendedor';

export function Navigator(): JSX.Element {
    const { tokenInfo } = useGenericAuth();
    const location = useLocation();
    const { data: domainConfigData } = useDomainConfigContext();
    const { documentTitle, googleAnalyticsTrackingId } = domainConfigData;

    useTitle({ documentTitle });

    const hasAnalyticsPermission = useMemo(() => {
        return tokenInfo?.tpUsuario === 'FRANQUEADO';
    }, [tokenInfo?.tpUsuario]);

    useEffect(() => {
        if (hasAnalyticsPermission && googleAnalyticsTrackingId && process.env.REACT_APP_ENV === 'prod') {
            ReactGA.initialize(googleAnalyticsTrackingId);

            if (tokenInfo) {
                ReactGA.set({ dimension1: tokenInfo?.dsNomeEmpresa });
                ReactGA.set({ dimension2: tokenInfo?.idUsuario?.toString() });
                ReactGA.set({ metric1: tokenInfo?.idEmpresa });
                ReactGA.set({ metric2: tokenInfo?.idUsuario });
            }
        }
    }, [location, googleAnalyticsTrackingId, hasAnalyticsPermission, tokenInfo]);

    return (
        <Routes>
            <Route path="/" element={<PrivateRoute component={<PortalDetail />} />}>
                <Route index element={<InicioDetail />} />

                <Route path="comunicado">
                    <Route index element={<ComunicadoList />} />
                    <Route path=":id" element={<ComunicadoDetail />} />

                    <Route path="form">
                        <Route index element={<ComunicadoForm />} />
                        <Route path=":id" element={<ComunicadoForm />} />
                    </Route>

                    <Route path="categoria">
                        <Route index element={<CategoriaComunicadoList />} />

                        <Route path="form">
                            <Route index element={<CategoriaComunicadoForm />} />
                            <Route path=":id" element={<CategoriaComunicadoForm />} />
                        </Route>
                    </Route>

                    <Route path="tag">
                        <Route index element={<TagListComunicado />} />

                        <Route path="form">
                            <Route index element={<TagFormComunicado />} />
                            <Route path=":id" element={<TagFormComunicado />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="banner">
                    <Route index element={<BannerList />} />

                    <Route path="form">
                        <Route index element={<BannerForm />} />
                        <Route path=":id" element={<BannerForm />} />
                    </Route>
                </Route>

                <Route path="calendario">
                    <Route index element={<CalendarioList />} />

                    <Route path="evento">
                        <Route index element={<EventoList />} />

                        <Route path="form">
                            <Route index element={<EventoForm />} />
                            <Route path=":id" element={<EventoForm />} />

                            <Route path="marcadores">
                                <Route index element={<EventoMarcadoresList />} />
                                <Route path="form">
                                    <Route index element={<EventoMarcadoresForm />} />
                                    <Route path=":id" element={<EventoMarcadoresForm />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path="estatistica">
                    <Route index element={<EstatisticasList />} />
                </Route>

                <Route path="grupo-economico">
                    <Route index element={<GrupoEconomicoList />} />

                    <Route path="form">
                        <Route index element={<GrupoEconomicoForm />} />
                        <Route path=":id" element={<GrupoEconomicoForm />} />
                    </Route>
                </Route>

                <Route path="financeiro">
                    <Route path="nota" element={<MovimentacaoList />} />
                    <Route path="titulos" element={<TituloList />} />

                    <Route path="extrato">
                        <Route path="fpp">
                            <Route index element={<ExtratoFppList />} />

                            <Route path="espelho">
                                <Route index element={<EspelhoFppList />} />
                                <Route path="nota-produto" element={<FppEspelhoNotaProdutoDetail />} />
                            </Route>
                        </Route>

                        <Route path="royalty">
                            <Route index element={<ExtratoRoyaltyList />} />

                            <Route path="espelho">
                                <Route index element={<EspelhoRoyaltyList />} />
                                <Route path="nota-produto" element={<NotaProdutoDetail />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="detalhamento-creditos">
                        <Route index element={<DetalhamentoCreditoList />} />

                        <Route path="detalhes" element={<DetalhamentoCreditoDetail />} />
                        <Route path="importar" element={<DetalhamentoCreditosImportacao />} />
                    </Route>

                    <Route path="dre">
                        <Route index element={<DreList />} />

                        <Route path="form">
                            <Route index element={<DreForm />} />
                            <Route path=":id" element={<DreForm />} />
                        </Route>

                        <Route path="relatorio/:categoria" element={<DreRelatorio />} />

                        <Route path="preenchimento" element={<PreenchimentoList />} />
                    </Route>

                    <Route path="nota-devolucao">
                        <Route index element={<NotaDevolucaoList />} />

                        <Route path="form">
                            <Route index element={<NotaDevolucaoForm />} />
                            <Route path=":id" element={<NotaDevolucaoForm />} />
                        </Route>

                        <Route path="motivo">
                            <Route index element={<MotivoDevolucaoList />} />

                            <Route path="form">
                                <Route index element={<MotivoDevolucaoForm />} />
                                <Route path=":id" element={<MotivoDevolucaoForm />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="recebiveis">
                        <Route index element={<RecebiveisPage />} />
                    </Route>
                </Route>

                <Route path="bi">
                    <Route path=":biName" element={<BiPage />} />
                </Route>

                <Route path="pedido">
                    <Route index element={<PedidoList />} />

                    <Route path="form" element={<PedidoForm />} />

                    <Route path="grade-pedido" element={<PedidoGrade />} />
                </Route>

                <Route path="marketing">
                    <Route index element={<MarketingList />} />
                    <Route path=":id" element={<MarketingDetail />} />

                    <Route path="form">
                        <Route index element={<MarketingForm />} />
                        <Route path=":id">
                            <Route index element={<MarketingForm />} />
                            <Route path="edicao" element={<MarketingEdicaoPecas />} />
                        </Route>
                    </Route>

                    <Route path="campanha">
                        <Route index element={<MarketingCampanhaList />} />

                        <Route path="form">
                            <Route index element={<MarketingCampanhaForm />} />
                            <Route path=":id" element={<MarketingCampanhaForm />} />
                        </Route>
                    </Route>

                    <Route path="tag">
                        <Route index element={<TagListMarketing />} />

                        <Route path="form">
                            <Route index element={<TagFormMarketing />} />
                            <Route path=":id" element={<TagFormMarketing />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="fontes">
                    <Route index element={<FontesList />} />

                    <Route path="form">
                        <Route index element={<FontesForm />} />
                        <Route path=":id" element={<FontesForm />} />
                    </Route>
                </Route>

                <Route path="predefinicoes-estilo">
                    <Route index element={<PredefinicoesEstiloList />} />

                    <Route path="form">
                        <Route index element={<PredefinicaoEstiloForm />} />
                        <Route path=":id" element={<PredefinicaoEstiloForm />} />
                    </Route>
                </Route>

                <Route path="trade">
                    <Route index element={<TradeList />} />
                    <Route path=":id" element={<TradeDetail />} />

                    <Route path="form">
                        <Route index element={<TradeForm />} />
                        <Route path=":id">
                            <Route index element={<TradeForm />} />
                            <Route path="edicao" element={<TradeEdicaoPecas />} />
                        </Route>
                    </Route>

                    <Route path="campanha">
                        <Route index element={<TradeCampanhaList />} />

                        <Route path="form">
                            <Route index element={<TradeCampanhaForm />} />
                            <Route path=":id" element={<TradeCampanhaForm />} />
                        </Route>
                    </Route>

                    <Route path="solicitacoes-pecas">
                        <Route index element={<TradeSolicitacoesPecasList />} />

                        <Route path="form">
                            <Route index element={<TradeSolicitacoesPecasForm />} />
                            <Route path=":id" element={<TradeSolicitacaoPecaMensagemForm />} />
                        </Route>

                        <Route path="mensagem-padrao">
                            <Route index element={<MensagemPadraoList />} />

                            <Route path="form">
                                <Route index element={<MensagemPadraoForm />} />
                                <Route path=":id" element={<MensagemPadraoForm />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="tag">
                        <Route index element={<TagListTrade />} />

                        <Route path="form">
                            <Route index element={<TagFormTrade />} />
                            <Route path=":id" element={<TagFormTrade />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="arquivo">
                    <Route index element={<ArquivoList />} />

                    <Route path="form">
                        <Route index element={<ArquivoForm />} />
                        <Route path=":id" element={<ArquivoForm />} />
                    </Route>

                    <Route path="categoria">
                        <Route index element={<CategoriaList />} />

                        <Route path="form">
                            <Route index element={<CategoriaForm />} />
                            <Route path=":id" element={<CategoriaForm />} />
                        </Route>
                    </Route>

                    <Route path="tag">
                        <Route index element={<TagListArquivo />} />

                        <Route path="form">
                            <Route index element={<TagFormArquivo />} />
                            <Route path=":id" element={<TagFormArquivo />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="logistica">
                    <Route path="grade">
                        <Route index element={<GradeList />} />

                        <Route path="form">
                            <Route index element={<GradeForm />} />
                            <Route path=":id" element={<GradeForm />} />
                        </Route>

                        <Route path="importacao">
                            <Route index element={<GradeImportacao />} />
                        </Route>

                        <Route path="exclusao">
                            <Route index element={<GradeExclusao />} />
                        </Route>
                    </Route>

                    <Route path="quebras">
                        <Route index element={<QuebrasList />} />
                    </Route>
                </Route>

                <Route path="cadastro">
                    <Route path="loja">
                        <Route index element={<LojaList />} />

                        <Route path="form">
                            <Route index element={<LojaForm />} />
                            <Route path=":id" element={<LojaForm />} />
                        </Route>
                    </Route>

                    <Route path="permissoes">
                        <Route index element={<PermissoesList />} />

                        <Route path="form">
                            <Route index element={<PermissoesForm />} />
                            <Route path=":id" element={<PermissoesForm />} />
                        </Route>
                    </Route>

                    <Route path="usuario">
                        <Route index element={<UsuarioList />} />

                        <Route path="form">
                            <Route index element={<UsuarioForm />} />
                            <Route path=":id" element={<UsuarioForm />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="compra">
                    <Route path="carrinho">
                        <Route index element={<CarrinhoList />} />
                    </Route>
                    <Route path="pedido">
                        <Route index element={<PedidoCompraList />} />
                        <Route path=":id" element={<PedidoCompraList />} />
                    </Route>
                    <Route path="pedido-lote">
                        <Route index element={<PedidoLoteCompraList />} />
                    </Route>
                    <Route path="pedido-realizado">
                        <Route index element={<PedidoRealizadoList />} />
                    </Route>
                    <Route path="pedido-totalizador">
                        <Route index element={<PedidoTotalizadorList />} />

                        <Route path="status-integracao">
                            <Route index element={<StatusIntegracaoList />} />
                        </Route>
                    </Route>
                    <Route path="percentual-nota-fiscal">
                        <Route index element={<PercentualNotaFiscalList />} />
                    </Route>
                    <Route path="tabela-preco">
                        <Route index element={<TabelaPrecoList />} />
                    </Route>
                    <Route path="configuracao-pedido">
                        <Route index element={<ConfiguracaoPedidoCompraList />} />

                        <Route path="form">
                            <Route index element={<ConfiguracaoPedidoCompraForm />} />
                            <Route path=":id" element={<ConfiguracaoPedidoCompraForm />} />
                        </Route>
                    </Route>
                    <Route path="produto">
                        <Route index element={<ProdutoList />} />
                    </Route>
                    <Route path="sugestao">
                        <Route index element={<SugestaoCompraList />} />
                    </Route>

                    <Route path="pagamento">
                        <Route index element={<PagamentoForm />} />
                    </Route>
                </Route>

                <Route path="complementar">
                    <Route path="dados-entrega">
                        <Route index element={<DadosEntregaList />} />

                        <Route path="form">
                            <Route index element={<DadosEntregaForm />} />
                            <Route path=":id" element={<DadosEntregaForm />} />
                        </Route>
                    </Route>

                    <Route path="grupo-emails">
                        <Route index element={<GrupoEmailsList />} />

                        <Route path="form">
                            <Route index element={<GrupoEmailsForm />} />
                            <Route path=":id" element={<GrupoEmailsForm />} />
                        </Route>
                    </Route>

                    <Route path="cargo">
                        <Route index element={<CargoList />} />

                        <Route path="form">
                            <Route index element={<CargoForm />} />
                            <Route path=":id" element={<CargoForm />} />
                        </Route>
                    </Route>

                    <Route path="depositos">
                        <Route index element={<DepositoList />} />

                        <Route path="form">
                            <Route index element={<DepositosForm />} />
                            <Route path=":id" element={<DepositosForm />} />
                        </Route>
                    </Route>

                    <Route path="centro-pedidos">
                        <Route index element={<CentroPedidoList />} />

                        <Route path="form">
                            <Route index element={<CentroPedidoForm />} />
                            <Route path=":id" element={<CentroPedidoForm />} />
                        </Route>
                    </Route>

                    <Route path="centro-distribuicao">
                        <Route index element={<CentroDistribuicaoList />} />

                        <Route path="form">
                            <Route index element={<CentroDistribuicaoForm />} />
                            <Route path=":id" element={<CentroDistribuicaoForm />} />
                        </Route>
                    </Route>

                    <Route path="condicao-pagamento">
                        <Route index element={<CondicaoPagamentoList />} />

                        <Route path="form">
                            <Route index element={<CondicaoPagamentoForm />} />
                            <Route path=":id" element={<CondicaoPagamentoForm />} />
                        </Route>
                    </Route>
                    <Route path="operacoes">
                        <Route index element={<OperacoesList />} />

                        <Route path="form">
                            <Route index element={<OperacoesForm />} />
                            <Route path=":id" element={<OperacoesForm />} />
                        </Route>
                    </Route>

                    <Route path="centro-custos">
                        <Route index element={<CentroCustoList />} />

                        <Route path="form">
                            <Route index element={<CentroCustoForm />} />
                            <Route path=":id" element={<CentroCustoForm />} />
                        </Route>
                    </Route>

                    <Route path="banda-preco">
                        <Route index element={<BandaPrecoList />} />

                        <Route path="form">
                            <Route index element={<BandaPrecoForm />} />
                            <Route path=":id" element={<BandaPrecoForm />} />
                        </Route>
                    </Route>

                    <Route path="local-funcionario">
                        <Route index element={<LocalFuncionarioList />} />

                        <Route path="form">
                            <Route index element={<LocalFuncionarioForm />} />
                            <Route path=":id" element={<LocalFuncionarioForm />} />
                        </Route>
                    </Route>
                </Route>

                <Route path="revendedor">
                    <Route index element={<RevendedorList />} />

                    <Route path="form">
                        <Route index element={<RevendedorForm />} />
                        <Route path=":id" element={<RevendedorForm />} />
                    </Route>

                    <Route path="promocao">
                        <Route index element={<PromocaoList />} />

                        <Route path="form">
                            <Route index element={<PromocaoForm />} />
                        </Route>
                    </Route>

                    <Route path="configuracao-geral">
                        <Route index element={<ConfiguracaoGeralForm />} />
                    </Route>
                </Route>
            </Route>

            <Route path="/login" element={<LoginPage />} />

            <Route path="/home" element={<LoginPage />} />

            <Route path="/logout" element={<LoginPage />} />

            <Route path="/login-ativacao" element={<LoginAtivacaoPage />} />

            <Route path="/login-recuperacao-senha" element={<LoginRecuperacaoSenhaPage />} />

            <Route path="*" element={<Page404Detail />} />
        </Routes>
    );
}
