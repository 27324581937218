import React, { Dispatch, SetStateAction, useCallback, useMemo, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { LoadingButton } from '@mui/lab';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { PercentualNotaMetaSazonalAutoComplete } from 'components/autocompletes/PercentualNotaMetaSazonalAutoComplete';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfigPedidoAutomaticaProcessamentoProps,
    LojaMetaAtingidaProps,
    PercentualNotaMetaSazonalProps,
    StatusGeracaoConfig,
} from 'pages/compra/configuracao-pedido/types';

interface ConfiguracaoPedidoCompraGeracaoConfigsProps {
    percentualNotaMetaSazonal: PercentualNotaMetaSazonalProps | null;
    setPercentualNotaMetaSazonal: Dispatch<SetStateAction<PercentualNotaMetaSazonalProps | null>>;
}

export function ConfiguracaoPedidoCompraGeracaoConfigs({
    percentualNotaMetaSazonal,
    setPercentualNotaMetaSazonal,
}: ConfiguracaoPedidoCompraGeracaoConfigsProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { mutate: mutateGerar, isLoading: mutateLoadingGerar } = useFormMutation();
    const { mutate: mutateReverter, isLoading: mutateLoadingReverter } = useFormMutation();
    const { id } = useParams();
    const { content } = useFormContext();
    const { metasList, tipoPercentual, initialMetasList } = content ?? { metasList: [], tipoPercentual: null, initialMetasList: [] };

    const requestOptions: RequestOptionsType = useMemo(() => ({ url: `gestao/configpedidoautomaticaprocessamento/configpedido/${id}` }), [id]);
    const { data: statusGeracaoData, refetch } = useQuery(
        [requestOptions],
        (): Promise<ConfigPedidoAutomaticaProcessamentoProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            refetchInterval: (data) => {
                if (data) {
                    const { idConfigPedidoAutomaticaProcessamentoStatus } = data.configPedidoAutomaticaProcessamentoStatus;

                    if ([StatusGeracaoConfig.AGUARDANDO, StatusGeracaoConfig.PROCESSANDO].includes(idConfigPedidoAutomaticaProcessamentoStatus)) {
                        return 2000;
                    }
                }

                return false;
            },
        },
    );

    const statusGeracao = useMemo(() => {
        if (statusGeracaoData?.configPedidoAutomaticaProcessamentoStatus) {
            const { idConfigPedidoAutomaticaProcessamentoStatus: idStatusGeracao, dsConfigPedidoAutomaticaProcessamentoStatus: dsStatusGeracao } =
                statusGeracaoData.configPedidoAutomaticaProcessamentoStatus;

            let colorStatusGeracao: 'error' | 'info' | 'success' | 'warning' = 'info';

            if (idStatusGeracao === StatusGeracaoConfig.FINALIZADO_COM_FALHA) {
                colorStatusGeracao = 'error';
            } else if (idStatusGeracao === StatusGeracaoConfig.FINALIZADO_COM_SUCESSO) {
                colorStatusGeracao = 'success';
            } else if (idStatusGeracao === StatusGeracaoConfig.AGUARDANDO) {
                colorStatusGeracao = 'warning';
            }

            return { colorStatusGeracao, dsStatusGeracao, idStatusGeracao };
        }

        return null;
    }, [statusGeracaoData]);

    const onClickReverterConfiguracoes = useCallback(
        (): void => mutateReverter({ url: `/gestao/configpedido/${id}/configautomatica/reverter` }, { onSuccess: () => refetch() }),
        [id, mutateReverter],
    );
    const onClickGerarConfiguracoes = useCallback(
        (): void => mutateGerar({ url: `/gestao/configpedido/${id}/configautomatica` }, { onSuccess: () => refetch() }),
        [id, mutateGerar],
    );

    const hasInitialPercentual = useMemo(() => tipoPercentual && Object.keys(tipoPercentual).length, [tipoPercentual]);

    useEffect(() => {
        if (hasInitialPercentual) {
            setPercentualNotaMetaSazonal(tipoPercentual);
        }
    }, [hasInitialPercentual]);

    const hasPercentualChanged = useMemo(() => {
        if (!hasInitialPercentual && !percentualNotaMetaSazonal) {
            return false;
        }

        if ((!hasInitialPercentual && percentualNotaMetaSazonal) || (hasInitialPercentual && !percentualNotaMetaSazonal)) {
            return true;
        }

        return tipoPercentual.idPercentualNotaRoyaltiesSazonal !== percentualNotaMetaSazonal?.idPercentualNotaRoyaltiesSazonal;
    }, [hasInitialPercentual, percentualNotaMetaSazonal, tipoPercentual]);

    const hasMetasChanged = useMemo(() => {
        if (!initialMetasList || !metasList) {
            return false;
        }

        if (initialMetasList.length !== metasList.length) {
            return true;
        }

        return initialMetasList.some((initialMetaLoja: LojaMetaAtingidaProps) => {
            const existMetaLoja = metasList.find((metaLoja: LojaMetaAtingidaProps) => initialMetaLoja.loja.idLoja === metaLoja.loja.idLoja);

            return (
                !existMetaLoja ||
                existMetaLoja.fgMetaAtingida !== initialMetaLoja.fgMetaAtingida ||
                existMetaLoja.tipoLogisticaLoja?.idTipoLogisticaLoja !== initialMetaLoja.tipoLogisticaLoja?.idTipoLogisticaLoja
            );
        });
    }, [initialMetasList, metasList]);

    const titleTooltipGerarConfiguraces = useMemo(() => {
        let message = null;

        if (hasPercentualChanged || hasMetasChanged) {
            if (!percentualNotaMetaSazonal) {
                message = 'É necessário informar um tipo de percentual de nota fiscal para habilitar a geração de configurações';
            } else {
                message = 'É necessário salvar para habilitar a geração de configurações';
            }
        } else if (initialMetasList && initialMetasList.length === 0) {
            message = 'É necessário informar ao menos uma meta de loja para habilitar a geração de configurações';
        }

        return message;
    }, [hasMetasChanged, hasPercentualChanged, initialMetasList, percentualNotaMetaSazonal]);

    const isStatusDiferrentRevertido = useMemo(
        () => Boolean(statusGeracao && statusGeracao.idStatusGeracao !== StatusGeracaoConfig.REVERTIDO),
        [statusGeracao],
    );

    const isStatusFinalizado = useMemo(
        () =>
            Boolean(
                statusGeracao &&
                    ![StatusGeracaoConfig.FINALIZADO_COM_FALHA, StatusGeracaoConfig.FINALIZADO_COM_SUCESSO].includes(statusGeracao.idStatusGeracao),
            ),
        [statusGeracao],
    );

    const disableBtnGerarConfiguracoes = useMemo(
        () =>
            mutateLoadingReverter ||
            !hasInitialPercentual ||
            hasPercentualChanged ||
            hasMetasChanged ||
            (initialMetasList && initialMetasList.length === 0) ||
            isStatusDiferrentRevertido,
        [hasInitialPercentual, hasMetasChanged, hasPercentualChanged, initialMetasList, isStatusDiferrentRevertido, mutateLoadingReverter],
    );

    return (
        <React.Fragment>
            <Block>
                <SubTitle label="Percentual de Nota Fiscal" />

                <Tooltip title={isStatusDiferrentRevertido ? 'Para alterar o tipo de percentual, reverta a geração de configurações' : ''}>
                    <span>
                        <PercentualNotaMetaSazonalAutoComplete
                            disabled={isStatusDiferrentRevertido}
                            value={percentualNotaMetaSazonal}
                            onChange={(e, value) => setPercentualNotaMetaSazonal(value)}
                        />
                    </span>
                </Tooltip>
            </Block>

            <Block>
                <SubTitle label="Configurações Automáticas" />

                <Stack direction="row" gap={2}>
                    <Tooltip title={titleTooltipGerarConfiguraces || ''}>
                        <span>
                            <LoadingButton
                                sx={{ width: 180 }}
                                variant="outlined"
                                color="success"
                                onClick={onClickGerarConfiguracoes}
                                loading={mutateLoadingGerar}
                                disabled={disableBtnGerarConfiguracoes}
                            >
                                Gerar Configurações
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <LoadingButton
                        variant="contained"
                        color="inherit"
                        onClick={onClickReverterConfiguracoes}
                        loading={mutateLoadingReverter}
                        disabled={mutateLoadingGerar || isStatusFinalizado}
                    >
                        Reverter Geração de Configurações
                    </LoadingButton>
                </Stack>

                {statusGeracao && (
                    <Chip
                        label={statusGeracao.dsStatusGeracao}
                        variant="outlined"
                        size="small"
                        color={statusGeracao.colorStatusGeracao}
                        sx={{ width: 180, mt: 1.5 }}
                    />
                )}
            </Block>
        </React.Fragment>
    );
}
