import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';

export function PagamentoPixSuccess() {
    return (
        <Box
            sx={{
                my: 'auto',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 2,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        filter: 'blur(28px)',
                        borderRadius: '50%',
                        position: 'absolute',
                        backgroundColor: 'rgba(78, 175, 78, 0.2)',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 0,
                    }}
                />

                <CheckCircleOutlineIcon
                    sx={{
                        position: 'relative',
                        color: 'success.light',
                        zIndex: 1,
                        fontSize: 160,
                    }}
                />
            </Box>

            <Typography sx={{ mt: 2, fontSize: 18 }}>Pagamento recebido!</Typography>
        </Box>
    );
}
