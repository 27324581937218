import { useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { useActionMenu } from 'pages/compra/pedido-realizado/contexts';
import { getSuccessMessage, SuccessMessageProps } from 'util/success';

export function PedidoRealizadoDeleteBtn() {
    const { row, disabledActions, setDisabledActions, setOpenConfirmModal, setDataConfirmModal } = useActionMenu();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const Api = useServiceApiContext();
    const { idPedido } = row;

    const handleDelete = () => {
        setDisabledActions(true);
        setIsDeleting(true);

        if (idPedido) {
            Api.delete(`/compra/pedidocompra/${idPedido}`)
                .then((result: SuccessMessageProps) => {
                    const message = getSuccessMessage(result);

                    enqueueSnackbar(message, { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setDisabledActions(false));

                    setIsDeleting(false);
                })
                .catch((error) => {
                    setIsDeleting(false);

                    setDisabledActions(false);

                    showError(error, 'Não foi possível cancelar o pedido. Tente novamente');
                });
        }
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<DeleteOutlinedIcon />}
            loading={isDeleting}
            disabled={disabledActions}
            onClick={() => {
                setOpenConfirmModal(true);

                setDataConfirmModal({ callback: handleDelete });
            }}
        >
            Excluir
        </LoadingButton>
    );
}
