import { GridValidRowModel } from '@mui/x-data-grid-pro';
import * as dateFns from 'date-fns';
import pLimit from 'p-limit';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { DreListProps } from 'pages/financeiro/dre/types';

interface DreListUpdateReturn {
    getDreCalculo: (dreProps: GridValidRowModel) => Promise<DreListProps>;
}

export const useDreGetCalculo = (): DreListUpdateReturn => {
    const Api = useServiceApiContext();

    const limit = pLimit(6); // Limita para 6 solicitações concorrentes

    const getDreCalculo = async (dreProps: GridValidRowModel): Promise<DreListProps> => {
        const dtInicio = dateFns.parseISO(dreProps?.dtInicio);
        const idAno = dtInicio.getUTCFullYear();
        const idMes = dtInicio.getUTCMonth() + 1;
        const { idLoja } = dreProps.loja;

        const url = '/dre/dremercadologica/calcula';
        const { data } = await limit(() => Api.post(url, {}, { params: { idLoja, idAno, idMes } }));

        return data.data;
    };

    return { getDreCalculo };
};
