import { GridColDef } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';
import { PagamentoCartaoCreditoDeleteColumn } from 'pages/compra/pagamento/components/templates/method/cartao-credito/PagamentoCartaoCreditoDeleteColumn';
import { PagamentoMethodAutoComplete } from 'pages/compra/pagamento/components/templates/method/PagamentoMethodAutoComplete';
import { usePagamentoContext } from 'pages/compra/pagamento/contexts/PagamentoContext';
import { useActionsPagamento, useQueryCartoesPagamento } from 'pages/compra/pagamento/hooks';
import { CartaoPagamento } from 'pages/compra/pagamento/types';

/**
 * Retorna as linhas da grid de cartões de pagamento.
 */
const getRows = (cartoesPagamento?: CartaoPagamento[]) => {
    if (Array.isArray(cartoesPagamento)) {
        return cartoesPagamento.map((cartao) => ({
            cartao,
            id: cartao.idCartaoPagamento,
            cdDigitosFinais: cartao.cdDigitosFinais,
            dsApelido: cartao.dsApelido,
            nrAnoValidade: cartao.nrAnoValidade,
            nrMesValidade: cartao.nrMesValidade,
            dsBandeiraCartao: cartao.bandeiraCartao?.dsBandeiraCartao,
            condicaoPagamento: cartao.condicaoPagamento,
        }));
    }

    return [];
};

export function PagamentoCartaoCreditoGrid() {
    const { condicoesPagamentoCartaoCredito, selecaoMeioPagamento, cartoesCredito } = usePagamentoContext();
    const { refetch, isFetching } = useQueryCartoesPagamento();
    const { updateSelectedCondicaoPagamentoInCartaoCredito, onClickRowGrid, getSelectionGrid } = useActionsPagamento();
    const rows = getRows(cartoesCredito);

    const columns: GridColDef[] = [
        { field: 'dsApelido', headerName: 'Nome no cartão', minWidth: 160, flex: 1 },
        { field: 'dsBandeiraCartao', headerName: 'Bandeira', width: 100 },
        { field: 'cdDigitosFinais', headerName: 'Digitos finais', width: 100 },
        {
            field: 'dataValidade',
            headerName: 'Data de validade',
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => `${row.nrMesValidade}/${row.nrAnoValidade}`,
        },
        {
            field: 'parcela',
            headerName: 'Parcelas',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            minWidth: 310,
            sortable: false,
            renderCell: ({ row }) => {
                const isSelectedCartao =
                    selecaoMeioPagamento?.meioPagamentoEnum === 'CARTAO_CREDITO' &&
                    selecaoMeioPagamento.cartaoPagamento?.idCartaoPagamento === row.id;

                return (
                    isSelectedCartao && (
                        <PagamentoMethodAutoComplete
                            sx={{ minWidth: 300 }}
                            value={row.condicaoPagamento}
                            condicoesPagamento={condicoesPagamentoCartaoCredito}
                            onChange={(condicaoPagamento) => updateSelectedCondicaoPagamentoInCartaoCredito(condicaoPagamento as CondicaoPagamento)}
                        />
                    )
                );
            },
        },
        {
            field: 'delete',
            headerName: 'Excluir',
            headerAlign: 'center',
            align: 'center',
            width: 80,
            sortable: false,
            renderCell: ({ row }) => <PagamentoCartaoCreditoDeleteColumn row={row} />,
        },
    ];

    return (
        <PanelGrid
            checkboxSelection
            rows={rows}
            rowsCount={rows.length}
            columns={columns}
            reload={refetch}
            loading={isFetching}
            onClickRow={onClickRowGrid}
            selectionModel={getSelectionGrid()}
            disableSelectionOnClick={false}
            customMessageNoRows="Nenhum cartão de crédito cadastrado."
        />
    );
}
