import { useEffect } from 'react';

export function useVisibilityChangeWindow({ callback }: { callback(): void }) {
    useEffect(() => {
        document.addEventListener('visibilitychange', callback);

        return () => {
            document.removeEventListener('visibilitychange', callback);
        };
    }, [callback]);
}
