import React from 'react';

import { useNavigate } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { LojaListFilter, LojaReport } from 'pages/cadastro/loja/components';
import { Loja } from 'pages/cadastro/loja/types';

interface ResponseData {
    data: Loja[];
    hasNext: boolean;
    total: number;
}

export function LojaListComp(): JSX.Element {
    const { sort, page, filter, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/loja',
        columns: 'idLoja,cdLoja,dsRazaoSocial,dsNomeFantasia,dsCnpj,dsGrupoEconomico,sgEstado,dsCidade,dsTipoLoja,fgAtivo,dsLojaPerfil',
        sort: sort?.length ? sort : [{ property: 'idLoja', direction: 'DESC' }],
        page,
        filter,
        limit,
    };

    const { data, refetch, fetchStatus } = useQuery(
        [requestOptions],
        (): Promise<ResponseData> => RequestListagem(requestOptions).then((res) => res.data),
    );

    const columns: GridColDef[] = [
        { field: 'cdLoja', headerName: 'Código', minWidth: 80 },
        { field: 'dsRazaoSocial', headerName: 'Razão Social', flex: 1, minWidth: 100 },
        { field: 'dsNomeFantasia', headerName: 'Nome Fantasia', flex: 1, width: 100, minWidth: 110 },
        { field: 'dsCnpj', headerName: 'CNPJ', width: 130, minWidth: 120 },
        { field: 'dsGrupoEconomico', headerName: 'Grupo Econômico', width: 130, minWidth: 120 },
        { field: 'sgEstado', headerName: 'Estado', minWidth: 60 },
        { field: 'dsCidade', headerName: 'Cidade', width: 130, minWidth: 80 },
        { field: 'dsTipoLoja', headerName: 'Tipo de Loja', width: 100, minWidth: 90 },
        { field: 'dsLojaPerfil', headerName: 'Perfil da Loja', width: 100, minWidth: 90 },
        { field: 'fgAtivo', headerName: 'Status', width: 80, minWidth: 60, type: 'boolean' },
    ];

    if (hasPermission(['LOJA_ALTERAR']) || hasPermission(['LOJA_EXCLUIR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 80,
            minWidth: 60,
            renderCell: (params) => (
                <EditRemoveBtn
                    id={params.row.idLoja}
                    sx={{ ml: 'auto', p: 1.5 }}
                    showEditBtn={hasPermission(['LOJA_ALTERAR'])}
                    showRemoveBtn={hasPermission(['LOJA_EXCLUIR'])}
                />
            ),
        });
    }

    const gridRows = (rows: Loja[] | undefined): GridRowsProp => {
        if (!rows) return [];
        return rows.map((row, index) => {
            return { ...row, id: index + 1 };
        });
    };

    return (
        <OneColumn
            title="Lojas"
            createButton={hasPermission(['LOJA_CADASTRAR'])}
            extraButtons={[
                {
                    title: 'Gerenciar grupos econômicos',
                    onClick: () => navigate('/grupo-economico'),
                    visible: hasPermission(['GRUPO_ECONOMICO_VISUALIZAR']),
                },
            ]}
        >
            <LojaListFilter />

            <LojaReport />

            <Block>
                <PanelGrid
                    rows={gridRows(data?.data)}
                    rowsCount={data?.total ?? 0}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    visibleRows={10}
                />
            </Block>
        </OneColumn>
    );
}
