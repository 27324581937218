import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useConfigPedidoCondicoesPagamentoContext } from 'pages/compra/configuracao-pedido/contexts/condicoes-pagamento/ConfigPedidoPagamentoContext';
import { ConfigPedidoCondicoesPagamento } from 'pages/compra/configuracao-pedido/types';

export function useQueryConfigPedidoCondicoesPagamento() {
    const { setConfigPedidoCondicoesPagamento } = useConfigPedidoCondicoesPagamentoContext();
    const requestListagem = useRequestListagem();
    const { id } = useParams();

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedidocondicaopagamentowrapper/configpedido/${id}`,
    };

    return useQuery<ConfigPedidoCondicoesPagamento>(
        ['queryConfigPedidoCondicoesPagamento', requestOptions],
        () => requestListagem(requestOptions).then((res) => res.data),
        {
            onSuccess: (data) => setConfigPedidoCondicoesPagamento(data),
            enabled: Boolean(id),
        },
    );
}
