import { useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type RequestConfigProps = {
    idLojaConfiguracao: number;
    configuracaoPropriedade: {
        idConfiguracaoPropriedade: number;
        dsConfiguracao: string;
        dsChave: string;
    };
    dsValor: string;
    idConfiguracaoPropriedade: number;
    idLoja: number;
};

export function useGetCobflexConfig() {
    const requestListagem = useRequestListagem();
    const [searchParams] = useSearchParams();
    const idLojaParam = searchParams.get('idLoja');
    const { fullFilterData } = useListagemContext();
    const filterLoja = fullFilterData?.find((filter) => filter.value.property === 'cdLoja');
    const { idLoja: idLojaFilter } = filterLoja?.value?.value || false;
    const idLoja = idLojaFilter || idLojaParam;

    const configRequestOptions: RequestOptionsType = {
        url: `gestao/lojaconfiguracao/loja/${idLoja}/chave/FG_COBFLEX`,
    };

    return useQuery<RequestConfigProps>(
        ['queryCobflexConfig', configRequestOptions],
        () => requestListagem(configRequestOptions).then((res) => res.data.data),
        {
            enabled: Boolean(idLoja),
            cacheTime: 0,
        },
    );
}
