import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useQueryCondicaoPagamento } from 'pages/complementar/condicao-pagamento/hooks';
import { CondicaoPagamento } from 'pages/complementar/condicao-pagamento/types';

const getRows = (data?: CondicaoPagamento[]): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idCondicaoPagamento,
            dsCondicaoPagamento: item.dsCondicaoPagamento,
            cdCondicaoPagamento: item.cdCondicaoPagamento,
            dsGatewayPagamento: item.gatewayPagamento?.dsGatewayPagamento,
            dsMeioPagamento: item.meioPagamento?.dsMeioPagamento,
            nrParcela: item.nrParcela,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function CondicaoPagamentoGrid() {
    const { refetch, data, isFetching } = useQueryCondicaoPagamento();

    const columns: GridColDef[] = [
        { field: 'dsCondicaoPagamento', headerName: 'Descrição', flex: 1, minWidth: 100, type: 'string' },
        { field: 'cdCondicaoPagamento', headerName: 'Código de integração', width: 160, type: 'string' },
        { field: 'dsGatewayPagamento', headerName: 'Gateway de pagamento', flex: 1, minWidth: 160, type: 'string' },
        { field: 'dsMeioPagamento', headerName: 'Meio de pagamento', flex: 1, minWidth: 160, type: 'string' },
        { field: 'nrParcela', headerName: 'Número de parcelas', width: 160, type: 'number' },
        { field: 'fgAtivo', headerName: 'Ativo', width: 80, type: 'boolean' },

        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: ({ row }) => (
                <EditRemoveBtn
                    id={row.id}
                    showEditBtn={true}
                    showRemoveBtn={true}
                    removeUrl={`/gestao/condicaopagamento/${row.id}`}
                    sx={{ ml: 'auto', p: 1.5 }}
                    refresh={refetch}
                />
            ),
        },
    ];

    return (
        <Block>
            <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={isFetching} columns={columns} />
        </Block>
    );
}
