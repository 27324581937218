import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Typography } from '@mui/material';

export function PagamentoPixFailure() {
    return (
        <Box
            sx={{
                'my': 'auto',
                'display': 'flex',
                'alignItems': 'center',
                'flexDirection': 'column',
                'justifyContent': 'center',
                'gap': 1,
                '.MuiTypography-root': {
                    textAlign: 'justify',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        filter: 'blur(28px)',
                        borderRadius: '50%',
                        position: 'absolute',
                        backgroundColor: 'rgba(240, 86, 84, 0.15)',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 0,
                    }}
                />

                <HighlightOffIcon
                    sx={{
                        position: 'relative',
                        color: 'error.light',
                        zIndex: 1,
                        fontSize: 160,
                    }}
                />
            </Box>

            <Typography>O pagamento do seu pedido foi recusado ou não foi realizado, portanto o pagamento foi cancelado.</Typography>

            <Typography>
                Para realizar um novo pagamento, acessa a tela de <b>Consulta de Pedidos</b>, encontre o pedido com a falha e clique na a opção{' '}
                <b>Alterar pagamento</b>, dentro do menu.
            </Typography>
        </Box>
    );
}
