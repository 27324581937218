import { Dispatch, SetStateAction } from 'react';

import { Alert } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';

type ErroPedidoModal = {
    erro: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function ErroPedidoModal({ erro, open, setOpen }: ErroPedidoModal) {
    return (
        <ContentModal
            closeButton
            open={open}
            setOpen={setOpen}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                width: { xs: '90%', sm: 'auto' },
            }}
        >
            <SubTitle label="Erro" />

            <Alert variant="outlined" severity="error">
                {erro}
            </Alert>
        </ContentModal>
    );
}
